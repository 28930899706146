import { Project } from '@owl-nest/api-client/latest'

import { getAnalyticsTags } from '../analyticsTags'
import { SafePush } from '../safePush'
import { TransactionTracking, TransactionTrackingEvent, TransactionTrackingEventPartial } from './types'
import { EVENT_TYPE } from '../types'

export function tracking(safePush: SafePush, project?: Project<'analytics'>): TransactionTracking {
  const analyticsTags = getAnalyticsTags(project)

  function track(event: TransactionTrackingEventPartial): void {
    if (project === undefined) {
      console.error('[shadow] transaction tracking was not initialized (need project)')
      return
    }

    safePush<TransactionTrackingEvent>({
      ...event,
      event: EVENT_TYPE.TRANSACTION,
      ululeProjectId: project.id,
      transactionCurrencyCode: project.currency ?? '',
      ...(analyticsTags !== undefined && { poTagIDs: analyticsTags }),
    })
  }

  return { track }
}
