import { Project } from '@owl-nest/api-client/latest'
import * as logger from '@owl-nest/logger'

import { getAnalyticsTags } from '../analyticsTags'
import { SafePush } from '../safePush'
import { ProjectTracking, ProjectTrackingEvent, ProjectTrackingEventPartial } from './types'
import { EVENT_TYPE } from '../types'

const triggered = new Set()

export function tracking(
  safePush: SafePush,
  project?: Project<'analytics'>,
  getUserId?: () => number | undefined,
): ProjectTracking {
  const analyticsTags = getAnalyticsTags(project)

  function track({
    eventAction,
    eventLabel = undefined,
    eventCategory,
    eventValue = undefined,
    ...params
  }: ProjectTrackingEventPartial): void {
    if (project === undefined) {
      console.error('[shadow] project tracking was not initialized (need project)')
      return
    }

    if (getUserId === undefined) {
      logger.err('[shadow] project tracking was not initialized (need getUserId)')
      return
    }

    const userId = getUserId()

    safePush<ProjectTrackingEvent>({
      ...params,
      event: EVENT_TYPE.GENERIC,
      ululeProjectId: String(project.id),
      ululeUserId: String(userId),
      eventAction,
      eventLabel,
      eventCategory,
      eventValue,
      ...(analyticsTags !== undefined && { poTagIDs: analyticsTags }),
    })
  }

  return {
    track,
    trackOnce: ({
      eventAction,
      eventLabel = undefined,
      eventCategory,
      eventValue = undefined,
      ...params
    }: ProjectTrackingEventPartial) => {
      if (triggered.has(eventAction)) {
        return
      }
      triggered.add(eventAction)
      track({ eventAction, eventLabel, eventCategory, eventValue, ...params })
    },
  }
}
