import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'
import * as api from '@owl-nest/api-client/latest'
import { t } from '@owl-nest/localize'

import * as UFE from '../UFE'
import { FOOTER_BREAKPOINT } from './constants'

type ToesProps = {
  className?: string
  lang: api.Lang
}

function ToesComponent({ className, lang }: ToesProps) {
  return (
    <div className={className}>
      <LogoLink href={`${UFE.ULULE_ORIGINS[lang]}/`}>
        <plume.logos.ulule.HorizontalLogo />
      </LogoLink>

      <SocialLinks>
        <li>
          <plume.Link
            href={`https://www.facebook.com/${UFE.FACEBOOK_ACCOUNTS[lang] ?? UFE.FACEBOOK_ACCOUNTS.en}`}
            target="_blank"
            title="Facebook"
          >
            <plume.glyphs.thirdParty.Facebook size={14} />
          </plume.Link>
        </li>
        <li>
          <plume.Link href={`https://twitter.com/${UFE.TWITTER_ACCOUNTS[lang]}`} target="_blank" title="Twitter">
            <plume.glyphs.thirdParty.Twitter size={14} />
          </plume.Link>
        </li>
        <li>
          <plume.Link href="https://www.instagram.com/ulule/" target="_blank" title="Instagram">
            <plume.glyphs.thirdParty.Instagram size={14} />
          </plume.Link>
        </li>
        <li>
          <plume.Link href="https://www.linkedin.com/company/ulule" target="_blank" title="LinkedIn">
            <plume.glyphs.thirdParty.Linkedin size={15} />
          </plume.Link>
        </li>
        <li>
          <plume.Link href="https://www.youtube.com/user/UluleChouette" target="_blank" title="YouTube">
            <plume.glyphs.thirdParty.Youtube size={15} />
          </plume.Link>
        </li>
      </SocialLinks>

      <LegalLinks>
        <LinkItem as="li">
          <plume.Link href="https://www.ulule.com/pages/about/terms/">{t('Terms of use')}</plume.Link>
        </LinkItem>
        <LinkItem>
          <plume.Link href="https://www.ulule.com/pages/about/cookie-policy/">{t('Cookies')}</plume.Link>
        </LinkItem>
        <LinkItem>
          <plume.Link href="https://www.ulule.com/pages/about/privacy/">{t('Privacy')}</plume.Link>
        </LinkItem>
      </LegalLinks>
    </div>
  )
}
export const Toes = styled(ToesComponent)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
  padding: 33px 0;
  position: relative;

  @media screen and ${FOOTER_BREAKPOINT} {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0px auto;
    max-width: 900px;
    min-height: 68px;
    padding: 0;
    width: 100%;
  }
`

const LogoLink = styled.a`
  color: ${plume.COLORS.PRIMARY_BLACK};

  &:hover {
    color: ${plume.COLORS.PRIMARY_BLACK};
  }
`

const LinkItem = styled(plume.styles.copy.XS)`
  margin: 0 6px;
  font-weight: 400; // HACK: Irregular font manipulation. We'll be adding copy.S.regular, so this is just by anticipation.

  ${plume.Link} {
    display: block;
    height: fit-content;
    line-height: 0;
  }
`

const SocialLinks = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  list-style: none;
  margin: 25px 0 0;
  padding: 0;
  width: 185px;

  ${plume.glyphs.Icon} {
    color: ${plume.COLORS.PRIMARY_BLACK};
    display: inline-block;
    fill-rule: evenodd;
    vertical-align: baseline;
  }

  ${plume.glyphs.thirdParty.Youtube} {
    position: relative;
    top: 2px;
  }

  @media screen and ${FOOTER_BREAKPOINT} {
    margin: 0 auto;
  }
`

const LegalLinks = styled.ul`
  align-items: center;
  color: ${plume.COLORS.PRIMARY_BLACK_HOVER};
  font-weight: 400; // HACK: Irregular font manipulation
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 25px;

  @media screen and ${FOOTER_BREAKPOINT} {
    margin: 0;
    justify-content: end;
  }
`
