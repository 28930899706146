import * as React from 'react'

import * as api from '@owl-nest/api-client/latest'
import * as auth from '@owl-nest/auth'
import * as models from '@owl-nest/models'
import * as services from '@owl-nest/services'
import * as plume from '@ulule/owl-kit-components/next'
import { MembershipDefaultBanner } from '@ulule/duvet'

import { useUnreadMessageCount } from './useUnreadMessageCount'
import { User } from '../types'
import * as UFE from '../utils/UFE'

type CurrentUserHelpers = {
  loadUser: () => Promise<void>
}

export function useCurrentUser(lang: api.Lang): CurrentUserHelpers & { user: User | undefined } {
  const authData = services.user.useAuth({ extraFields: ['orders', 'projects', 'stats', 'orders.project'] })
  const userData = authData.type === 'loggedin' ? authData.user : undefined

  const [user, setUser] = React.useState(userData ? formatUser(lang, userData) : undefined)
  const { unreadMessageCount } = useUnreadMessageCount()

  React.useEffect(() => {
    setUser((userInState) => {
      if (userInState) {
        return { ...userInState, unreadMessagesCount: unreadMessageCount }
      } else {
        return userInState
      }
    })
  }, [unreadMessageCount])

  React.useEffect(() => {
    if (userData) {
      setUser(formatUser(lang, userData))
    }
  }, [userData])

  return {
    user,
    loadUser,
  }

  async function loadUser(): Promise<void> {
    const response = await auth.currentUser(true, ['orders', 'orders.project', 'projects', 'stats'])
    response.next((user) => {
      setUser((userInState) => {
        const formattedUser = formatUser(lang, user)
        if (formattedUser && userInState) {
          const { urls: _, ...rest } = formattedUser
          return { ...userInState, ...rest }
        }
      })
    })
  }
}

export function formatUser(lang: api.Lang, user: api.AuthenticatedUser): User | undefined {
  const orders = user.orders ?? []
  const projects = user.projects ?? []

  return {
    avatarUrl: models.user.avatar(user, '128x128'),
    contributions: orders.map((order) => ({
      amount: order.order_total,
      date: order.created_at,
      is_free: order.subscription?.reward?.is_free,
      project: {
        absoluteUrl: order.project?.absolute_url,
        currency: order.project?.currency,
        name: order.project ? models.project.name(order.project) : undefined,
        picture: getImage(order.project),
      },
    })),
    createdProjectsCount: user.stats?.proposals_created_count || 0,
    id: user.id,
    isGuest: user.is_guest,
    name: models.user.shortName(user),
    projects: projects.map((project) => ({
      absoluteUrl: project.absolute_url,
      currency: project.currency,
      goal: project.goal,
      isOnline: project.is_online,
      language: project.lang,
      name: models.project.name(project),
      picture: getImage(project),
      productsSoldCount: project.nb_products_sold,
      progress: models.project.progress(project),
      raisedAmount: project.amount_raised,
      status: project.status,
      subtitle: models.project.subtitle(project),
      type: project.type,
    })),
    urls: {
      contributionsUrl: `${UFE.ULULE_ORIGINS[lang]}/users/${user.id}/transactions/`,
      profileUrl: user.absolute_url,
      projectsUrl: `${UFE.ULULE_ORIGINS[lang]}/users/${user.id}/backoffice/projects/`,
      settingsUrl: `${UFE.ULULE_ORIGINS[lang]}/users/${user.id}/settings/`,
    },
  }
}

function getImage(project: api.Project | undefined): string | undefined {
  if (project) {
    const projectImage = models.project.mainImage(project, 'small')

    if (projectImage) {
      return projectImage
    } else if (models.project.isMembership(project)) {
      return plume.utils.svg.toDataUrl(MembershipDefaultBanner, {})
    }
  }

  return undefined
}
