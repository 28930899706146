import * as React from 'react'

import { URLs } from '../../types'

type URLConfiguration = URLs

export const urlContext = React.createContext<URLConfiguration | undefined>(undefined)

export function useURLContext(): URLConfiguration {
  const urlConfiguration = React.useContext(urlContext)

  if (urlConfiguration === undefined) {
    throw Error(`Can't useURLContext outside <URLProvider />`)
  }

  return urlConfiguration
}
