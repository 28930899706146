import * as React from 'react'
import styled from 'styled-components'

import { GoalLabel } from '../../components/GoalLabel'
import * as S from '../../styles'

export type HorizontalProjectCardProps = {
  project: Project
  /** List of currency rates */
  rates: { [s: string]: number }
  /** Currency to use for display. If different from project currency, raised amount will be converted to this */
  targetCurrency: string | undefined
  translation: {
    comingSoon: string
  }
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

/** HorizontalProjectCard is a presentational component for projects. With the shape of a card. It's laid horizontally, too. */
function HorizontalProjectCardComponent({
  project,
  rates,
  targetCurrency,
  translation,
}: HorizontalProjectCardProps): React.ReactElement<HorizontalProjectCardProps> {
  return (
    <S.card.project.HorizontalCardWrapper href={project.absoluteUrl}>
      <Picture url={project.picture} />
      <ContentWrapper>
        <S.card.project.ProjectCardTitle>{project.name}</S.card.project.ProjectCardTitle>
        {project.subtitle && <Subtitle>{project.subtitle}</Subtitle>}
        <S.card.project.DiscreetText light>
          {project.isOnline ? (
            <GoalLabel
              currency={project.currency}
              goal={project.goal}
              language={project.language}
              productsSoldCount={project.productsSoldCount}
              progress={project.progress}
              rates={rates}
              raisedAmount={project.raisedAmount}
              targetCurrency={targetCurrency}
              type={project.type}
            />
          ) : (
            translation['comingSoon']
          )}
        </S.card.project.DiscreetText>
      </ContentWrapper>
    </S.card.project.HorizontalCardWrapper>
  )
}

export const HorizontalProjectCard = styled(HorizontalProjectCardComponent)``
HorizontalProjectCard.displayName = 'HorizontalProjectCard'

type Project = {
  /** URL of the project page on Ulule */
  absoluteUrl: string
  /** Currency of the project */
  currency: string
  /** Minimum amount that the project needs to raise or sell (if pre-sale) */
  goal: number
  /** Whether project has already launched or not. Determines goal display within the component. */
  isOnline: boolean
  /** Language of the project, to pick associated title and description */
  language: string
  /** Name of the project */
  name: string
  /** URL of the main project picture */
  picture: string | undefined
  /** Total of pre-orders sold (only when the project is a pre-sale */
  productsSoldCount: number
  /** Percentage representing goal achievement */
  progress: number
  /** Amount currently raised by the project */
  raisedAmount: number
  // FIXME: Remove 'membership' once MembershipProjectCard is ready
  /** Project type, determines goal display */
  type: 'donation' | 'presale' | 'project' | 'vendor' | 'membership'
  /** Short description of the project */
  subtitle: string | undefined
}

// FIXME: These styles should be moved to be re-used for classic project card?

const ContentWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

import * as colors from '../../constants/colors'

const Picture = styled.div<{ url: string | undefined }>`
  background-color: ${colors.GREY_SHADE_6};
  background-image: ${({ url }) => (url ? `url(${url})` : undefined)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 35px;
  margin: 6px 10px 0 0;
  max-width: 60px;
  width: 100%;
`

const Subtitle = styled.span`
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
