import styled, { css } from 'styled-components'
import * as copy from './copy'
import * as breakpoint from '../constants/breakpoints'
import * as colors from '../constants/colors'
import * as heading from './heading'

export const _titleStyle = css`
  margin: 25px 0 20px;
`

export const Title = styled(heading.M).attrs((props) => ({
  as: 'h1',
  ...props,
}))`
  ${_titleStyle}
`

export const Title2 = styled(heading.S).attrs((props) => ({
  as: 'h2',
  ...props,
}))`
  ${_titleStyle}
`

export const Title3 = styled(heading.XS).attrs((props) => ({
  as: 'h3',
  ...props,
}))`
  ${_titleStyle}
`

export const Title4 = styled(heading.XXS).attrs((props) => ({
  as: 'h4',
  ...props,
}))`
  ${_titleStyle}
`

export const Title5 = styled(heading.XXXS).attrs((props) => ({
  as: 'h5',
  ...props,
}))`
  ${_titleStyle}
`

export const Title6 = styled(heading.XXXXS).attrs((props) => ({
  as: 'h6',
  ...props,
}))`
  ${_titleStyle}
`

export const _paragraphStyle = css`
  ${copy._smallStyle}
`

export const Paragraph = styled.p`
  ${_paragraphStyle}
`

export const _boldStyle = css`
  font-weight: 600;
`

export const Bold = styled.strong`
  ${_boldStyle}
`

export const _italicStyle = css`
  font-style: italic;
`

export const Italic = styled.em`
  ${_italicStyle}
`

export const _underlineStyle = css`
  text-decoration: underline;
`

export const Underline = styled.span`
  ${_underlineStyle}
  font-weight: 300;
  overflow-wrap: break-word;
`

export const _hyperlinkStyle = css`
  color: ${colors.PRIMARY_BLUE};
`

export const Hyperlink = styled.a`
  ${_hyperlinkStyle}
`

export const _listItemStyle = css`
  ${_paragraphStyle}
`

export const ListItem = styled(Paragraph).attrs((props) => ({
  ...props,
  as: 'li',
}))`
  ${_listItemStyle}

  p {
    display: inline;
  }
`

export const _unorderedListStyle = css`
  color: inherit;
  list-style: none;
  margin: 1em 0;
  padding-left: 20px;

  & > li::before {
    content: '•';
    padding-right: 0.5em;
  }
`

export const UnorderedList = styled.ul`
  ${_unorderedListStyle}
`

export const _orderedListStyle = css`
  color: inherit;
  counter-reset: item;
  list-style: none;
  list-style: none;
  margin: 1em 0;
  padding-left: 20px;

  & > li {
    counter-increment: item;
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 2em;
    text-indent: -2em;
  }

  & > li::before {
    content: counter(item) '.';
    display: inline-block;
    font-weight: 500;
    padding-right: 0.5em;
    text-align: right;
    width: 1em;
  }
`

export const OrderedList = styled.ol`
  ${_orderedListStyle}
`

export const _blockQuoteStyle = css`
  ${heading._largeStyle}
  display: inline-flex;
  line-height: 38px;
  margin: 8px 0;

  &::before {
    border-left: 2px ${colors.GREY_SHADE_5} solid;
    content: '';
    padding-right: 26px;
  }
`

export const BlockQuote = styled(heading.L).attrs((props) => ({
  as: 'blockquote',
  ...props,
}))`
  p {
    ${_blockQuoteStyle}
  }
`

// TODO: from marketplace
// export const _claimStyle = css`
//   ${copy._smallStyle}

//   align-items: center;
//   display: flex;
//   font-weight: 600;
//   margin-bottom: 30px;
//   text-align: center;

//   p {
//     ${copy._smallStyle}
//   }

//   &::before {
//     margin-right: 17.5px;
//     content: url("data:image/svg+xml; utf8, <svg width='21' height='16' fill='none' version='1.1' viewBox='0 0 21 16' xmlns='http://www.w3.org/2000/svg'><path d='m5.2568 15.58c-0.96454 0-1.8487-0.2759-2.6525-0.8276-0.80378-0.5843-1.4468-1.3632-1.9291-2.337-0.45012-0.9737-0.67518-2.0448-0.67518-3.2133 0-1.493 0.32151-2.8725 0.96454-4.1383 0.67518-1.2658 1.6076-2.3369 2.7972-3.2133 1.1896-0.87635 2.5721-1.493 4.1475-1.8501l1.1092 3.7975c-0.90024 0.2272-1.704 0.568-2.4114 1.0224-0.67518 0.42195-1.2218 0.94127-1.6397 1.558-0.38582 0.58424-0.57873 1.2172-0.57873 1.8988v0.24343c0.38582-0.25966 0.85201-0.38949 1.3986-0.38949 0.64303 0 1.2218 0.16229 1.7362 0.48687 0.54657 0.32457 0.98062 0.76275 1.3021 1.3145 0.32152 0.55178 0.48227 1.1685 0.48227 1.8501s-0.1929 1.3145-0.57872 1.8988c-0.35367 0.5842-0.83594 1.0548-1.4468 1.4119-0.61088 0.3246-1.2861 0.4868-2.0255 0.4868zm11.14 0c-0.9645 0-1.8487-0.2759-2.6525-0.8276-0.8038-0.5843-1.4468-1.3632-1.9291-2.337-0.4501-0.9737-0.6751-2.0448-0.6751-3.2133 0-1.493 0.3215-2.8725 0.9645-4.1383 0.6752-1.2658 1.6076-2.3369 2.7972-3.2133 1.1896-0.87635 2.5721-1.493 4.1475-1.8501l1.1092 3.7975c-0.9002 0.2272-1.704 0.568-2.4113 1.0224-0.6752 0.42195-1.2218 0.94127-1.6398 1.558-0.3858 0.58424-0.5787 1.2172-0.5787 1.8988v0.24343c0.3858-0.25966 0.852-0.38949 1.3986-0.38949 0.643 0 1.2218 0.16229 1.7362 0.48687 0.5465 0.32457 0.9806 0.76275 1.3021 1.3145s0.4823 1.1685 0.4823 1.8501-0.1929 1.3145-0.5787 1.8988c-0.3537 0.5842-0.836 1.0548-1.4469 1.4119-0.6108 0.3246-1.286 0.4868-2.0255 0.4868z' fill='%23F0A781'/></svg>");
//   }

//   &::after {
//     margin-left: 20px;
//     content: url("data:image/svg+xml; utf8, <svg width='21' height='16' fill='none' version='1.1' viewBox='0 0 21 16' xmlns='http://www.w3.org/2000/svg'><path d='m15.192 0.42036c0.9645 0 1.8487 0.27589 2.6525 0.82767 0.8037 0.58424 1.4468 1.3632 1.929 2.337 0.4502 0.97372 0.6752 2.0448 0.6752 3.2133 0 1.493-0.3215 2.8725-0.9645 4.1383-0.6752 1.2659-1.6076 2.337-2.7972 3.2133-1.1896 0.8764-2.5721 1.4931-4.1475 1.8501l-1.1092-3.7975c0.9002-0.2272 1.704-0.568 2.4113-1.0224 0.6752-0.422 1.2218-0.9413 1.6397-1.558 0.3858-0.58424 0.5788-1.2172 0.5788-1.8988v-0.24343c-0.3859 0.25966-0.8521 0.38949-1.3986 0.38949-0.6431 0-1.2218-0.16229-1.7362-0.48687-0.5466-0.32457-0.9806-0.76275-1.3021-1.3145s-0.4823-1.1685-0.4823-1.8501 0.1929-1.3145 0.5787-1.8988c0.3537-0.58424 0.836-1.0549 1.4468-1.4119 0.6109-0.32458 1.2861-0.48686 2.0256-0.48686zm-11.14 0c0.96454 0 1.8487 0.27589 2.6525 0.82767 0.80378 0.58424 1.4468 1.3632 1.9291 2.337 0.45012 0.97372 0.67518 2.0448 0.67518 3.2133 0 1.493-0.32152 2.8725-0.96454 4.1383-0.67518 1.2659-1.6076 2.337-2.7972 3.2133-1.1896 0.8764-2.5721 1.4931-4.1475 1.8501l-1.1092-3.7975c0.90023-0.2272 1.704-0.568 2.4114-1.0224 0.67518-0.422 1.2218-0.9413 1.6397-1.558 0.38582-0.58424 0.57872-1.2172 0.57872-1.8988v-0.24343c-0.38581 0.25966-0.85201 0.38949-1.3986 0.38949-0.64303 0-1.2218-0.16229-1.7362-0.48687-0.54658-0.32457-0.98062-0.76275-1.3021-1.3145-0.32151-0.55178-0.48227-1.1685-0.48227-1.8501s0.19291-1.3145 0.57873-1.8988c0.35367-0.58424 0.83593-1.0549 1.4468-1.4119 0.61088-0.32458 1.2861-0.48686 2.0255-0.48686z' fill='%23F0A781'/></svg>");
//   }
// `

// export const Claim = styled.blockquote`
//   ${_claimStyle}
// `

// export const _imageStyle = css`
//   max-width: 100%;
// `

// export const Image = styled.img`
//   max-width: 100%;
// `

// export const _captionStyle = css`
//   font-style: italic;
//   font-size: 12px;
//   line-height: 16px;
//   margin-top: 10px;

//   a {
//     text-decoration: none;
//     color: ${colors.PRIMARY_BLUE};

//     &:hover {
//       text-decoration: none;
//     }
//   }
// `

// export const Caption = styled(copy.Base).attrs((props) => ({
//   as: 'figcaption',
//   ...props,
// }))`
//   ${_captionStyle}
// `

// const FloatBase = css<{ $float?: 'left' | 'right' | 'none' }>`
//   ${({ $float = 'none' }) => {
//     switch ($float) {
//       case 'left': {
//         return css`
//           float: ${$float};
//           width: 44%;
//           margin: 4px 34px 0 0;
//         `
//       }
//       case 'right': {
//         return css`
//           float: ${$float};
//           width: 44%;
//           margin: 4px 0 0 34px;
//         `
//       }
//     }
//   }}
// `

// export const Figure = styled.figure<{ $float?: 'left' | 'right' | 'none' }>`
//   ${FloatBase}

//   ${({ $float = 'none' }) => {
//     switch ($float) {
//       case 'none': {
//         return css`
//           width: 100%;
//           margin: 50px 0;
//         `
//       }
//     }
//   }}
// `

// export const OverflowContainer = styled.div`
//   width: 100%;
//   overflow: hidden;
//   border-radius: 12px;
// `

// export const Frame = styled.div`
//   border: 6px solid #fbda65;
//   border-radius: 12px;
//   margin: 50px 0px;
//   padding: 8% 10% 7%;
//   opacity: 80%;

//   @media screen and (${breakpoint.TABLET}) {
//     padding: 9% 17% 7%;
//   }
// `
