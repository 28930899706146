import accounting from 'accounting'

export function baseformatMoney(
  amount: number | string = 0,
  currencySettings: accounting.CurrencySettings<string>,
  isRounded = false,
): string {
  return isRounded
    ? `~\u00A0${accounting.formatMoney(amount, currencySettings)}`
    : accounting.formatMoney(amount, currencySettings)
}

const CURRENCY_CONFIG = {
  USD: {
    symbol: '$',
  },
  CAD: {
    symbol: '$CA',
  },
  AUD: {
    symbol: '$AU',
  },
  NZD: {
    symbol: 'NZD',
  },
  GBP: {
    symbol: '£',
  },
  DKK: {
    symbol: 'kr',
  },
  SEK: {
    symbol: 'kr',
  },
  NOK: {
    symbol: 'kr',
  },
  EUR: {
    symbol: '€',
  },
  CHF: {
    symbol: 'CHF',
  },
}

export function setCurrencies(config: { [trigram: string]: { symbol: string } }): void {
  Object.assign(CURRENCY_CONFIG, config)
}

export const NUMBER_FORMAT_CONFIG = {
  en: {
    decimal: '.',
    format: '%s%v',
    precision: 0,
    thousand: ',',
  },
  fr: {
    decimal: ',',
    format: '%v\u00A0%s',
    precision: 0,
    thousand: '\u00A0',
  },
  it: {
    decimal: ',',
    format: '%v\u00A0%s',
    precision: 0,
    thousand: '.',
  },
  nl: {
    decimal: ',',
    format: '%s\u00A0%v',
    precision: 0,
    thousand: '.',
  },
  de: {
    decimal: ',',
    format: '%v\u00A0%s',
    precision: 0,
    thousand: '.',
  },
  es: {
    decimal: ',',
    format: '%v\u00A0%s',
    precision: 0,
    thousand: '.',
  },
  ca: {
    decimal: ',',
    format: '%v\u00A0%s',
    precision: 0,
    thousand: '.',
  },
  pt: {
    decimal: ',',
    format: '%s%v',
    precision: 0,
    thousand: '.',
  },
}

type LocaleFormatMoney = {
  currency: string
  lang: string
}

export function localeCurrencySettings({ currency, lang }: LocaleFormatMoney): accounting.CurrencySettings<string> {
  const currencyConfig = CURRENCY_CONFIG[currency as keyof typeof CURRENCY_CONFIG] ?? {}
  const numberFormatConfig = NUMBER_FORMAT_CONFIG[lang as keyof typeof NUMBER_FORMAT_CONFIG] ?? {}

  return {
    ...currencyConfig,
    ...numberFormatConfig,
  }
}

type ConvertMoneySettings = {
  from: string
  to: string
  rates: Record<string, number>
}

export function convertMoney(amount = 0, { from, to, rates }: ConvertMoneySettings): number {
  if (from === to) return amount

  const inBaseCurrency = amount / rates[from]
  const convertedAmount = inBaseCurrency * rates[to]

  return convertedAmount
}

type LocaleMoneySettings = {
  precision?: number
} & {
  targetCurrency?: ConvertMoneySettings['to']
  rates?: ConvertMoneySettings['rates']
} & LocaleFormatMoney

export function localeMoney(amount = 0, settings: LocaleMoneySettings, includesRoundedSymbol = false): string {
  return baseformatMoney(
    settings.targetCurrency && settings.rates
      ? convertMoney(amount, {
          from: settings.currency,
          to: settings.targetCurrency ?? settings.currency,
          rates: settings.rates,
        })
      : amount,
    {
      ...localeCurrencySettings({
        currency: settings.targetCurrency && settings.rates ? settings.targetCurrency : settings.currency,
        lang: settings.lang,
      }),
      precision: settings.precision || 0,
    },
    Boolean(includesRoundedSymbol && settings.targetCurrency && settings.currency !== settings.targetCurrency),
  )
}

export const formatNumber = accounting.formatNumber
export const settings = accounting.settings
