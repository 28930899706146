import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import { t } from '@owl-nest/localize'
import { Lang } from '@owl-nest/api-client/latest'
import * as hooks from '@owl-nest/hooks'
import { MetaSettingDropdown } from '@owl-nest/metasettings'

import { AccordionSection } from './AccordionSection'
import * as UFE from '../UFE'
import { NavSection } from '../baseConfig'
import { FOOTER_BREAKPOINT } from './constants'

type BodyProps = {
  className?: string
  controlledCurrency?: string
  hasCurrencyAndLang: boolean
  lang: Lang
  navSections: NavSection[]
  withRedirectParam?: boolean
}

function BodyComponent({
  className,
  controlledCurrency,
  lang,
  navSections,
  hasCurrencyAndLang,
  withRedirectParam,
}: BodyProps): React.ReactElement<BodyProps> {
  const settings = hooks.useUserConfig(UFE.ULULE_ORIGINS[lang])

  const languages = UFE.ALLOWED_LANGUAGES
  const currentLanguage = settings.data?.lang
  const currencies = UFE.ALLOWED_CURRENCIES
  const currentCurrency = controlledCurrency ?? settings.data?.currency

  return (
    <div className={className}>
      {hasCurrencyAndLang && (
        <LangAndCurrencyWrapper>
          <plume.styles.copy.S>{t('Language and site currency')}</plume.styles.copy.S>
          {currentCurrency && currentLanguage && (
            <MetaSettingDropdown
              languages={languages}
              currentLanguage={currentLanguage}
              currencies={currencies}
              currentCurrency={currentCurrency}
              withRedirectParam={withRedirectParam}
            />
          )}
        </LangAndCurrencyWrapper>
      )}
      <Sections>
        {navSections.map((navConfig, i) => {
          return <Section key={i} navConfig={navConfig} lang={lang} />
        })}
      </Sections>
    </div>
  )
}

type SectionProps = {
  lang: Lang
  navConfig: NavSection
}

function Section({ navConfig, lang }: SectionProps) {
  return (
    <LinkColumn title={navConfig.href ? <a href={navConfig.href}>{navConfig.title}</a> : navConfig.title}>
      <LinkList>
        {navConfig.items
          .filter((navConfig) => {
            if (navConfig.language) {
              return lang === navConfig.language
            }
            return true
          })
          .map((item, i) => {
            return (
              <LinkItem key={i} as="li">
                <plume.Link href={item.href} target={`${item.openInNewTab ? '_blank' : '_self'}`}>
                  {item.label}
                </plume.Link>
              </LinkItem>
            )
          })}
      </LinkList>
    </LinkColumn>
  )
}

const LangAndCurrencyWrapper = styled.div`
  align-items: end;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 29px;

  ${plume.styles.copy.S} {
    /* HACK: Irregular font manipulation. We'll be adding copy.S.semiBold, so this is just by anticipation. */
    color: ${plume.COLORS.GREY_SHADE_2};
    font-weight: 600;
    margin-bottom: 16px;
    margin-right: 18px;
    min-width: 100%;
  }

  ${plume.Dropdown} {
    margin-right: 16px;
  }

  @media screen and ${FOOTER_BREAKPOINT} {
    display: flex;
    flex-wrap: nowrap;

    ${plume.styles.copy.S} {
      margin-bottom: 0px;
      min-width: auto;
    }
  }
`

const LinkItem = styled(plume.styles.copy.S)`
  margin-bottom: 16px;

  ${plume.Link} {
    font-weight: 400; //HACK: irregular font manipulation
  }

  &:first-child {
    margin-top: 16px;
  }

  @media screen and ${FOOTER_BREAKPOINT} {
    margin-left: 0px;
    margin-bottom: 8px;
  }
`

const LinkList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const LinkColumn = styled(AccordionSection)`
  margin-bottom: 15px;

  @media screen and ${FOOTER_BREAKPOINT} {
    margin-bottom: 0px;
  }
`

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and ${FOOTER_BREAKPOINT} {
    flex-direction: row;
  }
`

export const Body = styled(BodyComponent)<{ lang: Lang }>`
  margin: 0 auto;
  max-width: 450px;
  padding: 33px 20px 0;

  @media screen and ${FOOTER_BREAKPOINT} {
    max-width: ${({ lang }) => (lang === 'fr' ? '900px' : '700px')};
    padding: 30px 0 20px 0;
  }
`
