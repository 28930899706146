import * as React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import { transition, useTransition } from '../behaviours/transition'
import * as glyphs from '../icons/glyphs/index'
import * as S from '../styles'
import { ButtonProps, Button } from './button/Button'
import { LinkAsButtonProps, LinkAsButton } from './button/LinkAsButton'
import { ButtonAsLink, ButtonAsLinkProps } from './button/ButtonAsLink'
import { LinkProps, Link } from './Link'

type Button =
  | { type: 'button'; props: ButtonProps }
  | { type: 'link'; props: LinkProps }
  | { type: 'linkAsButton'; props: LinkAsButtonProps }
  | { type: 'buttonAsLink'; props: ButtonAsLinkProps }

export type ModalButtons = Button | [Button] | [Button, Button]

type Transition = 'from-bottom' | 'from-right'

export type ModalProps = {
  buttons?: ModalButtons
  children: React.ReactNode
  className?: string
  closable?: boolean
  lockScroll?: boolean
  transition?: Transition
  onClose?: () => void
  open: boolean
  forceInitMount?: boolean
}

export function ModalComponent({
  buttons,
  children,
  className,
  closable = false,
  lockScroll = false,
  transition = 'from-bottom',
  onClose,
  open,
  forceInitMount = false,
}: ModalProps): React.ReactElement<ModalProps> | null {
  const modalRef = React.useRef<HTMLDivElement>(null)

  function handleOverlayClick(event: React.MouseEvent<HTMLDivElement>): void {
    if (modalRef.current === null) {
      return
    }
    if (closable && onClose && !modalRef.current.contains(event.target as Node)) {
      onClose()
    }
  }

  const { step, unmounted, onTransitionEnd } = useTransition(open)

  // NOTE: forceInitMount is used to skip the mount animation, but !open keeps the unmount animation
  if (unmounted && (forceInitMount === false || !open)) {
    return null
  }

  return (
    <S.modal.Overlay
      step={!forceInitMount || !open ? step : 'during'}
      className={className}
      onClick={handleOverlayClick}
    >
      {lockScroll && <DisableBodyScroll />}
      <Wrapper transition={transition} step={step} onTransitionEnd={onTransitionEnd} ref={modalRef}>
        {closable && <glyphs.stroke.Close size={14} onClick={() => onClose?.()} />}
        <S.modal.Body>{children}</S.modal.Body>
        {buttons !== undefined && (
          <S.modal.Actions>
            {Array.isArray(buttons) ? (
              buttons.map((button, index) => {
                return <Action button={button} key={index} />
              })
            ) : (
              <Action button={buttons} />
            )}
          </S.modal.Actions>
        )}
      </Wrapper>
    </S.modal.Overlay>
  )
}

export const Modal = styled(ModalComponent)<ModalProps>``
Modal.displayName = 'ModalWithButtons'

type ActionProps = { button: Button }

function Action({ button }: ActionProps): React.ReactElement {
  switch (button.type) {
    case 'button':
      return <Button type={button.type} {...button.props} />

    case 'buttonAsLink':
      return <ButtonAsLink {...button.props} />

    case 'linkAsButton':
      return <LinkAsButton {...button.props} />

    case 'link':
      return <Link {...button.props} />

    default:
      return <></>
  }
}

const DisableBodyScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

type WrapperProps = {
  transition: Transition
  step: 'before' | 'during' | 'after'
  onTransitionEnd: React.TransitionEventHandler<HTMLDivElement>
  children: React.ReactNode
}

const Wrapper = React.forwardRef<HTMLDivElement, WrapperProps>(function Wrapper(
  { transition, children, onTransitionEnd, step },
  ref,
) {
  switch (transition) {
    case 'from-bottom':
      return (
        <TransitionFromBottom onTransitionEnd={onTransitionEnd} step={step} ref={ref}>
          {children}
        </TransitionFromBottom>
      )
    case 'from-right':
      return (
        <TransitionFromRight onTransitionEnd={onTransitionEnd} step={step} ref={ref}>
          {children}
        </TransitionFromRight>
      )
  }
})

const TransitionFromBottom = transition.fromBottom(S.modal.Wrapper)

const TransitionFromRight = transition.fromRight(S.modal.Wrapper)
