import * as React from 'react'
import styled from 'styled-components'

import * as api from '@owl-nest/api-client/latest'
import { QueryStatus } from '@owl-nest/hooks'
import { t } from '@owl-nest/localize'
import * as plume from '@ulule/owl-kit-components/next'

import { useConfigurationContext } from '../context/configuration/configurationContext'
import { useUserDrawerContext } from '../context/userDrawer/userDrawerContext'
import { useCategoriesService } from '../hooks/useCategoriesService'
import { useChannelsService } from '../hooks/useChannelsService'

import { ArrowedLink } from '../components/ArrowedLink'
import { Channel, ChannelList } from '../components/Channel'
import { Dropdown } from '../components/Dropdown'
import { SearchDrawer } from '../components/drawers/SearchDrawer'

import * as S from '../components/style'
import * as style from './style'

type CrowdfundingSubnavProps = {
  className?: string
  lang: api.Lang
}

const CHANNEL_LIMIT = 6

function CrowdfundingSubnavComponent({ lang, className }: CrowdfundingSubnavProps) {
  const categoriesService = useCategoriesService()
  const channelsService = useChannelsService()
  const { translations } = useConfigurationContext()
  // TODO: Replace with proper context
  const { urls } = useUserDrawerContext()

  const [channelsDropdownOpen, setChannelsDropdownAsOpen] = React.useState(false)
  const [categoriesDropdownOpen, setCategoriesDropdownAsOpen] = React.useState(false)

  const isDiscoverPage = React.useMemo(() => document.location.pathname.startsWith('/discover'), [])

  React.useEffect(() => {
    if (channelsDropdownOpen && channelsService.response.status === QueryStatus.PRISTINE) {
      channelsService.query()
    }

    if (categoriesDropdownOpen && categoriesService.response.status === QueryStatus.PRISTINE) {
      categoriesService.query()
    }
  }, [channelsDropdownOpen, categoriesDropdownOpen])

  return (
    <CrowdfundingNavWrapper className={className}>
      <style.SubNavItemList>
        <MenuItem>
          <S.LinkItem $active={isDiscoverPage} href={urls.discover}>
            {/* @ts-expect-error `translations` children are unknown, we should type properly type them. */}
            {translations.navigationItems.seeAllProjects}
          </S.LinkItem>
        </MenuItem>
        <CategoriesDropdown
          open={categoriesDropdownOpen}
          setAsOpen={(open) => {
            setCategoriesDropdownAsOpen(open)
          }}
          title={t('Categories')}
          withCaret
        >
          {ItemsLineDropdown()}
        </CategoriesDropdown>
        <ChannelsDropdown
          open={channelsDropdownOpen}
          setAsOpen={(open) => {
            setChannelsDropdownAsOpen(open)
          }}
          title={t('Channels')}
          withCaret
        >
          {channelsService.response.status === QueryStatus.SUCCESS ? (
            <>
              <plume.styles.heading.XXXXXS>{t('Staff picks')}</plume.styles.heading.XXXXXS>
              <ChannelList>
                <>
                  {channelsService.response.data.channels.map((channel, index) => {
                    if (index < CHANNEL_LIMIT) {
                      return <Channel key={channel.absolute_url} channel={channel} />
                    }
                  })}
                </>
              </ChannelList>
              {/* @ts-expect-error `translations` children are unknown, we should type properly type them. */}
              <ArrowedLink href={urls.channels}>{translations.searchDrawer.exploreChannels}</ArrowedLink>
            </>
          ) : (
            <plume.Spinner small />
          )}
        </ChannelsDropdown>
      </style.SubNavItemList>
    </CrowdfundingNavWrapper>
  )

  function ItemsLineDropdown() {
    return (
      <>
        {categoriesService.response.status === QueryStatus.SUCCESS ? (
          <ItemsLineDropdownWrapper>
            {categoriesService.response.data &&
              categoriesService.response.data.categories.map((category, index) => (
                <li key={index}>
                  <plume.Link href={category.absolute_url}>
                    <plume.styles.copy.S>{category.name[lang]}</plume.styles.copy.S>
                    <plume.glyphs.stroke.ArrowRight size={16} />
                  </plume.Link>
                </li>
              ))}
          </ItemsLineDropdownWrapper>
        ) : (
          <>{categoriesService.response.status && <plume.Spinner small />}</>
        )}
      </>
    )
  }
}

export const CrowdfundingSubnav = styled(CrowdfundingSubnavComponent)``

const CategoriesDropdown = styled(Dropdown)`
  & > ul {
    padding: 24px 8px;
  }
`

const ChannelsDropdown = styled(Dropdown)`
  ${ChannelList} {
    margin: 16px 0;
  }

  ${ArrowedLink} {
    ${plume.glyphs.Icon} {
      position: relative;
      top: 1px;
    }
  }
`

const CrowdfundingNavWrapper = styled(style.SubnavWrapper)`
  align-items: center;
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  box-shadow: ${plume.EFFECTS.SHADOW_4};
  display: flex;
  justify-content: space-between;
  position: relative;

  ${Dropdown} {
    height: 100%;
    margin-left: 20px;
  }

  ${SearchDrawer} {
    bottom: initial;
    left: initial;
    position: absolute;
    right: 0;
    top: 0;
  }
`

const ItemsLineDropdownWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  *:last-child {
    margin-bottom: 0;
  }

  li {
    margin-bottom: 16px;
    position: relative;
    padding-right: 50px;

    ${plume.glyphs.stroke.ArrowRight} {
      right: 20px;
      position: absolute;
      transition-duration: 300ms;
      transition-property: opacity, right;
      transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
      opacity: 0;
    }

    ${plume.Link} {
      align-items: center;
      display: flex;
    }

    ${plume.styles.copy.S} {
      margin-left: 16px;
    }

    &:hover {
      ${plume.styles.copy.S} {
        color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
      }

      ${plume.glyphs.stroke.ArrowRight} {
        color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
        right: 10px;
        opacity: 1;
      }
    }
  }
`

const MenuItem = styled.li`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-left: 20px;
`
