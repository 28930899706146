import { searchParams } from '@owl-nest/utils'

export function getUrlWithUTMs(baseUrl: string, currentSite: string, targetSite: string, campaign: string): string {
  return searchParams.addSearchParamsToUrl(baseUrl, getUTMs(currentSite, targetSite, campaign))
}

function getUTMs(
  currentSite: string,
  targetSite: string,
  campaign: string,
):
  | {
      ref: string
    }
  | {
      utm_source: string
      utm_medium: string
      utm_campaign: string
    } {
  if (currentSite === targetSite) {
    return { ref: 'header' }
  }
  
  return { utm_source: currentSite, utm_medium: 'header', utm_campaign: campaign }
}
