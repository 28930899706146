import styled from 'styled-components'

import * as colors from '../constants/colors'

export const Label = styled.label`
  background: ${colors.PRIMARY_GREY_000};
  border-radius: 4px;
  border: 1px dashed ${colors.PRIMARY_SAND_200};
  cursor: pointer;
  display: block;
  padding: 16px;
  width: 100%;
  transition: 0.3s;

  &:hover {
    border: 1px solid ${({ theme }) => theme?.colors?.primary ?? colors.PRIMARY_BLUE_700};
  }
`
