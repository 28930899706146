import { OAuthOptions, oauthRequest, OAuthRequestOptions } from './oauthRequester.ts'
import { HttpResponse } from './requester.ts'
import { UrlParameters } from './UrlParameters.ts'

type Transput<REQUEST_BODY = any, RESPONSE_BODY = any> = {
  output: RESPONSE_BODY
  input: REQUEST_BODY
}

type CompleteTransput<PARTIAL extends Partial<Transput>> = {
  output: PARTIAL extends { output: unknown } ? PARTIAL['output'] : void
  input: PARTIAL extends { input: unknown } ? PARTIAL['input'] : void
}

type ClientOptions = {
  version: string
  baseUrl: string
} & Partial<OAuthOptions>

export type EndpointOptions = Pick<OAuthRequestOptions, 'type' | 'withToken'>

type Simplify<TYPE> = { [K in keyof TYPE]: TYPE[K] }

type RequestOptionBody<TRANSPUT extends Transput> = TRANSPUT['input'] extends void ? {} : { body: TRANSPUT['input'] }

type RequestOptionUrlParameters<URL extends string> = UrlParameters<URL>['length'] extends 0
  ? {}
  : {
      urlParams: Record<UrlParameters<URL>[number], string>
    }

type RequestOptions<URL extends string, TRANSPUT extends Transput> = Simplify<
  Omit<OAuthRequestOptions, 'body'> & {
    getParams?: Record<string, string> | string | URLSearchParams
  } & RequestOptionBody<TRANSPUT> &
    RequestOptionUrlParameters<URL>
>

type RelaxedRequestOptions = Omit<OAuthRequestOptions, 'body'> & {
  version?: string
  body?: any
  getParams?: Record<string, string> | string | URLSearchParams
  urlParams?: Record<string, string>
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

type Endpoint<IDENTIFIER extends `${HttpMethod} ${string}`> = {
  identifier: IDENTIFIER
  options: EndpointOptions
}

export type TypedEndpoint<
  IDENTIFIER extends `${HttpMethod} ${string}`,
  TRANSPUT extends Transput,
> = Endpoint<IDENTIFIER> & {
  $$brand$$transput: TRANSPUT
}

type DeprecatedTypedEndpoint<IDENTIFIER extends `${HttpMethod} ${string}`, TRANSPUT extends Transput> = TypedEndpoint<
  IDENTIFIER,
  TRANSPUT
> & {
  deprecated: true
}

export function endpoint<IDENTIFIER extends `${HttpMethod} ${string}`>(
  identifier: IDENTIFIER,
): {
  config<PARTIAL_TRANSPUT extends Partial<Transput> = Record<string, never>>(
    options: EndpointOptions,
  ): TypedEndpoint<IDENTIFIER, CompleteTransput<PARTIAL_TRANSPUT>>
  deprecated<PARTIAL_TRANSPUT extends Partial<Transput>>(
    options: EndpointOptions,
  ): DeprecatedTypedEndpoint<IDENTIFIER, CompleteTransput<PARTIAL_TRANSPUT>>
} {
  return { config, deprecated }

  function deprecated<PARTIAL_TRANSPUT extends Partial<Transput>>(
    options: EndpointOptions,
  ): DeprecatedTypedEndpoint<IDENTIFIER, CompleteTransput<PARTIAL_TRANSPUT>> {
    return { identifier, options, deprecated: true } as DeprecatedTypedEndpoint<
      IDENTIFIER,
      CompleteTransput<PARTIAL_TRANSPUT>
    >
  }

  function config<PARTIAL_TRANSPUT extends Partial<Transput>>(
    options: EndpointOptions,
  ): TypedEndpoint<IDENTIFIER, CompleteTransput<PARTIAL_TRANSPUT>> {
    return { identifier, options } as TypedEndpoint<IDENTIFIER, CompleteTransput<PARTIAL_TRANSPUT>>
  }
}

export type EndpointDict<ENDPOINTS extends TypedEndpoint<any, any>[]> = {
  [IDENTIFIER in ENDPOINTS[number]['identifier']]: Extract<ENDPOINTS[number], TypedEndpoint<IDENTIFIER, any>>
}

export interface Client<
  ENDPOINTS extends TypedEndpoint<any, any>[],
  DEPRECATED_ENDPOINTS extends TypedEndpoint<any, any>[],
> {
  <IDENTIFIER extends ENDPOINTS[number]['identifier']>(
    identifier: IDENTIFIER,
    requestOptions: RequestOptions<
      IDENTIFIER extends `${HttpMethod} ${infer URL}` ? URL : '',
      EndpointDict<ENDPOINTS>[IDENTIFIER]['$$brand$$transput']
    >,
  ): EndpointDict<ENDPOINTS>[IDENTIFIER] extends TypedEndpoint<IDENTIFIER, infer TRANSPUT>
    ? Promise<HttpResponse<TRANSPUT['output']>>
    : never

  /** @deprecated */
  deprecated<IDENTIFIER extends DEPRECATED_ENDPOINTS[number]['identifier']>(
    /** @deprecated */
    identifier: IDENTIFIER,
    requestOptions: RequestOptions<
      IDENTIFIER extends `${HttpMethod} ${infer URL}` ? URL : '',
      EndpointDict<DEPRECATED_ENDPOINTS>[IDENTIFIER]['$$brand$$transput']
    >,
  ): EndpointDict<DEPRECATED_ENDPOINTS>[IDENTIFIER] extends TypedEndpoint<IDENTIFIER, infer TRANSPUT>
    ? Promise<HttpResponse<TRANSPUT['output']>>
    : never

  _generic: <BODY>(identifier: string, requestOptions: RequestOptions<any, any>) => Promise<HttpResponse<BODY>>
  _endpoints: [...ENDPOINTS, ...DEPRECATED_ENDPOINTS]
}

export function createClient<
  ENDPOINTS extends TypedEndpoint<any, any>[],
  DEPRECATED_ENDPOINTS extends TypedEndpoint<any, any>[],
>(config: {
  endpoints: ENDPOINTS
  deprecated: DEPRECATED_ENDPOINTS
}): (options: ClientOptions) => Client<ENDPOINTS, DEPRECATED_ENDPOINTS> {
  return (clientOptions) => {
    const client = makeGenericClient(clientOptions, config.endpoints, true) as Client<ENDPOINTS, DEPRECATED_ENDPOINTS>
    client.deprecated = makeGenericClient(clientOptions, config.endpoints, true) as Client<
      ENDPOINTS,
      DEPRECATED_ENDPOINTS
    >

    client._generic = makeGenericClient(clientOptions, config.endpoints, false)

    client._endpoints = [...config.endpoints, ...config.deprecated]

    return client
  }
}

export function makeGenericClient(clientOptions: ClientOptions, endpoints: TypedEndpoint<any, any>[], strict: boolean) {
  return <BODY>(identifier: string, _requestOptions: RequestOptions<any, any>) => {
    const requestOptions: RelaxedRequestOptions = { ..._requestOptions }

    let endpoint = endpoints.find((endpoint) => endpoint.identifier === identifier)
    if (endpoint === undefined) {
      if (strict) {
        throw Error('unknown endpoint')
      } else {
        endpoint = { identifier, options: {} } as TypedEndpoint<any, any>
      }
    }

    const method = endpoint.identifier.slice(0, endpoint.identifier.indexOf(' '))
    let path = endpoint.identifier.slice(endpoint.identifier.indexOf(' ') + 1)
    if (path.startsWith('/')) {
      path = path.slice(1)
    }

    const baseUrl = clientOptions.baseUrl.endsWith('/') ? clientOptions.baseUrl : `${clientOptions.baseUrl}/`

    const url = new URL(path, baseUrl)

    if (requestOptions.getParams) {
      url.search = new URLSearchParams(requestOptions.getParams).toString()
    }
    delete requestOptions.getParams

    if (requestOptions.urlParams) {
      for (const [name, value] of Object.entries(requestOptions.urlParams)) {
        url.pathname = url.pathname.replace(new RegExp(`\\:${name}`, 'g'), String(value))
      }
      delete requestOptions.urlParams
    }

    const headers = new Headers(requestOptions.headers)
    headers.set('Ulule-Version', requestOptions.version ?? clientOptions.version)

    return oauthRequest<BODY>(url.toString(), {
      ...requestOptions,
      method,
      headers,
      getAccessToken: requestOptions.getAccessToken ?? clientOptions.getAccessToken,
      getRefreshToken: requestOptions.getRefreshToken ?? clientOptions.getRefreshToken,
      persistAccessToken: requestOptions.persistAccessToken ?? clientOptions.persistAccessToken,
      refreshAccessToken: requestOptions.refreshAccessToken ?? clientOptions.refreshAccessToken,
      // checking key presence here (instead of just ?? fallback) to align with
      // legacy client-api. If a `withToken` or a `type` is passed during the
      // request, even if the value is undefined, it should override the
      // endpoint config
      withToken: 'withToken' in requestOptions ? requestOptions.withToken : endpoint.options.withToken,
      type: ('type' in requestOptions ? requestOptions.type : endpoint.options.type) as any,
    })
  }
}
