import * as jotai from 'jotai'
import * as React from 'react'

import { ApiResponse } from '@owl-nest/api-client'
import { Response, QueryStatus } from './useQuery'

export type Cache<VALUE> = Record<string, Response<VALUE> | undefined>

export type SharedQueryHelper<TYPE, PARAMS extends any[]> = {
  cache: Cache<TYPE>
  getCurrentResponse(params: PARAMS): Response<TYPE>
  mutate(params: PARAMS, data: (value: Response<TYPE>) => Response<TYPE>): void
  mutate(params: PARAMS, data: Response<TYPE>): void
  patch(
    params: PARAMS,
    patch: (response: Response<TYPE>) => Promise<ApiResponse<TYPE> | undefined>,
  ): Promise<ApiResponse<TYPE> | undefined>
  remove(
    params: PARAMS,
    remove: (response: Response<TYPE>) => Promise<ApiResponse<unknown>>,
  ): Promise<ApiResponse<unknown>>
  query: (...params: PARAMS) => Promise<void>
}

type SharedQueryConfig<TYPE, PARAMS extends any[] = []> = {
  atom: jotai.PrimitiveAtom<Cache<TYPE>>
  id: (...params: PARAMS) => string
}

export function useSharedQuery<TYPE, PARAMS extends any[] = []>(
  _query: (...params: PARAMS) => Promise<ApiResponse<TYPE>>,
  config: SharedQueryConfig<TYPE, PARAMS>,
): SharedQueryHelper<TYPE, PARAMS> {
  const store = jotai.useStore()
  const [cache, setCache] = jotai.useAtom(config.atom)

  return { cache, query, mutate, patch, remove, getCurrentResponse }

  async function remove(
    params: PARAMS,
    remove: (response: Response<TYPE>) => Promise<ApiResponse<unknown>>,
  ): Promise<ApiResponse<unknown>> {
    const currentResponse = getCurrentResponse(params)

    mutate(params, (response) => {
      return { data: response.data, status: QueryStatus.PENDING }
    })

    const response = await remove(currentResponse)

    response.caseOf({
      left: (failure) => {
        mutate(params, (response) => {
          return { data: response.data, status: QueryStatus.FAILURE, failure }
        })
      },
      right: () => {
        mutate(params, () => {
          return { status: QueryStatus.PRISTINE }
        })
      },
    })

    return response
  }

  async function patch(
    params: PARAMS,
    patch: (response: Response<TYPE>) => Promise<ApiResponse<TYPE> | undefined>,
  ): Promise<ApiResponse<TYPE> | undefined> {
    const currentResponse = getCurrentResponse(params)

    mutate(params, (response) => {
      return { data: response.data, status: QueryStatus.PENDING }
    })

    const response = await patch(currentResponse)

    if (response === undefined) {
      mutate(params, (response) => {
        if (response.data === undefined) {
          return { status: QueryStatus.PRISTINE }
        }
        return { data: response.data, status: QueryStatus.SUCCESS }
      })
      return undefined
    } else {
      response.caseOf({
        left: (failure) => {
          mutate(params, (response) => {
            return { data: response.data, status: QueryStatus.FAILURE, failure }
          })
        },
        right: (success) => {
          mutate(params, () => {
            return { data: success, status: QueryStatus.SUCCESS }
          })
        },
      })
      return response
    }
  }

  async function query(...params: PARAMS): Promise<void> {
    await patch(params, () => _query(...params))
  }

  function mutate(params: PARAMS, value: Response<TYPE> | ((value: Response<TYPE>) => Response<TYPE>)): void {
    const key = config.id(...params)

    setCache((cache) => {
      if (typeof value === 'function') {
        const response = getCurrentResponse(params)
        const nextResponse = (value as any)(response) as Response<TYPE>

        if (nextResponse === undefined || nextResponse === response) {
          return cache
        }

        return {
          ...cache,
          [key]: nextResponse,
        }
      }

      return {
        ...cache,
        [key]: value,
      }
    })
  }

  function getCurrentResponse(params: PARAMS): Response<TYPE> {
    const response = store.get(config.atom)[config.id(...params)]

    if (response === undefined) {
      return { status: QueryStatus.PRISTINE }
    }

    return response
  }
}

type FetchOnMountConfig = {
  fetchOnMount: 'force' | boolean
  query: () => void
  getResponse: () => Response<unknown>
}

export function useFetchOnMount({ fetchOnMount, query, getResponse }: FetchOnMountConfig, id: string) {
  const isResponseStale = getResponse().stale ?? false

  React.useEffect(() => {
    if (fetchOnMount === 'force' && getResponse().status !== QueryStatus.PENDING) {
      query()
    } else if (fetchOnMount && getResponse().status === QueryStatus.PRISTINE) {
      query()
    }
  }, [id, isResponseStale])
}
