import * as logger from '@owl-nest/logger'
import { Either } from '@owl-nest/monad'

import { HttpResult, HttpResponse, request, RequestOptions, RetryOptions } from './requester.ts'

export type OAuthOptions = {
  getRefreshToken: () => string | undefined
  persistAccessToken: (token: string) => void
  getAccessToken: () => string | undefined
  refreshAccessToken: (refreshToken: string) => Promise<HttpResult<string>>
}

export type OAuthRequestOptions = RequestOptions & {
  withToken?: boolean
  token?: string
} & Partial<OAuthOptions>

export async function oauthRequest<BODY = unknown>(
  url: string,
  options: OAuthRequestOptions = {},
  retryOptions?: RetryOptions,
): Promise<HttpResponse<BODY>> {
  if (!options.withToken && !options.token) {
    return request(url, options, retryOptions)
  }

  const accessToken = options.token ?? (options.getAccessToken ? options.getAccessToken() : undefined)
  const refreshToken = options.getRefreshToken ? options.getRefreshToken() : undefined

  if (!accessToken) {
    if (!refreshToken) {
      // no accessToken and no refreshToken, the request fails
      return Either.left({
        status: 401,
        url,
        body: { expected: true, message: 'no accessToken or refreshToken found' },
      })
    }

    // no accessToken, refresh the accessToken and run the request
    return refreshThenRequest(refreshToken, url, options)
  }

  // some accessToken (maybe expired), run the request and on error 401, try to refresh and retry.
  const eitherResponse = await request<BODY>(url, withToken(options, accessToken))

  return eitherResponse.exceptAsync(async (error) => {
    if (error.status === 401 && refreshToken) {
      return refreshThenRequest(refreshToken, url, options)
    }
    return Either.left(error)
  })
}

async function refreshThenRequest<BODY>(
  refreshToken: string,
  url: string,
  options: OAuthRequestOptions = {},
): Promise<HttpResponse<BODY>> {
  if (options.refreshAccessToken === undefined) {
    return Either.left({
      status: 401,
      url,
      body: { message: "don't know how to refresh access token" },
    })
  }

  return (await options.refreshAccessToken(refreshToken)).caseOf<Promise<HttpResponse<BODY>>>({
    left: async (error) =>
      Either.left({
        status: 401,
        url,
        body: { message: 'access token refresh failed' },
        cause: error,
      }),
    right: async (accessToken) => {
      if (options.persistAccessToken) {
        options.persistAccessToken(accessToken)
      }

      const requestWithFreshToken = await request<BODY>(url, withToken(options, accessToken))

      await requestWithFreshToken.exceptAsync(async (error) => {
        if (error.status === 401) {
          logger.warn('[api-client] Failed to re-authenticate right after refresh', {
            previousError: error,
            refreshTokenExists: !!refreshToken,
            url,
          })
          // HACK: We're observing cases where users get a 401 even right after a successful token refresh.
          //  Until we can truly identify the cause and possible fixes, we can only avoid page crashes.
          //  Thus, we sign the user out before reloading the current page, to allow loading a simpler but working resource.
          //  In addition, we (filthily) sleep to break the execution flow until redirection completes.
          document.location.href = `/signout?next=${document.location.href}`
          await new Promise((r) => setTimeout(r, 2000))
        }

        return Either.left(error)
      })

      return requestWithFreshToken
    },
  })
}

function withToken(options: OAuthRequestOptions, accessToken: string): OAuthRequestOptions {
  const headers = new Headers(options.headers)
  headers.set('Authorization', `Bearer ${accessToken}`)

  return Object.assign({}, options, { headers })
}
