import * as React from 'react'
import styled from 'styled-components'

import { lightweight, standalone, SymbolProps } from '../spritesheetUtils'

const HORIZONTAL_ID = 'ulule-horizontal-logo-icon'
const OWL_ONLY_ID = 'ulule-owl-only-logo-icon'
const TEXT_ONLY_ID = 'ulule-text-only-logo-icon'

export function HorizontalLogoSymbol({ id = HORIZONTAL_ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 506.93 157">
      <path
        d="M112.37 122.21a36.15 36.15 0 0 1-32.09-19.5 2 2 0 0 0-3.55 0 36.17 36.17 0 1 1 .37-32.58 17.91 17.91 0 0 0 .84 24.56.8.8 0 0 0 1.12 0 17.91 17.91 0 0 0 .84-24.56 36.16 36.16 0 1 1 32.46 52.07z"
        fill="#FFF"
      />
      <path d="M156 33.11a3.27 3.27 0 0 0-4.63 0L146 38.49a78.49 78.49 0 0 0-135 0l-5.41-5.38A3.27 3.27 0 0 0 0 35.43V78.5a78.5 78.5 0 0 0 157 0V35.43a3.27 3.27 0 0 0-1-2.32zm-43.67 89.09a36.15 36.15 0 0 1-32.09-19.5 2 2 0 0 0-3.55 0 36.17 36.17 0 1 1 .37-32.58 17.91 17.91 0 0 0 .84 24.56.8.8 0 0 0 1.12 0 17.91 17.91 0 0 0 .84-24.56 36.16 36.16 0 1 1 32.46 52.07z" />
      <path d="M43.34 57.86a15.83 15.83 0 1 1-3 0 22.83 22.83 0 1 0 3 0z" fill="#18A5D6" />
      <path
        d="M45.25 60.69a6 6 0 0 1 2.27 4.72 5.69 5.69 0 0 1-11.37 0 6 6 0 0 1 2.18-4.57l.34-.33a4.05 4.05 0 0 0 1.63-2.61 15.47 15.47 0 1 0 3 0h-.12c.12.9 1.11 1.92 2.07 2.79z"
        fill="#18A5D6"
      />
      <path
        d="M45.25 60.69a6 6 0 0 1 2.27 4.72 5.69 5.69 0 0 1-11.37 0 6 6 0 0 1 2.18-4.57l.34-.33a4.05 4.05 0 0 0 1.63-2.61 15.47 15.47 0 1 0 3 0h-.12c.12.9 1.11 1.92 2.07 2.79z"
        fill="#004E69"
      />
      <path d="M116.56 57.87a15.48 15.48 0 1 1-2.92 0 23 23 0 1 0 2.92 0z" fill="#F01446" />
      <path
        d="M118.58 60.69a6 6 0 0 1 2.27 4.72 5.69 5.69 0 0 1-11.37 0 6 6 0 0 1 2.18-4.57l.34-.33c.87-.84 1.56-1.51 1.63-2.59a15.82 15.82 0 1 0 2.92 0c.11.9 1.08 1.91 2.03 2.77z"
        fill="#F01446"
      />
      <path
        d="M118.58 60.69a6 6 0 0 1 2.27 4.72 5.69 5.69 0 0 1-11.37 0 6 6 0 0 1 2.18-4.57l.34-.33c.87-.84 1.56-1.51 1.63-2.59a15.82 15.82 0 1 0 2.92 0c.11.9 1.08 1.91 2.03 2.77z"
        fill="#8B0020"
      />
      <path d="M113.65 57.86z" fill="#fff" />
      <path d="M240.08 87.91c0 10.66-5.19 15.17-12.84 15.17s-12.85-4.51-12.85-15.17v-22a2 2 0 0 0-2.15-2l-16.54 1.47a2 2 0 0 0-1.81 2v20.53c0 22.82 13.39 34.3 33.34 34.3s33.34-11.61 33.34-34.3v-26a2 2 0 0 0-2.15-2l-16.54 1.45a2 2 0 0 0-1.81 2zm129.25 0c0 10.66-5.19 15.17-12.84 15.17s-12.85-4.51-12.85-15.17V54.6a2 2 0 0 0-2.15-2l-16.54 1.45a2 2 0 0 0-1.81 2v31.86c0 22.82 13.39 34.3 33.34 34.3s33.34-11.61 33.34-34.3V50.56a2 2 0 0 0-2.15-2L371.13 50a2 2 0 0 0-1.81 2zm33.33 12.02v18.52a2 2 0 0 0 2 2h37.64a2 2 0 0 0 2-2v-16.54a2 2 0 0 0-2-2h-19.14V47.64a2 2 0 0 0-2.15-2l-16.54 1.45a2 2 0 0 0-1.81 2zm-129.25 0v18.52a2 2 0 0 0 2 2h37.66a2 2 0 0 0 2-2v-16.54a2 2 0 0 0-2-2h-19.16v-41a2 2 0 0 0-2.15-2l-16.54 1.45a2 2 0 0 0-1.81 2zm233.52-43.01V40.31a2 2 0 0 0-2.15-2l-46.94 4.11a2 2 0 0 0-1.81 2v74a2 2 0 0 0 2 2H505a2 2 0 0 0 2-2v-16.51a2 2 0 0 0-2-2h-28.46v-11.1h17.28a2 2 0 0 0 2-2V73a2 2 0 0 0-2-2h-17.28v-9.61l28.59-2.5a2 2 0 0 0 1.8-1.97z" />
    </symbol>
  )
}

type LogoProps = React.SVGAttributes<SVGElement>

const HorizontalLogoStandaloneContent = standalone(HorizontalLogoSymbol, HORIZONTAL_ID)

export const HorizontalLogo = styled(function HorizontalLogoComponent(props: LogoProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="104" height="32.1">
      <HorizontalLogoStandaloneContent />
    </svg>
  )
})``

export const HorizontalLogoLightweight = styled(function HorizontalLogoComponent(props: LogoProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="104" height="32.1">
      {lightweight(HORIZONTAL_ID)}
    </svg>
  )
})``

export function OwlOnlyLogoSymbol({ id = OWL_ONLY_ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 140 140">
      <g fill="none">
        <path
          d="M96.866 105.612c-11.983 0-22.375-6.793-27.548-16.736a1.707 1.707 0 0 0-3.044 0c-5.172 9.943-15.565 16.736-27.548 16.736-17.879 0-32.227-15.117-30.96-33.267 1.06-15.177 13.269-27.51 28.435-28.71 13.295-1.05 24.993 6.286 30.39 17.278-2.378 2.714-3.832 6.257-3.832 10.15 0 4.276 1.749 8.136 4.557 10.932a.686.686 0 0 0 .96 0c2.809-2.796 4.557-6.656 4.557-10.933 0-3.892-1.454-7.435-3.832-10.15 5.397-10.99 17.096-18.327 30.39-17.276 15.167 1.199 27.375 13.532 28.434 28.71 1.268 18.149-13.08 33.266-30.96 33.266"
          fill="#FFF"
        />
        <path
          d="M96.866 105.612c-11.983 0-22.375-6.793-27.548-16.736a1.707 1.707 0 0 0-3.044 0c-5.172 9.943-15.565 16.736-27.548 16.736-17.879 0-32.227-15.117-30.96-33.267 1.06-15.177 13.269-27.51 28.435-28.71 13.295-1.05 24.993 6.286 30.39 17.278-2.378 2.714-3.832 6.257-3.832 10.15 0 4.276 1.749 8.136 4.557 10.932a.686.686 0 0 0 .96 0c2.809-2.796 4.557-6.656 4.557-10.933 0-3.892-1.454-7.435-3.832-10.15 5.397-10.99 17.096-18.327 30.39-17.276 15.167 1.199 27.375 13.532 28.434 28.71 1.268 18.149-13.08 33.266-30.96 33.266m37.49-76.476a2.808 2.808 0 0 0-3.975 0l-4.615 4.614C114.023 13.975 92.463.713 67.796.713S21.57 13.975 9.827 33.75l-4.614-4.614a2.802 2.802 0 0 0-1.988-.823 2.806 2.806 0 0 0-2.81 2.811v36.97c0 37.215 30.167 67.383 67.381 67.383s67.382-30.168 67.382-67.382V31.124c0-.718-.275-1.439-.823-1.988"
          fill="#000"
        />
        <path
          d="M37.62 50.382c6.77.65 12.09 6.334 12.135 13.277.048 7.418-5.925 13.475-13.344 13.525-7.42.05-13.476-5.92-13.523-13.34-.05-7.004 5.28-12.764 12.115-13.43l.002-.022c-10.298.682-18.413 9.261-18.344 19.718.074 10.86 8.938 19.601 19.792 19.528 10.86-.074 19.599-8.934 19.528-19.796-.07-10.37-8.171-18.794-18.361-19.46"
          fill="#18A5D6"
        />
        <path
          d="M39.254 52.808c1.18 1.119 1.96 2.233 1.949 4.055-.01 2.626-2.232 4.76-4.953 4.772-2.597-.017-4.791-2.222-4.807-4.815-.012-2.12 1.213-3.295 1.872-3.926l.29-.282c.743-.716 1.328-1.288 1.397-2.199-6.834.667-12.165 6.428-12.114 13.43.047 7.42 6.103 13.393 13.523 13.341 7.42-.05 13.392-6.106 13.344-13.524-.046-6.945-5.366-12.628-12.135-13.279l-.107-.004c.07.809.916 1.68 1.74 2.431"
          fill="#18A5D6"
        />
        <path
          d="M39.254 52.808c1.18 1.119 1.96 2.233 1.949 4.055-.01 2.626-2.232 4.76-4.953 4.772-2.597-.017-4.791-2.222-4.807-4.815-.012-2.12 1.213-3.295 1.872-3.926l.29-.282c.743-.716 1.328-1.288 1.397-2.199-6.834.667-12.165 6.428-12.114 13.43.047 7.42 6.103 13.393 13.523 13.341 7.42-.05 13.392-6.106 13.344-13.524-.046-6.945-5.366-12.628-12.135-13.279l-.107-.004c.07.809.916 1.68 1.74 2.431"
          fill="#004E69"
        />
        <path
          d="m100.463 50.383.002.018c6.895.608 12.289 6.397 12.239 13.443-.048 7.42-6.104 13.392-13.524 13.34-7.42-.05-13.391-6.107-13.344-13.525.046-6.937 5.357-12.616 12.119-13.275-10.182.673-18.275 9.095-18.345 19.458-.07 10.862 8.667 19.722 19.528 19.796 10.854.073 19.719-8.668 19.793-19.528.07-10.5-8.112-19.105-18.468-19.727"
          fill="#F01446"
        />
        <path
          d="M102.203 52.808c1.18 1.118 1.959 2.232 1.949 4.055-.01 2.625-2.231 4.76-4.953 4.772-2.598-.018-4.79-2.222-4.807-4.814-.011-2.12 1.213-3.296 1.871-3.927l.292-.283c.75-.724 1.342-1.299 1.4-2.227-6.761.658-12.073 6.338-12.119 13.275-.047 7.418 5.924 13.474 13.345 13.525 7.42.051 13.475-5.92 13.522-13.34.051-7.046-5.343-12.836-12.237-13.444.083.802.919 1.665 1.737 2.408"
          fill="#F01446"
        />
        <path
          d="M102.203 52.808c1.18 1.118 1.959 2.232 1.949 4.055-.01 2.625-2.231 4.76-4.953 4.772-2.598-.018-4.79-2.222-4.807-4.814-.011-2.12 1.213-3.296 1.871-3.927l.292-.283c.75-.724 1.342-1.299 1.4-2.227-6.761.658-12.073 6.338-12.119 13.275-.047 7.418 5.924 13.474 13.345 13.525 7.42.051 13.475-5.92 13.522-13.34.051-7.046-5.343-12.836-12.237-13.444.083.802.919 1.665 1.737 2.408"
          fill="#8B0020"
        />
        <path d="m97.972 50.382-.017.001h.017" fill="#FFF" />
      </g>
    </symbol>
  )
}

const OwlOnlyLogoStandaloneContent = standalone(OwlOnlyLogoSymbol, OWL_ONLY_ID)

export const OwlOnlyLogo = styled(function OwlOnlyLogoComponent(props: LogoProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="136" height="136">
      <OwlOnlyLogoStandaloneContent />
    </svg>
  )
})``

export const OwlOnlyLogoLightweight = styled(function OwlOnlyLogoComponent(props: LogoProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="136" height="136">
      {lightweight(OWL_ONLY_ID)}
    </svg>
  )
})``

export function TextOnlyLogoSymbol({ id = OWL_ONLY_ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 850 157">
      <g fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.661 126.977c0 27.155-13.23 38.637-32.724 38.637-19.498 0-32.728-11.482-32.728-38.637V70.914a5.039 5.039 0 0 0-5.478-5.02L5.594 69.582a5.04 5.04 0 0 0-4.6 5.02v52.376c0 58.135 34.118 87.379 84.944 87.379 50.822 0 84.937-29.588 84.937-87.379V60.621a5.039 5.039 0 0 0-5.478-5.02l-42.137 3.687a5.037 5.037 0 0 0-4.599 5.02v62.669ZM447.959 126.977c0 27.154-13.23 38.637-32.723 38.637-19.498 0-32.729-11.483-32.729-38.637V42.103a5.037 5.037 0 0 0-5.478-5.016l-42.137 3.686a5.039 5.039 0 0 0-4.601 5.017v81.187c0 58.135 34.117 87.379 84.945 87.379 50.823 0 84.937-29.588 84.937-87.379V31.811a5.038 5.038 0 0 0-5.477-5.02l-42.138 3.687a5.035 5.035 0 0 0-4.599 5.02v91.479ZM532.881 157.605v47.185a5.04 5.04 0 0 0 5.04 5.037h95.959a5.04 5.04 0 0 0 5.037-5.037v-42.145a5.04 5.04 0 0 0-5.037-5.04h-48.777V24.38a5.038 5.038 0 0 0-5.478-5.02l-42.145 3.687a5.039 5.039 0 0 0-4.599 5.02v129.538ZM203.585 157.605v47.185a5.038 5.038 0 0 0 5.039 5.037h95.959a5.04 5.04 0 0 0 5.037-5.037v-42.145a5.04 5.04 0 0 0-5.037-5.04h-48.777V53.19a5.037 5.037 0 0 0-5.477-5.02l-42.146 3.688a5.035 5.035 0 0 0-4.598 5.019v100.728ZM798.547 48.029V5.705a5.038 5.038 0 0 0-5.476-5.019L673.489 11.15a5.04 5.04 0 0 0-4.601 5.019V204.788a5.041 5.041 0 0 0 5.039 5.04H793.507a5.04 5.04 0 0 0 5.04-5.04v-42.145a5.039 5.039 0 0 0-5.04-5.037H721.11v-28.339h44.035a5.038 5.038 0 0 0 5.037-5.037V89.038A5.038 5.038 0 0 0 765.145 84H721.11V59.42l72.838-6.372a5.039 5.039 0 0 0 4.599-5.02Z"
          fill="#000"
        />
      </g>
    </symbol>
  )
}

const TextOnlyLogoStandaloneContent = standalone(TextOnlyLogoSymbol, TEXT_ONLY_ID)

export const TextOnlyLogo = styled(function TextOnlyLogoComponent(props: LogoProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <TextOnlyLogoStandaloneContent />
    </svg>
  )
})``

export const TextOnlyLogoLightweight = styled(function TextOnlyLogoComponent(props: LogoProps) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="136" height="136">
      {lightweight(TEXT_ONLY_ID)}
    </svg>
  )
})``
