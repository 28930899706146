import { Lang } from '@owl-nest/api-client/latest'
import { lens } from '@owl-nest/config'
import { accounting } from '@ulule/owl-kit-components/next'

export type Country = {
  content: string
  flag: string
  prefix: number
  value: string
}

type Countries = Country[]

type Currencies = {
  COUNTRIES: { [s: string]: string }
  CURRENT?: string
  RATES: {
    [s: string]: number
  }
  SELECTED?: string
  SYMBOLS: {
    [s: string]: {
      separator: string
      symbol: string
      trigram: string
    }
  }
}

type UFE = {
  countries: Countries
  locale: string
  defaultLocale: string
}

type Window = {
  CURRENCIES: Currencies
  UFE: UFE
}

const windowLens = lens<Window>()

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
      }
    : window

export const COUNTRIES = windowLens.get('UFE').get('countries').value(global, { trust: true })

export const CURRENCIES = windowLens.get('CURRENCIES').value(global, { trust: true })
accounting?.setCurrencies(CURRENCIES.SYMBOLS)

export const DEFAULT_LOCALE = windowLens.get('UFE').get('defaultLocale').value(global) as Lang

export const USER_LOCALE = windowLens.get('UFE').get('locale').value(global, { forgive: true, silent: true }) as Lang
