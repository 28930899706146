import * as UFE from './UFE.ts'

/**
 * Capitalize a given string
 * @param str The string to capitalize
 */
export function capitalize(str = ''): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// Strip tags content
export function stripTags(str = ''): string {
  return str.replace(/(<([^>]+)>)/gi, '')
}

// Truncate content
export function truncate(text = '', limit = 10): string {
  const str = text.trim()

  if (str.length <= limit) {
    return str
  }

  return `${str.substring(0, limit)}...`
}

// Localize number and return defaultValue is undefined or null
// NOTE: We replace space separator characters with a space (U+0020) character since some locales (e.g. 'fr-FR') use thin space (U+2009), which renders a visually unsatisfying result.
export function numberToLocaleString(value: number, defaultValue = '0'): string {
  return value ? value.toLocaleString(UFE.USER_LOCALE).replace(/\s/g, ' ') : defaultValue
}

export function slugify(str: string, separator = ' '): string {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/[^a-z0-9 _-]/g, '')
    .trim()
    .replace(/[\s_]/g, separator)
}

const CSS_PROPERTY_WHITELIST = ['font-style', 'font-variant', 'font-weight', 'text-decoration', 'text-align']

export function sanitizeDescriptionStyle(description: string) {
  return description.replace(/style=['"](.*?)['"]/g, (_, style: string) => {
    return `style="${style
      .split(';')
      .filter((rule) => CSS_PROPERTY_WHITELIST.some((property) => rule.toLowerCase().startsWith(property)))
      .join(';')}"`
  })
}
