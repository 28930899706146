import * as React from 'react'

/*
  Creates a ref that is kept updated on each render. Useful for event handlers,
  so they can access current values without needing to de-registerer/re-register
*/
export function useCommittedRef<VALUE>(value: VALUE): React.MutableRefObject<VALUE> {
  const ref = React.useRef(value)
  ref.current = value
  React.useEffect(() => {
    ref.current = value
  }, [value])
  return ref
}
