import algoliasearch from 'algoliasearch'
import algoliasearchLite from 'algoliasearch/lite'

const NEXT_PUBLIC_ALGOLIA_APP_ID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? ''
const NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? ''
const ALGOLIA_WRITE_API_KEY = process.env.ALGOLIA_WRITE_API_KEY ?? ''

export const client = algoliasearch(NEXT_PUBLIC_ALGOLIA_APP_ID, NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY)

export const liteClient = algoliasearchLite(NEXT_PUBLIC_ALGOLIA_APP_ID, NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY)

export const writeClient = algoliasearch(NEXT_PUBLIC_ALGOLIA_APP_ID, ALGOLIA_WRITE_API_KEY)
