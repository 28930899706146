import * as api from '@owl-nest/api-client/latest'
import * as auth from '@owl-nest/auth'

export type InitialState = {
  type: 'initial'
  isInitializing: boolean
}

export type UnknownState = {
  type: 'unknown'
  isIdentifying: boolean
  failure?: api.RequestFailure
}

export type KnownState = {
  type: 'known'
  knownUser: auth.KnownUser
  isLoggingIn: boolean
  failure?: api.RequestFailure
}

export type LoggedInGuestState = {
  type: 'loggedin-as-guest'
  extraFields: string[]
  isLoggingOut: boolean
  isRefreshing: boolean
  failure?: api.RequestFailure
  authenticatedUser: api.AuthenticatedUser
}

export type LoggedInState = {
  type: 'loggedin'
  extraFields: string[]
  isLoggingOut: boolean
  isRefreshing: boolean
  failure?: api.RequestFailure
  authenticatedUser: api.AuthenticatedUser
}

export type ExistingState = {
  type: 'existing'
  service: 'ulule' | 'facebook'
  identifier: { type: 'email' | 'username'; value: string }
  isLoggingIn: boolean
  failure?: api.RequestFailure
}

export type NewState = {
  type: 'new'
  identifier: { type: 'email' | 'username'; value: string }
  isSigningUp: boolean
  isGuestLoggingIn: boolean
  failure?: api.RequestFailure
}

export type AuthState =
  | InitialState
  | UnknownState
  | KnownState
  | LoggedInGuestState
  | LoggedInState
  | ExistingState
  | NewState

export enum TYPE {
  INITIALIZE_START = 'AUTH.INITIALIZE_START',
  INITIALIZE_FAILURE = 'AUTH.INITIALIZE_FAILURE',
  INITIALIZE_SUCCESS = 'AUTH.INITIALIZE_SUCCESS',

  REFRESH_START = 'AUTH.REFRESH_START',
  REFRESH_FAILURE = 'AUTH.REFRESH_FAILURE',
  REFRESH_SUCCESS = 'AUTH.REFRESH_SUCCESS',

  LOGIN_START = 'AUTH.LOGIN_START',
  LOGIN_SUCCESS = 'AUTH.LOGIN_SUCCESS',
  LOGIN_FAILURE = 'AUTH.LOGIN_ERROR',

  GUEST_LOGIN_START = 'AUTH.GUEST_LOGIN_START',
  GUEST_LOGIN_SUCCESS = 'AUTH.GUEST_LOGIN_SUCCESS',
  GUEST_LOGIN_FAILURE = 'AUTH.GUEST_LOGIN_ERROR',

  IDENTIFY_START = 'AUTH.IDENTIFY_START',
  IDENTIFY_FAILURE = 'AUTH.IDENTIFY_FAILURE',
  IDENTIFY_NEW = 'AUTH.IDENTIFY_NEW',
  IDENTIFY_EXISTING = 'AUTH.IDENTIFY_EXISTING',

  FORGET = 'AUTH.FORGET',

  SIGNUP_START = 'AUTH.SIGNUP_START',
  SIGNUP_SUCCESS = 'AUTH.SIGNUP_SUCCESS',
  SIGNUP_FAILURE = 'AUTH.SIGNUP_FAILURE',

  LOGOUT_START = 'AUTH.LOGOUT_START',
  LOGOUT_SUCCESS = 'AUTH.LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'AUTH.LOGOUT_FAILURE',

  UPDATE_REQUEST = 'AUTH.UPDATE_REQUEST',
  UPDATE_SUCCESS = 'AUTH.UPDATE_SUCCESS',
  UPDATE_FAILURE = 'AUTH.UPDATE_FAILURE',

  SYNC = 'AUTH.SYNC',
}

export type UserQueryResult<EXTRA_FIELDS extends string = string> =
  | { type: 'loggedin'; user: api.AuthenticatedUser<EXTRA_FIELDS>; extraFields: EXTRA_FIELDS[] }
  | { type: 'loggedin-as-guest'; user: api.AuthenticatedUser<EXTRA_FIELDS>; extraFields: EXTRA_FIELDS[] }
  | { type: 'known'; user: auth.KnownUser }
  | { type: 'unknown' }

export type InitializeStart = { type: TYPE.INITIALIZE_START }
export type InitializeFailure = { type: TYPE.INITIALIZE_FAILURE; failure: api.RequestFailure }
export type InitializeSuccess = { type: TYPE.INITIALIZE_SUCCESS; success: UserQueryResult }

export type RefreshStart = { type: TYPE.REFRESH_START }
export type RefreshFailure = { type: TYPE.REFRESH_FAILURE; failure: api.RequestFailure }
export type RefreshSuccess = { type: TYPE.REFRESH_SUCCESS; success: UserQueryResult }

export type LoginStart = { type: TYPE.LOGIN_START }
export type LoginFailure = { type: TYPE.LOGIN_FAILURE; failure: api.RequestFailure }
export type LoginSuccess = { type: TYPE.LOGIN_SUCCESS; success: api.AuthenticatedUser & { extraFields: string[] } }

export type GuestLoginStart = { type: TYPE.GUEST_LOGIN_START }
export type GuestLoginFailure = { type: TYPE.GUEST_LOGIN_FAILURE; failure: api.RequestFailure }
export type GuestLoginSuccess = {
  type: TYPE.GUEST_LOGIN_SUCCESS
  success: api.AuthenticatedUser & { extraFields: string[] }
}

export type IdentifyStart = { type: TYPE.IDENTIFY_START }
export type IdentifyFailure = { type: TYPE.IDENTIFY_FAILURE; failure: api.RequestFailure }
export type IdentifyNew = { type: TYPE.IDENTIFY_NEW; identifier: { type: 'email' | 'username'; value: string } }
export type IdentifyExisting = {
  type: TYPE.IDENTIFY_EXISTING
  identifier: { type: 'email' | 'username'; value: string }
  service: 'ulule' | 'facebook'
}

export type Forget = { type: typeof TYPE.FORGET }

export type SignupStart = { type: typeof TYPE.SIGNUP_START }
export type SignUpFailure = { type: typeof TYPE.SIGNUP_FAILURE; failure: api.RequestFailure }
export type SignupSuccess = { type: typeof TYPE.SIGNUP_SUCCESS; success: api.AuthenticatedUser }

export type LogoutStart = { type: typeof TYPE.LOGOUT_START }
export type LogoutSuccess = { type: typeof TYPE.LOGOUT_SUCCESS }
export type LogoutFailure = { type: typeof TYPE.LOGOUT_FAILURE; failure: api.RequestFailure }

export type UpdateStart = { type: typeof TYPE.UPDATE_REQUEST }
export type UpdateSuccess = { type: typeof TYPE.UPDATE_SUCCESS; success: api.AuthenticatedUser }
export type UpdateFailure = { type: typeof TYPE.UPDATE_FAILURE; failure: api.RequestFailure }

export type Sync = { type: typeof TYPE.SYNC; state: AuthState }

export type AuthAction =
  | InitializeStart
  | InitializeFailure
  | InitializeSuccess
  | LoginStart
  | LoginFailure
  | LoginSuccess
  | GuestLoginStart
  | GuestLoginFailure
  | GuestLoginSuccess
  | IdentifyStart
  | IdentifyFailure
  | IdentifyNew
  | IdentifyExisting
  | Forget
  | SignupStart
  | SignUpFailure
  | SignupSuccess
  | LogoutStart
  | LogoutFailure
  | LogoutSuccess
  | UpdateStart
  | UpdateSuccess
  | UpdateFailure
  | RefreshStart
  | RefreshFailure
  | RefreshSuccess
  | Sync
