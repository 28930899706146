import * as React from 'react'
import styled from 'styled-components'

import { FreeSizedSvg } from '../FreeSizedSvg'

import { IconProps } from '../types'

export type MediumIconProps = IconProps & {
  inverted?: boolean
  noBackground?: boolean
  size: number
  y?: string | number
}

/** Base Medium Icon Component */
function MediumIconComponent({
  children,
  className,
  inverted = false,
  name,
  noBackground = false,
  onClick,
  size = 32,
  title,
  viewBox = `0 0 ${size} ${size}`,
  transform,
  y,
}: MediumIconProps): React.ReactElement<MediumIconProps> {
  const height = inverted && !noBackground ? size * 0.6 : size
  const width = inverted && !noBackground ? '100%' : size

  return (
    <FreeSizedSvg
      aria-labelledby={title && 'title'}
      className={className}
      clipRule="evenodd"
      fillRule="evenodd"
      height={height}
      id={`icon-${name}`}
      onClick={onClick}
      preserveAspectRatio="xMidYMid meet"
      transform={transform}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      y={y ?? (inverted && !noBackground ? size / 5 : 0)}
    >
      {title && <title id="title">{name}</title>}
      {children}
    </FreeSizedSvg>
  )
}

export const MediumIcon = styled(MediumIconComponent)``
MediumIcon.displayName = 'illustrations.MediumIcon'

/** Base Large Icon Component */
function LargeIconComponent({
  children,
  className,
  height = 128,
  name,
  onClick,
  title,
  viewBox,
  width,
  transform,
}: IconProps): React.ReactElement<IconProps> {
  return (
    <FreeSizedSvg
      aria-labelledby={title && 'title'}
      className={className}
      clipRule="evenodd"
      fillRule="evenodd"
      height={height}
      id={`icon-${name}`}
      onClick={onClick}
      preserveAspectRatio="xMidYMid meet"
      transform={transform}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title id="title">{name}</title>}
      {children}
    </FreeSizedSvg>
  )
}

export const LargeIcon = styled(LargeIconComponent)``
LargeIcon.displayName = 'illustrations.LargeIcon'
