import styled from 'styled-components'

import * as image from './image'
import * as link from './link'

export const UserLabel = styled(link.Base)`
  display: flex;
  align-items: center;
  text-decoration: none;

  ${image.Avatar} {
    margin-right: 8px;
  }
`

UserLabel.displayName = 'S.userLabel.UserLabel'
