/**
 * Do not edit directly
 * Generated on Mon, 06 May 2024 11:28:48 GMT
 */

export const SHADOW_1 = '1px 2px 3px 0px rgba(89, 84, 78, 0.12)'

export const SHADOW_2 = '1px 2px 6px 0px rgba(89, 84, 78, 0.12)'

export const SHADOW_3 = '1px 2px 10px 0px rgba(89, 84, 78, 0.12)'

export const SHADOW_4 = '1px 2px 14px 0px rgba(89, 84, 78, 0.12)'

export const SHADOW_5 = '1px 2px 32px 0px rgba(89, 84, 78, 0.12)'