import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'
import { Lang } from '@owl-nest/api-client/latest'

import { Body } from './Body'
import { Toes } from './Toes'
import { Manifesto } from './Manifesto'

import { NavSection } from '../baseConfig'

type FooterProps = React.HTMLAttributes<HTMLDivElement> & {
  controlledCurrency?: string
  hasCurrencyAndLang?: boolean
  lang: Lang
  navSections: NavSection[]
  withRedirectParam?: boolean
}

function FooterComponent({
  controlledCurrency,
  lang,
  hasCurrencyAndLang = true,
  navSections,
  withRedirectParam,
  ...props
}: FooterProps): React.ReactElement<FooterProps> {
  return (
    <footer {...props}>
      <Body
        controlledCurrency={controlledCurrency}
        lang={lang}
        navSections={navSections}
        hasCurrencyAndLang={hasCurrencyAndLang}
        withRedirectParam={withRedirectParam}
      />

      <FooterSeparation />

      <Manifesto lang={lang} />

      <FooterSeparation />

      <Toes lang={lang} />
    </footer>
  )
}

const FooterSeparation = styled.hr`
  border-top: 1px solid ${plume.COLORS.GREY_SHADE_5};
  border-bottom: 0px;
  margin: 0;
`

export const Footer = styled(FooterComponent)`
  border-top: 1px solid ${plume.COLORS.GREY_SHADE_5};
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  box-sizing: border-box;
`
