import { BaseMethod, ClientMethod, legacy, OAuth2 } from '../../legacy/compat.ts'
import { client } from './api.ts'
import * as types from './types.ts'

export * from './types.ts'

/* /!\ Do not add any endpoint here. This is a legacy compat that should not be
expended. If an existing endpoint is modified (different configuration,
different type) you can update it here. But if an existing endpoint is replace
by another, only remove it here, write the new endpoint in the `api.ts` and use
the "next" version of the api-client for this endpoint.
 */

export type Api = {
  delete: BaseMethod & {
    account: ClientMethod<void, void, 'accountId'>
    addresses: ClientMethod<void, void, 'addressId'>
    analyticsTag: ClientMethod<void, void, 'analyticsTagId'>
    comment: ClientMethod<void, void, 'commentId'>
    image: ClientMethod<void, void, 'imageId'>
    member: ClientMethod<void, void, 'memberId'>
    projectSlug: ClientMethod<void, void, 'slugId'>
    review: ClientMethod<void, void, 'reviewId'>
  }
  get: BaseMethod & {
    account: ClientMethod<undefined, types.Account, 'accountId'>
    accounts: ClientMethod<undefined, types.Pageable<{ accounts: types.Account[] | null }>, 'userId'>
    addresses: ClientMethod<undefined, types.Address, 'addressId'>
    categories: ClientMethod<undefined, types.Pageable<types.CategoryList>, never>
    channels: ClientMethod<undefined, types.Pageable<types.ChannelList>, never>
    commentReplies: ClientMethod<undefined, types.Pageable<types.CommentList<false>>, 'commentId'>
    me: ClientMethod<undefined, types.AuthenticatedUser>
    myDiscussionsStats: ClientMethod<undefined, types.UserDiscussionsStats>
    news: ClientMethod<undefined, types.News<true, true>, 'newsId'>
    newsComment: ClientMethod<undefined, types.Pageable<types.CommentList<true>>, 'newsId'>
    officialUsers: ClientMethod<undefined, types.Pageable<types.PublicUserList>>
    order: ClientMethod<undefined, types.Order<true>, 'orderId'>
    partner: ClientMethod<undefined, types.Partner, 'partnerId'>
    productReview: ClientMethod<undefined, types.Pageable<types.ProductReviewPage>, 'productId'>
    project: ClientMethod<undefined, types.Project, 'projectId'>
    projectAnalytics: ClientMethod<undefined, types.AnalyticsTags, 'projectId'>
    projectComment: ClientMethod<undefined, types.Pageable<types.CommentList<true>>, 'projectId'>
    projectEvents: ClientMethod<undefined, types.Pageable<types.EventList>, 'projectId'>
    projectFan: ClientMethod<undefined, types.Pageable<types.FanList>, 'projectId'>
    projectFaq: ClientMethod<undefined, types.Pageable<types.FaqList>, 'projectId'>
    projectImage: ClientMethod<undefined, types.Pageable<{ images: types.ImageResource[] | null }>, 'projectId'>
    projectImpact: ClientMethod<undefined, types.Impact, 'projectId'>
    projectMember: ClientMethod<undefined, types.MemberList, 'projectId'>
    projectNews: ClientMethod<undefined, types.Pageable<types.NewsList<true>>, 'projectId'>
    projectOrders: ClientMethod<undefined, types.WithOrderFacets<types.Pageable<types.OrderList>>, 'projectId'>
    projectRewards: ClientMethod<undefined, types.Reward[], 'projectId'>
    projectSupporter: ClientMethod<undefined, types.Pageable<types.SupporterList>, 'projectId'>
    proposal: ClientMethod<never, types.Proposal, 'token'>
    sdgs: ClientMethod<undefined, types.SDGList, never>
    search: ClientMethod<undefined, types.Pageable<types.ProjectList>>
    subscriptionsOrders: ClientMethod<undefined, types.Pageable<types.OrderList<false>>>
    tags: ClientMethod<undefined, types.Pageable<types.TagList>, never>
    thread: ClientMethod<undefined, types.DiscussionThread, 'threadId'>
    user: ClientMethod<undefined, types.PublicUser, 'userId'>
    userAddresses: ClientMethod<undefined, types.Addresses, 'userId'>
    userOrders: ClientMethod<undefined, types.Pageable<types.OrderList>, 'userId'>
    userReviews: ClientMethod<undefined, types.Pageable<types.ReviewList>, 'userId'>
    variant: ClientMethod<undefined, types.Variant, 'variantId'>
    withdrawals: ClientMethod<undefined, types.Pageable<types.WithdrawalPage<boolean>>, 'projectId'>
  }
  patch: BaseMethod & {
    addresses: ClientMethod<types.UpdateAddress, types.Address, 'addressId'>
    proposal: ClientMethod<types.UpdateProposal, types.Proposal, 'token'>
    member: ClientMethod<types.UpdateMember, types.Member, 'memberId'>
    project: ClientMethod<types.UpdateProject, types.Project, 'projectId'>
    projectImpact: ClientMethod<types.UpdateImpact, types.Impact, 'projectId'>
    order: ClientMethod<types.UpdateOrder, types.Order, 'orderId'>
    review: ClientMethod<types.UpdateReview, types.Review, 'reviewId'>
    user: ClientMethod<types.UpdateUser, types.AuthenticatedUser, 'userId'>
    userAvatar: ClientMethod<{ image: File }, unknown, 'userId'>
  }
  post: BaseMethod & {
    accounts: ClientMethod<types.CreateAccount, types.Account, 'userId'>
    cancelOrder: ClientMethod<void, types.Order, 'orderId'>
    cancelSubscription: ClientMethod<void, types.Subscription, 'subscriptionId'>
    commentReplies: ClientMethod<types.CreateComment, types.Comment, 'commentId'>
    likeProject: ClientMethod<never, void, 'projectId'>
    link: ClientMethod<types.CreateLink, types.Link>
    markOrderAsDelivered: ClientMethod<never, types.Order<true>, 'orderId'>
    markOrderAsUndelivered: ClientMethod<never, types.Order<true>, 'orderId'>
    newsComment: ClientMethod<types.CreateComment, types.Comment<true>, 'newsId'>
    orderReturn: ClientMethod<types.CreateReturn, types.OrderReturn, 'orderId'>
    passwordScore: ClientMethod<{ password: string }, { score: types.PasswordStrength }>
    projectAnalytics: ClientMethod<types.CreateAnalyticsTag, types.NewAnalyticsTag, 'projectId'>
    projectCancel: ClientMethod<never, types.Project, 'projectId'>
    projectComment: ClientMethod<types.CreateComment, types.Comment<true>, 'projectId'>
    projectComputeExamples: ClientMethod<
      types.ProjectExamplesRequest,
      types.ProjectExamplesResponse | undefined,
      'projectId'
    >
    projectExports: ClientMethod<types.CreateExport, types.Export, 'projectId'>
    projectEnd: ClientMethod<never, types.Project, 'projectId'>
    projectFaq: ClientMethod<types.CreateFaq, types.Faq, 'projectId'>
    projectImage: ClientMethod<types.CreateImage, types.ImageResource, 'projectId'>
    projectMember: ClientMethod<types.CreateMember, types.Member, 'projectId'>
    projectOrders: ClientMethod<types.CreateOrder, types.Order, 'projectId'>
    projectPublish: ClientMethod<types.ProjectPublishRequest, types.Project, 'projectId'>
    projectSubmit: ClientMethod<types.ProjectSubmitRequest, types.Project, 'projectId'>
    projectSubscriptions: ClientMethod<types.CreateSubscription, types.Subscription, 'projectId'>
    /** @deprecated Set to be removed from the Ulule API, use the `video_url` field in the ProjectUpdate payload instead. */
    projectVideo: ClientMethod<types.CreateProjectVideo, types.VideoResource, 'projectId'>
    proposals: ClientMethod<types.CreateProposal | types.CreateAnonymousProposal, types.Proposal, never>
    proposalRefuse: ClientMethod<types.RefuseProposal, types.Proposal, 'token'>
    userProposals: ClientMethod<types.CreateProposal, types.Proposal, 'userId'>
    renewOrder: ClientMethod<Partial<types.Order>, types.Order, 'orderId'>
    rewardReview: ClientMethod<types.CreateReview, types.Review, 'variantId'>
    shippingRates: ClientMethod<types.ShippingRateRequest, types.ShippingRateList, never>
    subscribeNewsLetter: ClientMethod<{ country: string; email: string; lang: string }>
    unlikeProject: ClientMethod<never, void, 'projectId'>
    userAddresses: ClientMethod<types.CreateAddress, types.Address, 'userId'>
    userImage: ClientMethod<types.CreateImage, { id: number; versions?: Record<string, types.ImageVersion> }, 'userId'>
    withdrawals: ClientMethod<undefined, types.Withdrawal, 'projectId'>
  }
  put: BaseMethod
  oauth2: OAuth2

  endpoints: {
    me: { url: string }
  }
}

export const api = {
  ...legacy(client, {
    DELETE: (endpoint) => {
      endpoint('account', 'DELETE /accounts/:id', ({ accountId }) => ({ id: accountId }))
      endpoint('addresses', 'DELETE /addresses/:id', ({ addressId }) => ({ id: addressId }))
      endpoint('analyticsTag', 'DELETE /analytics/:id', ({ analyticsTagId }) => ({ id: analyticsTagId }))
      endpoint('comment', 'DELETE /comments/:id', ({ commentId }) => ({ id: commentId }))
      endpoint('image', 'DELETE /images/:id', ({ imageId }) => ({ id: imageId }))
      endpoint('member', 'DELETE /members/:id', ({ memberId }) => ({ id: memberId }))
      endpoint('projectSlug', 'DELETE /slugs/:id', ({ slugId }) => ({ id: slugId }))
      endpoint('review', 'DELETE /reviews/:id', ({ reviewId }) => ({ id: reviewId }))
    },
    GET: (endpoint) => {
      endpoint('account', 'GET /accounts/:id', ({ accountId }) => ({ id: accountId }))
      endpoint('accounts', 'GET /users/:id/accounts', ({ userId }) => ({ id: userId }))
      endpoint('addresses', 'GET /addresses/:id', ({ addressId }) => ({ id: addressId }))
      endpoint('categories', 'GET /categories', () => ({}))
      endpoint('channels', 'GET /channels', () => ({}))
      endpoint('commentReplies', 'GET /comments/:id/replies', ({ commentId }) => ({ id: commentId }))
      endpoint('me', 'GET /me', () => ({}))
      endpoint('myDiscussionsStats', 'GET /discussions/me', () => ({}))
      endpoint('news', 'GET /news/:id', ({ newsId }) => ({ id: newsId }))
      endpoint('newsComment', 'GET /news/:id/comments', ({ newsId }) => ({ id: newsId }))
      endpoint('officialUsers', 'GET /officialusers', () => ({}))
      endpoint('order', 'GET /orders/:id', ({ orderId }) => ({ id: orderId }))
      endpoint('partner', 'GET /partners/:id', ({ partnerId }) => ({ id: partnerId }))
      endpoint('productReview', 'GET /rewards/shopify-:id/reviews', ({ productId }) => ({ id: productId }))
      endpoint('project', 'GET /projects/:id', ({ projectId }) => ({ id: projectId }))
      endpoint('projectAnalytics', 'GET /projects/:id/analytics', ({ projectId }) => ({ id: projectId }))
      endpoint('projectComment', 'GET /projects/:id/comments', ({ projectId }) => ({ id: projectId }))
      endpoint('projectEvents', 'GET /projects/:id/events', ({ projectId }) => ({ id: projectId }))
      endpoint('projectFan', 'GET /projects/:id/fans', ({ projectId }) => ({ id: projectId }))
      endpoint('projectFaq', 'GET /projects/:id/faq', ({ projectId }) => ({ id: projectId }))
      endpoint('projectImage', 'GET /projects/:id/images', ({ projectId }) => ({ id: projectId }))
      endpoint('projectImpact', 'GET /projects/:id/impact', ({ projectId }) => ({ id: projectId }))
      endpoint('projectMember', 'GET /projects/:id/members', ({ projectId }) => ({ id: projectId }))
      endpoint('projectNews', 'GET /projects/:id/news', ({ projectId }) => ({ id: projectId }))
      endpoint('projectOrders', 'GET /projects/:id/orders', ({ projectId }) => ({ id: projectId }))
      endpoint('projectRewards', 'GET /projects/:id/rewards', ({ projectId }) => ({ id: projectId }))
      endpoint('projectSupporter', 'GET /projects/:id/supporters', ({ projectId }) => ({ id: projectId }))
      endpoint('proposal', 'GET /proposals/:id', ({ token }) => ({ id: token }))
      endpoint('sdgs', 'GET /sdgs', () => ({}))
      endpoint('search', 'GET /search/projects', () => ({}))
      endpoint('subscriptionsOrders', 'GET /subscriptions/:id/orders', ({ subscriptionId }) => ({ id: subscriptionId }))
      endpoint('tags', 'GET /tags', () => ({}))
      endpoint('thread', 'GET /threads/:id', ({ threadId }) => ({ id: threadId }))
      endpoint('user', 'GET /users/:id', ({ userId }) => ({ id: userId }))
      endpoint('userAddresses', 'GET /users/:id/addresses', ({ userId }) => ({ id: userId }))
      endpoint('userOrders', 'GET /users/:id/orders', ({ userId }) => ({ id: userId }))
      endpoint('userReviews', 'GET /users/:id/reviews', ({ userId }) => ({ id: userId }))
      endpoint('variant', 'GET /rewards/:id', ({ variantId }) => ({ id: variantId }))
      endpoint('withdrawals', 'GET /projects/:id/withdrawals', ({ projectId }) => ({ id: projectId }))
    },
    PATCH: (endpoint) => {
      endpoint('addresses', 'PATCH /addresses/:id', ({ addressId }) => ({ id: addressId }))
      endpoint('proposal', 'PATCH /proposals/:id', ({ token }) => ({ id: token }))
      endpoint('member', 'PATCH /members/:id', ({ memberId }) => ({ id: memberId }))
      endpoint('project', 'PATCH /projects/:id', ({ projectId }) => ({ id: projectId }))
      endpoint('projectImpact', 'PATCH /projects/:id/impact', ({ projectId }) => ({ id: projectId }))
      endpoint('order', 'PATCH /orders/:id', ({ orderId }) => ({ id: orderId }))
      endpoint('review', 'PATCH /reviews/:id', ({ reviewId }) => ({ id: reviewId }))
      endpoint('user', 'PATCH /users/:id', ({ userId }) => ({ id: userId }))
      endpoint('userAvatar', 'PATCH /users/:id/avatar', ({ userId }) => ({ id: userId }))
    },
    POST: (endpoint) => {
      endpoint('accounts', 'POST /users/:id/accounts', ({ userId }) => ({ id: userId }))
      endpoint('cancelOrder', 'POST /orders/:id/cancel', ({ orderId }) => ({ id: orderId }))
      endpoint('cancelSubscription', 'POST /subscriptions/:id/cancel', ({ subscriptionId }) => ({ id: subscriptionId }))
      endpoint('commentReplies', 'POST /comments/:id/replies', ({ commentId }) => ({ id: commentId }))
      endpoint('likeProject', 'POST /projects/:id/like', ({ projectId }) => ({ id: projectId }))
      endpoint('link', 'POST /links', () => ({}))
      endpoint('markOrderAsDelivered', 'POST /orders/:id/mark-as-delivered', ({ orderId }) => ({ id: orderId }))
      endpoint('markOrderAsUndelivered', 'POST /orders/:id/mark-as-undelivered', ({ orderId }) => ({ id: orderId }))
      endpoint('newsComment', 'POST /news/:id/comments', ({ newsId }) => ({ id: newsId }))
      endpoint('orderReturn', 'POST /orders/:id/returns', ({ orderId }) => ({ id: orderId }))
      endpoint('passwordScore', 'POST /passwords/score', () => ({}))
      endpoint('projectAnalytics', 'POST /projects/:id/analytics', ({ projectId }) => ({ id: projectId }))
      endpoint('projectCancel', 'POST /projects/:id/cancel', ({ projectId }) => ({ id: projectId }))
      endpoint('projectComment', 'POST /projects/:id/comments', ({ projectId }) => ({ id: projectId }))
      endpoint('projectComputeExamples', 'POST /projects/:id/compute-examples', ({ projectId }) => ({ id: projectId }))
      endpoint('projectExports', 'POST /projects/:id/exports', ({ projectId }) => ({ id: projectId }))
      endpoint('projectEnd', 'POST /projects/:id/end', ({ projectId }) => ({ id: projectId }))
      endpoint('projectFaq', 'POST /projects/:id/faq', ({ projectId }) => ({ id: projectId }))
      endpoint('projectImage', 'POST /projects/:id/images', ({ projectId }) => ({ id: projectId }))
      endpoint('projectMember', 'POST /projects/:id/members', ({ projectId }) => ({ id: projectId }))
      endpoint('projectOrders', 'POST /projects/:id/orders', ({ projectId }) => ({ id: projectId }))
      endpoint('projectPublish', 'POST /projects/:id/publish', ({ projectId }) => ({ id: projectId }))
      endpoint('projectSubmit', 'POST /projects/:id/submit', ({ projectId }) => ({ id: projectId }))
      endpoint('projectSubscriptions', 'POST /projects/:id/subscriptions', ({ projectId }) => ({ id: projectId }))
      endpoint('projectVideo', 'POST /projects/:id/videos', ({ projectId }) => ({ id: projectId }))
      endpoint('proposals', 'POST /proposals', () => ({}))
      endpoint('proposalRefuse', 'POST /proposals/:id/refuse', ({ token }) => ({ id: token }))
      endpoint('userProposals', 'POST /users/:id/proposals', ({ userId }) => ({ id: userId }))
      endpoint('renewOrder', 'POST /orders/:id/renew', ({ orderId }) => ({ id: orderId }))
      endpoint('rewardReview', 'POST /rewards/:id/reviews', ({ variantId }) => ({ id: variantId }))
      endpoint('shippingRates', 'POST /shipping-rates', () => ({}))
      endpoint('subscribeNewsLetter', 'POST /newsletters/monthly', () => ({}))
      endpoint('unlikeProject', 'POST /projects/:id/unlike', ({ projectId }) => ({ id: projectId }))
      endpoint('userAddresses', 'POST /users/:id/addresses', ({ userId }) => ({ id: userId }))
      endpoint('userImage', 'POST /users/:id/images', ({ userId }) => ({ id: userId }))
      endpoint('withdrawals', 'POST /projects/:id/withdrawals', ({ projectId }) => ({ id: projectId }))
    },
    PUT: () => {
      //empty
    },
  }),
  endpoints: {
    me: { url: '/me' },
  },
} as unknown as Api
