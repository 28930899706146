import { lens } from '@owl-nest/config'

type Window = {
  UFE: {
    cookies: {
      access_token: string
      refresh_token: string
    }
    urls: {
      refresh_token: string
    }
    api: {
      ulule: {
        url: string
      }
    }
  }
}

const windowLens = lens<Window>()

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
      }
    : window

export const ACCESS_TOKEN_COOKIE_NAME = windowLens
  .get('UFE')
  .get('cookies')
  .get('access_token')
  .value(global, { forgive: true })

export const REFRESH_TOKEN_COOKIE_NAME = windowLens
  .get('UFE')
  .get('cookies')
  .get('refresh_token')
  .value(global, { forgive: true })

export const REFRESH_TOKEN_ENDPOINT = windowLens
  .get('UFE')
  .get('urls')
  .get('refresh_token')
  .value(global, { forgive: true })

export const ROOT_API_URL =
  windowLens.get('UFE').get('api').get('ulule').get('url').value(global, { forgive: true }) || ''
