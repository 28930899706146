import * as React from 'react'
import styled from 'styled-components'

import * as S from '../../styles'
import * as colors from '../../constants/colors'

export type MiniChannelCardProps = {
  backgroundColor: string
  image: string | undefined
  title: string
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

function MiniChannelCardComponent({
  backgroundColor,
  href,
  image,
  title,
}: MiniChannelCardProps): React.ReactElement<MiniChannelCardProps> {
  return (
    <S.card.channel.MiniWrapper backgroundColor={backgroundColor} href={href} image={image}>
      <S.card.channel.MiniTitle>{title}</S.card.channel.MiniTitle>
    </S.card.channel.MiniWrapper>
  )
}

export const MiniChannelCard = styled(MiniChannelCardComponent)``
MiniChannelCard.displayName = 'MiniChannelCard'

type MiniChannelCardSkeletonProps = {
  className?: string
}

function MiniChannelCardSkeletonComponent({ className }: MiniChannelCardSkeletonProps) {
  return (
    <S.card.channel.MiniWrapper backgroundColor={colors.GREY_SHADE_5} className={className}>
      <S.card.channel.MiniTitle>
        <S.skeleton.Text size="medium" width={100}></S.skeleton.Text>
      </S.card.channel.MiniTitle>
    </S.card.channel.MiniWrapper>
  )
}
export const MiniChannelCardSkeleton = styled(MiniChannelCardSkeletonComponent)``
