import * as React from 'react'
import styled from 'styled-components'

import { t } from '@owl-nest/localize'
import * as plume from '@ulule/owl-kit-components/next'

import { SubmitForm } from './SubmitForm'
import { SubmitSuccess } from './SubmitSuccess'
import type { Project, SubmitFormValue } from './types'

enum Status {
  PRISTINE = 'PRISTINE',
  SUBMITTED = 'SUBMITTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

type ProjectSubmissionModalProps = {
  onClose: () => void
  onSuccessClose?: () => void
  onSubmit: (value: SubmitFormValue) => Promise<boolean | undefined>
  open: boolean
  project: Project
}

export function ProjectSubmissionModal({
  onClose,
  onSuccessClose,
  onSubmit,
  open,
  project,
}: ProjectSubmissionModalProps): React.ReactElement<ProjectSubmissionModalProps> {
  const [status, setStatus] = React.useState<Status>(Status.PRISTINE)
  const manager = project.manager

  return (
    <ProjectSubmissionModalWrapper
      open={open}
      closable={true}
      onClose={onClose}
      icon={<plume.illustrations.twoToned.Checklist size={140} />}
      svgScale={0.9}
    >
      {status === Status.PRISTINE ? (
        <>
          <SubmitForm onSubmit={handleSubmit} />
          <plume.LinkAsButton onClick={onClose}>{t('Later')}</plume.LinkAsButton>
        </>
      ) : (
        status === Status.SUCCESS && (
          <>
            <SubmitSuccess manager={manager} />
            <plume.LinkAsButton kind="secondary" onClick={onSuccessClose}>
              {t('Back to project')}
            </plume.LinkAsButton>
          </>
        )
      )}
    </ProjectSubmissionModalWrapper>
  )

  async function handleSubmit(values: SubmitFormValue) {
    const promise = onSubmit(values)
    setStatus(Status.SUBMITTED)

    const status = await promise
    setStatus(status ? Status.SUCCESS : Status.FAILURE)
  }
}

const ProjectSubmissionModalWrapper = styled(plume.ModalWithIcon)`
  ${plume.styles.modal.Body} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
