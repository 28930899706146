import { Lang } from '@owl-nest/api-client/latest'
import { lens } from '@owl-nest/config'

type UFE = {
  locale: string
}

type Window = {
  UFE: UFE
}

const windowLens = lens<Window>()

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
      }
    : window

export const USER_LOCALE = windowLens.get('UFE').get('locale').value(global, { forgive: true, silent: true }) as Lang
