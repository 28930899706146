import { Project } from '@owl-nest/api-client/latest'

import { getAnalyticsTags } from '../analyticsTags'
import { SafePush } from '../safePush'
import { BrandTracking } from './types'
import { EVENT_TYPE } from '../types'

export function tracking(safePush: SafePush, project?: Project<'analytics'>): BrandTracking {
  const analyticsTags = getAnalyticsTags(project)

  return {
    track: ({ eventAction, eventLabel, eventValue = undefined, ...params }) => {
      safePush({
        ...params,
        event: EVENT_TYPE.BRAND,
        eventCategory: 'Brand visibility',
        eventAction,
        eventLabel,
        eventValue,
        ...(analyticsTags !== undefined && { poTagIDs: analyticsTags }),
      })
    },
  }
}
