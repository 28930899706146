import * as React from 'react'
import 'intersection-observer'

import { useDramaticallyInefficientLocation } from '@owl-nest/hooks/src/useDramaticallyInefficientLocation'

import { SimpleBrandEvent } from './brand/types'
import { BRAND_ACTION } from './types'
import { useTracking } from './useTracking'

declare global {
  interface Window {
    BRANDS_ON_PAGE: string[]
  }
}

if (typeof window !== 'undefined') {
  window.BRANDS_ON_PAGE = []
}

type Config = {
  click?: {
    enabled?: boolean
    once?: boolean
  }
  eventPosition?: string
  /**
   * If falsy, limits unique (once = true) events to one Location, and re-tracks them when Location changes.
   * Enabling this allows ignoring Location change when keeping record of already tracked events. Defaults to `false`.
   */
  ignoreLocationChange?: boolean
  impression?: {
    enabled?: boolean
    once?: boolean
  }
}

type PartnershipTracker = {
  sendBrandsOnPage: () => void
}

export function usePartnershipTracker(
  partnershipUserId: number | undefined,
  ref: React.RefObject<HTMLElement>,
  config: Config,
): PartnershipTracker {
  const { brand } = useTracking()
  const location = useDramaticallyInefficientLocation()

  if (partnershipUserId === undefined) {
    return { sendBrandsOnPage }
  }

  const ignoreLocationChange = config.ignoreLocationChange ?? false

  const trackClickEnabled = config?.click?.enabled ?? true
  const trackClickOnce = config?.click?.once ?? true

  const trackImpressionEnabled = config?.impression?.enabled ?? true
  const trackImpressionOnce = config?.impression?.once ?? true

  const brandId = `_${partnershipUserId}_`

  React.useEffect(() => {
    if (!window.BRANDS_ON_PAGE.includes(brandId)) {
      window.BRANDS_ON_PAGE.push(brandId)
    }
    if (trackClickEnabled && ref.current) {
      ref.current.addEventListener('click', trackClick)
    }
    if (trackImpressionEnabled && ref.current) {
      const observer = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            trackImpression()
          }
        }
      })
      observer.observe(ref.current)
    }
  }, [])

  const triggered = React.useRef(new Set())

  React.useEffect(() => {
    if (!ignoreLocationChange) {
      triggered.current.clear()
    }
  }, [ignoreLocationChange, location])

  return { sendBrandsOnPage }

  // TODO: Improve this behaviour to ensure sending the event once initially visible brands are ready.
  // To achieve that, this should probably live as a HoC/hook on its own.
  function sendBrandsOnPage(): void {
    if (window.BRANDS_ON_PAGE && window.BRANDS_ON_PAGE.length > 0) {
      const brandEvent: SimpleBrandEvent = {
        eventAction: BRAND_ACTION.ON_PAGE,
        eventLabel: window.BRANDS_ON_PAGE.join(','),
        nonInteractionEvent: true,
      }

      brand.track(brandEvent)
    }
  }

  function trackClick(event?: MouseEvent): void {
    const brandEvent: SimpleBrandEvent = {
      eventAction: BRAND_ACTION.CLICK,
      eventLabel: brandId,
      linkUrl: (event?.target as any).href,
      ...(config.eventPosition && { logoId: config.eventPosition }),
    }

    if (trackClickOnce && triggered.current.has(brandEvent.eventAction)) {
      return
    }
    triggered.current.add(brandEvent.eventAction)

    brand.track(brandEvent)
  }

  function trackImpression(): void {
    const brandEvent: SimpleBrandEvent = {
      eventAction: BRAND_ACTION.IMPRESSION,
      eventLabel: brandId,
      ...(config.eventPosition && { logoId: config.eventPosition }),
      nonInteractionEvent: true,
    }

    if (trackClickEnabled) {
      brandEvent.eventAction = BRAND_ACTION.IMPRESSION_CLICKABLE
    }

    if (trackImpressionOnce && triggered.current.has(brandEvent.eventAction)) {
      return
    }
    triggered.current.add(brandEvent.eventAction)

    brand.track(brandEvent)
  }
}
