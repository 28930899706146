/** Either is a object allowing to group two possible outcomes (LEFT and RIGHT). If a computation can
 * have two outcomes (a result or an error for exemple), this class allows you to bundle those two outcomes
 * as one object, leaving you the opportunity to process the error further down the process.
 */
interface EitherInterface<LEFT, RIGHT> {
  /** Assert that the outcome is "LEFT" */
  isLeft(): boolean
  /** Assert that the outcome is "RIGHT" */
  isRight(): boolean
  /** Chain the map function if the outcome is "RIGHT", do nothing if it is "LEFT" */
  next<MAPPED>(map: (right: RIGHT) => MAPPED | Either<LEFT, MAPPED>): Either<LEFT, MAPPED>
  /** Chain the map async function if the outcome is "RIGHT", do nothing if it is "LEFT" */
  nextAsync<MAPPED>(map: (right: RIGHT) => Promise<MAPPED | Either<LEFT, MAPPED>>): Promise<Either<LEFT, MAPPED>>
  /** Chain the map function if the outcome is "LEFT", do nothing if it is "RIGHT". Allows to handle "LEFT" outcomes */
  except<MAPPED>(map: (left: LEFT) => RIGHT | Either<MAPPED, RIGHT>): Either<MAPPED, RIGHT>
  /** Chain the map async function if the outcome is "LEFT", do nothing if it is "RIGHT". Allows to handle "LEFT" outcomes */
  exceptAsync<MAPPED>(map: (left: LEFT) => Promise<RIGHT | Either<MAPPED, RIGHT>>): Promise<Either<MAPPED, RIGHT>>
  /** Do something if the outcomes is "LEFT", something else if it is "RIGHT" */
  caseOf<MAPPED>(pattern: { left: (left: LEFT) => MAPPED; right: (right: RIGHT) => MAPPED }): MAPPED
  /** Return the outcome if it is "RIGHT", throws otherwise */
  unwrap(): RIGHT
  /** Return the outcome if it is "LEFT", throws otherwise */
  unwrapLeft(): LEFT
  /** Return Promise that resolve to the "RIGHT" outcome, or reject to the "LEFT" outcome */
  toPromise(): Promise<RIGHT>
  /** Return the "RIGHT" outcome, or throws the "LEFT" outcome */
  doThrow(): RIGHT
}

export class Either<LEFT, RIGHT> implements EitherInterface<LEFT, RIGHT> {
  _inside: Left<LEFT, RIGHT> | Right<LEFT, RIGHT>
  constructor(params: { type: 'left'; value: LEFT } | { type: 'right'; value: RIGHT }) {
    if (params.type === 'right') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      this._inside = new Right(params.value)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      this._inside = new Left(params.value)
    }
  }

  /** Create a "LEFT" outcome */
  static left<LEFT, RIGHT>(value: LEFT): Either<LEFT, RIGHT> {
    return new Either<LEFT, RIGHT>({ type: 'left', value })
  }

  /** Create a "RIGHT" outcome */
  static right<LEFT, RIGHT>(value: RIGHT): Either<LEFT, RIGHT> {
    return new Either<LEFT, RIGHT>({ type: 'right', value })
  }

  /** Create a "RIGHT" outcome */
  static of<LEFT, RIGHT>(value: RIGHT): Either<LEFT, RIGHT> {
    return new Either<LEFT, RIGHT>({ type: 'right', value })
  }

  isLeft(): boolean {
    return this._inside.isLeft()
  }

  isRight(): boolean {
    return this._inside.isRight()
  }

  next<MAPPED>(map: (right: RIGHT) => MAPPED | Either<LEFT, MAPPED>): Either<LEFT, MAPPED> {
    return this._inside.next(map)
  }

  async nextAsync<MAPPED>(
    map: (right: RIGHT) => Promise<MAPPED | Either<LEFT, MAPPED>>,
  ): Promise<Either<LEFT, MAPPED>> {
    return this._inside.nextAsync(map)
  }

  except<MAPPED = LEFT>(map: (left: LEFT) => RIGHT | Either<MAPPED, RIGHT>): Either<MAPPED, RIGHT> {
    return this._inside.except(map)
  }

  async exceptAsync<MAPPED>(
    map: (left: LEFT) => Promise<RIGHT | Either<MAPPED, RIGHT>>,
  ): Promise<Either<MAPPED, RIGHT>> {
    return this._inside.exceptAsync(map)
  }

  caseOf<MAPPED>(pattern: { left: (left: LEFT) => MAPPED; right: (right: RIGHT) => MAPPED }): MAPPED {
    return this._inside.caseOf(pattern)
  }

  unwrap(): RIGHT {
    return this._inside.unwrap()
  }

  unwrapLeft(): LEFT {
    return this._inside.unwrapLeft()
  }

  toPromise(): Promise<RIGHT> {
    return this._inside.toPromise()
  }

  doThrow(): RIGHT {
    return this._inside.doThrow()
  }
}

class Left<LEFT, RIGHT> implements EitherInterface<LEFT, RIGHT> {
  value: LEFT
  constructor(value: LEFT) {
    this.value = value
  }

  isLeft(): boolean {
    return true
  }
  isRight(): boolean {
    return false
  }

  next<MAPPED>(_map: (right: RIGHT) => MAPPED | Either<LEFT, MAPPED>): Either<LEFT, MAPPED> {
    return Either.left(this.value)
  }

  async nextAsync<MAPPED>(
    _map: (right: RIGHT) => Promise<MAPPED | Either<LEFT, MAPPED>>,
  ): Promise<Either<LEFT, MAPPED>> {
    return Either.left<LEFT, MAPPED>(this.value)
  }

  except<MAPPED = LEFT>(map: (left: LEFT) => RIGHT | Either<MAPPED, RIGHT>): Either<MAPPED, RIGHT> {
    const mapped = map(this.value)
    if (mapped instanceof Either) {
      return mapped
    }
    return Either.right(mapped)
  }

  async exceptAsync<MAPPED>(
    map: (left: LEFT) => Promise<RIGHT | Either<MAPPED, RIGHT>>,
  ): Promise<Either<MAPPED, RIGHT>> {
    const mapped = await map(this.value)
    if (mapped instanceof Either) {
      return mapped
    }
    return Either.right(mapped)
  }

  caseOf<MAPPED>(pattern: { left: (left: LEFT) => MAPPED; right: (right: RIGHT) => MAPPED }): MAPPED {
    return pattern.left(this.value)
  }

  unwrap(): RIGHT {
    throw Error("can't unwrap a Left")
  }

  unwrapLeft(): LEFT {
    return this.value
  }

  toPromise(): Promise<RIGHT> {
    return Promise.reject(this.value)
  }

  doThrow(): RIGHT {
    throw this.value
  }
}

class Right<LEFT, RIGHT> implements EitherInterface<LEFT, RIGHT> {
  value: RIGHT
  constructor(value: RIGHT) {
    this.value = value
  }

  isLeft(): boolean {
    return false
  }
  isRight(): boolean {
    return true
  }

  next<MAPPED>(map: (right: RIGHT) => MAPPED | Either<LEFT, MAPPED>): Either<LEFT, MAPPED> {
    const mapped = map(this.value)
    if (mapped instanceof Either) {
      return mapped
    }
    return Either.right<LEFT, MAPPED>(mapped)
  }

  async nextAsync<MAPPED>(
    map: (right: RIGHT) => Promise<MAPPED | Either<LEFT, MAPPED>>,
  ): Promise<Either<LEFT, MAPPED>> {
    const mapped = await map(this.value)
    if (mapped instanceof Either) {
      return mapped
    }
    return Either.right<LEFT, MAPPED>(mapped)
  }

  except<MAPPED = LEFT>(_map: (left: LEFT) => RIGHT | Either<MAPPED, RIGHT>): Either<MAPPED, RIGHT> {
    return Either.right(this.value)
  }

  async exceptAsync<MAPPED>(
    _map: (left: LEFT) => Promise<RIGHT | Either<MAPPED, RIGHT>>,
  ): Promise<Either<MAPPED, RIGHT>> {
    return Either.right(this.value)
  }

  caseOf<MAPPED>(pattern: { left: (left: LEFT) => MAPPED; right: (right: RIGHT) => MAPPED }): MAPPED {
    return pattern.right(this.value)
  }

  unwrap(): RIGHT {
    return this.value
  }

  unwrapLeft(): LEFT {
    throw Error("can't unwrapLeft a Right")
  }

  toPromise(): Promise<RIGHT> {
    return Promise.resolve(this.value)
  }

  doThrow(): RIGHT {
    return this.value
  }
}
