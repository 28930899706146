import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'
import * as api from '@owl-nest/api-client/latest'
import { t } from '@owl-nest/localize'
import * as UFE from '../UFE'
import { FOOTER_BREAKPOINT } from './constants'

type ManifestoProps = {
  className?: string
  lang: api.Lang
}

function ManifestoComponent({ className, lang }: ManifestoProps) {
  return (
    <div className={className}>
      <plume.styles.heading.XXXXXS>{t('Proudly B Corp since 2015')}</plume.styles.heading.XXXXXS>
      {lang === 'fr' ? <plume.logos.bcorp.FrLogo /> : <plume.logos.bcorp.EnLogo />}

      <plume.Link underlined href={`${UFE.ULULE_ORIGINS[lang]}/about/ulule/`}>
        {t('Read our manifesto')}
      </plume.Link>
    </div>
  )
}

export const Manifesto = styled(ManifestoComponent)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px 0;

  @media screen and ${FOOTER_BREAKPOINT} {
    margin: 15px 0;
  }

  ${plume.Link} {
    align-items: center;
    display: flex;
    font-size: 13px; // HACK: Irregular font manipulation
    justify-content: center;
    margin-top: 21px;
    text-align: center;
    width: 100%;

    @media screen and ${FOOTER_BREAKPOINT} {
      margin: 15px 0;
      width: fit-content;
    }
  }

  ${plume.styles.heading.XXXXXS} {
    text-transform: none; // HACK: Irregular font manipulation
    font-weight: 500; // HACK: Irregular font manipulation
    font-size: 13px; // HACK: Irregular font manipulation
    color: ${plume.COLORS.PRIMARY_BLACK}; // HACK: Irregular font manipulation
    text-align: center;
    max-width: 350px;

    @media screen and ${plume.BREAKPOINTS.MOBILE_L} {
      text-align: right;
    }
  }

  ${plume.logos.bcorp.FrLogo}, ${plume.logos.bcorp.EnLogo} {
    margin: 0 15px;
  }
`

const BCorpoLink = styled.a`
  margin: 0 15px;
`
