import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../icons/glyphs'
import * as S from '../styles'

type AccordionProps = {
  children: React.ReactNode
  className?: string
  closedContent?: React.ReactNode
  id?: string
  title: React.ReactNode
} & (
  | {
      onToggle: () => void
      onToggleIcon?: () => void
      open: boolean
    }
  | {
      onToggle?: undefined
      onToggleIcon?: undefined
      open?: undefined
    }
)

function AccordionComponent({
  children,
  className,
  closedContent,
  id,
  onToggle,
  onToggleIcon,
  open,
  title,
}: AccordionProps): React.ReactElement<AccordionProps> {
  const stableRandomId = React.useId()
  const stableId = id ?? stableRandomId

  const handleIconClick = (event: React.MouseEvent) => {
    if (onToggleIcon) {
      event.preventDefault()
      onToggleIcon()
    }
  }

  return (
    <S.accordion.Wrapper className={className}>
      <S.accordion.Toggler type="checkbox" id={stableId} checked={open} onChange={onToggle} />
      <S.accordion.Title htmlFor={stableId}>
        <S.copy.M as="h3">{title}</S.copy.M>
        <glyphs.stroke.CaretDown onClick={handleIconClick} />
        <glyphs.stroke.CaretUp onClick={handleIconClick} />
      </S.accordion.Title>
      <S.accordion.Content open={open}>{children}</S.accordion.Content>
      {closedContent && <S.accordion.ClosedContent>{closedContent}</S.accordion.ClosedContent>}
    </S.accordion.Wrapper>
  )
}
export const Accordion = styled(AccordionComponent)``
