import * as api from '@owl-nest/api-client/latest'
import { t } from '@owl-nest/localize'

import * as UFE from './UFE'

export type NavSection = {
  items: {
    label: string
    href: string
    openInNewTab?: boolean
    language?: api.Lang
  }[]
  title: string
  href?: string
}

export type NavSections = NavSection[]

export function getNavSectionList(lang: api.Lang): NavSections {
  if (lang === 'fr') {
    return [
      {
        title: 'Ulule Crowdfunding',
        href: 'https://fr.ulule.com',
        items: [
          {
            label: t('Start a fundraising'),
            href: `${UFE.ULULE_ORIGINS[lang]}/projects/create/`,
          },
          {
            label: 'Voir toutes les collectes',
            href: `${UFE.ULULE_ORIGINS[lang]}/discover/`,
          },
          {
            label: 'Appels à projets',
            href: `${UFE.ULULE_ORIGINS[lang]}/pages/appel-a-projet/`,
          },
          {
            label: 'Foire aux questions',
            href: 'https://support.ulule.com/hc/fr-fr',
            openInNewTab: true,
          },
          {
            label: 'Boîte à outils',
            href: `https://outils.ulule.com/`,
          },
          {
            label: 'Les bases du crowdfunding',
            href: 'https://outils.ulule.com/guides/comprendre-crowdfunding-financement-participatif',
          },
          {
            label: 'Bons plans',
            href: 'https://outils.ulule.com/bons-plans',
          },
          {
            label: 'Logo / Kits de campagne',
            href: 'https://ulule.frontify.com/d/EX3dK8qsXgqh/branding-guidelines?#/basics/logotype',
            openInNewTab: true,
          },
          {
            label: 'Statistiques',
            href: `${UFE.ULULE_ORIGINS[lang]}/stats/`,
          },
        ],
      },
      {
        title: 'Ulule Formation',
        href: 'https://formation.ulule.com',
        items: [
          {
            label: 'Formation entrepreneuriat',
            href: `https://formation.ulule.com/formations/entrepreneuriat`,
          },
          {
            label: 'Formation crowdfunding',
            href: 'https://formation.ulule.com/formations/crowdfunding',
          },
          {
            label: 'Formation marketing digital',
            href: 'https://formation.ulule.com/formations/webmarketing',
          },
          {
            label: 'Bilan de compétences',
            href: 'https://formation.ulule.com/formations/bilan-de-competences',
          },
          {
            label: 'Financer ma formation',
            href: 'https://formation.ulule.com/financer-formation',
          },
          {
            label: 'Ateliers en entreprise',
            href: 'https://partenaires.ulule.com/formations-entreprise',
          },
          {
            label: 'Fresque du climat',
            href: 'https://partenaires.ulule.com/formations/fresque-du-climat',
          },
          {
            label: 'Atelier  2 tonnes',
            href: `https://partenaires.ulule.com/formations/2-tonnes`,
          },
          {
            label: 'Reconversion',
            href: 'https://formation.ulule.com/reconversion',
          },
        ],
      },
      {
        title: 'Ulule Boutique',
        href: 'https://boutique.ulule.com',
        items: [
          {
            label: 'Idées cadeaux',
            href: 'https://boutique.ulule.com/idees-cadeaux',
          },
          {
            label: 'Carte cadeau éthique',
            href: 'https://boutique.ulule.com/produit/carte-cadeau-ulule-boutique-7070601576631',
          },
          {
            label: 'Sélection du moment',
            href: 'https://boutique.ulule.com/produits',
          },
          {
            label: 'Bonnes affaires',
            href: 'https://boutique.ulule.com/produits?hasCompareAtPrice=true',
          },
          {
            label: 'Newsletter Bien ou Bien',
            href: 'https://www.bienoubien.com/infolettre',
            openInNewTab: true,
          },
          {
            label: 'Guide des marques engagées',
            href: 'https://www.bienoubien.com/',
            openInNewTab: true,
          },
          {
            label: 'Vendre sur Ulule Boutique',
            href: 'https://outils.ulule.com/articles/vendre-sur-ulule-boutique',
            openInNewTab: true,
          },
        ],
      },
      {
        title: 'À propos d’Ulule',
        items: [
          {
            label: 'Découvrir Ulule',
            href: `${UFE.ULULE_ORIGINS[lang]}/pages/discover/`,
          },
          {
            label: 'Nous contacter',
            href: `${UFE.ULULE_ORIGINS[lang]}/about/contact/`,
          },
          {
            label: 'Visite vidéo',
            href: `${UFE.ULULE_ORIGINS[lang]}/pages/visite`,
          },
          {
            label: 'Devenir partenaire',
            href: 'https://partenaires.ulule.com/',
          },
          {
            label: 'Organiser un appel à projets',
            href: 'https://partenaires.ulule.com/organiser-appel-projets',
          },
          {
            label: 'API',
            href: 'https://developers.ulule.com/',
          },
          {
            label: 'Équipe',
            href: `${UFE.ULULE_ORIGINS[lang]}/about/team/`,
          },
          {
            label: 'Presse',
            href: `${UFE.ULULE_ORIGINS[lang]}/about/press/`,
          },
          {
            label: 'Newsletter',
            href: `${UFE.ULULE_ORIGINS[lang]}/newsletter/`,
          },
        ],
      },
    ]
  } else {
    return [
      {
        title: t('Help and resources'),
        items: [
          {
            label: t('Start a fundraising'),
            href: `${UFE.ULULE_ORIGINS[lang]}/projects/create?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage`,
          },
          {
            label: t('FAQ'),
            href: `https://support.ulule.com/hc/`,
          },
          {
            label: t('Contact us'),
            href: `${UFE.ULULE_ORIGINS[lang]}/about/contact-us?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage`,
          },
          {
            label: t('Statistics'),
            href: `${UFE.ULULE_ORIGINS[lang]}/stats?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage`,
          },
          {
            label: t('Open API'),
            href: 'https://developers.ulule.com?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage',
          },
          {
            label: t('Branding guidelines'),
            href: `https://ulule.frontify.com`,
          },
        ],
      },
      {
        title: t('Focus on'),
        items: [
          /* HACK: Cannot solely rely on i18n.get() on ulule/next since it depends on "ufe.locale", which doesn't reflect the real-world value. */
          {
            label: t('How works Ulule?'),
            href: {
              ca: 'https://community.ulule.com/topics/guia-de-creacion-de-tu-campana-de-crowdfunding-16917/#main-30775?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage',
              de: 'https://support.ulule.com/hc/de-de/articles/211812489',
              en: 'https://support.ulule.com/hc/en-us/articles/211812489-What-is-Ulule-',
              es: 'https://community.ulule.com/topics/guia-de-creacion-de-tu-campana-de-crowdfunding-16917/#main-30775?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage',
              fr: 'https://formations.ulule.com/bilan-de-competences?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage',
              it: 'https://community.ulule.com/topics/tre-cerchi-del-finanziamento-collettivo-crowdfunding-8749?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage',
              nl: 'https://support.ulule.com/hc/nl/articles/211812489-Ulule-wat-is-dat-',
              pt: 'https://support.ulule.com/hc/pt-pt/articles/211812489',
            }[lang],
          },
          {
            label: t('Supported countries'),
            href: {
              ca: 'https://support.ulule.com/hc/ca/articles/10510451394449-Quina-%C3%A9s-la-difer%C3%A8ncia-entre-els-m%C3%A8todes-de-recaptaci%C3%B3-de-fons',
              de: 'https://support.ulule.com/hc/de-de/articles/212423305',
              en: 'https://support.ulule.com/hc/en-us/articles/212423305-From-which-country-can-I-start-a-crowdfunding-campaign-on-Ulule-',
              es: 'https://support.ulule.com/hc/es-es/articles/10510451394449--Cu%C3%A1l-es-la-diferencia-entre-los-tres-m%C3%A9todos-de-recaudaci%C3%B3n',
              fr: 'https://formations.ulule.com/crowdfunding?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage',
              it: 'https://community.ulule.com/topics/le-campagne-di-crowdfunding-e-il-loro-obiettivo-finanziario-come-calcolarlo-e-quando-perche-applicare-la-strategia-dello-step-by-step-13945?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage',
              nl: 'https://support.ulule.com/hc/nl/articles/212423305-Vanuit-welk-land-mag-ik-een-campagne-lanceren-op-Ulule-',
              pt: 'https://support.ulule.com/hc/pt-pt/articles/212423305',
            }[lang],
          },
          {
            label: t('Eligible projects'),
            href: {
              ca: 'https://support.ulule.com/hc/ca/articles/212423325',
              de: 'https://support.ulule.com/hc/de-de/articles/212423325',
              en: 'https://support.ulule.com/hc/en-us/articles/212423325-What-kinds-of-projects-are-eligible-',
              es: 'https://support.ulule.com/hc/es-es/articles/212423325',
              fr: 'https://formations.ulule.com/formation-webmarketing?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage',
              it: 'https://community.ulule.com/topics/il-retroplanning-per-aumentare-le-chance-di-successo-della-propria-campagna-di-crowdfunding-9962?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage',
              nl: 'https://support.ulule.com/hc/nl/articles/212423325-Welke-projecten-komen-in-aanmerking-',
              pt: 'https://support.ulule.com/hc/pt-pt/articles/212423325',
            }[lang],
          },
        ],
      },
      {
        title: t('About ulule'),
        items: [
          {
            label: t('Discover Ulule'),
            href: `${UFE.ULULE_ORIGINS[lang]}/pages/discover/?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage`,
          },
          {
            label: 'Ulule partners',
            href: `https://partenaires.ulule.com/espana`,
            language: 'es',
          },
          {
            label: 'Ulule partners',
            href: `https://partenaires.ulule.com/espana`,
            language: 'ca',
          },
          {
            label: t('Team'),
            href: `${UFE.ULULE_ORIGINS[lang]}/about/team?utm_source=ulule&utm_medium=footer&utm_campaign=bas_de_homepage`,
          },
        ],
      },
    ]
  }
}
