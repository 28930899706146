import * as yup from 'yup'

export function email() {
  return new EmailSchema()
}

export class EmailSchema extends yup.StringSchema {
  constructor() {
    super()

    // HACK
    // @ts-expect-error: schema.type is supposed to be read-only, but who cares, right ?
    this.type = 'email'

    this.withMutation((schema) => {
      return schema.email()
    })
  }
}
