import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import { AuthSection, AuthSectionProps } from '../../AuthSection'
import { SearchDrawer } from '../../drawers/SearchDrawer'
import * as S from '../../style'

type RightZoneProps = {
  search: {
    action: string
  }
} & AuthSectionProps

export function Crowdfunding({ loadUserExtraData, search, user }: RightZoneProps): React.ReactElement<RightZoneProps> {
  const $separator = React.useRef<HTMLDivElement>(null)

  return (
    <>
      <SearchDrawer
        action={search.action}
        siblingRef={$separator}
        user={user ? { unreadMessagesCount: user.unreadMessagesCount } : undefined}
      />
      <SeparatorContainer ref={$separator}>
        <Separator />
      </SeparatorContainer>
      <AuthSection loadUserExtraData={loadUserExtraData} user={user} />
    </>
  )
}

const Separator = styled(function Separator({ className }: React.SVGAttributes<SVGElement>): React.ReactElement {
  return (
    <svg className={className} width="1" height="31" viewBox="0 0 1 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="31" stroke={plume.COLORS.GREY_SHADE_5} />
    </svg>
  )
})``

const SeparatorContainer = styled.div`
  padding: 0 22px 0 0;
  visibility: hidden;

  svg {
    vertical-align: middle;
  }

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    visibility: visible;
  }
`
