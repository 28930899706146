import * as React from 'react'
import styled from 'styled-components'

import * as link from '../styles/link'

export type LinkProps = link.BaseLinkStyleProps & {
  children?: React.ReactNode
  /** Whether to use a darker blue tint. Only has effect on the primary link */
  darkened?: boolean
  kind?: 'primary' | 'inverted' | 'secondary' | 'important'
  /** Whether to add color tint to the link. Only has effect on the primary link */
  tinted?: boolean
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

const LinkComponent = React.forwardRef<HTMLAnchorElement, LinkProps>(function LinkComponent(
  { children, kind = 'primary', ...props },
  ref,
): React.ReactElement<LinkProps> {
  switch (kind) {
    case 'secondary':
      return (
        <link.Secondary ref={ref} {...props}>
          {children}
        </link.Secondary>
      )
    case 'inverted':
      return (
        <link.Inverted ref={ref} {...props}>
          {children}
        </link.Inverted>
      )
    case 'important':
      return (
        <link.Important ref={ref} {...props}>
          {children}
        </link.Important>
      )
    case 'primary':
    default:
      break
  }

  return (
    <link.Primary ref={ref} {...props}>
      {children}
    </link.Primary>
  )
})

export const Link = styled(LinkComponent)<LinkProps>``
Link.displayName = 'Link'
