/**
 * Do not edit directly
 * Generated on Mon, 06 May 2024 11:28:48 GMT
 */

export const PRIMARY_BLUE_100 = '#e7f8fcff'

export const PRIMARY_BLUE_200 = '#d1edf7ff'

export const PRIMARY_BLUE_500 = '#18a5d6ff'

export const PRIMARY_BLUE_700 = '#007199ff'

export const PRIMARY_BLUE_900 = '#004e69ff'

export const PRIMARY_RED_200 = '#fddce3ff'

export const PRIMARY_RED_500 = '#f01446ff'

export const PRIMARY_RED_700 = '#bd0c33ff'

export const PRIMARY_RED_900 = '#8b0020ff'

export const PRIMARY_GREY_500 = '#bdbdbdff'

export const PRIMARY_GREY_700 = '#363636ff'

export const PRIMARY_GREY_900 = '#232221ff'

export const PRIMARY_GREY_000 = '#ffffffff'

export const PRIMARY_SAND_100 = '#f6f1ebff'

export const PRIMARY_SAND_200 = '#e3ddd3ff'

export const PRIMARY_SAND_500 = '#b8afa4ff'

export const PRIMARY_SAND_700 = '#807a74ff'

export const PRIMARY_SAND_900 = '#59544eff'

export const SECONDARY_GREEN_200 = '#e7f9ecff'

export const SECONDARY_GREEN_500 = '#66dc88ff'

export const SECONDARY_GREEN_800 = '#02715cff'

export const SECONDARY_RED_200 = '#ffecebff'

export const SECONDARY_RED_500 = '#ff4336ff'

export const SECONDARY_YELLOW_200 = '#ffeac9ff'

export const SECONDARY_YELLOW_500 = '#e9ae5aff'

export const SECONDARY_YELLOW_800 = '#8a400bff'

export const SECONDARY_GREY_400 = '#f5f6f9ff'

export const SECONDARY_MINT_300 = '#d9f8f6ff'

export const SECONDARY_MINT_700 = '#2e8d87ff'

export const TERTIARY_SPOTIFY = '#00d95aff'

export const TERTIARY_WHATSAPP = '#25d366ff'

export const TERTIARY_VIMEO = '#1ab7eaff'

export const TERTIARY_X = '#000000ff'

export const TERTIARY_FACEBOOK = '#0574f6ff'

export const TERTIARY_LINKEDIN = '#2867b2ff'

export const TERTIARY_TWITCH = '#9246ffff'

export const TERTIARY_INSTAGRAM = '#f30074ff'

export const TERTIARY_YOUTUBE = '#ff0000ff'

export const TERTIARY_GOOGLE = '#db4437ff'

export const TERTIARY_SOUNDCLOUD = '#ff5500ff'

export const TERTIARY_BANDCAMP = '#61929cff'

export const TERTIARY_MESSENGER = '#057ff5ff'

export const TERTIARY_BLUESKY = '#295ef6ff'

export const TERTIARY_TIKTOK = '#000000ff'

export const TERTIARY_MASTODON = '#6364ffff'

export const TERTIARY_DISCORD = '#5865f2ff'

export const TERTIARY_LINKTREE = '#43e660ff'

export const BRAND_GREEN = '#02715cff'

export const BRAND_PURPLE = '#9e3f78ff'

export const BRAND_YELLOW = '#fdce03ff'

export const BRAND_INDIGO = '#3e51b5ff'

export const BRAND_ORANGE = '#f86802ff'

export const BACKGROUND_PRIMARY = '#ffffffff'

export const BACKGROUND_SECONDARY = '#f6f1ebff'

export const BACKGROUND_TERTIARY = '#e7f8fcff'

export const BACKGROUND_SUCCESS = '#e7f9ecff'

export const BACKGROUND_ERROR = '#ffecebff'

export const BACKGROUND_ALERT = '#fef4e7ff'

export const BACKGROUND_DISABLED = '#f5f6f9ff'

export const BACKGROUND_OVERLAY = '#23222166'

export const BACKGROUND_BUTTON_PRIMARY = '#18a5d6ff'

export const BACKGROUND_BUTTON_PRIMARY_HOVER = '#007199ff'

export const BACKGROUND_BUTTON_SECONDARY = '#f01446ff'

export const BACKGROUND_BUTTON_SECONDARY_HOVER = '#bd0c33ff'

export const BACKGROUND_BUTTON_TERTIARY = '#66dc88ff'

export const BACKGROUND_BUTTON_TERTIARY_HOVER = '#02715cff'

export const BACKGROUND_BUTTON_DISABLED = '#bdbdbdff'

export const BORDER_PRIMARY = '#e3ddd3ff'

export const BORDER_HOVER = '#18a5d6ff'

export const BORDER_SUCCESS = '#66dc88ff'

export const BORDER_ERROR = '#ff4336ff'

export const BORDER_DISABLED = '#bdbdbdff'

export const TEXT_PRIMARY = '#232221ff'

export const TEXT_SECONDARY = '#59544eff'

export const TEXT_TERTIARY = '#807974ff'

export const TEXT_LINK = '#18a5d6ff'

export const TEXT_LINK_HOVER = '#007199ff'

export const TEXT_HEADING_BLUE = '#004e69ff'

export const TEXT_RED = '#f01446ff'

export const TEXT_RED_HOVER = '#bd0c33ff'

export const TEXT_HEADING_RED = '#8b0020ff'

export const TEXT_SUCCESS = '#02715cff'

export const TEXT_ERROR = '#ff4336ff'