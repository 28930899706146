import * as React from 'react'
import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

type MobileNavigationDrawerProps = {
  children: React.ReactNode
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  slideHorizontally?: boolean
  title?: React.ReactNode
}

export function MobileNavigationDrawer({
  children,
  open,
  setOpen,
  slideHorizontally = true,
  title,
}: MobileNavigationDrawerProps): React.ReactElement<MobileNavigationDrawerProps> | null {
  const $triggerRef = React.useRef<HTMLButtonElement>(null)
  const $drawerRef = React.useRef<HTMLDivElement>(null)

  plume.hooks.useOnClickOutside([$drawerRef, $triggerRef], () => setOpen(false))

  return (
    <>
      <Button
        onClick={() => {
          setOpen(!open)
        }}
        ref={$triggerRef}
      >
        <AnimatedBurgerIcon>
          <AnimatedBurgerCeilingBread active={open} />
          <AnimatedBurgerFilling active={open} />
          <AnimatedBurgerFloorBread active={open} />
        </AnimatedBurgerIcon>
        {title && <plume.styles.copy.S>{title}</plume.styles.copy.S>}
      </Button>
      <div ref={$drawerRef}>
        <Drawer open={open} slideHorizontally={slideHorizontally}>
          {children}
        </Drawer>
      </div>
    </>
  )
}

function Drawer({
  children,
  open,
  slideHorizontally,
}: {
  children: React.ReactNode
  open: boolean
  slideHorizontally: boolean
}) {
  const { onTransitionEnd, step, unmounted } = plume.behaviours.useTransition(open)

  if (unmounted) {
    return null
  }

  return (
    <DrawerWrapper onTransitionEnd={onTransitionEnd} open={open} slideHorizontally={slideHorizontally} step={step}>
      {children}
    </DrawerWrapper>
  )
}

// TODO: Put in common w/ ProfileDrawer.Drawer?
const DrawerWrapper = styled.nav<{
  open: boolean
  step: plume.behaviours.UseTransition['step']
  slideHorizontally: boolean
}>`
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: start;
  // min-height prevents currencies pop-up hiding when not enough height is available
  min-height: 440px;
  height: calc(100vh - ${plume.SIZES.HEADER_HEIGHT}); // use use100vh ?
  max-height: calc(100vh - ${plume.SIZES.HEADER_HEIGHT}); // use use100vh ?$
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px 20px;
  position: absolute;
  left: 0;
  top: ${plume.SIZES.HEADER_HEIGHT};
  transition-duration: 300ms;
  transition-property: transform, visibility;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  min-width: 315px;
  width: 80%;
  z-index: -1;

  // FIXME: add specifity
  > *:not(:last-child) {
    margin-bottom: 24px;
  }

  ${({ step, slideHorizontally }) => {
    if (slideHorizontally) {
      if (step === 'before') {
        return css`
          transform: translate3d(-100%, 0, 0);
        `
      }
      if (step === 'during') {
        return css`
          transform: translate3d(0, 0, 0);
        `
      }
      if (step === 'after') {
        return css`
          transform: translate3d(-100%, 0, 0);
        `
      }
    } else {
      if (step === 'before') {
        return css`
          transform: translate3d(0, -100%, 0);
        `
      }
      if (step === 'during') {
        return css`
          transform: translate3d(0, 0, 0);
        `
      }
      if (step === 'after') {
        return css`
          transform: translate3d(0, -100%, 0);
        `
      }
    }
  }}
`

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  padding: 0;

  & > ${plume.styles.copy.S} {
    display: inline-flex;
    font-style: italic; // HACK: Irregular font-manipulation
    font-weight: 600; // HACK: Irregular font-manipulation
    margin-bottom: 4px;
    margin-left: 8px;
    margin-top: 1px;
    vertical-align: middle;
  }

  &:focus {
    outline: none;
  }
`

const AnimatedBurgerIcon = styled.div`
  cursor: pointer;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 500ms ease-in-out;
  width: 22px;
`

const AnimatedBurgerIngredient = styled.span<{ active: boolean }>`
  background: ${plume.COLORS.PRIMARY_BLACK};
  border-radius: 18px;
  display: block;
  height: 2px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform-origin: left center;
  transform: rotate(0deg);
  transition: 250ms ease-in-out;
  width: 100%;
`

const AnimatedBurgerCeilingBread = styled(AnimatedBurgerIngredient)`
  top: 6px;

  ${({ active }) => {
    if (active) {
      return css`
        left: 2px;
        top: 3px;
        transform: rotate(45deg);
      `
    }
  }}
`

const AnimatedBurgerFilling = styled(AnimatedBurgerIngredient)`
  top: 12px;

  ${({ active }) => {
    if (active) {
      return css`
        opacity: 0;
        width: 0;
      `
    }
  }}
`

const AnimatedBurgerFloorBread = styled(AnimatedBurgerIngredient)`
  top: 18px;

  ${({ active }) => {
    if (active) {
      return css`
        left: 2px;
        top: 19px;
        transform: rotate(-45deg);
      `
    }
  }}
`
