import * as React from 'react'
import styled from 'styled-components'

import * as ds from '@ulule/owl-kit-components/next'
// TODO: Migrate this to next
// @ts-ignore
import { LocaleMoneyDisplayer } from '@ulule/owl-kit-components'

type ContributionCardProps = {
  contribution: {
    absoluteUrl: string | undefined
    amount: number
    label?: string | undefined
    date: string
  }
  currentLocale: string
  project: {
    currency: string | undefined
    picture: string | undefined
    name: string | undefined
  }
}

function ContributionCardComponent({
  contribution,
  currentLocale,
  project,
}: ContributionCardProps): React.ReactElement {
  return (
    <CardWrapper href={contribution.absoluteUrl}>
      {/* TODO: Use <img /> to have alt? */}
      <Picture url={project.picture} />
      <ContentWrapper>
        <Amount>
          {contribution.label ? (
            contribution.label
          ) : (
            <LocaleMoneyDisplayer
              amount={contribution.amount}
              currency={project.currency}
              localeFormat={currentLocale}
            />
          )}
        </Amount>
        <Details>
          <Date dateTime={contribution.date}>{contribution.date}</Date>
          <ProjectName>— {project.name}</ProjectName>
        </Details>
      </ContentWrapper>
    </CardWrapper>
  )
}

export const ContributionCard = styled(ContributionCardComponent)``

const Amount = styled.header`
  color: ${ds.COLORS.PRIMARY_GREEN};
  display: block;
  font-family: ${ds.FONTS.PRIMARY_FONT};
  font-size: 17px; // HACK:
  margin-bottom: 5px;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CardWrapper = styled.a`
  align-items: center;
  display: flex;
  text-decoration: none;
  width: 100%;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Date = styled.time`
  margin-right: 3px;
`

const Details = styled.span`
  align-items: center;
  color: ${ds.COLORS.GREY_SHADE_4};
  display: flex;
  font-family: ${ds.FONTS.PRIMARY_FONT};
  font-size: 12px; // HACK:
  font-weight: 500; // HACK:
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`

// TODO: Re-use?
const Picture = styled.div<{ url: string | undefined }>`
  background-color: ${ds.COLORS.GREY_SHADE_6};
  background-image: ${({ url }) => (url ? `url(${url})` : undefined)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 35px;
  margin: 2px 10px 0 0;
  max-width: 60px;
  width: 100%;
`

const ProjectName = styled.span`
  display: block;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
`
