import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import * as accounting from './index'

export class LocaleMoneyFormatProvider extends React.Component {
  render() {
    return this.props.children(
      accounting.localeCurrencySettings({
        currency: this.props.currency,
        lang: this.props.lang,
        currencies: this.props.currencies,
      }),
    )
  }
}

LocaleMoneyFormatProvider.defaultProps = {
  currency: 'USD',
  lang: 'en',
}

LocaleMoneyFormatProvider.propTypes = {
  currency: PropTypes.string,
  lang: PropTypes.string,
  currencies: PropTypes.object,
  children: PropTypes.func.isRequired,
}

export const FormatedMoney = ({ amount, symbol, precision, thousand, decimal, format }) => (
  <React.Fragment>
    {accounting.baseformatMoney(amount, { symbol, precision, thousand, decimal, format })}
  </React.Fragment>
)

FormatedMoney.defaultProps = {
  amount: 0,
  precision: 0,
}

FormatedMoney.propTypes = {
  amount: PropTypes.number,
  symbol: PropTypes.string.isRequired,
  precision: PropTypes.number,
  thousand: PropTypes.string.isRequired,
  decimal: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
}

export class MoneyExchangeProvider extends React.Component {
  render() {
    const { rates, amount, from, to, children } = this.props

    return children(accounting.convertMoney(amount, { from, to, rates }))
  }
}

MoneyExchangeProvider.defaultProps = {
  amount: 0,
}

MoneyExchangeProvider.propTypes = {
  amount: PropTypes.number,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  rates: PropTypes.object,
  children: PropTypes.func.isRequired,
}

// NOTE: number must be a non-breaking word
export const MoneyWrapper = styled.span`
  display: inline-block;
  text-decoration: inherit;
  color: inherit;
`

export function Money({ value }) {
  return <MoneyWrapper>{value}</MoneyWrapper>
}

/** Money, money ! */
export class LocaleMoneyDisplayer extends React.Component {
  render() {
    const { rates, amount, currency, includesRoundedSymbol, targetCurrency, localeFormat, precision, currencies } =
      this.props

    const fallbackPrecision = !precision ? (Number.isInteger(amount) ? 0 : 2) : precision

    if (targetCurrency && (!rates || rates.length === 0)) {
      throw new Error('If targetCurrency props is passed, you have to pass rates props also.')
    }

    return (
      <MoneyWrapper>
        {accounting.localeMoney(
          amount,
          {
            precision: fallbackPrecision,
            targetCurrency,
            lang: localeFormat,
            currency,
            rates,
            currencies,
          },
          includesRoundedSymbol,
        )}
      </MoneyWrapper>
    )
  }
}

LocaleMoneyDisplayer.defaultProps = {
  amount: 0,
  localeFormat: 'en',
}

LocaleMoneyDisplayer.propTypes = {
  precision: PropTypes.number,
  amount: PropTypes.number,
  currency: PropTypes.string.isRequired,
  currencies: PropTypes.object,
  includesRoundedSymbol: PropTypes.bool,
  targetCurrency: PropTypes.string,
  localeFormat: PropTypes.string.isRequired,
  rates: PropTypes.object,
}
LocaleMoneyDisplayer.displayName = 'legacy.LocaleMoneyDisplayer'
