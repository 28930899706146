import * as React from 'react'

// TODO: add a useEffect like useThrottle into use100vh.ts
//  to clear timeout
export function useDebounce<ARGS extends any[]>(
  call: (...args: ARGS) => void,
  immediate = true,
  timeout = 200,
): (...args: ARGS) => void {
  const handle = React.useRef<number>()

  return (...args: ARGS) => {
    const firstCall = handle.current === undefined
    if (!firstCall) {
      clearTimeout(handle.current)
    }

    handle.current = setTimeout(() => {
      call(...args)
      handle.current = undefined
    }, timeout) as any

    if (firstCall && immediate) {
      call(...args)
    }
  }
}
