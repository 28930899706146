import * as services from '@owl-nest/services'

import { useUserDrawerContext } from '../context/userDrawer/userDrawerContext'

export function useChannelsService(limit = 6) {
  const { sessionData } = useUserDrawerContext()

  const params = { lang: sessionData.currentLocale, limit: String(limit), country: '' }
  if (sessionData.currentCountry) {
    params.country = sessionData.currentCountry
  }

  return services.channel.useChannels({ fetchOnMount: false, params })
}
