import 'whatwg-fetch'

export async function fetchAndRetry(
  url: RequestInfo | URL,
  options: RequestInit,
  delay = 300,
  maxAttempts = 5,
): Promise<Response> {
  async function retry(error: Error): Promise<Response> {
    const attemptsLeft = maxAttempts - 1
    if (attemptsLeft === 0) {
      throw error
    }

    if (attemptsLeft !== maxAttempts) {
      console.warn(`[api-client] Retrying to fetch resource (attempts left: ${attemptsLeft})`, url)
    }

    return new Promise((resolve) => setTimeout(resolve, delay)).then(() =>
      fetchAndRetry(url, options, delay, attemptsLeft),
    )
  }

  return fetch(url, options).catch(retry)
}
