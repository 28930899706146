import styled from 'styled-components'

export type ImageProps = {
  sizing?: 'contain' | 'cover'
}

export const Image = styled.img<ImageProps>`
  object-fit: ${({ sizing = 'cover' }) => sizing};
  object-position: center;
  width: 100%;
  height: 100%;

  // HACK: IE: hide image, object-fit not supported
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    opacity: 0;
  }
`

export type PictureProps = {
  src?: string
  sizing?: 'contain' | 'cover'
}

export const BasePicture = styled.picture<PictureProps>`
  overflow: hidden;
  display: block;

  // for IE, add background-image, object-fit not supported
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-position: center;
    pointer-events: none;
    background-repeat: no-repeat;
  }
`

export const Picture = styled(BasePicture)<PictureProps>`
  // HACK: Safari messes up rendering of rasterised images (among other stuff) when scaled up
  // while having borders and hidden overflow. This forces size re-calculation, skipping the rendering glitch.
  -webkit-transform: translate3d(0, 0, 0);

  // HACK: IE: add background-image, object-fit not supported
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-size: ${({ sizing = 'cover' }) => sizing};
    background-image: url(${({ src }) => src});
  }
`

export const ZommablePicture = styled.div`
  position: relative;

  ${Picture} {
    width: 100%;
  }
`

export const Zoomer = styled.div<{ src: string; zoomIsActive: boolean }>`
  background-image: url('${({ src }) => src}');
  background-repeat: no-repeat;
  background-size: 150%;
  cursor: zoom-in;
  height: 100%;
  opacity: ${({ zoomIsActive }) => (zoomIsActive ? 1 : 0)};
  position: absolute;
  width: 100%;
  z-index: 1;
`
