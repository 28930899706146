import * as api from '@owl-nest/api-client/latest'
import * as date from '@owl-nest/date'
import { t } from '@owl-nest/localize'
import * as logger from '@owl-nest/logger'
import { payment } from '@owl-nest/utils'

import * as delivery from '../delivery'
import * as i18n from '../i18n'
import * as image from '../image'
import * as UFE from '../utils/UFE'

export const EXTRA_FIELDS = [
  'account',
  'analytics',
  'delivery',
  'latest_project_online',
  'links',
  'partnerships',
  'rewards',
  'share_images',
  'shippings',
  'sponsorships',
  'tags',
  'socials',
] as const
export type ExtraFields = (typeof EXTRA_FIELDS)[number]
export type Project = api.Project<ExtraFields>

export type DonationFrequency = 'monthly' | 'one-time'

export enum DonationAllowed {
  All = 'all',
  Monthly = 'monthly',
  OneTime = 'one-time',
}

export const OWNER_EXTRA_FIELDS = [
  'account',
  'analytics',
  'answer_code',
  'billing',
  'community_members',
  'default_manager',
  'delivery',
  'examples',
  'fields_needed',
  'fieldsets',
  'latest_news',
  'latest_project_online',
  'latest_supporter',
  'links',
  'manager',
  'onboarding_steps',
  'owner.stats',
  'partnerships',
  'proposal',
  'rewards',
  'share_images',
  'shippings',
  'socials',
  'sponsorships',
  'sponsorships.channel',
  'tags',
  'tax_receipts_available',
  'thread',
  'unread_count',
  'user_orders',
  'user_role',
  'user_subscriptions',
  'membership.stats',
] as const
export type ProjectOwnerExtraFields = (typeof OWNER_EXTRA_FIELDS)[number]
export type ProjectForOwner = api.Project<ProjectOwnerExtraFields>
export type UpdateProject = api.UpdateProject<ProjectOwnerExtraFields>

export const STAFF_EXTRA_FIELDS = [...OWNER_EXTRA_FIELDS, 'slugs'] as const
export type StaffExtraFields = (typeof STAFF_EXTRA_FIELDS)[number]
export type ProjectForStaff = api.Project<StaffExtraFields>

export type BackofficeExtraFields = ProjectOwnerExtraFields | StaffExtraFields

export type RibbonData = {
  partnerOwnerId: number
  partnerUrl: string
  ribbonImageUrl: string | undefined
}

export enum EditingUserRole {
  EDITOR = 'editor',
  OWNER = 'owner',
  STAFF = 'staff',
}

export enum LifecycleStatus {
  CANCELLED,
  CLOSED,
  EXTENDED,
  FAILURE,
  OFFLINE,
  ONGOING,
  REFUSED,
  SUCCESS,
}

const PRIMARY_PAYMENT_METHOD = 'card'

export function allowedDonationFrequency<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
): DonationAllowed {
  if (project.donations_single_allowed && project.donations_monthly_allowed) {
    return DonationAllowed.All
  }

  if (project.donations_monthly_allowed && !project.donations_single_allowed) {
    return DonationAllowed.Monthly
  }

  return DonationAllowed.OneTime
}

export function backgroundColor<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
): string | undefined {
  return project.background_color && i18n.get(project.background_color, project.lang as api.Lang)
}

export function backgroundImage<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
): string | undefined {
  const backgroundImageResource = project.background_image && i18n.get(project.background_image)
  return backgroundImageResource?.url
}

export function comingSoonLabel(project: api.Project, isStaff: boolean): string {
  if (
    isStaff ||
    (project.user_role && [api.UserRole.Editor, api.UserRole.Moderator, api.UserRole.Owner].includes(project.user_role))
  ) {
    return project.status === api.ProjectStatus.VALIDATED ? t('Ready to go online') : t('In progress')
  }
  return t('Online shortly, sign up now!')
}

export function currencySymbol<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): string {
  const currency = project.currency
  const currencySymbol = UFE.CURRENCIES.SYMBOLS[currency]
  return currencySymbol && currencySymbol.symbol
}

export function description<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): string | undefined {
  return i18n.get(project.description || {}, language<EXTRA_FIELDS>(project) as api.Lang, '')
}

export function failure<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS> | ProjectForOwner | ProjectForStaff,
): boolean {
  return isFinished(project) && !project.goal_raised
}

export function funded<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): boolean {
  return !project.finished && progress(project) >= 100
}

export function fundingDescription<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
): string | undefined {
  return i18n.get(project.description_funding || {}, language(project) as api.Lang, '')
}

export function getCountDown<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
  short = true,
): string {
  if (!project.date_end) {
    return ''
  }
  const endDate = new Date(project.date_end)
  const countDown = date.getCountDown(endDate, short)

  if (project.type === api.ProjectType.Donation && countDown.value === '-') {
    return ''
  }

  return countDown.label
}

/**
 * @deprecated use project.shipping.delivery_date instead
 */
export function getDeliveryDate(project: api.Project): api.Delivery['date_delivery'] {
  return delivery.getDeliveryDate(project.delivery)
}

/**
 * Formats the reward delivery date in the user's locale.
 * Defaults to the 'MONTH year' format.
 * @param reward
 * @param format Optional - Unicode token to describe the desired format
 * @deprecated use formattedDate from shipping model instead
 */
export function formattedDeliveryDate(project: api.Project, format = 'MMMM yyyy'): string {
  const rewardDeliveryDate = getDeliveryDate(project)

  return rewardDeliveryDate ? date.format(date.parseISO(rewardDeliveryDate), format) : ''
}

export function getEditingUserRole(project: api.Project, isStaff: boolean): EditingUserRole | undefined {
  if (isStaff) return EditingUserRole.STAFF
  if (project.user_role === api.UserRole.Owner) return EditingUserRole.OWNER
  if (project.user_role === api.UserRole.Editor) return EditingUserRole.EDITOR

  return undefined
}

export function getFinishedLabel(endTime: string): string {
  const endDate = new Date(endTime)
  const currentDate = new Date()
  const hoursPassed = date.differenceInHours(currentDate, endDate)
  const minutesPassed = date.differenceInMinutes(currentDate, endDate)

  if (hoursPassed === 0) {
    return t('Just ended %(hours)d min ago!', { hours: minutesPassed })
  }

  if (hoursPassed < 12 && hoursPassed > 0) {
    return t('Just ended %(hours)dh ago!', { hours: hoursPassed })
  }

  return t('Finished')
}

export function getPaymentMethods<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
): payment.PaymentMethod[] {
  const paymentMethods = payment.getPaymentMethods(isDonationBased(project))
  return project.payment_methods?.map((method) => paymentMethods[method]).filter((method) => method !== undefined) || []
}

export function getNumberOfPublicRewards(project: api.Project): number {
  return project.rewards?.filter((reward) => !reward.is_hidden).length ?? 0
}

export function getOrderedPaymentMethods(project: api.Project, enablePaypal = true): payment.PaymentMethod[] {
  const paymentMethods = getPaymentMethods(project)

  // Primary method must always be first
  function sortPaymentMethods(methodA: payment.PaymentMethod, methodB: payment.PaymentMethod): number {
    return methodA.id === PRIMARY_PAYMENT_METHOD ? -1 : methodB.id === PRIMARY_PAYMENT_METHOD ? 1 : 0
  }

  return paymentMethods.filter((method) => enablePaypal || method.id !== 'paypal').sort(sortPaymentMethods)
}

export function getPrimaryPaymentMethod<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
  enablePaypal = true,
): payment.PaymentMethod {
  const primaryPaymentMethod = getOrderedPaymentMethods(project, enablePaypal)[0]

  if (!primaryPaymentMethod) {
    logger.warn('[project] Found no primary payment method', { project })
  }

  return primaryPaymentMethod || payment.getPaymentMethods()[PRIMARY_PAYMENT_METHOD]
}

export type Statusproperties = 'failed' | 'pending' | 'succeeded' | 'waiting'
export type StepProperties = {
  status: Statusproperties
  percent: number
}

export type Properties =
  | {
      preparation: StepProperties
      publishing: StepProperties
      reread: StepProperties
    }
  | undefined

export function getStepProperties(project: api.Project): Properties {
  const allRequiredFieldFilled = isAllFieldsetsFilled(project)

  switch (project.status) {
    case api.ProjectStatus.NEW:
      if (!allRequiredFieldFilled) {
        return {
          preparation: { status: 'pending', percent: 25 },
          reread: { status: 'waiting', percent: 0 },
          publishing: { status: 'waiting', percent: 0 },
        }
      } else {
        return {
          preparation: { status: 'pending', percent: 75 },
          reread: { status: 'waiting', percent: 0 },
          publishing: { status: 'waiting', percent: 0 },
        }
      }

    case api.ProjectStatus.PENDING:
      return {
        preparation: { status: 'succeeded', percent: 100 },
        reread: { status: 'pending', percent: 25 },
        publishing: { status: 'waiting', percent: 0 },
      }
    case api.ProjectStatus.PENDING_OWNER:
    case api.ProjectStatus.PENDING_FINAL_VALIDATION:
      if (project.moderation_message) {
        if (project.moderation_message.read_by_owner) {
          return {
            preparation: { status: 'succeeded', percent: 100 },
            reread: { status: 'pending', percent: 75 },
            publishing: { status: 'waiting', percent: 0 },
          }
        }
        return {
          preparation: { status: 'succeeded', percent: 100 },
          reread: { status: 'pending', percent: 50 },
          publishing: { status: 'waiting', percent: 0 },
        }
      }

      return {
        preparation: { status: 'succeeded', percent: 100 },
        reread: { status: 'pending', percent: 75 },
        publishing: { status: 'waiting', percent: 0 },
      }

    case api.ProjectStatus.VALIDATED:
      if (project.validation_message === undefined) {
        return {
          preparation: { status: 'succeeded', percent: 100 },
          reread: { status: 'succeeded', percent: 100 },
          publishing: { status: 'pending', percent: 75 },
        }
      } else if (project.validation_message.read_by_owner) {
        return {
          preparation: { status: 'succeeded', percent: 100 },
          reread: { status: 'succeeded', percent: 100 },
          publishing: { status: 'pending', percent: 75 },
        }
      }

      return {
        preparation: { status: 'succeeded', percent: 100 },
        reread: { status: 'succeeded', percent: 100 },
        publishing: { status: 'pending', percent: 25 },
      }

    default:
      return
  }
}

export enum Steps {
  SETUP = 'setup',
  AWAITING_REVIEW_SUBMISSION = 'awaiting_review_submission',
  IN_REVIEW = 'in_review',
  AWAITING_OWNER_REVIEW_REPLY = 'awaiting_owner_review_reply',
  AWAITING_VALIDATION = 'awaiting_validation',
  AWAITING_OWNER_VALIDATION_ACKNOWLEDGMENT = 'awaiting_owner_validation_acknowledgement',
  AWAITING_OWNER_PUBLISHING = 'awaiting_owner_publishing',
}

export function getCurrentStep(project: api.Project) {
  const properties = getStepProperties(project)

  if (!properties) {
    return
  }

  if (properties.preparation.status === 'pending') {
    if (properties.preparation.percent > 50) {
      return Steps.AWAITING_REVIEW_SUBMISSION
    }

    if (properties.preparation.percent < 50) {
      return Steps.SETUP
    }
  }

  if (properties.reread.status === 'pending') {
    if (properties.reread.percent === 25) {
      return Steps.IN_REVIEW
    }

    if (properties.reread.percent === 50) {
      return Steps.AWAITING_OWNER_REVIEW_REPLY
    }

    if (properties.reread.percent === 75) {
      return Steps.AWAITING_VALIDATION
    }
  }

  if (properties.publishing.status === 'pending') {
    if (properties.publishing.percent === 25) {
      return Steps.AWAITING_OWNER_VALIDATION_ACKNOWLEDGMENT
    }

    if (properties.publishing.percent === 75) {
      return Steps.AWAITING_OWNER_PUBLISHING
    }
  }
}

export function getFieldset(project: api.Project, key: api.FIELDSETS_KEYS) {
  return project.fieldsets?.find((fieldset) => fieldset.key === key)
}

export function getRibbonData(project: api.Project): RibbonData | null {
  if (!project || !project.partnerships) {
    return null
  }

  const ribbonPartnership = project.partnerships.find((partnership) => !!partnership.ribbon)

  if (ribbonPartnership) {
    return {
      partnerOwnerId: ribbonPartnership.partner.user_id,
      partnerUrl: ribbonPartnership.partner.url,
      ribbonImageUrl: ribbonPartnership.ribbon?.url,
    }
  }

  const winnerSponsor = project.partnerships.find(
    (partnership) => partnership.is_winner && partnership.partner.ribbon_winner,
  )

  if (winnerSponsor) {
    return {
      partnerOwnerId: winnerSponsor.partner.user_id,
      partnerUrl: winnerSponsor.partner.url,
      ribbonImageUrl: winnerSponsor.partner.ribbon_winner?.url,
    }
  }

  const supportiveSponsor = project.partnerships.find(
    (partnership) => partnership.is_support && partnership.partner.ribbon,
  )

  if (supportiveSponsor) {
    return {
      partnerOwnerId: supportiveSponsor.partner.user_id,
      partnerUrl: supportiveSponsor.partner.url,
      ribbonImageUrl: supportiveSponsor.partner.ribbon?.url,
    }
  }

  return null
}

export function getSecondaryPaymentMethods<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
  enablePaypal = true,
): payment.PaymentMethod[] {
  const paymentMethods = payment.getPaymentMethods(isDonationBased(project))
  const primaryPaymentMethod = getPrimaryPaymentMethod(project, enablePaypal)
  return (
    (project.payment_methods ?? [])
      .map((method) => paymentMethods[method])
      .filter((method) => method !== undefined && method.id !== primaryPaymentMethod?.id) || []
  )
}

export function getStatus(project: api.Project): string | undefined {
  if (project.is_in_extra_time) {
    return t('Extension')
  } else if (project.finished) {
    return t('Finished')
  } else if (project.is_online && !project.date_end) {
    return ''
  }
}

export function hadExtraTime<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): boolean {
  if (!project.date_end_extra_time) return false

  const dateEndExtraTime = project.extra_time_canceled_at
    ? new Date(project.extra_time_canceled_at)
    : new Date(project.date_end_extra_time)
  const currentDate = new Date()

  return isFinished(project) && date.compareAsc(currentDate, dateEndExtraTime) >= 0
}

const FIELDSET_NEEDED_FOR_PREVIEW = [
  api.FIELDSETS_KEYS.DATE_END,
  api.FIELDSETS_KEYS.GOAL,
  api.FIELDSETS_KEYS.MAIN_IMAGE,
  api.FIELDSETS_KEYS.NB_DAYS,
  api.FIELDSETS_KEYS.TITLE,
]
type HasAccessToPreviewAllowKey = (typeof FIELDSET_NEEDED_FOR_PREVIEW)[number]

export function hasAccessToPreview(project: api.Project): boolean {
  // HACK: This should be handled by the API
  const projectIsDonationBased = isDonationBased(project)
  const fieldsNeeded = project.fieldsets
  const KEYS = api.FIELDSETS_KEYS

  if (isAllFieldsetsFilled(project)) {
    return true
  }

  const fieldsFiltered = fieldsNeeded?.reduce(
    (fieldAcc, field) => {
      if (FIELDSET_NEEDED_FOR_PREVIEW.includes(field.key)) {
        return { ...fieldAcc, [field.key]: { ...field } }
      }

      return fieldAcc
    },
    [] as { [key in HasAccessToPreviewAllowKey]?: api.ProjectFieldset },
  )

  if (projectIsDonationBased) {
    return (
      (fieldsFiltered !== undefined &&
        fieldsFiltered[KEYS.TITLE]?.completed &&
        fieldsFiltered[KEYS.MAIN_IMAGE]?.completed) ??
      false
    )
  } else {
    return (
      (fieldsFiltered !== undefined &&
        getNumberOfPublicRewards(project) > 0 &&
        fieldsFiltered[KEYS.TITLE]?.completed &&
        fieldsFiltered[KEYS.MAIN_IMAGE]?.completed &&
        fieldsFiltered[KEYS.GOAL]?.completed &&
        (fieldsFiltered[KEYS.DATE_END]?.completed || fieldsFiltered[KEYS.NB_DAYS]?.completed)) ??
      false
    )
  }
}

// return if project has a checkout
export function hasCheckout(project: api.Project): boolean {
  if (project.user_role === api.UserRole.Owner) {
    return true
  }

  if (isFinished(project)) {
    return project.post_campaign_link !== undefined
  }

  return project.is_online && !isCancelled(project)
}

export function hasExtraTime<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): boolean {
  return project.has_extra_time
}

export function hasPickupPoints(project: api.Project): boolean {
  return pickupPoints(project).length !== 0
}

export function hasSubscriptions<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): boolean {
  return project.subscriptions_count !== undefined && project.subscriptions_count >= 1
}

export function hasSelfPermission<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
  permission: api.ProjectSelfPermission,
): boolean {
  if (project.permissions.self === null) {
    return false
  }
  return project.permissions.self.includes(permission)
}

export function isAllFieldsetsFilled(project: api.Project) {
  const fieldsets = project.fieldsets
  return fieldsets !== undefined && fieldsets.every((field) => !field.required || field.completed)
}

export function isCancelled<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS> | ProjectForOwner | ProjectForStaff,
): boolean {
  return project.goal_status === api.GoalStatus.CANCELLED || project.is_cancelled
}

export function isFraud<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS> | ProjectForOwner | ProjectForStaff,
): boolean {
  return project.goal_status === api.GoalStatus.FRAUD
}

export function isClosed(project: api.Project): boolean {
  return isFinished(project) || isCancelled(project)
}

// Although we avoid implementing such simple model methods, this one is here for a purpose:
// be able to easily locate view sections and logic blocks that are related to donation-based projects.
export function isDonationBased(project: api.Project): boolean {
  return project.type === api.ProjectType.Donation
}

export function isMembership(project: api.Project): boolean {
  return project.type === api.ProjectType.Membership
}

export function isRewardBased(project: api.Project): boolean {
  return project.type === api.ProjectType.Presale || project.type == api.ProjectType.Project
}

export function isVendorBased(project: api.Project): boolean {
  return project.type === api.ProjectType.Vendor
}

export function isExtraTime<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): boolean {
  return project.is_in_extra_time
}

export function isFinished<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS> | ProjectForOwner | ProjectForStaff,
): boolean {
  return project.finished && !project.is_in_extra_time
}

export function isComingSoonEnabled(project: api.Project): boolean {
  return (
    !project.is_cancelled &&
    ![api.ProjectStatus.ONLINE, api.ProjectStatus.REFUSED].includes(project.status) &&
    project.answer_code !== api.ProjectAnswerCode.NEED_MODERATION
  )
}

export function isOnline<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS> | ProjectForOwner | ProjectForStaff,
): boolean {
  return project.status === api.ProjectStatus.ONLINE
}

export function isOkpalOffboardingAvailable<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
): boolean {
  return project.currency === 'EUR' || project.currency === 'CAD'
}

export function isValidated<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS> | ProjectForOwner | ProjectForStaff,
): boolean {
  return project.status === api.ProjectStatus.VALIDATED
}

export function language<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): string | undefined {
  return project?.lang
}

export function lifecycleStatus<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): LifecycleStatus {
  // Project with status 'refused' or goal_status 'fraud'
  if (refused(project) || project.goal_status === api.GoalStatus.FRAUD) {
    return LifecycleStatus.REFUSED
  }

  // Project with goal_status 'cancelled', no matters the status
  if (isCancelled(project)) {
    return LifecycleStatus.CANCELLED
  }

  // Project not online yet (status 'new', 'pending', 'pending-owner', 'waiting',
  // 'pending-final-validation' or 'validated'
  if (offline(project)) {
    return LifecycleStatus.OFFLINE
  }

  // At this point, all statuses are handled except 'online'

  // RB finished and successfull projects or DB/MB closed projects
  if (success(project)) {
    if (isRewardBased(project)) {
      return LifecycleStatus.SUCCESS
    }

    return LifecycleStatus.CLOSED
  }

  // RB successfull and extended projects
  if (!isDonationBased(project) && project.is_in_extra_time) {
    return LifecycleStatus.EXTENDED
  }

  // Failed projects
  if (failure(project)) {
    return LifecycleStatus.FAILURE
  }

  // Others projects including:
  // - RB projects with status 'online' and goal_status 'funding'
  // - DB/MB projects that are not finished, with status 'online'
  // and goal_status 'failed' or 'funding'
  return LifecycleStatus.ONGOING
}

export function mainImage<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
  size = 'large',
  lang = UFE.USER_LOCALE,
): string | undefined {
  const mainImageResource = project.main_image && i18n.getWithLang(project.main_image, lang, project.lang)
  return image.getUrl(mainImageResource?.versions, size)
}

export function shareImages(project: api.Project, lang = UFE.USER_LOCALE): string | undefined {
  return project.share_images?.facebook && i18n.getWithLang(project.share_images.facebook, lang, project.lang)
}

export function avatarImage(project: api.Project, lang = UFE.USER_LOCALE, size: string): string | undefined {
  const avatarImageResource = project.avatar_image && i18n.getWithLang(project.avatar_image, lang, project.lang)
  return image.getUrl(avatarImageResource?.versions, size)
}

export function mainTag<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): string | undefined {
  return project.main_tag && i18n.get(project.main_tag.name, project.lang as api.Lang)
}

export function name<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
  overrideLang?: api.Lang,
  fallbackLang?: api.Lang,
): string | undefined {
  if (overrideLang) {
    return (
      project.name[overrideLang] ??
      /* HACK: Cannot solely rely on i18n.get() on ulule/next since it depends on "ufe.locale", which doesn't reflect the real-world value. */
      // HACK: i18n.get uses UFE.USER_LOCALE first, which happen to be "fr" on @ulule/next,
      // but current production values doesn't reflect, that so we're controlling it from here.
      // If the targeted project.name[overrideLang] fails we try project.name[fallbackLang]
      // if fallbackLang is defined
      (fallbackLang && project.name[fallbackLang]) ??
      // and eventually fallback to UFE.USER_LOCALE from within i18n.get
      i18n.get(project.name, project.lang as api.Lang)
    )
  }

  return i18n.get(project.name, project.lang as api.Lang)
}

export function ownerDescription<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
  lang = UFE.USER_LOCALE,
): string | undefined {
  return i18n.getWithLang(project.description_yourself || {}, lang, project.lang)
}

export function pickupPoints(project: api.Project): api.PickupPoint[] {
  if (project.delivery === undefined) {
    return []
  }
  return delivery.pickupPoints(project.delivery)
}

export function progress<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): number {
  return Math.floor(rawProgress(project))
}

export function rawProgress<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): number {
  const projectGoal = project.goal

  if (projectGoal <= 0) {
    return 0
  }

  let value = project.amount_raised
  if (project.type === api.ProjectType.Presale) {
    value = project.nb_products_sold
  } else if (project.type === api.ProjectType.Membership) {
    if (project.goal_type === api.GoalType.QUANTITATIVE && project.subscriptions_count) {
      value = project.subscriptions_count
    } else if (project.goal_type === api.GoalType.FINANCIAL && project.subscriptions_amount) {
      value = project.subscriptions_amount
    } else {
      value = 0
    }
  }

  return (value * 100) / projectGoal
}

export function refused<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): boolean {
  return project.status === api.ProjectStatus.REFUSED
}

export function requiresAddress(project: api.Project): boolean {
  if (!project.delivery) {
    return false
  }
  return delivery.requiresAddress(project.delivery, isDonationBased(project))
}

export function success<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): boolean {
  return project.goal_status === api.GoalStatus.SUCCESS && !project.is_in_extra_time
}

export function offline<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): boolean {
  return (
    project.status === api.ProjectStatus.PENDING ||
    project.status === api.ProjectStatus.PENDING_FINAL_VALIDATION ||
    project.status === api.ProjectStatus.PENDING_OWNER ||
    project.status === api.ProjectStatus.VALIDATED ||
    project.status === api.ProjectStatus.WAITING ||
    project.status === api.ProjectStatus.NEW
  )
}

export function subtitle<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
  lang = UFE.USER_LOCALE,
): string | undefined {
  return i18n.getWithLang(project.subtitle, lang, project.lang)
}

export function shippingType(project: api.Project): api.Delivery['shipping_type'] | undefined {
  return delivery.shippingType(project.delivery)
}

export function showOnboardingSteps(project: api.Project): boolean {
  if (project.status !== api.ProjectStatus.NEW) {
    return false
  }

  // when using old onboarding, we rely on step 'slug'
  const onboardingSteps = project.onboarding_steps

  // when using new onboarding, we have to rely on step 'done' if we want to avoid confettis everytime
  if (onboardingSteps?.onboarding?.done) {
    return false
  }

  return project.user_role === api.UserRole.Owner
}

export function tagName<EXTRA_FIELDS extends string = ''>(
  tag: api.TagBase,
  project: api.Project<EXTRA_FIELDS>,
): string | undefined {
  return i18n.get(tag.name, project.lang as api.Lang)
}

export function video<EXTRA_FIELDS extends string = ''>(
  project: api.Project<EXTRA_FIELDS>,
): api.VideoResource | undefined {
  return project.video && i18n.get(project.video, project.lang as api.Lang)
}

export function isOrdersEnabled<EXTRA_FIELDS extends string = ''>(project: api.Project<EXTRA_FIELDS>): boolean {
  return project.orders_enabled !== undefined ? project.orders_enabled : true
}

export function isPresaleType(project: api.Project): boolean {
  return project.type === api.ProjectType.Presale
}

export function getShippingFormattedDate(project: api.Project, format = 'MMMM yyyy') {
  if (project.shippings === undefined) {
    return ''
  }

  return date.format(date.parseISO(project.shippings?.date_delivery), format)
}

export function isUserMember(project: api.Project): boolean {
  return getActiveMembershipOrder(project) !== undefined
}

export function getActiveMembershipOrder(project: api.Project): api.Subscription | undefined {
  return project.user_subscriptions?.[0]
}

export function getAutomaticGoalValue(project: api.Project): number | undefined {
  const currentAmount = getSubscriptionsCount(project)

  if (currentAmount === undefined) {
    return
  }

  const levels =
    project.goal_type === api.GoalType.QUANTITATIVE
      ? [
          { target: 10, step: 10 },
          { target: 100, step: 25 },
          { target: 500, step: 50 },
          { target: 1000, step: 100 },
          { target: 5000, step: 250 },
          { target: 10000, step: 500 },
          { target: 50000, step: 2500 },
          { target: Infinity, step: 5000 },
        ]
      : [
          { target: 100, step: 25 },
          { target: 500, step: 50 },
          { target: 1000, step: 100 },
          { target: 5000, step: 250 },
          { target: 10000, step: 500 },
          { target: 50000, step: 2500 },
          { target: Infinity, step: 5000 },
        ]

  let nextGoal = 1
  let i = 0

  while (nextGoal === 1) {
    const level = levels[i]
    if (currentAmount < level.target) {
      nextGoal = (Math.floor(currentAmount / level.step) + 1) * level.step
    } else {
      i += 1
    }
  }

  return nextGoal
}

export function getSubscriptionName(project: api.Project, lang: api.Lang) {
  const reward = getActiveMembershipOrder(project)?.reward

  if (reward?.title) {
    return i18n.getWithLang(reward.title, lang, project.lang)
  }

  return undefined
}

export function getSubscriptionType(project: api.Project) {
  const reward = getActiveMembershipOrder(project)?.reward

  if (reward) {
    return reward.is_free ? 'free' : 'paid'
  }
  return 'none'
}

// FIXME: We need to rename this.
export function getSubscriptionsCount(project: api.Project) {
  return project.goal_type === api.GoalType.QUANTITATIVE ? project.subscriptions_count : project.subscriptions_amount
}
