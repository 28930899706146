import * as yup from 'yup'
import { locales } from './locale'

export function numberWithComma() {
  return new NumberWithCommaSchema()
}

export class NumberWithCommaSchema extends yup.NumberSchema {
  constructor() {
    super()

    // HACK: schema.type is supposed to be read-only, but who cares, right ?
    //@ts-ignore
    this.type = 'numberWithComma'

    this.withMutation((schema) => {
      this.transform(function (value, originalValue) {
        if (!isNaN(value)) {
          return value
        }
        return Number(String(originalValue).replace(/,/g, '.'))
      })

      schema.typeError(() => locales.numberWithComma.type())
      return schema
    })
  }
}
