import * as React from 'react'

import { ERROR_STATUS_CLASS, SUCCESS_STATUS_CLASS } from './constants'

export type FieldStatus = 'default' | 'error' | 'success'

export function getStatusClassName(
  className: string | undefined,
  error?: React.ReactNode,
  success?: React.ReactNode,
): {
  classList: string[]
  status: FieldStatus
} {
  let status: FieldStatus = 'default'

  // This classList is used to add a class depending on the status of the field.
  // This is used to easily scroll to the first erroneous field of a form. Other
  // solutions (like refs) are way too heavy (and frankly a PITA) for such a
  // simple problem. We would have to track an array of refs for each rendered
  // error message, and to do so we would need a ton of forwardRef in field
  // components… Using className and `querySelector` for this use-case (scrolling
  // to the first error message) is innocuous. The component doing the scrolling
  // is also the one responsible for displaying those error messages in the first
  // place (usually `<Formik>`), so there is no tight coupling. Remember, with
  // great power comes great responsibility. Don't abuse the power of
  // `querySelector`, and don't use this as an excuse to indulge in old school DOM
  // manipulation.
  const classList = [className ?? '']

  if (error && !success) {
    status = 'error'
    classList.push(ERROR_STATUS_CLASS)
  }

  if (success && !error) {
    status = 'success'
    classList.push(SUCCESS_STATUS_CLASS)
  }

  return { classList, status }
}
