import { lens } from '@owl-nest/config'

type Window = {
  UFE: {
    locale: string
  }
}

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
      }
    : window

export const USER_LOCALE = lens<Window>().get('UFE').get('locale').value(global, { forgive: true, silent: true })
