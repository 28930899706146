// TODO: HACK: Use proper imports once the main workspace (`ulule`) uses date-fns v2
// @ts-ignore
import en from '../../../../node_modules/date-fns/locale/en-US'
// @ts-ignore
import fr from '../../../../node_modules/date-fns/locale/fr'
// @ts-ignore
import es from '../../../../node_modules/date-fns/locale/es'
// @ts-ignore
import ca from '../../../../node_modules/date-fns/locale/en-CA'
// @ts-ignore
import it from '../../../../node_modules/date-fns/locale/it'
// @ts-ignore
import de from '../../../../node_modules/date-fns/locale/de'
// @ts-ignore
import nl from '../../../../node_modules/date-fns/locale/nl'
export const locales = {
  en,
  fr,
  es,
  ca,
  it,
  de,
  nl,
}

export const defaultLocaleName = 'en'
