import * as React from 'react'
import * as jotai from 'jotai'

const IS_CLIENT_SIDE_ATOM = jotai.atom(false)

export function useClientSide(): boolean {
  const [isClientSide, setIsClientSide] = jotai.useAtom(IS_CLIENT_SIDE_ATOM)
  React.useEffect(() => {
    setIsClientSide(true)
  }, [])

  return isClientSide
}
