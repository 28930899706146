import styled, { css } from 'styled-components'

import { InlineSvg } from './InlineSvg'

export const FreeSizedSvg = styled(InlineSvg)<{ height?: number | string; width?: number | string }>`
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
  vertical-align: middle;
  width: ${({ width }) => (width ? (Number.isInteger(width) ? `${width}px` : width) : 'auto')};
`
