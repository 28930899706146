import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import type { Contribution, Project } from '../types'
import { useUserDrawerContext } from '../context/userDrawer/userDrawerContext'
import { ProfileDrawer } from './drawers/ProfileDrawer'
import * as S from './style'

export type AuthSectionProps = {
  loadUserExtraData: () => Promise<void>
  user?: {
    avatarUrl: string | undefined
    contributions: Contribution[]
    createdProjectsCount: number
    id: number
    isGuest: boolean
    name: string
    projects: Project[]
    unreadMessagesCount?: number
    urls: {
      contributionsUrl?: string
      profileUrl: string
      projectsUrl?: string
      settingsUrl?: string
    }
  }
}

export function AuthSection({ loadUserExtraData, user }: AuthSectionProps): React.ReactElement<AuthSectionProps> {
  const $profileDrawerTrigger = React.useRef<HTMLAnchorElement>(null)
  const [profileDrawerOpen, setProfileDrawerAsOpen] = React.useState(false)
  const [userExtraDataLoaded, setUserExtraDataAsLoaded] = React.useState(false)

  const { translations, urls } = useUserDrawerContext()

  return (
    <>
      {user && !user.isGuest ? (
        <>
          <UserIdentity
            onClick={async () => {
              if (window.zE) {
                if (profileDrawerOpen === false) {
                  window.zE('webWidget', 'hide')
                } else {
                  window.zE('webWidget', 'show')
                }
              }

              setProfileDrawerAsOpen(!profileDrawerOpen)

              if (!userExtraDataLoaded) {
                await loadUserExtraData()
                setUserExtraDataAsLoaded(true)
              }
            }}
            ref={$profileDrawerTrigger}
          >
            <plume.styles.image.Avatar src={user.avatarUrl} type="extra-small" />
            {user.unreadMessagesCount ? (
              <plume.Jewel number={user.unreadMessagesCount}>
                <UserName>{user.name}</UserName>
              </plume.Jewel>
            ) : (
              <UserName>{user.name}</UserName>
            )}
          </UserIdentity>
          <S.Overlay visible={profileDrawerOpen}>{profileDrawerOpen && <S.LockScroll />}</S.Overlay>
          <ProfileDrawer
            contributions={user.contributions}
            loading={!userExtraDataLoaded}
            onClose={() => {
              setProfileDrawerAsOpen(false)
              if (window.zE) {
                window.zE('webWidget', 'show')
              }
            }}
            open={profileDrawerOpen}
            projects={user.projects}
            user={{
              createdProjectsCount: user.createdProjectsCount,
              unreadMessagesCount: user.unreadMessagesCount,
              urls: user.urls,
            }}
            trigger={$profileDrawerTrigger}
          />
        </>
      ) : (
        <LoginLink href={urls.signIn.url} onClick={urls.signIn.onClick}>
          <plume.glyphs.stroke.Person size={18} />
          <LoginLabel>{translations.signIn}</LoginLabel>
        </LoginLink>
      )}
    </>
  )
}

const LoginLabel = styled.span`
  display: none;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: inline-block;
    width: max-content;
  }
`

const LoginLink = styled(S.MainNavLink)`
  display: flex;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${plume.glyphs.Icon} {
      margin-right: 10px;
    }
  }
`

const UserName = styled.span`
  display: none;
  ${plume.styles.link._primaryStyle}
  margin-left: 8px;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: initial;
    width: max-content;
  }
`

const UserIdentity = styled(S.MainNavLink)`
  align-items: center;
  display: flex;

  ${plume.styles.image.Avatar} {
    box-sizing: border-box;
  }

  &:active,
  &:hover {
    ${UserName} {
      color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
    }
  }
`
