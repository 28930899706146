import styled from 'styled-components'

import * as colors from '../constants/colors'

export const Wrapper = styled.span`
  display: flex;
  align-items: center;
`

export const Bullet = styled.sup`
  align-items: baseline;
  color: ${colors.SECONDARY_RED};
  display: inline-flex;
  flex-shrink: 0;
  font-size: 11px;
  font-weight: 500;
  justify-content: center;
  line-height: inherit;
  margin-left: 6px;
  top: 0px;

  &::before {
    background-color: ${colors.SECONDARY_RED};
    border-radius: 6px;
    content: '';
    margin-right: 3px;
    min-height: 6px;
    min-width: 6px;
  }
`
Bullet.displayName = 'S.jewel.Bullet'
