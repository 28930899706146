type Params = {
  [id: string]: boolean | number | string
}

function buildSearchParams(params: Params): string {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&')
}

export function addSearchParamsToUrl(baseUrl: string, params: Params): string {
  if (!params) return baseUrl

  const character = !baseUrl.includes('?') ? '?' : '&'
  return `${baseUrl}${character}${buildSearchParams(params)}`
}
