import styled, { css } from 'styled-components'

import * as colors from '../../constants/colors'
import * as fonts from '../../constants/fonts'
import * as glyphs from '../../icons/glyphs'

import { hexToRgb } from '../../utils/colors'

export type Status = 'selected' | 'disabled' | 'default' | 'disabled-selected'

export const Label = styled.div<{ status?: Status }>`
  display: inline;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  line-height: 18px;
  padding-right: 12px;
  width: 100%;

  ${({ status }) => {
    if (status === 'disabled' || status === 'disabled-selected') {
      return css`
        color: ${colors.GREY_SHADE_4};
      `
    }

    if (status === 'selected') {
      return css`
        color: ${colors.PRIMARY_BLUE};
      `
    }

    return css`
      color: ${colors.PRIMARY_BLACK};
    `
  }}
`

export const MonoSelectedIcon = styled(glyphs.fill.CheckCircle).attrs({ size: 12 })<{
  status: Status
}>`
  margin: 0 6px 0 0;
  stroke-width: 2px;
  stroke: ${colors.PRIMARY_WHITE};

  ${({ status }) => {
    if (status === 'selected') {
      return css`
        color: ${colors.PRIMARY_BLUE};
      `
    }

    if (status === 'disabled') {
      return css`
        color: transparent;
      `
    }

    if (status === 'default') {
      return css`
        color: transparent;
      `
    }
  }}
`

export const MultiSelectedIcon = styled(glyphs.stroke.Check).attrs({ size: 8 })<{
  status: Status
}>`
  border-style: solid;
  border-width: 2px;
  color: ${colors.PRIMARY_GREY_000};
  margin: 0 6px 0 0;
  stroke-width: 3px;
  padding: 2px 0px;

  ${({ status }) => {
    if (status === 'disabled-selected') {
      return css`
        stroke: ${colors.PRIMARY_SAND_500};
        border-color: ${colors.PRIMARY_SAND_500};
        background-color: ${colors.SECONDARY_GREY_400};
      `
    }

    if (status === 'selected') {
      return css`
        stroke: currentColor;
        border-color: ${colors.PRIMARY_BLUE_500};
        background-color: ${colors.PRIMARY_BLUE_500};
      `
    }

    if (status === 'disabled') {
      return css`
        border-color: ${colors.PRIMARY_SAND_200};
        background-color: ${colors.SECONDARY_GREY_400};
        stroke: transparent;
      `
    }

    if (status === 'default') {
      return css`
        border-color: ${colors.PRIMARY_GREY_500};
        stroke: transparent;
      `
    }
  }}
`

export const Wrapper = styled.li<{ focused: boolean; filtered: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 6px 16px 6px 10px;
  user-select: none;

  ${({ focused }) => {
    if (focused) {
      return css`
        background-color: ${hexToRgb(colors.PRIMARY_BLUE_500, 0.05)};
      `
    } else {
      return css`
        background-color: ${colors.PRIMARY_GREY_000};
      `
    }
  }}

  ${({ filtered }) => {
    if (filtered) {
      return css`
        /* don't use display:none keep width of dropdown even if largest item is filtered.
        Instead, reduce vertical dimension to 0 (height and padding) and hide overflow */
        height: 0;
        overflow: hidden;
        padding: 0 16px 0 10px;
        visibility: hidden;
      `
    }
  }}

  ${MonoSelectedIcon}, ${MultiSelectedIcon} {
    flex: 0 0 12px;
  }
`
