import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import { useConfigurationContext } from '../../../context/configuration/configurationContext'
import { useURLContext } from '../../../context/urls/urlContext'
import { useUserDrawerContext } from '../../../context/userDrawer/userDrawerContext'

import { MobileNavigationDrawer } from '../../drawers/MobileNavigationDrawer'
import { UluleLogo } from '../../logos/UluleLogo'

import { getUrlWithUTMs } from '../../../utils/urls'

import * as S from '../../style'

export function International(): React.ReactElement {
  const [mobileDrawerOpen, setMobileDrawerAsOpen] = React.useState(false)
  const { translations } = useConfigurationContext()
  const urlContext = useURLContext()
  // TODO: Replace with proper URLs context
  const { urls } = useUserDrawerContext()

  const navigationLinks = (
    <>
      {/* TODO: copy as span? */}
      <plume.styles.copy.S>
        <S.LinkItem href={getUrlWithUTMs(urls.proposal, 'ulule', 'ulule', 'header')}>
          {/* @ts-expect-error `translations` children are unknown, we should type properly type them. */}
          {translations.navigationItems.startProject}
        </S.LinkItem>
      </plume.styles.copy.S>
      <plume.styles.copy.S>
        <S.LinkItem href={getUrlWithUTMs(urls.discover, 'ulule', 'ulule', 'header')}>
          {/* @ts-expect-error `translations` children are unknown, we should type properly type them. */}
          {translations.navigationItems.seeAllProjects}
        </S.LinkItem>
      </plume.styles.copy.S>
    </>
  )

  return (
    <>
      <S.DesktopOnlyNav>
        <LogoLink href={getUrlWithUTMs(urlContext.home, 'ulule', 'ulule', 'logo')}>
          <UluleLogo />
        </LogoLink>
        {navigationLinks}
      </S.DesktopOnlyNav>
      <S.MobileOnlyNav>
        <MobileNavigationDrawer open={mobileDrawerOpen} setOpen={setMobileDrawerAsOpen} slideHorizontally>
          {navigationLinks}
        </MobileNavigationDrawer>
        <S.Overlay visible={mobileDrawerOpen}>{mobileDrawerOpen && <S.LockScroll />}</S.Overlay>
      </S.MobileOnlyNav>
    </>
  )
}

const LogoLink = styled.a`
  align-items: center;
  color: ${plume.COLORS.PRIMARY_BLACK};
  display: flex;
  height: 100%;
  justify-content: center;
`
