import * as yup from 'yup'
import { locales } from './locale'

export function file(): FileSchema {
  return new FileSchema()
}

export class FileSchema extends yup.Schema<File> {
  constructor() {
    super({
      type: 'file',
      check: (value: unknown): value is File => {
        return value instanceof File
      },
    })

    this.withMutation(() => {
      this.transform(function (value) {
        if (this.isType(value)) {
          // we have a valid value
          return value
        }
        throw 'error'
      })
    })
  }

  formats(formats: string[], message: yup.Message<{ formats: string[] }> = locales.file.formats): FileSchema {
    return this.test({
      message,
      name: 'formats',
      exclusive: true,
      params: { formats },
      test(value) {
        if (value === undefined) {
          return true
        }
        return formats.includes(value.type)
      },
    })
  }

  max(max: number, message: yup.Message<{ max: number }> = locales.file.max): FileSchema {
    return this.test({
      message,
      name: 'max',
      exclusive: true,
      params: { max },
      test(value) {
        if (value === undefined) {
          return true
        }
        return value.size <= max
      },
    })
  }
}
