import styled, { css } from 'styled-components'

import * as breakpoints from '../constants/breakpoints'
import * as colors from '../constants/colors'
import * as glyphs from '../icons/glyphs'

type LockedProps = {
  isLocked?: boolean
}

export const LoaderWrapper = styled.div<LockedProps>`
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  user-select: ${({ isLocked }) => (isLocked ? 'none' : 'auto')};
  pointer-events: ${({ isLocked }) => (isLocked ? 'none' : 'auto')};

  ${glyphs.fill.VideoPlay} {
    width: 56px;
    height: 56px;

    @media screen and ${breakpoints.MOBILE_L} {
      width: 72px;
      height: 72px;
    }

    circle {
      fill: ${({ isLocked }) => (isLocked ? colors.PRIMARY_GREY_500 : colors.PRIMARY_GREY_000)};
      opacity: 0.9;
    }
    polygon {
      fill: ${({ isLocked }) => (isLocked ? colors.PRIMARY_GREY_000 : colors.PRIMARY_BLUE_500)};
    }
  }

  &:hover {
    cursor: ${({ isLocked }) => (isLocked ? 'default' : 'pointer')};

    ${glyphs.fill.VideoPlay} {
      polygon {
        fill: ${colors.PRIMARY_BLUE_700};
      }
    }
  }

  ${(props) =>
    props.isLocked &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors.PRIMARY_GREY_900};
        opacity: 0.5;
      }
    `}
`

export const Button = styled.div<LockedProps>`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ isLocked }) => (isLocked ? 1 : 0)};
`

export const Thumbnail = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Wrapper = styled.div<{ ratio: number; videoLoaded: boolean }>`
  background-color: black;
  height: 0;
  position: relative;

  ${({ ratio }) => {
    return css`
      padding-bottom: ${ratio * 100}%;
    `
  }}

  ${({ videoLoaded }) => {
    if (!videoLoaded) {
      return css`
        visibility: hidden;
      `
    }
  }}

  iframe,
  video {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`
