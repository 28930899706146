import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import { FOOTER_BREAKPOINT } from './constants'

type AccordionSectionProps = {
  title: React.ReactNode
  id?: string
  children: React.ReactNode
  className?: string
}

function AccordionSectionComponent({
  title,
  id,
  children,
  className,
}: AccordionSectionProps): React.ReactElement<AccordionSectionProps> {
  return (
    <plume.Accordion id={id} title={title} className={className}>
      {children}
    </plume.Accordion>
  )
}
export const AccordionSection = styled(AccordionSectionComponent)`
  ${plume.styles.accordion.Title} {
    a {
      color: inherit;
      pointer-events: none;
      text-decoration: none;
    }
  }

  @media screen and ${FOOTER_BREAKPOINT} {
    border: 0;
    padding: 0;

    ${plume.styles.accordion.Title} {
      cursor: default;
      a {
        pointer-events: all;
        cursor: pointer;
      }
    }

    ${plume.styles.accordion.Content} {
      max-height: max-content;
    }

    ${plume.glyphs.Icon} {
      display: none !important;
    }

    ${plume.styles.accordion.Toggler}:checked ~ {
      ${plume.glyphs.Icon} {
        display: none !important;
      }
    }
  }
`
