import * as React from 'react'
import * as size from '../constants/sizes'
import { isMobile } from '../utils/matchMediaOnBrowser'

type Options = { lockClassName?: string; targetSelector?: string; withHeaderSize?: boolean }

export function useScrollLock(
  lock: boolean | 'preserve',
  { lockClassName = 'lock-scroll', withHeaderSize = true, targetSelector = 'body' }: Options = {},
): void {
  React.useEffect(() => {
    if (lock === 'preserve') {
      return
    }

    const html = document.body.parentElement!

    const scrollTop = html.scrollTop

    const targetElement: HTMLElement | null = document.querySelector(targetSelector)
    targetElement?.classList.toggle(lockClassName, lock)

    // If we lock, the body will change to `position: fixed`, to effectively
    // remove all scrolling possibility to the user while keeping an empty
    // scroll bar
    // To keep the page scrolled to the same point, we use `scrollTop` (minus
    // the height of the header) as a `top`
    if (lock) {
      if (!isMobile() && targetElement !== null) {
        if (withHeaderSize) {
          targetElement.style.top = `calc(-${scrollTop}px + ${size.HEADER_HEIGHT})`
        } else {
          targetElement.style.top = `calc(-${scrollTop}px)`
        }
      }
    }

    return () => {
      if (lock) {
        // when we unlock, we restore the scroll. But since we have a
        // `scroll-behaviour: smooth`, we must override this before
        // setting the scroll position (to have it set immediatly, without
        // smooth animation)
        // We remove the `scroll-behaviour` override juste after having
        // set the `scrollTop`
        targetElement?.classList.remove(lockClassName)
        targetElement?.removeAttribute('style')
        html.style.scrollBehavior = 'initial'
        html.scrollTop = scrollTop
        html.removeAttribute('style')
      }
    }
  }, [lock])
}
