import * as React from 'react'

type AnyEvent = MouseEvent | TouchEvent

export function useOnClickOutside(
  refs: React.RefObject<HTMLElement>[],
  onClickOutside: (event: AnyEvent) => void,
): void {
  React.useEffect(() => {
    document.addEventListener(`mousedown`, handler)
    document.addEventListener(`touchstart`, handler)

    return () => {
      document.removeEventListener(`mousedown`, handler)
      document.removeEventListener(`touchstart`, handler)
    }
  }, [onClickOutside])

  function handler(event: AnyEvent): void {
    const isEventOutside = refs.every((ref) => {
      const el = ref?.current
      return !(!el || el.contains(event.target as Node))
    })

    if (isEventOutside) {
      onClickOutside(event)
    }
  }
}
