import * as React from 'react'

import * as plume from '@ulule/owl-kit-components/next'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import * as UFE from '../../utils/UFE'

import { ConfigurationProvider } from '../../context/configuration/ConfigurationProvider'
import { UserDrawerProvider } from '../../context/userDrawer/UserDrawerProvider'

import { Crowdfunding } from '../../components/zones/right/Crowdfunding'
import { International } from '../../components/zones/left/International'
import { HeaderWrapper } from '../../components/HeaderWrapper'
import { MobileOnlyLogo } from '../../components/zones/center/MobileOnlyLogo'

import { HeaderProps } from '../../types'
import * as S from '../../components/style'
import { URLProvider } from '../../context/urls/URLProvider'

export function Header({
  lang = UFE.USER_LOCALE ?? UFE.DEFAULT_LOCALE,
  search = { action: `${UFE.ULULE_ORIGINS[lang]}${UFE.DISCOVER_URL}` },
  theme = {
    colors: {
      primary: plume.COLORS.PRIMARY_BLUE,
    },
  },
}: HeaderProps): React.ReactElement<HeaderProps> {
  const { user, loadUser } = useCurrentUser(lang)

  const [isTop, setItTop] = React.useState(true)

  React.useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

    function handleScroll(): void {
      if (window.scrollY > 0) {
        setItTop(false)
      } else {
        setItTop(true)
      }
    }
  }, [])

  return (
    <UserDrawerProvider lang={lang}>
      <ConfigurationProvider
        configuration={{
          defaultLocale: UFE.DEFAULT_LOCALE,
          locale: lang,
        }}
      >
        <URLProvider>
          <S.HeaderContent isTop={isTop}>
            <HeaderWrapper
              centerZone={<MobileOnlyLogo lang={lang} siteIdentifier="ulule" />}
              leftZone={<International />}
              rightZone={<Crowdfunding search={search} loadUserExtraData={loadUser} user={user} />}
              theme={theme}
            />
          </S.HeaderContent>
        </URLProvider>
      </ConfigurationProvider>
    </UserDrawerProvider>
  )
}
