import * as React from 'react'
import styled from 'styled-components'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'
import { lightweight, standalone, SymbolProps } from '../../spritesheetUtils'

const ID = 'linkedin-third-party-glyph-icon'

export function LinkedInSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        d="M.41 32h6.857V11.082H.41V32ZM3.887 1C1.536 1 0 2.564 0 4.613 0 6.623 1.492 8.23 3.79 8.23h.051c2.39 0 3.88-1.607 3.88-3.617C7.678 2.564 6.231 1 3.887 1ZM32 20.005V32h-6.863V20.808c0-2.811-.993-4.73-3.47-4.73-1.896 0-3.02 1.294-3.518 2.545-.178.45-.228 1.072-.228 1.697V32h-6.863s.101-18.95 0-20.918h6.863v2.966c-.01.024-.028.046-.046.069h.046v-.07c.909-1.42 2.54-3.457 6.18-3.457 4.513 0 7.899 2.992 7.899 9.415Z"
        clipRule="evenodd"
      />
    </symbol>
  )
}

const LinkedInStandaloneContent = standalone(LinkedInSymbol, ID)

export const Linkedin = styled(function LinkedInStandaloneComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-linkedin">
      <LinkedInStandaloneContent />
    </Icon>
  )
})``

export const LinkedInLightweight = styled(function LinkedInComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-linkedin">
      {lightweight(ID)}
    </Icon>
  )
})``
