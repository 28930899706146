import styled, { css, type FlattenInterpolation, type ThemedStyledProps } from 'styled-components'

import * as breakpoints from '../constants/breakpoints'
import * as colors from '../constants/colors'
import * as fonts from '../constants/fonts'

export type Sizes = keyof typeof styles
type AsDekstopProps = { asDesktop?: Sizes; breakpoint?: string }
export type HeadingProps = React.HTMLAttributes<HTMLDivElement> & AsDekstopProps

export const Base = styled.div<HeadingProps>`
  color: ${colors.PRIMARY_GREY_900};
  font-family: ${fonts.PRIMARY_FONT};
  margin: 0;
  padding: 0;
`

export const _xxxLargeStyle = css`
  font-size: 76px;
  font-style: italic;
  font-weight: 900;
  line-height: 76px;
`

export const _xxLargeStyle = css`
  font-size: 65px;
  line-height: 65px;
  font-weight: 900;
`

export const _xLargeStyle = css`
  font-size: 44px;
  line-height: 44px;
  font-weight: 900;
`

export const _largeStyle = css`
  font-size: 30px;
  line-height: 31px;
  font-weight: 900;
`

export const _serifMStyle = css`
  font-size: 26px;
  line-height: 35px;
  font-family: ${fonts.SECONDARY_FONT};
`

export const _mediumStyle = css`
  font-size: 26px;
  line-height: 34px;
  font-weight: 500;
`

export const _smallStyle = css`
  font-size: 23px;
  line-height: 30px;
  font-weight: 500;
`
export const _xSmallStyle = css`
  font-size: 19px;
  line-height: 26px;
  font-weight: 600;
`

export const _xxSmallStyle = css`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
`
export const _xxxSmallStyle = css`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
`

export const _xxxxSmallStyle = css`
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: ${colors.TEXT_TERTIARY};
  text-transform: uppercase;
`

export const _card1Style = css`
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  color: ${colors.TEXT_SECONDARY};
`

export const _card2Style = css`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${colors.TEXT_SECONDARY};
`

const _card3Style = css`
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  color: ${colors.TEXT_SECONDARY};
`

export const _xxxxxSmallStyle = css`
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: ${colors.TEXT_SECONDARY};
  text-transform: uppercase;
`

const styles = {
  XXXL: _xxxLargeStyle,
  XXL: _xxLargeStyle,
  XL: _xLargeStyle,
  L: _largeStyle,
  SerifM: _serifMStyle,
  M: _mediumStyle,
  S: _smallStyle,
  XS: _xSmallStyle,
  XXS: _xxSmallStyle,
  XXXS: _xxxSmallStyle,
  XXXXS: _xxxxSmallStyle,
  XXXXXS: _xxxxxSmallStyle,
}

const asDesktopStyle: FlattenInterpolation<ThemedStyledProps<AsDekstopProps, any>> = css<AsDekstopProps>`
  @media screen and ${({ breakpoint = breakpoints.TABLET }) => breakpoint} {
    ${({ asDesktop }) => {
      return asDesktop === undefined ? '' : styles[asDesktop]
    }}
  }
`

export const XXXL = styled(Base)<HeadingProps>`
  ${_xxxLargeStyle};
  ${asDesktopStyle};
`
XXXL.displayName = 'S.heading.XXXL'

export const XXL = styled(Base)<HeadingProps>`
  ${_xxLargeStyle};
  ${asDesktopStyle};
`
XXL.displayName = 'S.heading.XXL'

export const XL = styled(Base)<HeadingProps>`
  ${_xLargeStyle};
  ${asDesktopStyle};
`
XL.displayName = 'S.heading.XL'

export const L = styled(Base)<HeadingProps>`
  ${_largeStyle};
  ${asDesktopStyle}
`
L.displayName = 'S.heading.L'

export const SerifM = styled(Base)<HeadingProps>`
  ${_serifMStyle};
  ${asDesktopStyle}
`
SerifM.displayName = 'S.heading.SerifM'

export const M = styled(Base)<HeadingProps>`
  ${_mediumStyle};
  ${asDesktopStyle}
`
M.displayName = 'S.heading.M'

export const S = styled(Base)<HeadingProps>`
  ${_smallStyle};
  ${asDesktopStyle}
`
S.displayName = 'S.heading.S'

export const XS = styled(Base)<HeadingProps>`
  ${_xSmallStyle};
  ${asDesktopStyle}
`
XS.displayName = 'S.heading.XS'

export const XXS = styled(Base)<HeadingProps>`
  ${_xxSmallStyle};
  ${asDesktopStyle}
`
XXS.displayName = 'S.heading.XXS'

export const XXXS = styled(Base)<HeadingProps>`
  ${_xxxSmallStyle};
  ${asDesktopStyle}
`
XXXS.displayName = 'S.heading.XXXS'

export const XXXXS = styled(Base)<HeadingProps>`
  ${_xxxxSmallStyle};
  ${asDesktopStyle}
`
XXXXS.displayName = 'S.heading.XXXXS'

export const Card1 = styled(Base)<HeadingProps>`
  ${_card1Style};
  ${asDesktopStyle}
`
Card1.displayName = 'S.heading.Card1'

export const Card2 = styled(Base)<HeadingProps>`
  ${_card2Style};
  ${asDesktopStyle}
`
Card2.displayName = 'S.heading.Card2'

export const Card3 = styled(Base)<HeadingProps>`
  ${_card3Style};
  ${asDesktopStyle}
`
Card3.displayName = 'S.heading.Card3'

export const XXXXXS = styled(Base)<HeadingProps>`
  ${_xxxxxSmallStyle};
  ${asDesktopStyle}
`
XXXXXS.displayName = 'S.heading.XXXXXS'

/**
 * HIGHLIGHT
 */

export type HighlightedTitleProps = {
  color?: 'blue' | 'green' | 'red'
  small?: boolean
}

const COLOR_PAIRING = {
  blue: [colors.PRIMARY_BLUE_100, colors.PRIMARY_BLUE_700],
  green: [colors.SECONDARY_MINT_300, colors.SECONDARY_MINT_700],
  red: [colors.SECONDARY_RED_200, colors.PRIMARY_RED_500],
}

export const HighlightedTitle = styled.span<HighlightedTitleProps>`
  display: inline-block;

  ${({ color, theme }) => {
    if (color && Object.keys(COLOR_PAIRING).includes(color)) {
      return css`
        background-color: ${COLOR_PAIRING[color][0]};
        color: ${COLOR_PAIRING[color][1]};
      `
    } else if (theme?.colors?.background && theme?.colors?.primary) {
      return css`
        background-color: ${theme.colors.background};
        color: ${theme.colors.primary};
      `
    } else {
      return css`
        background-color: ${colors.PRIMARY_SAND_100};
        color: ${colors.PRIMARY_GREY_900};
      `
    }
  }}

  ${({ small }) =>
    small
      ? css`
          margin-bottom: -10px;
          padding: 4px 6px 6px;

          @media screen and ${breakpoints.LAPTOP} {
            margin-bottom: -10px;
            padding: 1px 6px 8px;
          }
        `
      : css`
          margin-bottom: -6px;
          padding: 4px 6px 6px;

          @media screen and ${breakpoints.LAPTOP} {
            margin-bottom: 0px;
            padding: 1px 6px 8px;
          }
        `}
`
