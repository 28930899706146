import * as React from 'react'
import styled from 'styled-components'

import { useDramaticallyInefficientMediaQuery } from '@owl-nest/hooks'
import * as plume from '@ulule/owl-kit-components/next'

import { useURLContext } from '../../../context/urls/urlContext'
import { useConfigurationContext } from '../../../context/configuration/configurationContext'
import { SearchProductDrawer } from '../../drawers/SearchProductDrawer'

import * as S from '../../style'

export function DiscoverUlule({ cart }: { cart: React.ReactNode }) {
  const { translations } = useConfigurationContext()
  const urls = useURLContext()

  /*
   ** NOTE: Due to major layout differences, we need to declare instances of the product search drawer at two different places (subnav for large screens, in the header right zone for small ones).
   ** Knowing that, we need to ensure that the product search drawer exists only once at a time on the page:
   ** We use id-based node targeting for external control, and thus cannot afford having conflicts.
   ** This is why we use this awful but useful hook.
   */
  const isLargerThanTablet = useDramaticallyInefficientMediaQuery(S.INTERMEDIATE_BREAKPOINT)

  return (
    <Wrapper>
      {!isLargerThanTablet && (
        <MobileWrapper>
          <SearchProductDrawer action="/produits" />
          {cart}
        </MobileWrapper>
      )}

      <S.MainNavLinkItem href={urls.discoverLandingPage}>
        <>
          <plume.glyphs.stroke.Menu size={20} />
          {translations.discoverUlule}
        </>
      </S.MainNavLinkItem>
    </Wrapper>
  )
}

const MobileWrapper = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: -6px;

  a {
    color: inherit;
    margin-left: 17px;
  }

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    display: none;
  }
`

const Wrapper = styled.div`
  ${S.LinkItem} {
    display: none;
  }

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    ${S.LinkItem} {
      display: flex;
    }
  }
`
