import { SafePush } from '../safePush'
import { Tracking } from './types'

export function tracking(safePush: SafePush): Tracking {
  return {
    track: ({
      eventAction = undefined,
      eventLabel = undefined,
      eventCategory = undefined,
      eventValue = undefined,
      ...params
    }) => {
      safePush({
        eventAction,
        eventLabel,
        eventCategory,
        eventValue,
        ...params,
      })
    },
  }
}
