import * as React from 'react'

/**
 * Stores the previous version of the given value.
 *
 * Changes are tracked through a `ref` which updates at each component render,
 * which makes it useful for comparing props/state to their previous value during render.
 **
 * @param value The value to track
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = React.useRef<T | undefined>()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}
