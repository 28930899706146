import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../constants/colors'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'

export const EggFull = styled(function EggFull(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} viewBox="0 0 60 78" name="owl-egg-full">
      <g fill={colors.PRIMARY_GREEN}>
        <path d="M 16.3647 33.3086 L 31.5018 18.1715 L 41.1246 27.7943 L 51.9057 17.0132 C 51.4602 16.1816 50.9949 15.3599 50.5098 14.5778 C 45.0945 5.9153 37.7289 0.5 29.7099 0.5 C 29.7099 0.5 29.7 0.5 29.7 0.5 C 18.9387 0.5099 9.5337 10.3109 4.6134 23.8145 L 16.3647 33.3086 Z" />
        <path d="M 40.9068 35.7786 L 31.4325 26.3043 L 16.8993 40.8375 L 2.7621 29.4129 C 1.5939 34.2342 0.9504 39.4614 0.9504 44.9064 C 0.9603 53.5986 3.9006 62.5383 9.2763 68.3199 C 14.6124 74.0619 21.8592 77.22 29.6802 77.22 C 29.6901 77.22 29.6901 77.22 29.7 77.22 C 37.5507 77.22 44.7975 74.0817 50.1039 68.3991 C 55.4796 62.6472 58.4595 53.6679 58.4501 44.8767 C 58.4501 36.729 57.0636 28.9476 54.4896 22.1958 L 40.9068 35.7786 Z" />
      </g>
    </Icon>
  )
})``

export const EggPartial = styled(function EggPartial({
  progress = 60,
  ...iconProps
}: SpecificIconProps & {
  progress: number
}): React.ReactElement<SpecificIconProps> {
  const id = React.useId()
  const offset = 74 - ((74 - 8.22) * progress) / 100

  return (
    <Icon {...iconProps} viewBox="0 0 60 78" name="owl-egg-partial">
      <g fill={colors.BLUE_SHADE_2} fillRule="evenodd">
        <defs>
          <clipPath id={id}>
            <path d={`m0 ${offset}h53v62h-55z`} />
          </clipPath>
        </defs>
        <path
          d="M 28.991 2.18 C 28.991 2.18 28.982 2.18 28.982 2.18 C 21.728 2.18 15.005 6.626 10.055 14.681 C 5.42 22.223 2.864 32.078 2.873 42.428 C 2.882 50.33 5.573 57.575 10.46 62.831 C 15.311 68.051 21.899 70.922 29.009 70.922 C 29.018 70.922 29.018 70.922 29.027 70.922 C 36.164 70.922 42.752 68.069 47.576 62.903 C 52.463 57.674 55.154 50.393 55.145 42.401 C 55.127 19.847 43.643 2.18 28.991 2.18 Z"
          clipPath={`url(#${id})`}
        />
        <path d="M30,77.19 C22.1,77.19 14.78,74 9.39,68.2 C3.96,62.36 0.959988485,54.31 0.959988485,45.53 C0.95,20.88 13.96,0.82 29.98,0.81 C46.23,0.83 59.03,20.43 59.050012,45.5 C59.06,63.56 46.58,77.18 30.02,77.19 C30.01,77.19 30.01,77.19 30,77.19 Z M29.9490874,7.81 C16.9255077,7.82 5.9491127,25.09 5.95997773,45.53 C5.95997773,52.54 8.49720439,58.89 13.1033701,63.44 C17.5135288,67.8 23.5026332,70.19 29.9708659,70.19 C29.9708659,70.19 29.9817552,70.19 29.9817552,70.19 C43.8873667,70.18 53.9708406,59.8 53.9599777,45.5 C53.9490621,24.72 43.1795634,7.81 29.9490874,7.81 Z" />
        <path
          fill={colors.PRIMARY_WHITE}
          d="M29.9834716,6 C22.9342823,6 16.4557658,10.3397739 11.7398793,18.1203144 C7.3815744,25.3105061 4.99161282,34.6670856 4.99997836,44.4724448 C5.00856386,52.0755658 7.57010405,59.0617713 12.2299505,64.1430228 C16.7687133,69.0948194 22.9081266,71.8747609 29.5466153,71.9958685 L30.0165295,72 C36.8458854,72 43.1634385,69.2296734 47.7851311,64.2116321 C52.4503342,59.1506719 55.0085105,52.1272725 55.0000206,44.445344 C54.9830673,22.9617513 44.1057232,6 29.9834716,6 Z M29.9834716,8 C42.7676675,8 52.9838303,23.9307283 53.0000203,44.4472401 C53.007971,51.6415109 50.6273329,58.1774844 46.3143018,62.8563975 C42.1696767,67.3564586 36.5550976,69.8809081 30.4531951,69.9958906 L30,70 C23.7500114,70 17.9683156,67.4436655 13.7041404,62.7914444 C9.39212747,58.0894821 7.00801505,51.5871835 6.9999781,44.4704631 C6.99191339,35.0176098 9.29013691,26.0201802 13.4502228,19.1570039 C17.8291469,11.9324034 23.6995311,8 29.9834716,8 Z"
        />
      </g>
    </Icon>
  )
})``

type ProgressProps = SpecificIconProps & {
  progress: number
}

function ProgressEggComponent({ progress, ...iconProps }: ProgressProps): React.ReactElement<ProgressProps> {
  if (progress >= 100) {
    return <EggFull {...iconProps} />
  }

  return <EggPartial progress={progress} {...iconProps} />
}

export const ProgressEgg = styled(ProgressEggComponent)``

export const Owl = styled(function Owl(props: SpecificIconProps) {
  return (
    <Icon name="owl-head" viewBox="0 0 20 21" {...props}>
      <path
        d="M18.668 5.649C17.025 2.7 13.894.709 10.3.709c-3.593 0-6.725 1.992-8.37 4.942l-.918-.874a.183.183 0 0 0-.309.136v5.371c0 5.351 4.258 9.768 9.563 9.786 5.317.018 9.631-4.324 9.631-9.68V4.97a.21.21 0 0 0-.353-.154l-.876.833Z"
        fill="currentColor"
      />
    </Icon>
  )
})`
  margin-top: '1px';
`
