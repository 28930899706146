import * as React from 'react'
import styled from 'styled-components'

import * as api from '@owl-nest/api-client/latest'
import * as plume from '@ulule/owl-kit-components/next'

import { UluleLogo } from '../../logos/UluleLogo'
import * as S from '../../style'

import { useURLContext } from '../../../context/urls/urlContext'
import { getUrlWithUTMs } from '../../../utils/urls'

type MobileOnlyLogoProps = {
  lang: api.Lang
  siteIdentifier: string
}

export function MobileOnlyLogo({ lang, siteIdentifier }: MobileOnlyLogoProps): React.ReactElement {
  const urlContext = useURLContext()

  return (
    <LogoWrapper>
      <a href={getUrlWithUTMs(urlContext.home, siteIdentifier, 'ulule', 'logo')}>
        <UluleLogo />
      </a>
    </LogoWrapper>
  )
}

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 33px;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  svg {
    height: 100%;
  }

  a {
    color: ${plume.COLORS.TEXT_PRIMARY}; // Force color on logo for legacy pages
  }

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    display: none;
  }
`
