import * as React from 'react'

import { SessionData, Translations, URLs } from '../../types'

type UserDrawerConfiguration = {
  sessionData: SessionData
  translations: Translations['userDrawer']
  urls: URLs
}

export const userDrawerContext = React.createContext<UserDrawerConfiguration | undefined>(undefined)

export function useUserDrawerContext(): UserDrawerConfiguration {
  const userDrawerConfiguration = React.useContext(userDrawerContext)

  if (userDrawerConfiguration === undefined) {
    throw Error(`Can't useUserDrawerContext outside <UserDrawerProvider />`)
  }

  return userDrawerConfiguration
}
