import * as React from 'react'

import { api, UserDiscussionsStats } from '@owl-nest/api-client/latest'
import { isExpectedError } from '@owl-nest/api-client'
import * as logger from '@owl-nest/logger'

export function useUnreadMessageCount(): { unreadMessageCount: number } {
  const [unreadMessageCount, setCount] = React.useState(0)

  React.useEffect(() => {
    fetchDiscussionsStats().then(discussionsStats => {
      if (discussionsStats) {
        setCount(discussionsStats.unread_count)
      }
    })
  }, [])

  return { unreadMessageCount }

  async function fetchDiscussionsStats(): Promise<UserDiscussionsStats | undefined> {
    const response = await api.get.myDiscussionsStats()

    return response.caseOf({
      left: failure => {
        if (!isExpectedError(failure)) {
          // logger.err('[header] Failed to fetch user discussions stats', { failure })
        }
        return undefined
      },
      right: response => response.body,
    })
  }
}
