import * as React from 'react'
import styled, { css } from 'styled-components'

import * as copy from './copy'
import * as bp from '../constants/breakpoints'
import * as colors from '../constants/colors'
import * as fonts from '../constants/fonts'
import * as glyphs from '../icons/glyphs'

export type RoleTagColors =
  | 'brand-indigo'
  | 'primary-blue-500'
  | 'primary-blue-900'
  | 'primary-red-900'
  | 'primary-sand-500'
  | 'secondary-green-500'
  | 'secondary-yellow-500'

type RoleTagProps = {
  backgroundColor?: RoleTagColors
} & React.HTMLAttributes<HTMLDivElement>

const TagBase = css`
  align-items: center;
  border-radius: 2px;
  color: ${colors.PRIMARY_GREY_000};
  display: flex;
  display: inline-block;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 10px;
  font-weight: 700;
  justify-content: center;
  text-align: center;
`

export const RoleTag = styled.div<RoleTagProps>`
  ${TagBase}
  height: 20px;
  text-transform: uppercase;
  line-height: 20px;
  padding: 0em 0.6em;
  width: max-content;

  /* Color */
  ${(props) => {
    if (props.theme?.colors?.primary) {
      return css`
        background-color: ${props.theme.colors.primary};
      `
    }

    if (props.backgroundColor === 'primary-blue-500') {
      return css`
        background-color: ${colors.PRIMARY_BLUE_500};
      `
    }

    if (props.backgroundColor === 'primary-blue-900') {
      return css`
        background-color: ${colors.PRIMARY_BLUE_900};
      `
    }

    if (props.backgroundColor === 'brand-indigo') {
      return css`
        background-color: ${colors.BRAND_INDIGO};
      `
    }

    if (props.backgroundColor === 'primary-red-900') {
      return css`
        background-color: ${colors.PRIMARY_RED_900};
      `
    }

    if (props.backgroundColor === 'primary-sand-500') {
      return css`
        background-color: ${colors.PRIMARY_SAND_500};
      `
    }

    if (props.backgroundColor === 'secondary-green-500') {
      return css`
        background-color: ${colors.SECONDARY_GREEN_500};
      `
    }

    if (props.backgroundColor === 'secondary-yellow-500') {
      return css`
        background-color: ${colors.SECONDARY_YELLOW_500};
      `
    }
  }};
`
RoleTag.displayName = 'S.tag.RoleTag'

export type PointsTagColors =
  | 'brand-indigo'
  | 'primary-blue-500'
  | 'primary-blue-900'
  | 'primary-red-900'
  | 'primary-sand-100'
  | 'secondary-yellow-500'

type PointsTagProps = {
  backgroundColor?: PointsTagColors
} & React.HTMLAttributes<HTMLDivElement>

export const Points = styled.div<PointsTagProps>`
  ${TagBase}
  height: 20px;
  line-height: 19px;
  min-width: 16px;
  padding: 0 3px;
  width: max-content;

  /* Color */
  ${(props) => {
    if (props.theme?.colors?.primary) {
      return css`
        background-color: ${props.theme.colors.primary};
      `
    }

    if (props.backgroundColor === 'primary-blue-500') {
      return css`
        background-color: ${colors.PRIMARY_BLUE_500};
      `
    }

    if (props.backgroundColor === 'primary-blue-900') {
      return css`
        background-color: ${colors.PRIMARY_BLUE_900};
      `
    }

    if (props.backgroundColor === 'brand-indigo') {
      return css`
        background-color: ${colors.BRAND_INDIGO};
      `
    }

    if (props.backgroundColor === 'primary-red-900') {
      return css`
        background-color: ${colors.PRIMARY_RED_900};
      `
    }

    if (props.backgroundColor === 'primary-sand-100') {
      return css`
        color: ${colors.PRIMARY_SAND_700};
        background-color: ${colors.PRIMARY_SAND_100};
      `
    }

    if (props.backgroundColor === 'secondary-yellow-500') {
      return css`
        background-color: ${colors.SECONDARY_YELLOW_500};
      `
    }
  }};
`
Points.displayName = 'S.tag.Points'

export type TagColor = 'blue' | 'green' | 'sand' | 'indigo' | 'red' | 'yellow'

type TagProps = {
  size?: 'small' | 'regular'
  color?: TagColor
} & React.HTMLAttributes<HTMLDivElement>
export const Tag = styled.div<TagProps>`
  ${copy._smallStyle};
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  min-height: 24px;
  padding: 2px 6px;
  text-transform: none;
  width: max-content;

  @media screen and ${bp.TABLET} {
    min-height: 20px;
  }

  /* Size */
  ${(props) => {
    if (props.size === 'small') {
      return css`
        ${copy._xSmallStyle};
      `
    }
  }};

  /* Color */
  ${(props) => {
    if (props.color === 'green') {
      return css`
        background-color: ${colors.SECONDARY_GREEN_200};
        color: ${colors.BRAND_GREEN};

        ${glyphs.Icon} {
          color: ${colors.BRAND_GREEN};
        }
      `
    } else if (props.color === 'sand') {
      return css`
        background-color: ${colors.PRIMARY_SAND_100};
        color: ${colors.PRIMARY_SAND_900};

        ${glyphs.Icon} {
          color: ${colors.PRIMARY_SAND_900};
        }
      `
    } else if (props.color === 'indigo') {
      return css`
        background-color: ${colors.hexToRgb(colors.BRAND_INDIGO, 0.1)};
        color: ${colors.BRAND_INDIGO};

        ${glyphs.Icon} {
          color: ${colors.BRAND_INDIGO};
        }
      `
    } else if (props.color === 'red') {
      return css`
        color: ${colors.PRIMARY_RED_700};
        background-color: ${colors.SECONDARY_RED_200};

        ${glyphs.Icon} {
          color: ${colors.PRIMARY_RED_700};
        }
      `
    }
    if (props.color === 'yellow') {
      return css`
        background-color: ${colors.SECONDARY_YELLOW_200};
        color: ${colors.SECONDARY_YELLOW_800};

        ${glyphs.Icon} {
          color: ${colors.SECONDARY_YELLOW_800};
        }
      `
    }
    if (props.theme?.colors?.primary && props.theme?.colors?.background) {
      return css`
        color: ${props.theme.colors.primary};
        background-color: ${props.theme.colors.background};

        ${glyphs.Icon} {
          color: ${props.theme.colors.primary};
        }
      `
    }

    return css`
      background-color: ${colors.PRIMARY_BLUE_100};
      color: ${colors.PRIMARY_BLUE_900};

      ${glyphs.Icon} {
        color: ${colors.PRIMARY_BLUE_900};
      }
    `
  }};
`
Tag.displayName = 'S.tag.Tag'

export const StatusPill = styled.div<{ bordered?: boolean }>`
  align-items: center;
  background-color: ${colors.PRIMARY_GREY_000};
  border-radius: 40px;
  display: flex;
  justify-content: center;
  padding: 3px 8px;
  width: fit-content;

  ${copy.XS} {
    top: -1px;
    position: relative;
  }

  ${({ bordered }) => {
    if (bordered) {
      return css`
        border: 1px solid ${colors.PRIMARY_SAND_200};
      `
    }
  }}
`
StatusPill.displayName = 'S.tag.StatusPill'

export type StatusPastilleColor = 'green' | 'blue' | 'grey' | 'red' | 'orange'
export const StatusPastille = styled.span<{ color: StatusPastilleColor }>`
  background-color: ${({ color }) => {
    switch (color) {
      case 'green':
        return colors.SECONDARY_GREEN_500
      case 'blue':
        return colors.PRIMARY_BLUE_500
      case 'grey':
        return colors.PRIMARY_SAND_500
      case 'red':
        return colors.PRIMARY_RED_500
      case 'orange':
        return colors.SECONDARY_YELLOW_500
      default:
    }
  }};

  border-radius: 100%;
  height: 9px;
  margin-right: 6px;
  width: 9px;
`

// Clickable Tag
export type ClickableTagProps = { isActive?: boolean }

export const ClickableTag = styled.button<ClickableTagProps>`
  ${copy._smallStyle}
  align-items: center;
  background-color: transparent;
  border-color: ${colors.PRIMARY_SAND_200};
  border-radius: 19px;
  border-style: solid;
  border-width: 1px;
  color: ${colors.TEXT_TERTIARY};
  cursor: pointer;
  display: flex;
  padding: 0px 8px 2px;
  transition-duration: 300ms;

  ${glyphs.Icon} {
    margin-right: 3px;
    color: ${colors.PRIMARY_SAND_500};
  }

  &:disabled {
    background-color: ${colors.SECONDARY_GREY_400};
    color: ${colors.GREY_SHADE_3};
    cursor: not-allowed;

    ${({ isActive }) => {
      if (isActive)
        return css`
          border-color: ${colors.TEXT_TERTIARY};
        `
      if (!isActive)
        return css`
          border-color: ${colors.SECONDARY_GREY_400};
        `
    }}
  }

  &:hover {
    border-color: ${colors.PRIMARY_BLUE_700};
    color: ${colors.PRIMARY_BLUE_700};

    &:disabled {
      color: ${colors.GREY_SHADE_3};

      ${({ isActive }) => {
        if (isActive)
          return css`
            border-color: ${colors.TEXT_TERTIARY};
          `
        if (!isActive)
          return css`
            border-color: ${colors.SECONDARY_GREY_400};
          `
      }}
    }
  }

  ${({ isActive }) => {
    if (isActive) {
      return css`
        border-color: ${colors.PRIMARY_BLUE_500};
        color: ${colors.PRIMARY_BLUE_500};
      `
    }
  }}
`
ClickableTag.displayName = 'S.tag.ClickableTag'
