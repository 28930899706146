import * as React from 'react'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'
import type * as api from '@owl-nest/api-client/latest'

import * as UFE from '../../utils/UFE'
import { configurationContext } from '../../context/configuration/configurationContext'
import { UserDrawerProvider } from '../../context/userDrawer/UserDrawerProvider'
import { URLProvider } from '../../context/urls/URLProvider'
import { DiscoverUlule } from '../../components/zones/right/DiscoverUlule'
import { HeaderWrapper } from '../../components/HeaderWrapper'
import { MobileOnlyLogo } from '../../components/zones/center/MobileOnlyLogo'
import { Crowdfunding } from '../../components/zones/left/Crowdfunding'
import * as S from '../../components/style'
import { type HeaderProps } from '../../types'

const DEFAULT_CONFIGURATION = {
  defaultLocale: 'fr' as api.Lang,
  locale: 'fr' as api.Lang,
  translations: {
    discoverUlule: 'Découvrir Ulule',
    startFundraising: 'Lancer une collecte', // TODO: key subject to change, depending on the final english translation key
    searchDrawer: {
      searchPlaceholder: 'Rechercher un produit',
    },
  },
}

export function Header({
  cart,
  mobileDrawerContent,
  onCloseDrawer,
  lang = UFE.USER_LOCALE ?? UFE.DEFAULT_LOCALE,
  theme = {
    colors: {
      primary: plume.COLORS.PRIMARY_BLUE,
    },
  },
  isDrawerOpen,
  setIsDrawerOpen,
}: HeaderProps & {
  mobileDrawerContent: React.ReactNode
  onCloseDrawer: () => void
  cart: React.ReactNode
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}): React.ReactElement<HeaderProps> {
  return (
    <configurationContext.Provider value={DEFAULT_CONFIGURATION}>
      <URLProvider baseUrl="https://fr.ulule.com">
        <UserDrawerProvider lang={lang}>
          <HeaderContent>
            <HeaderWrapper
              centerZone={<MobileOnlyLogo lang={lang} siteIdentifier="boutique" />}
              enableSubnav={true}
              leftZone={
                <Crowdfunding
                  onClose={onCloseDrawer}
                  siteIdentifier="boutique"
                  subnavConfig={{
                    title: <MobileTitle as="span">Boutique</MobileTitle>,
                    mobileOnlySection: mobileDrawerContent,
                    withDiscoverLink: true,
                    withSeparator: true,
                  }}
                  isDrawerOpen={isDrawerOpen}
                  setIsDrawerOpen={setIsDrawerOpen}
                />
              }
              rightZone={<DiscoverUlule cart={cart} />}
              smallBorder={true}
              theme={theme}
            />
          </HeaderContent>
        </UserDrawerProvider>
      </URLProvider>
    </configurationContext.Provider>
  )
}

const HeaderContent = styled(S.HeaderContent)`
  position: relative;

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    ${S.LeftZone} {
      flex: auto;
    }

    ${S.CenterZone} {
      display: none;
    }

    ${S.RightZone} {
      flex: auto;
    }
  }

  & .ul-animated-logo {
    top: 0px !important;
  }
`

const MobileTitle = styled(plume.styles.copy.S)`
  font-size: 13px; // HACK: Irregular font manipulation
  font-style: italic;
  font-weight: 700; // HACK: Irregular font manipulation
`
