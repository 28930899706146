import * as React from 'react'
import type { AuthenticatedUser, PublicUser, UpdateUser, RequestFailure } from '@owl-nest/api-client/latest'
import * as auth from '@owl-nest/auth'
import * as hooks from '@owl-nest/hooks'
import * as jotaiUtils from 'jotai/utils'
import * as jotai from 'jotai'

import { reducer, getInitialState } from './reducer'
import * as action from './actions'
import { AuthState, AuthAction, TYPE } from './types'

type UseAuthInitial = {
  type: 'initial'
  previousType: UseAuth['type']
}

export type UseAuthUnknown = {
  type: 'unknown'
  previousType: UseAuth['type']
  failure?: RequestFailure
  identify: (type: 'email' | 'username', identifier: string, allowGuest?: boolean) => void
  isIdentifying: boolean
}

export type UseAuthKnown = {
  type: 'known'
  previousType: UseAuth['type']
  failure?: RequestFailure
  knownUser: auth.KnownUser
  login: (login: auth.LoginUser) => void
  forget: () => void
  isLoggingIn: boolean
}

export type UseAuthLoggedin<EXTRA_FIELDS extends string = ''> = {
  type: 'loggedin'
  previousType: UseAuth['type']
  failure?: RequestFailure
  user: AuthenticatedUser<EXTRA_FIELDS>
  logout: () => void
  update: (userId: number, user: UpdateUser) => void
  isLoggingOut: boolean
  isRefreshing: boolean
}

export type UseAuthLoggedinGuest<EXTRA_FIELDS extends string = ''> = {
  type: 'loggedin-as-guest'
  previousType: UseAuth['type']
  failure?: RequestFailure
  user: AuthenticatedUser<EXTRA_FIELDS>
  logout: () => void
  isLoggingOut: boolean
  isRefreshing: boolean
}

export type UseAuthExisting = {
  type: 'existing'
  previousType: UseAuth['type']
  service: 'ulule' | 'facebook'
  failure?: RequestFailure
  identifier: { type: 'email' | 'username'; value: string }
  login: (login: auth.LoginUser) => void
  toNew: (email: string) => void
  forget: () => void
  isLoggingIn: boolean
}

export type UseAuthNew = {
  type: 'new'
  previousType: UseAuth['type']
  failure?: RequestFailure
  identifier: { type: 'email' | 'username'; value: string }
  guestLogin: (mail: string) => void
  signup: (signup: auth.SignupUser) => void
  toExisting: (email: string, service: 'facebook' | 'ulule') => void
  forget: () => void
  isSigningUp: boolean
  isGuestLoggingIn: boolean
}

export type UseAuth<EXTRA_FIELDS extends string = ''> =
  | UseAuthInitial
  | UseAuthUnknown
  | UseAuthKnown
  | UseAuthLoggedin<EXTRA_FIELDS>
  | UseAuthLoggedinGuest<EXTRA_FIELDS>
  | UseAuthExisting
  | UseAuthNew

export type AuthOptions<EXTRA_FIELDS extends string = ''> = {
  onGuestLoggedIn?: (user: AuthenticatedUser<EXTRA_FIELDS>) => void
  onLoggedIn?: (user: AuthenticatedUser<EXTRA_FIELDS>) => void
  onSignedUp?: (user: AuthenticatedUser) => void
  onLogout?: () => void
  extraFields?: EXTRA_FIELDS[]
  reduxDispatch?: hooks.Dispatch<AuthState, AuthAction>
}

export const AUTH_ATOM = jotaiUtils.atomWithReducer(getInitialState(), reducer)

export function useAuth<EXTRA_FIELDS extends string = ''>({
  onGuestLoggedIn,
  onLoggedIn,
  onLogout,
  onSignedUp,
  extraFields = [],
  reduxDispatch,
}: AuthOptions<EXTRA_FIELDS> = {}): UseAuth<EXTRA_FIELDS> {
  // subscribe reduxDispatcher to jotai store to keep redux in sync
  const store = jotai.useStore()
  React.useEffect(() => {
    if (reduxDispatch !== undefined) {
      return store.sub(AUTH_ATOM, () => {
        reduxDispatch({ type: TYPE.SYNC, state: store.get(AUTH_ATOM) })
      })
    }
  }, [])

  const [state, dispatch] = hooks.useAtomWithReducer(AUTH_ATOM) //useSharedReducer(SHARED_AUTH, listeners)

  const previousTypeRef = React.useRef(state.type)

  React.useEffect(() => {
    // dont use the hook state, use the container state to have the updated
    // state. If another hook initialized right before this one, the state was
    // updated, but the `state` variable returned from the hook is still the
    // "stale" uninitialized state. Using the state from the store allows us to
    // have the updated state from eventual previous initialization (and skip
    // the initialization if it was already done)
    const state = store.get(AUTH_ATOM)

    // initialize once on first hook run
    if (state.type === 'initial' && !state.isInitializing) {
      dispatch(action.initialize(true, extraFields))
    }

    // re-initialize if more extrafields are needed
    else if ((state.type === 'loggedin' || state.type === 'loggedin-as-guest') && !state.isRefreshing) {
      const hasAllExtraFields = extraFields.every((field) => state.extraFields.includes(field))
      if (!hasAllExtraFields) {
        const mergedExtraFields = Array.from(new Set([...state.extraFields, ...extraFields]))
        dispatch(action.refresh(true, mergedExtraFields))
      }
    }
  }, [extraFields])

  switch (state.type) {
    case 'initial': {
      const auth = { type: 'initial', previousType: previousTypeRef.current } as const
      previousTypeRef.current = state.type
      return auth
    }
    case 'unknown': {
      // in unknown state, we can identify (and move either to existing or new)
      const auth = {
        type: 'unknown',
        previousType: previousTypeRef.current,
        failure: state.failure,
        identify,
        isIdentifying: state.isIdentifying,
      } as const
      previousTypeRef.current = state.type
      return auth
    }
    case 'known': {
      // in known state, we can login (and stay to known or move to loggedin) or forget (back to unknown)
      const auth = {
        type: 'known',
        previousType: previousTypeRef.current,
        knownUser: state.knownUser,
        failure: state.failure,
        login,
        forget,
        isLoggingIn: state.isLoggingIn,
      } as const
      previousTypeRef.current = state.type
      return auth
    }
    case 'new': {
      // in new state, we can signup (and stay to new or move to loggedin) or force move to existing
      const auth = {
        type: 'new',
        previousType: previousTypeRef.current,
        failure: state.failure,
        identifier: state.identifier,
        guestLogin,
        toExisting: (email: string, service: 'facebook' | 'ulule') =>
          dispatch({ type: TYPE.IDENTIFY_EXISTING, identifier: { type: 'email', value: email }, service }),
        signup,
        forget,
        isSigningUp: state.isSigningUp,
        isGuestLoggingIn: state.isGuestLoggingIn,
      } as const
      previousTypeRef.current = state.type
      return auth
    }
    case 'existing': {
      // in existing state, we can login (and stay to existing or move to loggedin) or force move to new
      const auth = {
        type: 'existing',
        previousType: previousTypeRef.current,
        service: state.service,
        failure: state.failure,
        identifier: state.identifier,
        toNew: (email: string) => dispatch({ type: TYPE.IDENTIFY_NEW, identifier: { type: 'email', value: email } }),
        forget,
        login,
        isLoggingIn: state.isLoggingIn,
      } as const
      previousTypeRef.current = state.type
      return auth
    }
    case 'loggedin': {
      // in loggedin state, we can logout (and stay to loggedin or move to unknown)
      const auth = {
        type: 'loggedin',
        previousType: previousTypeRef.current,
        failure: state.failure,
        update,
        logout,
        isLoggingOut: state.isLoggingOut,
        user: state.authenticatedUser as AuthenticatedUser<EXTRA_FIELDS>,
        isRefreshing: state.isRefreshing,
      } as const
      previousTypeRef.current = state.type
      return auth
    }
    case 'loggedin-as-guest': {
      // in loggedin state, we can logout (and stay to loggedin or move to unknown)
      const auth = {
        type: 'loggedin-as-guest',
        previousType: previousTypeRef.current,
        failure: state.failure,
        logout,
        isLoggingOut: state.isLoggingOut,
        user: state.authenticatedUser as AuthenticatedUser<EXTRA_FIELDS>,
        isRefreshing: state.isRefreshing,
      } as const
      previousTypeRef.current = state.type
      return auth
    }
  }

  function identify(type: 'email' | 'username', identifier: string, allowGuest?: boolean): void {
    dispatch(action.identify(type, identifier, extraFields, allowGuest, onGuestLoggedIn))
  }

  function signup(signup: auth.SignupUser): void {
    dispatch(action.signup(signup)).then((response) => {
      response.next((user) => {
        onSignedUp && onSignedUp(user)
        return user
      })
    })
  }

  function login(login: auth.LoginUser): void {
    dispatch(action.login(login, true, extraFields)).then((response) => {
      response.next((user) => {
        onLoggedIn && onLoggedIn(user)
        return user
      })
    })
  }

  function guestLogin(email: string): void {
    dispatch(action.guestLogin(email, true, extraFields)).then((response) => {
      response.next((user) => {
        onGuestLoggedIn && onGuestLoggedIn(user)
        return user
      })
    })
  }

  function logout(): void {
    dispatch(action.logout()).then(() => {
      onLogout && onLogout()
    })
  }

  function update(userId: number, user: UpdateUser): void {
    dispatch(action.update(userId, user))
  }

  function forget(): void {
    auth.forgetUser()
    dispatch(action.forget())
  }
}

export function useAuthEffect(effect: (state: AuthState) => void): () => void {
  const store = jotai.useStore()

  return store.sub(AUTH_ATOM, () => effect(store.get(AUTH_ATOM)))
}

export function useAuthenticatedUser<EXTRA_FIELDS extends string = ''>(
  authOptions?: AuthOptions<EXTRA_FIELDS>,
): AuthenticatedUser<EXTRA_FIELDS> | undefined {
  const auth = useAuth(authOptions)
  if (auth.type === 'loggedin' || auth.type === 'loggedin-as-guest') {
    return auth.user
  }
  return undefined
}

export function useCurrentPublicUser<EXTRA_FIELDS extends string = ''>(
  authOptions?: AuthOptions<EXTRA_FIELDS>,
): PublicUser | undefined {
  const auth = useAuth(authOptions)
  if (auth.type === 'loggedin' || auth.type === 'loggedin-as-guest') {
    return auth.user
  }
  if (auth.type === 'known') {
    return auth.knownUser
  }
  return undefined
}

export function isLoggedIn<EXTRA_FIELDS extends string = ''>(
  auth: UseAuth<EXTRA_FIELDS>,
): auth is UseAuthLoggedin<EXTRA_FIELDS> | UseAuthLoggedinGuest<EXTRA_FIELDS> {
  return auth.type === 'loggedin' || auth.type === 'loggedin-as-guest'
}

export function useSafeAuth(): UseAuthLoggedin {
  const _auth = useAuth()
  if (_auth.type === 'loggedin') {
    return _auth
  }
  throw Error('the user is not loggedin !')
}
