import styled, { css } from 'styled-components'

import * as colors from '../../constants/colors'
import * as fonts from '../../constants/fonts'
import * as glyphs from '../../icons/glyphs'

import * as button from '../button'

export type Status = 'focused' | 'error' | 'default'

export const Label = styled.span<{ disabled?: boolean }>`
  color: ${colors.PRIMARY_BLACK};
  display: block; // another value might break text-overflow ellipsis
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  line-height: 20px;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        color: ${colors.GREY_SHADE_3};
      `
    }
  }}
`

export const CaretDown = styled(glyphs.fill.CaretDown).attrs({ size: 10 })`
  color: ${colors.GREY_SHADE_4};
`

export const Button = styled.button<{ status?: Status }>`
  align-items: center;
  background: ${colors.PRIMARY_WHITE};
  border: 1px solid ${colors.GREY_SHADE_5};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  padding: 14px 13px;
  text-align: left;
  user-select: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    border: 1px solid ${colors.PRIMARY_BLUE};
    color: ${colors.PRIMARY_BLUE};
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid ${colors.GREY_SHADE_5};
    background-color: ${colors.GREY_SHADE_6};
  }

  ${({ status }) => {
    if (status === 'focused') {
      return css`
        border: 1px solid ${colors.PRIMARY_BLUE};
        color: ${colors.PRIMARY_BLUE};
      `
    }
    if (status === 'error') {
      return css`
        border: 1px dashed ${colors.PRIMARY_RED};
      `
    }
  }}

  ${CaretDown} {
    flex: 0 0 auto;
  }
`

export const DrawerHead = styled.div`
  display: none;
`

export const Drawer = styled.div<{ isOpen: boolean }>`
  height: 0;
  ${({ isOpen }) => {
    if (!isOpen) {
      return css`
        visibility: hidden;
        overflow: hidden;
      `
    }
  }};
`

export const ScrollList = styled.ul<{ height?: number }>`
  background-color: ${colors.PRIMARY_WHITE};
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative; /* make this the offsetParent of all children (used for scroll) */

  ${({ height }) => {
    if (height !== undefined) {
      return css`
        max-height: ${height}px;
        overflow-y: auto;
      `
    }
  }}
`

export const MultiSelectButton = styled(button.Button)`
  width: 100%;
`

export const SearchBox = styled.div`
  padding: 10px;
`

export const Wrapper = styled.div<{ native?: boolean }>`
  position: relative;

  ${({ native }) => {
    if (native) {
      return css`
        select {
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 0;
        }

        ${Button} {
          pointer-events: none;
          position: relative;
          z-index: 1;
        }
      `
    }
  }}
`
