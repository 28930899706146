import * as React from 'react'

// TODO : add a useEffect like useThrottle into use100vh.ts
// to clear timeout
export function useThrottle<ARGS extends any[]>(call: (...args: ARGS) => void, timeout = 200): (...args: ARGS) => void {
  const throttling = React.useRef(false)

  return (...args) => {
    if (!throttling.current) {
      throttling.current = true
      call(...args)
      setTimeout(() => {
        throttling.current = false
      }, timeout)
    }
  }
}

// This hook returns a function that will call `call` at most every
// `timeout` milliseconds. This hooks guarantees that the first and
// last calls are done (the first one done immediatly, the last one
// at most `timeout` milliseconds after the fact)
export function useRealThrottle<ARGS extends any[]>(
  call: (...args: ARGS) => void,
  timeout = 200,
): (...args: ARGS) => void {
  const handle = React.useRef<number>()
  const params = React.useRef<ARGS>()

  React.useEffect(() => {
    if (handle.current) {
      clearTimeout(handle.current)
    }
  }, [])

  return (...args) => {
    if (!handle.current) {
      // if not currently throttling, call, and dispatch a future call
      call(...args)
      dispatchCall()
    } else {
      // otherwise we save the args for a future call
      params.current = args
    }
  }

  function dispatchCall() {
    // when we dispatch a future call, we throttle any calls in between
    handle.current = window.setTimeout(() => {
      // when throttling timeout done, we "unthrottle" first
      handle.current = undefined
      // is there were any call during previous throttling period, we call the
      // function, clean saved params and dispatch a future call
      if (params.current) {
        call(...params.current)
        params.current = undefined
        dispatchCall()
      }
    }, timeout)
  }
}
