import * as React from 'react'

import { OptionData, SelectApi, OptionApi } from './types'
import { reducer } from './reducer'
import { useNavigableList } from '../list'
import { useCommittedRef } from '../../hooks/useCommitedRef'

export function useSelect(
  selected: any[],
  selectBoxRef: React.RefObject<HTMLElement>,
  handleClose: (selected: OptionData[]) => void,
  handleChange: (selected: OptionData[]) => void,
  multiple: boolean,
  disableInitFocus: boolean,
  filter?: string,
): SelectApi {
  const selectedHead = selected[0]
  const [state, dispatch] = React.useReducer(reducer, {
    registered: [],
    open: false,
    focused: selectedHead,
  })
  const stateCommittedRef = useCommittedRef(state)

  const selectedOptions = state.registered.filter((option) => selected.includes(option.value))
  const selectedOptionsCommittedRef = useCommittedRef(selectedOptions)
  selectedOptionsCommittedRef.current = selectedOptions

  const handleChangeComittedRef = useCommittedRef(handleChange)

  const navigable = useNavigableList(
    state.open,
    state.focused,
    filter === undefined, // type-ahead if no filter
    disableInitFocus,
    filter,
  )

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return {
    register,
    isOpen: state.open,
    open: () => dispatch({ type: 'open', focused: selectedHead }),
    close,
    clear: () => {
      const handleChange = handleChangeComittedRef.current

      selected.forEach((value) => {
        const option = getOption(value)
        if (option === undefined) {
          return
        }
        option.handleToggle(false)
      })
      handleChange([])
    },
    selectedOptions: state.registered.filter((option) => selected.includes(option.value)),
  }

  function handleKeyDown(event: KeyboardEvent): void {
    const state = stateCommittedRef.current
    const selectBox = selectBoxRef.current

    if (selectBox === null || !selectBox.contains(document.activeElement)) {
      return
    }

    if (!state.open) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Down' || event.key === 'Up') {
        return dispatch({ type: 'open', focused: selectedHead })
      }
    } else {
      if (event.key === 'Escape' || event.key === 'Esc') {
        event.preventDefault()
        close()
        return
      }
      if (event.key === 'Enter') {
        event.preventDefault()
        if (state.focused === undefined) {
          return
        }
        const option = getOption(state.focused)
        if (option !== undefined) {
          handleOptionToggle(option)
        }
        return
      }
    }
  }

  // register an option in the Select. Usually not used directly, but passed
  // down the tree in a context, to allow child self-registration.
  // see /src/behaviours/README.md
  function register(option: OptionData): OptionApi {
    const { focus, isFocused, isFiltered } = navigable.register({
      identifier: option.value,
      label: option.label,
      disabled: option.disabled,
      handleFocus: () => {
        option.handleFocus()
        dispatch({ type: 'focus', focused: option.value })
      },
      filtered: false,
    })

    React.useEffect(() => {
      dispatch({ type: 'register', option })
      return () => {
        dispatch({ type: 'deregister', value: option.value })
      }
    }, [option.disabled])

    return {
      toggle: React.useCallback(() => handleOptionToggle(option), [option.value, option.disabled]),
      isSelected: isSelected(option),
      multiple,
      focus,
      isFocused,
      isFiltered,
    }
  }

  function getOption(value: any): OptionData | undefined {
    const state = stateCommittedRef.current
    return state.registered.find((option) => option.value === value)
  }

  function isSelected(option: OptionData): boolean {
    const selectedOptions = selectedOptionsCommittedRef.current
    return selectedOptions.some(({ value }) => value === option.value)
  }

  function close(): void {
    const state = stateCommittedRef.current
    if (state.open) {
      handleClose(selectedOptions)
      dispatch({ type: 'close' })
    }
  }

  function handleOptionToggle(option: OptionData): void {
    const selectedOptions = selectedOptionsCommittedRef.current
    const handleChange = handleChangeComittedRef.current

    if (option.disabled === true) {
      return
    }

    const isOptionSelected = isSelected(option)
    if (multiple) {
      if (isOptionSelected) {
        const index = selectedOptions.findIndex(({ value }) => value === option.value)
        handleChange([...selectedOptions.slice(0, index), ...selectedOptions.slice(index + 1)])
        option.handleToggle(false)
      } else {
        handleChange([...selectedOptions, option])
        option.handleToggle(true)
      }
    } else {
      if (!isOptionSelected) {
        if (selectedOptions[0] !== undefined) {
          selectedOptions[0].handleToggle(false)
        }
        handleChange([option])
        option.handleToggle(true)
      }
      close()
    }
  }
}
