import styled from 'styled-components'

import * as colors from '../constants/colors'
import * as effects from '../constants/neue/effects'

import * as heading from './heading'
import * as image from './image'

export const Wrapper = styled.a`
  min-width: 220px;
  min-height: 70px;
  max-width: 320px;
  max-height: 244px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  display: block;
  transition: all 150ms ease-out;

  &:hover {
    transition: all 200ms ease-in-out;
    box-shadow: ${effects.SHADOW_4};
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 4px;
    background: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 100%);
    height: 129px;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 11px 0 15px;
  align-items: center;
  box-sizing: border-box;
`

export const Info = styled.div`
  padding-right: 10px;
`

export const Title = styled(heading.L)`
  color: ${colors.PRIMARY_WHITE};
`

export const Avatar = styled(image.Avatar)`
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-end;
  margin: 0 0 18px;
`

export const Description = styled(heading.Card1)`
  color: ${colors.PRIMARY_WHITE};
  margin: 5px 0 15px;
`

export const Img = styled.img`
  width: 100%;
  border-radius: 4px;
  flex: 1 1 auto;
  object-fit: cover;
  display: flex;
  max-height: 244px;
  min-height: 244px;
  min-width: 320px;
`
export const SubButton = styled.div`
  position: absolute;
  top: -7%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
