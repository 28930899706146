import * as React from 'react'

import { t, tn, tp } from '@owl-nest/localize'
import { searchParams } from '@owl-nest/utils'
import * as api from '@owl-nest/api-client/latest'

import { userDrawerContext } from './userDrawerContext'
import { SessionData, Translations, URLs } from '../../types'
import * as UFE from '../../utils/UFE'
import * as hooks from '@owl-nest/hooks'

type UserDrawerProviderProps = {
  lang: api.Lang
  children: React.ReactNode
}

export function UserDrawerProvider({
  lang,
  children,
}: UserDrawerProviderProps): React.ReactElement<UserDrawerProviderProps> {
  const sessionData = useSessionData(lang)
  return (
    <userDrawerContext.Provider value={{ sessionData, translations: getTranslations(), urls: getURLs(lang) }}>
      {children}
    </userDrawerContext.Provider>
  )
}

function formatURL(lang: api.Lang, url: string): string {
  const params: Record<string, boolean | number | string> = { lang }
  if (typeof window !== 'undefined') {
    params.next = window.location.href
  }
  return searchParams.addSearchParamsToUrl(url, params)
}

function useSessionData(lang: api.Lang): SessionData {
  const config = hooks.useUserConfig(UFE.ULULE_ORIGINS[lang])
  return {
    currentCountry: config.data?.country,
    currentCurrency: config.data?.currency,
    currentLocale: lang,
    defaultLocale: UFE.DEFAULT_LOCALE,
    rates: UFE.CURRENCIES.RATES,
  }
}

function getTranslations(): Translations['userDrawer'] {
  return {
    contributionsBoxTitle: (contributionCount = 0) =>
      tn('My latest contribution', 'My latest contributions', contributionCount),
    myContributions: tp('My contributions', 'User menu'),
    myMessages: t('My messages'),
    myProfile: t('My profile'),
    myProjects: tp('My projects', 'User menu'),
    noProjectName: t('No title'),
    projectComingSoon: {
      validatedProject: t('Ready to go online'),
      notValidatedProject: t('In progress'),
    },
    projectsBoxTitle: (projectCount) => tn('My project', 'My projects', projectCount),
    seeMore: t('See more'),
    settings: t('Settings'),
    signIn: t('Sign in'),
    signOut: t('Sign out'),
    i18nSettingsLabel: t('Language and site currency'),
  }
}

function getURLs(lang: api.Lang): URLs {
  return {
    channels: `${UFE.ULULE_ORIGINS[lang]}${UFE.CHANNELS_URL}`,
    discover: `${UFE.ULULE_ORIGINS[lang]}${UFE.DISCOVER_URL}`,
    discoverLandingPage: `${UFE.ULULE_ORIGINS[lang]}${UFE.DISCOVER_LANDING_URL}`,
    home: `${UFE.ULULE_ORIGINS[lang]}${UFE.HOMEPAGE_URL}`,
    inbox: `${UFE.ULULE_ORIGINS[lang]}${UFE.DISCUSSIONS_URL}`,
    proposal: `${UFE.ULULE_ORIGINS[lang]}${UFE.PROPOSAL_URL}`,
    signIn: {
      onClick: (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault()
        window.location.href = formatURL(lang, `${UFE.ULULE_ORIGINS[lang]}${UFE.SIGNIN_URL}`)
      },
      url: `${UFE.ULULE_ORIGINS[lang]}${UFE.SIGNIN_URL}`,
    },
    signOut: formatURL(lang, `${UFE.ULULE_ORIGINS[lang]}${UFE.SIGNOUT_URL}`),
  }
}
