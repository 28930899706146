import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../constants/colors'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'

export const Binoculars = styled(function Binoculars(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="binoculars" viewBox="0 0 130 85">
      <circle cx="29.95" cy="54.57" r="26.08" fill={colors.PRIMARY_SAND} />
      <circle cx="97.77" cy="54.57" r="26.08" fill={colors.PRIMARY_SAND} />
      <circle cx="66.84" cy="34.74" r="9.98" fill={colors.PRIMARY_SAND} />
      <path
        fill={colors.PRIMARY_BLACK}
        d="M124.75,20.15 L124.77,19.95 C125.02,18.9 125.17,17.8 125.17,16.67 C125.17,16.37 125.14,16.07 125.12,15.77 L125.15,15.39 C125.21,14.74 125.08,14.1 124.8,13.55 C123.37,7.24 117.73,2.51 111,2.51 C106.32,2.51 102.17,4.81 99.6,8.33 C99.46,8.48 99.34,8.64 99.23,8.81 L96.22,13.59 C94.22,14.58 92.37,15.84 90.74,17.35 C89.31,15.56 87.43,14.14 85.28,13.27 C84.38,6.33 78.44,0.94 71.26,0.94 C67.87,0.94 64.77,2.14 62.33,4.13 L51.71,11.43 C50.62,11.27 49.51,11.19 48.39,11.19 C45,11.19 41.78,11.93 38.88,13.24 C38.82,13.26 38.77,13.29 38.71,13.32 C36.57,14.31 34.61,15.61 32.88,17.16 L15.52,28.76 C14.18,29.51 12.91,30.36 11.71,31.3 L11.66,31.33 C11.63,31.35 11.6,31.38 11.57,31.41 C4.76,36.84 0.37,45.2 0.37,54.57 C0.37,70.88 13.64,84.15 29.95,84.15 C39.5,84.15 48,79.6 53.41,72.56 C53.44,72.52 53.47,72.49 53.5,72.46 L53.55,72.39 C54.14,71.61 54.7,70.8 55.21,69.95 C55.21,69.95 65.64,54.77 66.09,54 C66.54,53.23 68.21,53.18 68.21,54.58 C68.21,70.89 81.48,84.16 97.79,84.16 C110.87,84.16 121.99,75.62 125.88,63.82 C125.9,63.78 125.91,63.74 125.92,63.69 C126.85,60.82 129.4,37.66 129.4,37.66 C129.4,37.65 129.4,37.64 129.4,37.63 C129.55,36.56 129.64,35.47 129.64,34.36 C129.63,29 127.8,24.07 124.75,20.15 Z M29.95,77.14 C17.5,77.14 7.37,67.01 7.37,54.56 C7.37,42.11 17.5,31.99 29.95,31.99 C42.4,31.99 52.53,42.12 52.53,54.57 C52.53,67.02 42.39,77.14 29.95,77.14 Z M66.84,41.21 C63.27,41.21 60.36,38.3 60.36,34.73 C60.36,31.16 63.27,28.25 66.84,28.25 C70.41,28.25 73.32,31.16 73.32,34.73 C73.32,38.3 70.41,41.21 66.84,41.21 Z M97.77,77.14 C85.32,77.14 75.19,67.01 75.19,54.56 C75.19,42.11 85.32,31.98 97.77,31.98 C110.22,31.98 120.35,42.11 120.35,54.56 C120.35,67.01 110.22,77.14 97.77,77.14 Z"
      />
    </Icon>
  )
})``

export const Calendar = styled(function Calendar(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="calendar" viewBox="0 0 122 93">
      <path
        fill={colors.PRIMARY_SAND}
        d="M34.59,3.99 C34.59,3.99 33.37,21.65 25.43,45.63 C19.79,62.69 4.02,81.52 4.02,81.52 L75.3,81.52 C83.81,81.52 91.81,77.4 96.68,70.41 C100.72,64.61 105.27,56.69 108.49,47.01 C116.74,22.18 118.01,3.99 118.01,3.99 L34.59,3.99 Z"
      />
      <g fill={colors.PRIMARY_BLACK}>
        <path d="M121.5,4.24 C121.51,4.12 121.49,4 121.49,3.88 C121.49,3.77 121.49,3.66 121.48,3.55 C121.46,3.42 121.42,3.29 121.39,3.16 C121.36,3.06 121.35,2.95 121.31,2.85 C121.27,2.73 121.2,2.61 121.15,2.49 C121.1,2.39 121.07,2.29 121.01,2.2 C120.94,2.08 120.85,1.97 120.76,1.86 C120.7,1.78 120.65,1.7 120.59,1.63 C120.59,1.63 120.58,1.62 120.58,1.62 C120.58,1.62 120.57,1.61 120.57,1.6 C120.48,1.5 120.36,1.42 120.26,1.33 C120.19,1.27 120.12,1.19 120.04,1.14 C119.92,1.06 119.79,1 119.67,0.93 C119.58,0.88 119.51,0.83 119.42,0.79 C119.28,0.73 119.13,0.69 118.98,0.65 C118.9,0.63 118.82,0.59 118.74,0.57 C118.5,0.52 118.26,0.49 118.02,0.49 L34.59,0.49 C34.36,0.49 34.14,0.51 33.92,0.55 C33.85,0.56 33.79,0.59 33.72,0.61 C33.58,0.65 33.43,0.68 33.3,0.74 C33.23,0.77 33.16,0.81 33.09,0.85 C32.97,0.91 32.84,0.96 32.73,1.04 C32.66,1.08 32.6,1.14 32.54,1.18 C32.43,1.26 32.32,1.33 32.22,1.42 C32.16,1.47 32.11,1.54 32.06,1.6 C31.97,1.7 31.88,1.79 31.8,1.9 C31.75,1.97 31.71,2.05 31.66,2.12 C31.59,2.23 31.52,2.33 31.46,2.45 C31.42,2.53 31.39,2.62 31.36,2.71 C31.31,2.83 31.26,2.94 31.23,3.06 C31.2,3.16 31.19,3.26 31.17,3.36 C31.15,3.47 31.12,3.57 31.11,3.68 L30.99,5.03 C30.54,9.44 28.62,24.9 22.12,44.54 C16.75,60.74 1.49,79.09 1.33,79.27 C0.46,80.31 0.27,81.77 0.84,83 C1.42,84.23 2.65,85.02 4.01,85.02 L23.71,85.02 L23.41,88.34 C23.32,89.32 23.65,90.29 24.31,91.02 C24.97,91.75 25.91,92.16 26.89,92.16 L110.3,92.16 C112.11,92.16 113.62,90.78 113.79,88.98 L121.33,6.07 C121.44,4.99 121.49,4.36 121.5,4.24 Z M28.76,46.73 C34.84,28.35 37.04,13.73 37.77,7.49 L114.12,7.49 C113.31,13.97 111.02,28.29 105.17,45.91 C102.55,53.79 98.73,61.36 93.81,68.41 C89.61,74.43 82.69,78.02 75.3,78.02 L11.19,78.02 C16.43,70.98 24.84,58.57 28.76,46.73 Z" />
        <circle cx="49.77" cy="17.87" r="5.11" />
        <circle cx="98.95" cy="17.87" r="5.11" />
      </g>
    </Icon>
  )
})``

export const EggFunded = styled(function EggFunded(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} viewBox="0 0 53 52" name="owl-egg-funded">
      <path
        d="M21.675 21.603 28.078 10.7l6.689 4.214 4.56-7.766a25.64 25.64 0 0 0-1.113-1.213C34.006 1.68 29.223-.04 24.743 1.09l-.006.002C18.721 2.615 14.721 9.243 13.808 17.76l7.867 3.843ZM35.913 26.883l-5.452-5.647-8.365 8.664-8.137-6.81a40.426 40.426 0 0 0-1.043 9.237c.002 5.181 1.71 9.933 4.804 13.378 3.072 3.42 7.242 5.303 11.744 5.303h.01c4.52-.003 8.69-1.871 11.745-5.259 3.092-3.431 4.795-8.206 4.791-13.443-.002-4.854-.796-9.496-2.279-13.52l-7.818 8.097Z"
        fill={colors.PRIMARY_SAND}
      />
      <path
        d="M6.562 11.242a.901.901 0 0 0-.667-.46.884.884 0 0 0-.76.268l-1.79 1.854a.954.954 0 0 0-.258.783c.035.29.196.54.44.69l4.292 2.62c.147.09.305.132.462.132a.886.886 0 0 0 .636-.276.954.954 0 0 0 .147-1.137l-2.502-4.474ZM6.49 20.844l-4.348-2.542a.877.877 0 0 0-.8-.047.92.92 0 0 0-.523.628l-.636 2.539a.96.96 0 0 0 .161.81c.173.23.433.363.716.366l4.982.004c.417 0 .77-.282.877-.7a.943.943 0 0 0-.43-1.058ZM44.406 18.517a.731.731 0 0 0-.524-.466.707.707 0 0 0-.663.203l-3.458 3.582-2.97-2.728a.705.705 0 0 0-.987.025l-3.2 3.263-1.633-1.691a.704.704 0 0 0-1.022 0l-1.247 1.292-4.343-3.527 3.96-6.742 6.073 3.824a.71.71 0 0 0 .993-.25l4.56-7.767a.768.768 0 0 0-.08-.89c-.42-.483-.793-.89-1.146-1.246C34.328.955 29.303-.832 24.572.36l-.009.002c-6.1 1.544-10.497 8.18-11.476 17.314a.75.75 0 0 0 .411.76l4.757 2.323-2.176 3.144-1.668-1.396a.701.701 0 0 0-.686-.127.733.733 0 0 0-.47.533 41.142 41.142 0 0 0-1.064 9.413c.005 5.371 1.778 10.302 4.998 13.886 3.21 3.573 7.57 5.543 12.285 5.543 4.722-.002 9.08-1.955 12.273-5.498 3.219-3.571 4.99-8.528 4.988-13.955-.004-4.89-.81-9.656-2.329-13.786Zm-29.82-1.206c1.014-8.22 4.94-14.134 10.32-15.494.002 0 .004 0 .004-.002h.002c4.23-1.067 8.774.586 12.795 4.653.227.229.465.483.72.768l-3.903 6.648-6.071-3.826a.71.71 0 0 0-.993.25l-6.066 10.329-6.808-3.326ZM40.69 45.238c-2.917 3.235-6.9 5.019-11.226 5.02-4.298 0-8.282-1.797-11.216-5.065-2.97-3.306-4.607-7.877-4.61-12.868a40.012 40.012 0 0 1 .77-7.9l7.236 6.056c.287.24.704.218.964-.053l7.855-8.136 4.943 5.117c.27.28.751.28 1.021 0l7.013-7.264c1.21 3.699 1.848 7.875 1.85 12.156 0 5.05-1.633 9.644-4.6 12.937Z"
        fill={colors.PRIMARY_BLACK}
      />
      <path
        d="M52.658 38.044a.378.378 0 0 0-.417.077l-.558.546c-1.517-2.598-4.246-4.202-7.186-4.202-2.94 0-5.669 1.604-7.186 4.202l-.592-.58a.351.351 0 0 0-.395-.07.375.375 0 0 0-.218.346v4.704c0 4.827 3.75 8.767 8.36 8.785h.031c2.236 0 4.34-.9 5.923-2.534 1.592-1.643 2.469-3.83 2.469-6.157v-4.745a.404.404 0 0 0-.231-.372ZM44.355 13.19l5.545-.831c.605-.09.89-.828.51-1.324L46.94 6.476a.782.782 0 0 0-1.362.206l-2.077 5.39c-.227.586.248 1.209.853 1.119ZM11.376 36.924l1.164-.567c-.198-1.133-.31-2.3-.336-3.492l-1.35-.267a.846.846 0 0 1-.665-.734l-.53-4.385c-.1-.824-1.159-1.033-1.545-.306l-2.058 3.87a.817.817 0 0 1-.88.428l-4.19-.832c-.787-.156-1.307.821-.76 1.426l2.92 3.225a.881.881 0 0 1 .121.999l-2.058 3.87c-.386.727.35 1.542 1.075 1.189l3.863-1.878a.803.803 0 0 1 .954.19l2.919 3.225c.547.605 1.523.13 1.422-.692l-.53-4.386a.857.857 0 0 1 .464-.883Z"
        fill={colors.PRIMARY_GREEN}
      />
    </Icon>
  )
})``

export const Owl = styled(function Owl(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="owl" viewBox="0 0 110 114">
      <path
        fill={colors.PRIMARY_SAND}
        d="M93.18,28.12 C85,13.57 69.41,3.74 51.52,3.74 C33.63,3.74 18.04,13.57 9.85,28.13 L5.28,23.82 C4.7,23.27 3.74,23.68 3.74,24.49 L3.74,51 C3.74,77.41 24.94,99.21 51.35,99.3002824 C77.82,99.39 99.3,77.96 99.3,51.52 L99.3,24.77 C99.3,23.86 98.21,23.39 97.54,24.01 L93.18,28.12 Z"
      />
      <path
        fill={colors.PRIMARY_BLACK}
        d="M107.02,31.57 C105.62,30.97 104.05,31.12 102.79,31.91 L102.79,24.77 C102.79,22.96 101.71,21.32 100.05,20.6 C98.38,19.88 96.45,20.22 95.13,21.47 L93.89,22.64 C84.39,8.72 68.53,0.24 51.52,0.24 C34.5,0.24 18.64,8.72 9.14,22.65 L7.69,21.28 C6.41,20.07 4.53,19.74 2.91,20.44 C1.29,21.14 0.24,22.73 0.24,24.5 L0.24,51 C0.24,63.61 4.74,75.18 12.2,84.18 C20.43,101.59 37.99,113.69 58.31,113.76 C58.37,113.76 58.43,113.76 58.49,113.76 C72.16,113.76 85,108.45 94.69,98.8 C104.42,89.11 109.77,76.21 109.77,62.48 L109.77,35.74 C109.76,33.93 108.69,32.29 107.02,31.57 Z M7.24,30.47 L7.45,30.67 C8.23,31.41 9.31,31.75 10.38,31.58 C11.44,31.42 12.38,30.77 12.9,29.84 C20.74,15.9 35.54,7.24 51.52,7.24 C67.5,7.24 82.29,15.9 90.14,29.84 C90.67,30.78 91.6,31.42 92.66,31.58 C93.73,31.74 94.81,31.4 95.59,30.67 L95.8,30.47 L95.8,51.51 C95.8,63.37 91.18,74.51 82.78,82.88 C74.42,91.21 63.33,95.79 51.53,95.79 C51.48,95.79 51.42,95.79 51.37,95.79 C27.03,95.71 7.24,75.62 7.24,51 L7.24,30.47 Z"
      />
    </Icon>
  )
})``

export const PaperPlane = styled(function PaperPlane(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="paper-plane" viewBox="0 0 126 106">
      <polygon fill={colors.PRIMARY_SAND} points="122.1 4.21 3.9 39.73 36.9 62.67 31.4 93.89 51.57 73.4 73.87 88.36" />
      <path
        fill={colors.PRIMARY_BLACK}
        d="M125.48,5.1 C125.49,5.07 125.5,5.04 125.51,5.01 C125.57,4.77 125.59,4.53 125.6,4.28 C125.6,4.25 125.61,4.23 125.61,4.2 C125.61,4.16 125.6,4.11 125.6,4.07 C125.6,3.99 125.59,3.91 125.58,3.82 C125.57,3.73 125.56,3.64 125.54,3.55 C125.52,3.47 125.51,3.39 125.49,3.31 C125.47,3.22 125.44,3.14 125.41,3.05 C125.38,2.97 125.35,2.89 125.32,2.81 C125.29,2.73 125.25,2.66 125.21,2.58 C125.17,2.5 125.12,2.42 125.07,2.34 C125.05,2.3 125.03,2.26 125,2.23 C124.98,2.2 124.96,2.19 124.94,2.16 C124.91,2.12 124.88,2.07 124.85,2.03 C124.78,1.94 124.69,1.86 124.61,1.77 C124.58,1.74 124.55,1.7 124.52,1.67 C124.37,1.53 124.21,1.4 124.04,1.29 C123.99,1.26 123.94,1.23 123.89,1.2 C123.76,1.12 123.63,1.06 123.5,1 C123.43,0.97 123.37,0.95 123.3,0.92 C123.16,0.87 123.03,0.83 122.89,0.8 C122.83,0.79 122.77,0.77 122.71,0.76 C122.52,0.73 122.32,0.71 122.12,0.71 C122.07,0.71 122.01,0.72 121.96,0.72 C121.81,0.73 121.66,0.74 121.52,0.77 C121.44,0.78 121.37,0.8 121.3,0.82 C121.24,0.83 121.18,0.84 121.11,0.86 L2.89,36.38 C1.6,36.77 0.65,37.86 0.44,39.18 C0.23,40.51 0.8,41.84 1.9,42.6 L16.02,52.41 L9.84,54.27 C8.55,54.66 7.6,55.75 7.39,57.07 C7.18,58.4 7.75,59.73 8.85,60.49 L31.01,75.89 L27.95,93.27 C27.68,94.78 28.43,96.29 29.79,96.99 C30.3,97.25 30.85,97.38 31.39,97.38 C32.31,97.38 33.21,97.02 33.89,96.34 L44.67,85.39 L72.41,104.67 C73,105.08 73.7,105.3 74.41,105.3 C74.67,105.3 74.92,105.27 75.18,105.21 C76.14,105 76.96,104.39 77.45,103.54 L121.25,27.11 C121.96,25.87 121.84,24.32 120.95,23.19 C120.06,22.07 118.57,21.61 117.2,22.01 L115.67,22.47 L125.14,5.94 C125.16,5.91 125.17,5.88 125.18,5.85 C125.2,5.8 125.22,5.76 125.25,5.71 C125.34,5.52 125.42,5.31 125.48,5.1 Z M72.73,83.31 L44.38,63.61 L42.95,62.53 L111.47,15.71 L72.73,83.31 Z M101.48,14.06 L36.74,58.29 L11.85,41 L101.48,14.06 Z M39.4,68.67 L40.28,69.28 L46.27,73.79 L36.8,83.41 L39.4,68.67 Z"
      />
    </Icon>
  )
})``

export const Stopwatch = styled(function Stopwatch(props: SpecificIconProps): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="stopwatch" viewBox="0 0 106 126">
      <circle cx="48.49" cy="69.5" r="44.3" fill={colors.PRIMARY_SAND} />
      <g fill={colors.PRIMARY_BLACK}>
        <path d="M46.97,77.27 C51.3,77.56 55.04,74.29 55.33,69.96 C55.37,69.34 55.33,68.74 55.24,68.16 L70.64,50.65 C71.14,50.08 71.14,49.22 70.63,48.66 C70.12,48.1 69.27,48 68.65,48.44 L49.59,61.86 C49.09,61.72 48.56,61.63 48.02,61.59 C43.69,61.3 39.95,64.57 39.66,68.9 C39.37,73.23 42.64,76.98 46.97,77.27 Z" />
        <path d="M90.72,43.51 L93.16,41.37 C94.62,40.1 94.76,37.89 93.49,36.43 L87.73,29.84 C87.12,29.14 86.26,28.71 85.33,28.65 C84.41,28.58 83.49,28.9 82.79,29.51 L79.08,32.76 C72.78,27.49 65.27,23.97 57.15,22.49 L57.63,15.3 L64.69,15.77 C64.77,15.78 64.85,15.78 64.92,15.78 C65.76,15.78 66.58,15.47 67.22,14.91 C67.92,14.3 68.35,13.44 68.41,12.51 L68.86,5.77 C68.99,3.84 67.53,2.17 65.6,2.04 L40.5,0.36 C39.56,0.3 38.66,0.61 37.96,1.22 C37.26,1.83 36.83,2.69 36.77,3.62 L36.32,10.36 C36.19,12.29 37.65,13.96 39.58,14.09 L46.64,14.56 L46.16,21.75 C22.27,22.9 2.44,41.86 0.8,66.3 C-0.05,79.04 4.1,91.35 12.51,100.96 C15.04,103.86 17.88,106.4 20.94,108.57 C29.71,118.99 42.85,125.63 57.51,125.63 C83.87,125.63 105.32,104.18 105.32,77.83 C105.31,64.37 99.71,52.2 90.72,43.51 Z M7.78,66.77 C9.22,45.25 27.2,28.7 48.47,28.7 C49.38,28.7 50.3,28.73 51.23,28.79 C62.1,29.52 72.04,34.44 79.22,42.65 C86.39,50.86 89.94,61.36 89.21,72.24 C89.21,72.24 89.21,72.24 89.21,72.24 C88.48,83.11 83.56,93.05 75.36,100.23 C67.15,107.4 56.65,110.95 45.77,110.22 C34.89,109.49 24.96,104.57 17.78,96.37 C10.6,88.15 7.05,77.65 7.78,66.77 Z" />
      </g>
    </Icon>
  )
})``

export const StopwatchPlus = styled(function StopwatchPlus(
  props: SpecificIconProps,
): React.ReactElement<SpecificIconProps> {
  return (
    <Icon {...props} name="stopwatch-plus" viewBox="0 0 113 143">
      <g fill="none" fillRule="evenodd">
        <path d="M-15-5h142v153H-15z" />
        <g transform="rotate(5 55.95 -32.55)">
          <circle
            cx="72.35"
            cy="90.14"
            r="47.5"
            fill={colors.PRIMARY_BLACK}
            stroke={colors.PRIMARY_BLACK}
            strokeWidth="7"
          />
          <path
            fill={colors.PRIMARY_SAND}
            stroke={colors.PRIMARY_BLACK}
            strokeDasharray="156,400"
            strokeLinecap="round"
            strokeWidth="7"
            d="M16.6 91.6a51 51 0 1096.44-33.21 51 51 0 00-96.44 33.2z"
          />
          <path
            stroke={colors.PRIMARY_BLACK}
            strokeDasharray="86,400"
            strokeLinecap="round"
            strokeWidth="7"
            d="M78.02 25.73a51 51 0 10-26.4 98.53 51 51 0 0026.4-98.53z"
          />
          <path
            fill={colors.PRIMARY_BLACK}
            fillRule="nonzero"
            d="M65.17 85.42c-4.97.32-9.26-3.27-9.6-8.03-.04-.68 0-1.34.1-1.98L38.02 56.17a1.6 1.6 0 010-2.19 1.78 1.78 0 012.28-.24L62.16 68.5a9.61 9.61 0 011.8-.3c4.97-.32 9.27 3.28 9.6 8.03.33 4.76-3.42 8.88-8.39 9.2z"
          />
          <path fill={colors.PRIMARY_BLACK} d="M59.82 14.99h10v10h-10z" />
          <rect width="34" height="16" x="47.82" y=".99" fill={colors.PRIMARY_BLACK} rx="5" />
          <path
            fill={colors.PRIMARY_BLACK}
            fillRule="nonzero"
            d="M119.28 42.41c0-2.97-2.4-5.38-5.38-5.38h-10.76a1 1 0 01-1-1V25.27a5.37 5.37 0 10-10.76 0v10.76a1 1 0 01-1 1H79.62a5.37 5.37 0 100 10.75h10.75a1 1 0 011 1v10.76a5.38 5.38 0 1010.75-.01V48.77a1 1 0 011-1h10.76c3 .02 5.4-2.38 5.4-5.36zM16.5 113.18l20.14-8.78c1.58-.7 1.57-3.16-.02-4.45L16.37 83.49c-1.58-1.29-3.56-.43-3.55 1.55l.12 25.23c0 1.99 1.98 3.6 3.56 2.91z"
          />
        </g>
      </g>
    </Icon>
  )
})``
