import { updateSettings } from './services'

export function handleCurrencyChange(currencyCode: string, withRedirectParam?: boolean): void {
  handleMetaSettingsChange('currency_code', currencyCode, withRedirectParam)
}

export function handleLanguageChange(languageCode: string): void {
  handleMetaSettingsChange('language_code', languageCode)
}

async function handleMetaSettingsChange(key: string, value: string, withRedirectParam?: boolean): Promise<void> {
  const currentWindowLocation = encodeURI(window.location.pathname)
  const formData = `redirect_url=${currentWindowLocation}&${key}=${value}&submit=1`

  const response = await updateSettings(formData)

  if (response?.status === 302) {
    let nextLocation = response?.redirect_url

    if (withRedirectParam) {
      if (response?.redirect_url.includes('?')) {
        nextLocation = `${response?.redirect_url}&${key}=${value}`
      } else {
        nextLocation = `${response?.redirect_url}?${key}=${value}`
      }
    }

    window.location.replace(nextLocation)
  }
}
