import styled, { css } from 'styled-components'

import * as colors from '../constants/colors'
import * as glyphs from '../icons/glyphs'

export const statusGlyph = {
  failed: glyphs.stroke.TimesCircle,
  pending: glyphs.stroke.Clock,
  succeeded: glyphs.stroke.CheckCircle,
  waiting: glyphs.stroke.Ellipse,
}

export const IconPastille = styled.div<{
  status: 'failed' | 'pending' | 'succeeded' | 'waiting'
  withBackground: boolean
}>`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 8px;
  width: 32px;

  ${({ status, withBackground }) => {
    if (status === 'failed') {
      if (withBackground) {
        return css`
          background-color: ${colors.SECONDARY_RED_LIGHT};
          color: ${colors.PRIMARY_RED_HOVER};
        `
      } else {
        return css`
          color: ${colors.PRIMARY_RED_HOVER};
        `
      }
    }
    if (status === 'waiting') {
      if (withBackground) {
        return css`
          background-color: ${colors.PRIMARY_SAND};
        `
      }
    }
    if (status === 'succeeded') {
      if (withBackground) {
        return css`
          background-color: ${colors.SECONDARY_GREEN};
          color: ${colors.PRIMARY_GREEN};
        `
      } else {
        return css`
          color: ${colors.PRIMARY_GREEN};
        `
      }
    }
    if (status === 'pending') {
      if (withBackground) {
        return css`
          background-color: ${colors.SECONDARY_YELLOW_LIGHT};
          color: ${colors.SECONDARY_YELLOW};
        `
      } else {
        return css`
          color: ${colors.SECONDARY_YELLOW};
        `
      }
    }
  }}
`
