import * as React from 'react'
import styled from 'styled-components'
import * as S from '../../styles'

export type SearchInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'value' | 'defaultValue'> & {
  /** Value of the search input */
  value?: string
  defaultValue?: string
  /** Translation object */
  translations?: {
    clear: string
  }
}

const SearchInputComponent = React.forwardRef<HTMLInputElement, SearchInputProps>(function SearchInputComponent(
  { className, translations, ...inputProps },
  ref,
) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  function handleClear(): void {
    /* We want to clear the input.

      We can't change `inputProps.value` since it's just a props passed.

      We could set the value on a ref of the input, but this would not dispatch a
      change event, so the UI would not be refreshed with the value set.
      We aditionaly need to dispatch an `input` event for React to dispatch a change
      event.

      BUT React messes with the value setter on the input, so we need to do a bit of
      prototype black magic to get the original value setter.

      (see https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js#46012210)
    */
    const input = inputRef.current
    const valueDescriptor = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
    const nativeValueSetter = valueDescriptor?.set
    if (input === null || nativeValueSetter === undefined) {
      return
    }

    const event = document.createEvent('Event')
    event.initEvent('input', true, true)
    nativeValueSetter.call(input, '')
    input.dispatchEvent(event)
  }

  const setRef = React.useCallback(function setRef(node: HTMLInputElement) {
    const anyInputRef = inputRef as any
    anyInputRef.current = node
    if (ref) {
      const anyRef = ref as any
      if (typeof ref === 'function') {
        ref(node)
      } else {
        anyRef.current = node
      }
    }
  }, [])

  return (
    <S.autocomplete.Wrapper className={className}>
      <S.autocomplete.SearchIcon />
      <S.autocomplete.Input {...inputProps} type="text" ref={setRef} />
      {inputProps.value && translations && (
        <S.autocomplete.Clear onClick={handleClear}>{translations.clear}</S.autocomplete.Clear>
      )}
    </S.autocomplete.Wrapper>
  )
})

/** Clearable text field. Will forward any other props to the underlying `<input>` */
export const SearchInput = styled(SearchInputComponent)<SearchInputProps>``
SearchInput.displayName = 'SearchInput'
