export function hexToRgb(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r},${g},${b},${alpha})`
  } else {
    return `rgb(${r},${g},${b})`
  }
}

export function shade(hex: string, diff: number): string {
  const base = hex.startsWith('#') ? 1 : 0

  let r = parseInt(hex.substring(base, 3), 16)
  let g = parseInt(hex.substring(base + 2, 5), 16)
  let b = parseInt(hex.substring(base + 4, 7), 16)

  r = Math.round(r / diff)
  g = Math.round(g / diff)
  b = Math.round(b / diff)

  r = r < 255 ? r : 255
  g = g < 255 ? g : 255
  b = b < 255 ? b : 255

  const rr = r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16)
  const gg = g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16)
  const bb = b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16)

  return `#${rr}${gg}${bb}`
}

export function rgbaToHexA(rgba: string | undefined, forceRemoveAlpha = false) {
  if (!rgba) {
    return undefined
  }

  return (
    '#' +
    rgba
      .replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
      .split(',') // splits them at ","
      .filter((string, index) => !forceRemoveAlpha || index !== 3)
      .map((string) => parseFloat(string)) // Converts them to numbers
      .map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
      .map((number) => number.toString(16)) // Converts numbers to hex
      .map((string) => (string.length === 1 ? '0' + string : string)) // Adds 0 when length of one number is 1
      .join('')
  ) // Puts the array to togehter to a string
}
