import styled from 'styled-components'

import * as colors from '../constants/colors'
import * as copy from './copy'

interface ProgressBarProps {
  width: number
}

export const ProgressBarContainer = styled.div`
  position: relative;
  max-width: 488px;
  width: 100%;
  background-color: ${colors.PRIMARY_SAND_100};
  border-radius: 100px;
  height: 8px;
  margin-bottom: 22px;
`

export const ProgressBarFill = styled.div<ProgressBarProps>`
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  height: 8px;
  max-width: 100%;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.theme?.colors?.primary ?? colors.PRIMARY_BLUE_500};
  border-radius: 100px;
  transition: width 500ms ease-in-out;
`

export const ProgressBarText = styled(copy.S)`
  position: absolute;
  top: 16px;
  right: 4px;
  color: ${(props) => props.theme?.colors?.primary ?? colors.PRIMARY_BLUE_500};
  font-weight: 600; // HACK: Irregular font manipulation
  white-space: nowrap;
  padding: 0;

  span {
    color: ${colors.PRIMARY_SAND_700};
    font-weight: 600; // HACK: Irregular font manipulation

    span {
      font-weight: 400; // HACK: Irregular font manipulation
    }
  }
`
