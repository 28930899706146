import styled, { css, keyframes } from 'styled-components'

import * as breakpoint from '../constants/breakpoints'

export const Wrapper = styled.div`
  position: relative;

  @media screen and (${breakpoint.TABLET}) {
    display: grid;
    gap: 20px;
    align-items: center;
    grid-template-columns: 1fr;
  }
`

export const controls = css<{ fixedControls?: boolean }>`
  position: absolute;
  z-index: 1;
  top: ${({ fixedControls }) => (fixedControls ? '260px' : '50%')};
  transform: translateY(-50%);
`

export const ControlPrev = styled.div`
  ${controls};

  left: -27px;
`

export const ControlNext = styled.div`
  ${controls};

  right: -27px;
`

const fade = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const next = keyframes`
    from {
        opacity: 0;
        transform: translate3d(150px, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`

const prev = keyframes`
    from {
        opacity: 0;
        transform: translate3d(-150px, 0, 0)
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`

export const Slide = styled.div`
  & > * {
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  &[data-animation='fade'] > * {
    animation-name: ${fade};
  }
  &[data-animation='next'] > * {
    animation-name: ${next};
  }
  &[data-animation='prev'] > * {
    animation-name: ${prev};
  }
`
