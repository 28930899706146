import styled, { css } from 'styled-components'

import * as behaviours from '../behaviours/transition'
import * as colors from '../constants/colors'
import * as effects from '../constants/neue/effects'
import * as glyphs from '../icons/glyphs'
import * as heading from './heading'

export const DrawerContent = styled.ul<{
  align?: 'left' | 'right'
  open: boolean
  step: behaviours.UseTransition['step']
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${colors.PRIMARY_GREY_000};
  border-radius: 4px;
  box-shadow: ${effects.SHADOW_5};
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 24px;
  position: absolute;
  top: 43px;
  transition-duration: 300ms;
  transition-property: opacity, visibility;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  width: max-content;
  z-index: 1;

  ${({ align }) => {
    if (align === 'right') {
      return css`
        right: 0;
      `
    } else {
      return css`
        left: 0;
      `
    }
  }}

  ${({ step }) => {
    if (step === 'before') {
      return css`
        opacity: 0;
      `
    }
    if (step === 'during') {
      return css`
        opacity: 1;
      `
    }
    if (step === 'after') {
      return css`
        opacity: 0;
      `
    }
  }}
`

export const Title = styled(heading.Card1)`
  align-items: center;
  display: flex;
  justify-content: center;

  ${glyphs.Icon} {
    margin-left: 4px;
  }
`

export const MenuWrapper = styled.menu`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`

const MenuItem = css<{ active: boolean }>`
  align-items: center;
  cursor: default;
  display: flex;
  height: 100%;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  position: relative;

  ${Title} {
    color: ${colors.PRIMARY_GREY_900};
    font-weight: 600; // HACK: Irregular font manipulation
    text-transform: uppercase;
    transition: color 0.3s ease;

    &:hover {
      cursor: pointer;
    }

    ${({ active }) => {
      if (active) {
        return css`
          color: ${colors.PRIMARY_BLUE_700} !important;
        `
      }
    }};
  }

  &:hover {
    cursor: pointer;
  }
`

export const MenuItemLi = styled.li`
  ${MenuItem}
`

export const MenuItemDiv = styled.div`
  ${MenuItem}
`
