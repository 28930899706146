import * as React from 'react'
import styled from 'styled-components'

import { lightweight, standalone, SymbolProps } from '../../spritesheetUtils'
import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'

const CARET_PATH = (
  <path
    d="M28.965144.527208c.694265-.702944 1.819892-.702944 2.514157 0 .694265.702944.694265 1.84264 0 2.545584l-14.222222 14.4c-.694266.702944-1.819892.702944-2.514158 0l-14.222222-14.4c-.694265-.702944-.694265-1.84264 0-2.545584.694265-.702944 1.819892-.702944 2.514157 0L16 13.654416 28.965144.527208z"
    fillRule="nonzero"
  />
)

const ID_DOWN = 'caret-down-stroke-glyph-icon'

export function CaretDownSymbol({ id = ID_DOWN, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 32 20">
      {CARET_PATH}
    </symbol>
  )
}

const CaretDownStandaloneContent = standalone(CaretDownSymbol, ID_DOWN)

export const CaretDown = styled(function CaretComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="caret-down">
      <CaretDownStandaloneContent />
    </Icon>
  )
})``

export const CaretDownLightweight = styled(function CaretComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="caret-down-light-weight">
      {lightweight(ID_DOWN)}
    </Icon>
  )
})``

export const CaretLeft = styled(function CaretComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="caret-left">
      <g transform="rotate(90 16 16) translate(0, 5)">
        <CaretDownStandaloneContent />
      </g>
    </Icon>
  )
})``

export const CaretLeftLightweight = styled(function CaretComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="caret-left-light-weight">
      <g transform="rotate(90 16 16) translate(0, 5)">{lightweight(ID_DOWN)}</g>
    </Icon>
  )
})``

export const CaretRight = styled(function CaretComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="caret-right">
      <g transform="rotate(-90 16 16) translate(0, 5)">
        <CaretDownStandaloneContent />
      </g>
    </Icon>
  )
})``

export const CaretRightLightweight = styled(function CaretComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="caret-right-light-weight">
      <g transform="rotate(-90 16 16), translate(0, 8)">{lightweight(ID_DOWN)}</g>
    </Icon>
  )
})``

export const CaretUp = styled(function CaretComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} viewBox="0 4 32 24" name="caret-up">
      <g transform="rotate(180 16 16), translate(0, 7)">
        <CaretDownStandaloneContent />
      </g>
    </Icon>
  )
})``

export const CaretUpLightweight = styled(function CaretComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} viewBox="0 4 32 24" name="caret-up-light-weight">
      <g transform="rotate(180 16 16), translate(0, 7)">{lightweight(ID_DOWN)}</g>
    </Icon>
  )
})``
