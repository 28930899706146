import * as React from 'react'
import styled from 'styled-components'

import { FreeSizedSvg } from '../FreeSizedSvg'

export type Collection = 'boutique' | 'twoToned' | 'training'

export type IllustrationProps = React.SVGAttributes<SVGElement> & {
  children: React.ReactNode
  className?: string
  height?: number | string
  large?: boolean
  name: string
  onClick?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
  size: number
  title?: string
  viewBox?: string
}

export type SpecificIllustrationProps = Omit<IllustrationProps, 'children' | 'name'> & {
  children?: React.ReactNode
}

/** Base SVG illustration component */
function IllustrationComponent({
  children,
  className,
  height,
  large = false,
  name,
  onClick,
  size = 128,
  title,
  viewBox = `0 0 ${size} ${size}`,
  width,
}: IllustrationProps): React.ReactElement<IllustrationProps> {
  return (
    <FreeSizedSvg
      aria-labelledby={title && 'title'}
      className={className}
      clipRule="evenodd"
      fillRule="evenodd"
      height={height || size}
      id={`illustration-${name}`}
      onClick={onClick}
      preserveAspectRatio="xMidYMid meet"
      viewBox={viewBox}
      width={large ? undefined : width || size}
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title id="title">{name}</title>}
      {children}
    </FreeSizedSvg>
  )
}

export const Illustration = styled(IllustrationComponent)`
  && {
    overflow: visible;
  }
`
Illustration.displayName = 'illustrations.Illustration'
