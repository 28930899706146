import * as React from 'react'

// Problem: 100vh is taller than the visible part of the document in some mobile browsers
// website interface was partially hidden behind the browser buttons bar or below the "fold" of some mobile browsers.
// Solution : we have to calcul this height with innerHeight / clientHeight
// So, we calculate this height only when it's needed: on mobile
export function use100vh(deps?: any[]): string | null {
  const [height, setHeight] = React.useState<number | null>(measureHeight)
  const isMobile =
    typeof window === 'undefined' ? false : window.matchMedia && window.matchMedia('(max-width: 480px)').matches
  const setMeasuredHeightThrottled = useThrottle(setMeasuredHeight, isMobile, 100)

  React.useEffect(() => {
    if (isMobile && setMeasuredHeightThrottled) {
      window.addEventListener('resize', setMeasuredHeightThrottled)
      return () => window.removeEventListener('resize', setMeasuredHeightThrottled)
    }
  }, deps)

  function setMeasuredHeight() {
    const measuredHeight = measureHeight()
    setHeight(measuredHeight)
  }

  return isMobile ? `${height}px` : '100vh'
}

export function measureHeight(): number | null {
  if (typeof window === 'undefined') {
    return null
  }
  return document.documentElement?.clientHeight || window.innerHeight
}

function useThrottle(actionToThrottle: () => void, isMobile: boolean, timeout: number) {
  const handle = React.useRef<number>()
  let previousCallTimestamp = 0

  React.useEffect(() => {
    return () => {
      if (handle.current) {
        clearTimeout(handle.current)
      }
    }
  }, [actionToThrottle, timeout])

  return () => {
    if (!isMobile) return

    const firstCall = handle.current === undefined
    if (!firstCall) {
      clearTimeout(handle.current)
    }

    const now = Date.now()
    if (previousCallTimestamp === 0) {
      previousCallTimestamp = now
    }

    const remaining = timeout - (now - previousCallTimestamp)

    if (remaining <= 0) {
      clearTimeout(handle.current)
      call()
    } else if (!handle.current) {
      handle.current = setTimeout(() => {
        call()
        handle.current = undefined
      }, remaining) as any
    }
  }

  function call() {
    previousCallTimestamp = Date.now()
    actionToThrottle()
  }
}
