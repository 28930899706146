import * as React from 'react'

import * as S from './style'

type HeaderProps = {
  centerZone: React.ReactNode
  enableSubnav?: boolean
  leftZone: React.ReactNode
  rightZone: React.ReactNode
  theme: Theme
  smallBorder?: boolean
}

type Theme = {
  colors: {
    primary: string
  }
}

export function HeaderWrapper({
  enableSubnav,
  centerZone,
  leftZone,
  rightZone,
  theme,
  smallBorder,
}: HeaderProps): React.ReactElement<HeaderProps> {
  return (
    <S.HeaderWrapper theme={theme} enableSubnav={enableSubnav} smallBorder={smallBorder}>
      <S.LeftZone>{leftZone}</S.LeftZone>
      <S.CenterZone>{centerZone}</S.CenterZone>
      <S.RightZone>{rightZone}</S.RightZone>
    </S.HeaderWrapper>
  )
}
