import styled, { css } from 'styled-components'

import * as button from '../button'
import * as copy from '../copy'
import * as link from '../link'
import * as tag from '../tag'

import * as colors from '../../constants/colors'
import * as effects from '../../constants/neue/effects'
import * as fonts from '../../constants/fonts'
import * as zindex from '../../constants/zindex'
import * as glyphs from '../../icons/glyphs'

export const Picture = styled.div<{ url: string }>`
  background-color: ${colors.GREY_SHADE_4};
  background-image: ${({ url }) => (url ? `url(${url})` : undefined)};
  background-size: cover;
  background-position: center;
  height: 122px;
  width: 100%;
`

export const Category = styled.span`
  background-color: ${(props) => colors.primary(props)};
  border-radius: 2px;
  color: ${colors.PRIMARY_WHITE};
  font-size: 13px;
  font-style: ${fonts.PRIMARY_FONT};
  font-weight: 500;
  left: -5px;
  line-height: 18px;
  padding: 3px 15px;
  position: relative;
  top: 10px;
`

export const Brand = styled.h3`
  color: ${colors.GREY_SHADE_2};
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  margin: 0 0 6px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
`

export const Description = styled.p`
  color: ${colors.PRIMARY_BLACK};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  height: calc(3 * 22px); /* 3 lines max */
`

export const Body = styled.div`
  padding: 12px;
  display: block;

  &:hover {
    opacity: 0.9;
  }
`

export const Container = styled.a`
  cursor: pointer;
  text-decoration: none;
`

export const Wrapper = styled.div`
  background-color: ${colors.PRIMARY_WHITE};
  border: 1px solid ${colors.GREY_SHADE_5};
  width: 250px;
  flex: 1 1 auto;
  box-shadow: none;
  transition: all 150ms ease-out;

  &:hover {
    transition: all 200ms ease-in-out;
    box-shadow: ${effects.SHADOW_4};
  }
`

export const OperationWrapper = styled.div`
  background-color: ${colors.PRIMARY_WHITE};
  border-radius: 4px;
  border: 1px solid ${colors.GREY_SHADE_5};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

export const OperationLink = styled.a`
  display: flex;
`

export const OperationHeader = styled.div`
  box-sizing: border-box;
  padding-bottom: 0px;
  position: relative;

  ${tag.StatusPill} {
    z-index: ${zindex.ABOVE};
    position: absolute;
    top: 16px;
    left: 16px;
  }
`

export const LinkWrapper = styled(copy.S)`
  display: flex;
  justify-content: center;
  width: 100%;

  ${link.Base} {
    display: flex;
  }
`

export const OperationContent = styled.div<{ compact?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ compact }) => {
    if (compact) {
      return css`
        padding: 21px 16px 16px;
      `
    }

    return css`
      padding: 21px 22px 26px 22px;
    `
  }}

  ${button.Base} {
    display: flex;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 16px;
  }

  ${button.RoundButton} {
    width: 100%;
    font-weight: 700;

    &:hover {
      color: ${colors.PRIMARY_BLUE_700};

      ${glyphs.stroke.ArrowRight} {
        color: ${colors.PRIMARY_BLUE_700};
        right: -8px;
      }
    }

    ${glyphs.stroke.ArrowRight} {
      right: 0px;
      color: ${colors.GREY_SHADE_7};
      margin-left: 12px;
      position: relative;
      top: 2px;
      transition: 0.3s cubic-bezier(0.83, 0, 0.17, 1);
      transition-property: right color;
    }
  }
`

export const CompactOperationDescription = styled(copy.S)`
  margin: 0 0 18px 0;

  && {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const OperationDescription = styled.span`
  margin: 16px 0;
`

export const KeyList = styled.div`
  margin-bottom: 40px;
  flex-grow: 1;

  ${copy.M} {
    font-weight: bold; // HACK: Irregular font manipulation
    margin-bottom: 8px;
  }

  ul {
    margin: 0;

    li {
      position: relative;
      margin-bottom: 8px;

      &::before {
        content: url("data:image/svg+xml; utf8, <svg fill='%2318A5D6' clip-rule='evenodd' fill-rule='evenodd' height='15' viewBox='0 0 32 32' width='15' xmlns='http://www.w3.org/2000/svg'><g><path d='M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm0-3.556c6.873 0 12.444-5.571 12.444-12.444 0-6.873-5.571-12.444-12.444-12.444C9.127 3.556 3.556 9.127 3.556 16c0 6.873 5.571 12.444 12.444 12.444zm6.328-18.442a1.778 1.778 0 112.514 2.514l-10.43 10.43a1.778 1.778 0 01-2.514 0l-4.74-4.74a1.778 1.778 0 012.514-2.515l3.484 3.484 9.172-9.173z' fill-rule='nonzero'></path></g></svg>");
        position: absolute;
        left: -23px;
        top: 3px;
      }
    }
  }
`
