import styled, {
  css,
  AnyStyledComponent,
  StyledComponent,
  StyledComponentInnerComponent,
  StyledComponentInnerOtherProps,
  StyledComponentInnerAttrs,
} from 'styled-components'

export function fromBottom<COMPONENT extends AnyStyledComponent>(
  component: COMPONENT,
): StyledComponent<
  StyledComponentInnerComponent<COMPONENT>,
  any,
  StyledComponentInnerOtherProps<COMPONENT> & {
    step: 'before' | 'during' | 'after'
  },
  StyledComponentInnerAttrs<COMPONENT>
> {
  return styled(component)<{ step: 'before' | 'during' | 'after' }>`
    transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    ${({ step }) => {
      switch (step) {
        case 'before': {
          return css`
            transform: translate(0, 100vh);
          `
        }
        case 'after': {
          return css`
            transform: translate(0, 100vh);
          `
        }
      }
    }}
  `
}

export function fromRight<COMPONENT extends AnyStyledComponent>(
  component: COMPONENT,
): StyledComponent<
  StyledComponentInnerComponent<COMPONENT>,
  any,
  StyledComponentInnerOtherProps<COMPONENT> & {
    step: 'before' | 'during' | 'after'
  },
  StyledComponentInnerAttrs<COMPONENT>
> {
  return styled(component)<{ step: 'before' | 'during' | 'after' }>`
    transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);

    ${({ step }) => {
      switch (step) {
        case 'before': {
          return css`
            transform: translate(100vw, 0);
          `
        }
        case 'after': {
          return css`
            transform: translate(100vw, 0);
          `
        }
      }
    }}
  `
}

export function fromTop<COMPONENT extends AnyStyledComponent>(
  component: COMPONENT,
): StyledComponent<
  StyledComponentInnerComponent<COMPONENT>,
  any,
  StyledComponentInnerOtherProps<COMPONENT> & {
    step: 'before' | 'during' | 'after'
  },
  StyledComponentInnerAttrs<COMPONENT>
> {
  return styled(component)<{ step: 'before' | 'during' | 'after' }>`
    transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    animation: fadein 1s;
    @keyframes fadein {
      from {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    ${({ step }) => {
      switch (step) {
        case 'before': {
          return css`
            transform: translate(0, -100vh);
          `
        }
        case 'after': {
          return css`
            transform: translate(-0, -100vh);
          `
        }
      }
    }}
  `
}

export function fromLeft<COMPONENT extends AnyStyledComponent>(
  component: COMPONENT,
): StyledComponent<
  StyledComponentInnerComponent<COMPONENT>,
  any,
  StyledComponentInnerOtherProps<COMPONENT> & {
    step: 'before' | 'during' | 'after'
  },
  StyledComponentInnerAttrs<COMPONENT>
> {
  return styled(component)<{ step: 'before' | 'during' | 'after' }>`
    transition: transform 300ms cubic-bezier(0.83, 0, 0.17, 1);

    ${({ step }) => {
      if (step === 'before') {
        return css`
          transform: translate3d(100%, 0, 0);
        `
      }
      if (step === 'during') {
        return css`
          transform: translate3d(0, 0, 0);
        `
      }
      if (step === 'after') {
        return css`
          transform: translate3d(100%, 0, 0);
        `
      }
    }}
  `
}

export function fromLeftFadeIn<COMPONENT extends AnyStyledComponent>(
  component: COMPONENT,
): StyledComponent<
  StyledComponentInnerComponent<COMPONENT>,
  any,
  StyledComponentInnerOtherProps<COMPONENT> & {
    step: 'before' | 'during' | 'after'
  },
  StyledComponentInnerAttrs<COMPONENT>
> {
  return styled(component)<{ step: 'before' | 'during' | 'after' }>`
    transition: opacity 300ms cubic-bezier(0.83, 0, 0.17, 1), transform 300ms cubic-bezier(0.83, 0, 0.17, 1);

    ${({ step }) => {
      if (step === 'before') {
        return css`
          opacity: 0;
          transform: translate3d(100%, 0, 0);
        `
      }
      if (step === 'during') {
        return css`
          opacity: 1;
          transform: translate3d(0, 0, 0);
        `
      }
      if (step === 'after') {
        return css`
          opacity: 0;
          transform: translate3d(100%, 0, 0);
        `
      }
    }}
  `
}
