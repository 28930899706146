import * as React from 'react'
import styled from 'styled-components'

import * as colors from '../../../constants/colors'

import * as backgrounds from '../backgrounds'
import { Illustration, IllustrationProps as BaseIllustrationProps } from '../Illustration'

export type IllustrationProps = Omit<BaseIllustrationProps, 'children' | 'name'> & {
  inverted?: boolean
  noBackground?: boolean
  tint?: string
  full?: boolean
}

export function iconTint(inverted = false): string {
  return inverted ? colors.PRIMARY_GREEN : colors.BRAND_ACCENT_PURPLE
}

type RegularIllustrationProps = IllustrationProps & { children: React.ReactNode; name: string }

export function RegularIllustration({
  children,
  inverted,
  name,
  noBackground,
  size,
  full = false,
}: RegularIllustrationProps): React.ReactElement<RegularIllustrationProps> {
  return (
    <Illustration name={name} size={size}>
      {!noBackground && <backgrounds.CircularBackground diameter={size} full={full} inverted={inverted} />}
      {children}
    </Illustration>
  )
}

type IrregularIllustrationProps = IllustrationProps & {
  backgroundOffset: { x?: number | string; y?: number | string }
  children: React.ReactNode
  name: string
}

export function IrregularIllustration({
  backgroundOffset,
  children,
  name,
  noBackground,
  size,
  viewBox,
}: IrregularIllustrationProps): React.ReactElement<IrregularIllustrationProps> {
  return (
    <Illustration large name={name} size={size} viewBox={viewBox}>
      {!noBackground && <backgrounds.PartialDisk viewBox={viewBox} x={backgroundOffset.x} y={backgroundOffset.y} />}
      {children}
    </Illustration>
  )
}

// HACK: This is a workaround since chromium doesn't allow applying CSS transforms to nested svg elements
export const Scaler = styled.g``
