import * as React from 'react'

import { useClientSide } from './useClientSide'
import { usePoll } from './usePoll'

export function useDramaticallyInefficientLocation() {
  const isClientSide = useClientSide()
  const initialLocation = isClientSide ? window.location.href : 'https://www.ulule.com'
  const [location, setLocation] = React.useState(new URL(initialLocation))

  const poller = usePoll(async () => {
    if (isClientSide && window.location.href !== location.href) {
      setLocation(new URL(window.location.href))
    }
  })

  React.useEffect(() => {
    poller.start()
    return () => poller.stop()
  }, [])

  return location
}
