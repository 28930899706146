import * as entities from '@owl-nest/entities'
import * as algolia from '@boutique/algolia'

import * as queryParser from './queryParser.ts'

export type Sort = algolia.product.SortIndex

export type Query<FILTERS extends Record<string, any>> = {
  facetFilters?: Partial<FILTERS>
  sort?: Sort
  page?: number
  hitsPerPage?: number
  showHidden?: boolean
  priceRange?: { start?: number; end?: number }
  filters?: (string | string[])[]
}

type FilterFacet = { data: Record<string, number>; total: number }
type PriceFacet = { data: Record<string, number>; total: number; min: number; max: number }

export type Result<FILTERS extends Record<string, any>> = {
  nbHits: number
  nbPages: number
  facets: Partial<Record<keyof FILTERS, FilterFacet>> & { price?: PriceFacet }
  hits: entities.product.SearchHitProduct[]
}

export function toUrlSearchParams<FILTERS extends Record<string, any>>(query: Query<FILTERS>): URLSearchParams {
  const params = new URLSearchParams()

  if (query.sort) {
    params.set('sort', query.sort)
  }
  if (query.page && query.page > 1) {
    params.set('page', String(query.page))
  }
  if (query.showHidden !== undefined) {
    params.set('hidden', query.showHidden ? 'true' : 'false')
  }
  if (query.priceRange !== undefined && (query.priceRange.start !== undefined || query.priceRange.end !== undefined)) {
    params.set('price', `${query.priceRange.start ?? ''}:${query.priceRange.end ?? ''}`)
  }

  return params
}

export function fromParsedUrlQuery<FILTERS extends Record<string, any>>(
  query: Record<string, string | string[] | undefined>,
): Query<FILTERS> {
  return {
    sort: queryParser.string(query.sort) as algolia.product.SortIndex,
    // page is displayed 1 based, but is internally 0 based, so we offset it by one
    page: (queryParser.number(query.page) ?? 1) - 1,
    showHidden: queryParser.boolean(query.hidden),
    priceRange: queryParser.priceRange(query.price),
  }
}

export function setPriceRange<FILTERS extends Record<string, any>>(
  query: Query<FILTERS>,
  { start, end }: Query<FILTERS>['priceRange'] = {},
): Query<FILTERS> {
  return { ...query, priceRange: { start, end }, page: undefined }
}

export function setSort<FILTERS extends Record<string, any>>(
  query: Query<FILTERS>,
  sort?: algolia.product.SortIndex,
): Query<FILTERS> {
  return { ...query, sort, page: undefined }
}

export function setPage<FILTERS extends Record<string, any>>(query: Query<FILTERS>, page?: number): Query<FILTERS> {
  return { ...query, page }
}

type Options<T> = {
  [K in keyof T]: { value: T[K]; label: string }
}

export const SORT_OPTIONS: Options<algolia.product.Sorts> = [
  {
    label: 'Popularité',
    value: 'popular',
  },
  {
    label: 'Nouveauté',
    value: 'mostRecent',
  },
  {
    label: 'Prix (croissant)',
    value: 'ascendingPrice',
  },
  {
    label: 'Prix (décroissant)',
    value: 'descendingPrice',
  },
  {
    label: 'Nom (A à Z)',
    value: 'AtoZ',
  },
  {
    label: 'Nom (Z à A)',
    value: 'ZtoA',
  },
]
