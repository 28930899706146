import * as React from 'react'
import styled from 'styled-components'

import * as behaviours from '../behaviours/transition'
import * as glyphs from '../icons/glyphs'
import * as S from '../styles'

export type MenuProps = {
  align?: 'left' | 'right'
  children: React.ReactNode
  className?: string
  dropdownOnClick?: boolean
  elementType?: 'li' | 'div'
  /** Enable overriding transition mount status to allow mounting on non-client side scenarios, improving SEO. */
  forceMount?: boolean
  open?: boolean
  setAsOpen?: (open: boolean) => void
  title: string | React.ReactNode
  withCaret?: boolean
}

function MenuComponent({ open, setAsOpen, ...props }: MenuProps): React.ReactElement {
  if (open !== undefined && setAsOpen !== undefined) {
    return <ControlledMenu {...props} open={open} setAsOpen={setAsOpen} />
  } else {
    return <UncontrolledMenu {...props} />
  }
}

function ControlledMenu({
  align = 'left',
  children,
  className,
  dropdownOnClick,
  elementType = 'li',
  forceMount = false,
  open,
  setAsOpen,
  title,
  withCaret,
}: MenuProps & {
  open: boolean
  setAsOpen: (open: boolean) => void
}): React.ReactElement<MenuProps> {
  const MenuItem = (elementType === 'div' ? S.menu.MenuItemDiv : S.menu.MenuItemLi) as React.ElementType

  const menuRef = React.useRef<HTMLElement>(null)

  const handleClickOutside = React.useCallback(
    (e: MouseEvent): void => {
      if (!menuRef.current?.contains(e.target as Node)) {
        setAsOpen(false)
      }
    },
    [setAsOpen],
  )

  React.useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open, handleClickOutside])

  return (
    <S.menu.MenuWrapper ref={menuRef}>
      {dropdownOnClick ? (
        <MenuItem active={open} className={className} onClick={() => setAsOpen(!open)}>
          <S.menu.Title>
            {title}
            {withCaret && <glyphs.stroke.CaretDown size={9} />}
          </S.menu.Title>
          <Drawer open={open} align={align}>
            {children}
          </Drawer>
        </MenuItem>
      ) : (
        <MenuItem
          active={open}
          className={className}
          onMouseLeave={() => setAsOpen(false)}
          onMouseOver={() => setAsOpen(true)}
        >
          <S.menu.Title>
            {title}
            {withCaret && <glyphs.stroke.CaretDown size={9} />}
          </S.menu.Title>
          <Drawer align={align} forceMount={forceMount} open={open}>
            {children}
          </Drawer>
        </MenuItem>
      )}
    </S.menu.MenuWrapper>
  )
}

function UncontrolledMenu(props: MenuProps): React.ReactElement {
  const [open, setAsOpen] = React.useState(false)

  return <ControlledMenu {...props} open={open} setAsOpen={setAsOpen} />
}

function Drawer({
  align,
  children,
  forceMount = false,
  open,
}: {
  align: 'left' | 'right'
  forceMount?: boolean
  open: boolean
  children: React.ReactNode
}): React.ReactElement | null {
  const $drawerRef = React.useRef<HTMLUListElement>(null)
  const { onTransitionEnd, step, unmounted } = behaviours.useTransition(open)

  if (!forceMount && unmounted) {
    return null
  }

  return (
    <S.menu.DrawerContent onTransitionEnd={onTransitionEnd} open={open} ref={$drawerRef} step={step} align={align}>
      {children}
    </S.menu.DrawerContent>
  )
}

export const Menu = styled(MenuComponent)``
