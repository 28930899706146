import * as React from 'react'

import { type Lang } from '@owl-nest/api-client/latest'

// TODO: Use genericity to allow passing specific translations type?
type Configuration = {
  defaultLocale: Lang
  locale: Lang
  translations: Record<string, unknown>
}

export const configurationContext = React.createContext<Configuration | undefined>(undefined)

export function useConfigurationContext(): Configuration {
  const configuration = React.useContext(configurationContext)

  if (configuration === undefined) {
    throw Error(`Can't useConfigurationContext outside its Provider`)
  }

  return configuration
}
