import * as React from 'react'
import styled, { css } from 'styled-components'
import Link from 'next/link'

import { use100vh, usePrevious, useNextRouter } from '@owl-nest/hooks'
import * as plume from '@ulule/owl-kit-components/next'
import * as algolia from '@boutique/algolia'
import * as url from '@boutique/url'

import { useConfigurationContext } from '../../context/configuration/configurationContext'
import { useUserDrawerContext } from '../../context/userDrawer/userDrawerContext'

import * as S from '../style'

import { type SessionData } from '../../types'

const SEARCH_INPUT_IDENTIFIER = 'q'

type SearchProductDrawerProps = {
  action: string
  className?: string
}

function SearchProductDrawerComponent({ action, className }: SearchProductDrawerProps): React.ReactElement {
  const router = useNextRouter()
  const $drawerRef = React.useRef<HTMLDivElement>(null)
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')
  const heightFullScreen = use100vh()

  const { results, isLoading } = plume.behaviours.useAutocompleteList(
    searchValue,
    () => {
      // Note: using setIsExpanded(false) here doesn't work
      // noop
    },
    getAutocompleteResults,
    100,
  )
  const searchInputRef = React.useRef<HTMLInputElement>(null)
  const wasFirstLoaded = React.useRef(false)
  const wasPreviouslyLoading = usePrevious(isLoading)
  if (wasPreviouslyLoading && !isLoading) {
    wasFirstLoaded.current = true
  }
  if (searchValue === '') {
    wasFirstLoaded.current = false
  }

  const { translations } = useConfigurationContext()
  // TODO: Replace with proper context
  const { sessionData } = useUserDrawerContext()
  plume.hooks.useOnClickOutside([$drawerRef], () => setIsExpanded(false))

  return (
    <>
      <SearchWrapper
        className={className}
        dualColorBackground={false}
        expanded={isExpanded}
        heightFullScreen={heightFullScreen}
        locale={sessionData.currentLocale}
        ref={$drawerRef}
      >
        <SearchForm action={action} expanded={isExpanded} method="GET">
          <SearchLabel
            htmlFor={SEARCH_INPUT_IDENTIFIER}
            onClick={() => {
              setIsExpanded(true)
              searchInputRef.current?.focus()
            }}
          >
            <plume.glyphs.stroke.Search size={18} />
          </SearchLabel>
          <SearchInputWrapper>
            {/* @ts-expect-error `translations` children are unknown, we should type properly type them. */}
            <HiddenSearchLabel>{translations.searchDrawer.searchPlaceholder}</HiddenSearchLabel>

            <SearchInput
              autoComplete="off"
              id="product-search-form"
              name={SEARCH_INPUT_IDENTIFIER}
              onChange={(event) => {
                setSearchValue(event.target.value)
                setIsExpanded(true)
              }}
              onFocus={(event) => {
                event.preventDefault()
                setIsExpanded(true)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && results.length > 0) {
                  router.push(url.products({ query: { fullTextSearch: searchValue } }).path, undefined)
                  setIsExpanded(false)
                }
                if (e.key === 'Escape' && searchValue !== '') {
                  setSearchValue('')
                  setIsExpanded(false)
                }
              }}
              // @ts-expect-error `translations` children are unknown, we should type properly type them.
              placeholder={translations.searchDrawer.searchPlaceholder}
              ref={searchInputRef}
              type="text"
              value={searchValue}
            />
          </SearchInputWrapper>
          <CloseButton
            onClick={() => {
              // TODO: Empty user entry, too
              setIsExpanded(false)
            }}
          >
            <plume.glyphs.stroke.Close size={18} />
          </CloseButton>
        </SearchForm>
        <SearchDrawerBody visible={isExpanded}>
          {wasFirstLoaded.current && (
            <NavigationSection>
              {results.map((result) => {
                return (
                  <Link
                    key={result.value.id}
                    href={url.product({ handle: result.value.handle, id: result.value.id, title: '' }).path}
                    onClick={() => {
                      setIsExpanded(false)
                    }}
                  >
                    <plume.Link as="span">
                      <plume.styles.copy.S>{result.label}</plume.styles.copy.S>
                    </plume.Link>
                  </Link>
                )
              })}
              {searchValue !== '' && results.length === 0 && <NoResults>Pas de résultats</NoResults>}
              {searchValue !== '' && results.length > 0 && (
                <>
                  <hr />
                  <Link
                    href={url.products({ query: { fullTextSearch: searchValue } }).path}
                    onClick={() => {
                      setIsExpanded(false)
                    }}
                  >
                    <MoreLink as="span">
                      <plume.styles.copy.S>{`Plus de résultats pour "${searchValue}"`}</plume.styles.copy.S>
                      <plume.glyphs.stroke.ArrowRight size={16} />
                    </MoreLink>
                  </Link>
                </>
              )}
            </NavigationSection>
          )}
        </SearchDrawerBody>
      </SearchWrapper>
      <S.Overlay visible={isExpanded}>{isExpanded && <S.LockScroll />}</S.Overlay>
    </>
  )
}

async function getAutocompleteResults(query: string): Promise<
  {
    label: string
    value: {
      handle: string
      id: string
    }
  }[]
> {
  const data = await algolia.clients.liteClient
    .initIndex('popular')
    .search<{ name: string; id: string; handle: string }>(query, {
      attributesToHighlight: [],
      attributesToRetrieve: ['name', 'id', 'handle'],
      distinct: true,
      filters: 'availableForSale:true AND NOT tags:hidden',
      hitsPerPage: 6,
    })

  const results = data?.hits.map((hit) => ({
    label: hit.name ?? '',
    value: { handle: hit.handle, id: hit.id },
  }))

  return results ?? []
}

export const SearchProductDrawer = styled(SearchProductDrawerComponent)`
  z-index: 1;
`

export const NoResults = styled(plume.styles.copy.S)`
  margin: 10px 20px;
`

const EASE_IN_OUT_EXPO = 'cubic-bezier(0.79, 0.14, 0.15, 0.86)'
const MAX_DESKTOP_WIDTH = '322px'

const searchInputPlaceholderStyle = css`
  font-family: ${plume.FONTS.PRIMARY_FONT};
  font-size: 13px; // HACK: Irregular font manipulation
  font-weight: 600; // HACK: Irregular font manipulation
  text-transform: uppercase;
`

const CloseButton = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  height: 36px;
  width: 36px;
`

// This hidden span holds the same text as the input placeholder,
// allowing us to size the input according to its placeholder content.
const HiddenSearchLabel = styled.span`
  display: none;

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    display: block;
    height: 0;
    visibility: hidden;

    ${searchInputPlaceholderStyle}
  }
`

const NavigationSection = styled.nav`
  position: relative;
  transition: visibility 150ms ${EASE_IN_OUT_EXPO} 200ms;
  margin: 0 20px;

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    margin: 0px;
  }

  ${plume.Link} {
    margin: 10px 20px;
    display: flex;

    &:first-child {
      margin-top: 0px;
    }

    ${plume.styles.copy.S} {
      font-weight: 600; /* HACK: Irregular font manipulation. We'll be adding copy.S.semiBold, so this is just by anticipation. */
    }

    @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
      &:hover {
        ${plume.styles.copy.S} {
          color: #2e8d87; /* HACK: Replace with COLORS.SECONDARY_MINT_700 */
        }
      }
    }
  }

  hr {
    border-bottom: 0px;
    border-top: 1px solid ${plume.COLORS.GREY_SHADE_5};
    margin-bottom: 12px;
  }
`

const MoreLink = styled(plume.Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SearchDrawerBody = styled.section<{ visible: boolean }>`
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  height: 100%;

  @media screen and ${S.LARGE_BREAKPOINT} {
    height: calc(100% - 136px);
  }

  ${({ visible }) => {
    if (!visible) {
      return css`
        visibility: hidden;
      `
    }
  }}
`

const SearchInput = styled.input`
  border: none;
  color: ${plume.COLORS.PRIMARY_BLACK};
  display: inline;
  height: 100%;
  padding: 0 0 3px;
  width: 100%;

  ${searchInputPlaceholderStyle}
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${plume.COLORS.PRIMARY_BLACK};
    display: block;
    opacity: 1;
  }
`

const SearchForm = styled.form<{ expanded: boolean }>`
  align-items: center;
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  display: flex;
  height: ${plume.SIZES.HEADER_SUB_NAV_HEIGHT};
  padding: 0 0 4px 30px;
  position: relative;

  ${({ expanded }) => {
    if (expanded) {
      return css`
        box-sizing: border-box;
        padding-left: 30px;
        width: 100%;
        margin-bottom: 16px;

        @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
          margin-bottom: 0px;
        }
      `
    }
  }};

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    &:hover:not(:focus-within) {
      ${SearchInput} {
        cursor: pointer;

        ::placeholder {
          font-weight: 600;
          color: #2e8d87; /* HACK: Replace with COLORS.SECONDARY_MINT_700 */
        }
      }

      ${plume.glyphs.Icon} {
        cursor: pointer;
        color: #2e8d87; /* HACK: Replace with COLORS.SECONDARY_MINT_700 */
      }
    }
  }

  ${CloseButton} {
    position: absolute;
    right: 7px;
    transition-delay: 100ms;
    transition-duration: 120ms;
    transition-property: opacity, visibility;
    transition-timing-function: ${EASE_IN_OUT_EXPO};

    @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
      display: none;
    }

    ${({ expanded }) => {
      if (expanded) {
        return css`
          opacity: 1;
          visibility: visible;
        `
      } else {
        return css`
          opacity: 0;
          transition-delay: 0ms;
          visibility: hidden;
        `
      }
    }}
  }
`

const SearchWrapper = styled.nav<{
  dualColorBackground: boolean
  expanded: boolean
  heightFullScreen: string | null
  locale: SessionData['currentLocale']
}>`
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  box-sizing: border-box;
  max-height: 43px;
  overflow: hidden;
  position: absolute;
  right: 64px;
  top: 3px;
  transition-delay: 0ms, 0ms, 160ms, 100ms, 160ms;
  transition-duration: 150ms, 150ms, 120ms, 250ms, 120ms;
  transition-property: height, max-height, max-width, right, width;
  transition-timing-function: ${EASE_IN_OUT_EXPO};
  width: 58px;

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    top: 0;
  }

  @media screen and ${S.LARGE_BREAKPOINT} {
    left: initial;
    max-width: 245px;
    right: 56px;
    width: 100%;
  }

  ${({ dualColorBackground = true, expanded = false, heightFullScreen }) => {
    if (expanded) {
      return css`
        left: 0;
        right: 0;
        transition-delay: 200ms, 200ms, 0ms, 0ms, 0ms;
        transition-duration: 200ms, 200ms, 200ms, 0ms, 200ms;
        width: 100%;

        &::before {
          background-color: ${plume.COLORS.PRIMARY_WHITE};
          box-sizing: border-box;
          content: '';
          display: block;
          height: ${dualColorBackground ? '150px' : '62px'};
          left: 0;
          padding: 0 30px;
          position: absolute;
          top: 0;
          width: 100%;
        }

        ${SearchForm} {
          border-bottom: 2px solid #d9f8f6; /* HACK: Replace with COLORS.SECONDARY_MINT_300 */

          @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
            border-bottom: 0px;
          }
        }

        ${SearchInput}::placeholder {
          color: ${plume.COLORS.PRIMARY_BLACK};
        }

        @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
          box-shadow: ${plume.EFFECTS.SHADOW_3};
          left: initial;
          max-height: 700px;
          right: 56px;
          width: ${MAX_DESKTOP_WIDTH};

          ${SearchInput}::placeholder {
            color: ${plume.COLORS.GREY_SHADE_2};
          }
        }

        @media not screen and ${S.INTERMEDIATE_BREAKPOINT} {
          height: ${heightFullScreen};
          max-height: 1200px;
        }

        @media screen and ${S.LARGE_BREAKPOINT} {
          max-width: ${MAX_DESKTOP_WIDTH};
        }
      `
    }
  }}
`

const SearchLabel = styled.label`
  color: ${plume.COLORS.PRIMARY_BLACK};
  cursor: pointer;

  ${plume.glyphs.Icon} {
    margin-right: 10px;
    vertical-align: text-top;
  }

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    margin-top: 4px;
  }
`

const SearchInputWrapper = styled.div`
  width: 100%;

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    padding-right: 12px;
  }
`
