import * as React from 'react'

export type SymbolProps = React.SVGAttributes<SVGSymbolElement>

export function standalone(Symbol: React.ComponentType<SymbolProps>, id: string): React.ComponentType<SymbolProps> {
  const uniqueId = `id-${id}`

  const StandaloneSvgContent: React.ComponentType<SymbolProps> = (props) => {
    return (
      <>
        <defs>
          <Symbol {...props} id={uniqueId} />
        </defs>
        <use href={`#${uniqueId}`} />
      </>
    )
  }

  StandaloneSvgContent.displayName = id

  return StandaloneSvgContent
}

export function lightweight(id: string): React.ReactElement<React.SVGProps<SVGUseElement>> {
  return <use href={`#${id}`} />
}
