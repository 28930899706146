import styled, { css } from 'styled-components'

import * as breakpoints from '../../constants/breakpoints'
import * as colors from '../../constants/colors'
import * as fonts from '../../constants/fonts'
import * as glyphs from '../../icons/glyphs'
import * as copy from '../../styles/copy'
import * as heading from '../../styles/heading'
import * as button from '../button'
import * as image from '../image'

import { VideoLoader } from '../../components/VideoLoader'

const _baseStyle = css`
  background-color: ${colors.PRIMARY_GREY_000};
  display: flex;
  position: relative;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.PRIMARY_SAND_200};
  border-radius: 6px;
  min-width: 230px;
  flex: 1 1 auto;
  font-family: ${fonts.PRIMARY_FONT};
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
`

/* Horizontal ProjectCard */
export const DiscreetText = styled.span<{ light?: boolean }>`
  color: ${colors.GREY_SHADE_2};
  font-size: 12px;
  font-weight: ${({ light = false }) => (light ? 300 : 400)};
`

export const ProjectCardTitle = styled.h2<{ theme?: { color: { primary: string } } }>`
  color: ${(props) => colors.primary(props)};
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const HorizontalCardWrapper = styled.a`
  color: ${colors.PRIMARY_BLACK};
  cursor: pointer;
  display: flex;
  font-family: ${fonts.PRIMARY_FONT};
  line-height: 1.23;
  min-width: 280px;
  text-decoration: none;
  width: 100%;

  ${ProjectCardTitle} {
    margin-bottom: 5px;
  }

  &:hover ${ProjectCardTitle} {
    text-decoration: underline;
  }
`

/* Regular ProjectCard */
export const Title = styled(copy.M)`
  font-weight: 600; /* HACK: Irregular font manipulation. We'll be adding copy.M.semiBold, so this is just by anticipation. */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const FooterText = styled.div<{ size: 'small' | 'large' }>`
  ${({ size }) => {
    if (size === 'large') {
      return css`
        ${copy._smallStyle}
        color: ${colors.GREY_SHADE_2};
      `
    }

    return css`
      ${copy._xSmallStyle}
      color: ${colors.GREY_SHADE_2};
    `
  }}
`

export const Owner = styled(heading.XXXXXS)`
  color: ${colors.GREY_SHADE_2}; // HACK: Irregular font manipulation
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const LabelWithIconContainer = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: ${(props) => (props.inline ? 'nowrap' : 'normal')};
  overflow: ${(props) => (props.inline ? 'hidden' : 'visible')};

  > div:first-child {
    margin-right: 10px;
  }
`

export const ProjectCardReverse = styled.div<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? colors.PRIMARY_GREEN : colors.PRIMARY_BLUE_HOVER)};
  padding: 15px 20px 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
`

export const ProjectCardClampedText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const ProjectCardSubtitle = styled(copy.S)`
  color: ${colors.PRIMARY_WHITE};
  flex-grow: 1;
`

export const ProjectCardReverseData = styled(copy.XS)`
  color: ${colors.PRIMARY_WHITE};
  line-height: normal;
`

const mediaBase = css`
  bottom: 0;
  display: block;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`

export const ProjectCardPicture = styled.img`
  background-color: ${colors.PRIMARY_WHITE};
  ${mediaBase};
`

export const ProjectCardTag = styled.span`
  ${copy._smallStyle};

  background-color: ${colors.PRIMARY_GREEN};
  border-radius: 2px;
  color: ${colors.PRIMARY_WHITE};
  padding: 3px 10px;
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`

export const ProjectCardRibbon = styled.img`
  max-height: 42%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`

export const ProjectCardWrapper = styled.div<{
  isSelected: boolean
  isOnline: boolean
  size: 'small' | 'large'
}>`
  ${_baseStyle};
  border-width: ${(props) => (props.isSelected ? '3px' : '1px')};
  border-color: ${(props) => (props.isSelected ? colors.PRIMARY_GREEN : colors.GREY_SHADE_5)};

  ${({ size }) => {
    if (size === 'small') {
      return css`
        max-width: 310px;
      `
    }

    if (size === 'large') {
      return css`
        max-width: 580px;
        min-width: 100%;

        @media screen and ${breakpoints.LAPTOP} {
          min-width: 450px;
        }
      `
    }
  }};

  ${Title} {
    transition: color 200ms ease-out;
  }

  &:hover {
    text-decoration: none;

    ${Title} {
      color: ${colors.PRIMARY_BLUE_HOVER};
    }
  }

  ${ProjectCardPicture},
  ${ProjectCardRibbon} {
    transition: opacity 250ms ease-out;
    opacity: 1;
  }

  ${ProjectCardReverse} {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 250ms ease-out;
    opacity: 0;
  }

  ${button.RoundButton} {
    font-weight: revert;
    padding: 7.5px;
    position: absolute;
    right: 12px;
    top: 0;
    transform: translate(0, -50%);

    ${glyphs.Icon} {
      margin: 0;
    }

    ${glyphs.fill.CheckCircle} {
      fill: ${colors.PRIMARY_GREEN};
    }

    ${glyphs.fill.StarCircle} {
      fill: ${colors.SECONDARY_STATE_YELLOW_500};
    }

    ${glyphs.fill.Heart} {
      fill: ${colors.PRIMARY_BLUE};
    }

    ${copy.XS} {
      color: ${colors.GREY_SHADE_1};
      margin: 0 8px;
      text-transform: initial;
    }
  }

  ${VideoLoader} {
    ${mediaBase};
  }

  @media screen and ${breakpoints.TABLET} {
    &:hover {
      ${ProjectCardPicture},
      ${ProjectCardRibbon} {
        transition: opacity 250ms ease-in;
        opacity: ${(props) => (props.isOnline ? 0 : 1)};
      }

      ${ProjectCardReverse} {
        transition: opacity 250ms ease-in;
        opacity: 1;
      }
    }
  }
`

export const ProjectCardHeader = styled.div`
  background-color: ${colors.GREY_SHADE_6};
  border-radius: 6px 6px 0 0;
  display: block;
  height: auto;
  padding: ${() => 9 / (16 / 100)}% 0 0 0; /* keep ratio A:B -> B / (A / 100) = C% */
  position: relative;
  overflow: hidden;
  width: 100%;
`

export const ProjectCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 16px 0;
  position: relative;
`

export const ProjectCardFooter = styled.div`
  align-items: center;
  box-sizing: initial;
  display: flex;
  flex-direction: row;
  height: 22px;
  justify-content: space-between;
  line-height: 14px;
  padding: 10px 16px 10px 14px;
`

export const MembershipProjectCardBackdrop = styled.div<{ backgroundImage?: string }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 56.25% 0 0;
  position: relative;
  transition: all 250ms ease-in;
  width: 100%;

  ${({ backgroundImage }) => {
    if (backgroundImage) {
      return css`
        background-color: ${colors.hexToRgb(colors.PRIMARY_BLUE_700, 0.6)};
        background-image: url(${backgroundImage});
        background-position: center;
        background-size: cover;
      `
    }
  }};

  ${image.Avatar} {
    border-width: 4px;
    bottom: -20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  ${copy.S} {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: flex-start;
    color: ${colors.PRIMARY_GREY_000};
    display: -webkit-box;
    max-height: 70px;
    opacity: 0;
    overflow: hidden;
    padding: 20px;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    word-break: break-word;
  }
`

export const MembershipProjectCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 28px 16px 14px;
`

export const MembershipProjectCardTitle = styled(copy.M)`
  font-weight: 600; // HACK : irregular font manipulation
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const MembershipProjectCardSubtitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 16px;
`

export const MembershipProjectCardFooterRight = styled.div`
  ${copy.XS},${copy.S} {
    color: ${colors.PRIMARY_BLUE_700};
    font-weight: 600; /* HACK: Irregular font manipulation. We'll be adding copy.XS.semiBold, so this is just by anticipation. */
  }
`

export const MembershipProjectCardFooterLeft = styled.div`
  display: flex;
  flex-direction: row;

  ${glyphs.stroke.Person} {
    color: ${colors.PRIMARY_SAND_500};
    margin-right: 5px;
  }

  ${copy.XS},${copy.S} {
    color: ${colors.PRIMARY_SAND_700};
  }
`

export const MembershipProjectCardWrapper = styled.div<{ size: 'small' | 'large' }>`
  ${_baseStyle};
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;

  ${({ size }) => {
    if (size === 'small') {
      return css`
        max-width: 310px;
        height: 100%;
      `
    }

    if (size === 'large') {
      return css`
        max-width: 580px;
        min-width: 100%;

        @media screen and ${breakpoints.LAPTOP} {
          min-width: 450px;
          max-width: 450px;
        }

        ${MembershipProjectCardBackdrop} {
          height: 251px;
        }

        ${image.Avatar} {
          bottom: -20px;
          top: auto;
        }
      `
    }
  }};

  :hover {
    cursor: pointer;

    ${MembershipProjectCardTitle} {
      color: ${colors.PRIMARY_BLUE_700};
    }

    ${MembershipProjectCardBackdrop} {
      background-image: none;
      background-color: ${colors.PRIMARY_BLUE_700};
      transition: all 150ms ease-in;

      ${copy.S} {
        opacity: 1;
      }
    }
  }
`
