import * as React from 'react'
import styled, { css } from 'styled-components'

import * as BREAKPOINTS from '../constants/breakpoints'
import * as COLORS from '../constants/colors'
import * as S from '../styles'
import { Scaler } from '../icons/illustrations/helpers/TwoToned'
import * as illustrations from '../icons/illustrations/Illustration'

import { Modal, ModalProps } from './Modal'

export type ModalWithIconProps = ModalProps & {
  children: React.ReactNode
  className?: string
  icon: React.ReactElement
  showMobileIcon?: boolean
  svgScale?: number
}

export function ModalWithIconComponent({
  children,
  className,
  icon,
  showMobileIcon = false,
  svgScale = 1,
  ...modalProps
}: ModalWithIconProps): React.ReactElement<ModalWithIconProps> | null {
  const clonedIcon = React.cloneElement(icon, { full: true })

  return (
    <Modal {...modalProps} className={className}>
      <ModalIconWrapper svgScale={svgScale} showMobileIcon={showMobileIcon}>
        {clonedIcon}
      </ModalIconWrapper>
      {children}
    </Modal>
  )
}

export const ModalIconWrapper = styled.div<{ svgScale: number; showMobileIcon: boolean }>`
  ${({ showMobileIcon }) => {
    if (showMobileIcon) {
      return css`
        display: flex;
        justify-content: center;
        align-items: center;
      `
    }
    return css`
      display: none;
    `
  }}

  @media screen and ${BREAKPOINTS.TABLET} {
    align-self: center;
    box-shadow: 0 0 0 5px ${COLORS.PRIMARY_GREY_000};
    border-radius: 50%;
    display: flex;
    margin-top: -80px;
    overflow: hidden;

    ${Scaler} {
      transform: scale(${({ svgScale }) => svgScale});
      transform-origin: center;
    }
  }
`

export const ModalWithIcon = styled(ModalWithIconComponent)<ModalWithIconProps>`
  ${S.modal.Body} {
    display: flex;
    flex-direction: column;
  }

  ${illustrations.Illustration} {
    background-color: ${COLORS.PRIMARY_SAND_200};
  }

  @media screen and ${BREAKPOINTS.TABLET} {
    ${S.modal.Wrapper} {
      max-width: 450px;
      overflow: visible;
    }
  }
`
ModalWithIcon.displayName = 'ModalWithIcon'
