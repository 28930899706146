import * as React from 'react'
import styled, { css } from 'styled-components'

import * as api from '@owl-nest/api-client/latest'
import { QueryStatus, use100vh, useDramaticallyInefficientMediaQuery } from '@owl-nest/hooks'
import * as models from '@owl-nest/models'
import * as shadow from '@owl-nest/shadow'

import * as plume from '@ulule/owl-kit-components/next'

import { useConfigurationContext } from '../../context/configuration/configurationContext'
import { useUserDrawerContext } from '../../context/userDrawer/userDrawerContext'
import { useChannelsService } from '../../hooks/useChannelsService'
import { useCategoriesService } from '../../hooks/useCategoriesService'

import { ArrowedLink } from '../ArrowedLink'
import * as S from '../style'

import { type SessionData } from '../../types'

const SEARCH_INPUT_IDENTIFIER = 'search'

type SearchDrawerProps = {
  action: string
  className?: string
  height?: string
  siblingRef?: React.RefObject<HTMLElement>
  user?: {
    unreadMessagesCount?: number
  }
}

function SearchDrawerComponent({ action, className, height, siblingRef, user }: SearchDrawerProps): React.ReactElement {
  const $drawerRef = React.useRef<HTMLDivElement>(null)
  const [expanded, setAsExpanded] = React.useState(false)
  const [rightOffset, setRightOffset] = React.useState('9%')
  const [searchValue, setSearchValue] = React.useState('')
  const $searchFormRef = React.useRef<HTMLFormElement>(null)
  const heightFullScreen = use100vh()

  const { translations } = useConfigurationContext()
  // TODO: Replace with proper context
  const { sessionData, urls } = useUserDrawerContext()
  plume.hooks.useOnClickOutside([$drawerRef], () => setAsExpanded(false))

  const categoriesService = useCategoriesService()
  const channelsService = useChannelsService()

  React.useEffect(() => {
    if (expanded) {
      if (channelsService.response.status === QueryStatus.PRISTINE) {
        channelsService.query()
      }
      if (categoriesService.response.status === QueryStatus.PRISTINE) {
        categoriesService.query()
      }
      if (window.zE) {
        window.zE('webWidget', 'hide')
      }
    } else {
      if (window.zE) {
        window.zE('webWidget', 'show')
      }
      // Reset search value on close
      setSearchValue('')
    }
  }, [expanded])

  const isLargerThanTablet = useDramaticallyInefficientMediaQuery(plume.BREAKPOINTS.TABLET)

  // HACK: This is utter sh*t. We depend on `user` (and more specifically, `user.unreadMessageCount`) to detect potential
  //  sibling width changes (when notification count is asynchronously updated). Needless to say this is absurd.
  //  It is here to allow for a faster initial release, but a cleaner solution will follow.
  React.useEffect(() => {
    const rightOffset =
      siblingRef && siblingRef.current ? Math.ceil(window.innerWidth - siblingRef.current?.offsetLeft) : null

    if (rightOffset) {
      setRightOffset(`${rightOffset}px`)
    }
  }, [expanded, isLargerThanTablet, user])

  function determinePlaceholder(): string {
    if (isLargerThanTablet) {
      /* @ts-expect-error `translations` children are unknown, we should type properly type them. */
      return translations.searchDrawer.searchPlaceholder
    } else {
      /* @ts-expect-error `translations` children are unknown, we should type properly type them. */
      return translations.searchDrawer.shortSearchPlaceholder
    }
  }

  const placeholder = React.useMemo(() => determinePlaceholder(), [isLargerThanTablet, expanded])

  // We use this to show the channels section title even before content is loaded.
  // This causes content flash for countries that have no channels configured, but avoids it for the others.
  // The latter scenario being predominant, this seems to be the best compromise.
  const maybeThereAreChannels =
    channelsService.response.data !== undefined && channelsService.response.data.channels.length > 0

  return (
    <>
      <SearchDrawerWrapper
        className={className}
        dualColorBackground={maybeThereAreChannels}
        expanded={expanded}
        id="search-form"
        height={height ?? plume.SIZES.HEADER_INTERNAL_HEIGHT}
        heightFullScreen={heightFullScreen}
        locale={sessionData.currentLocale}
        ref={$drawerRef}
        rightOffset={rightOffset}
      >
        <SearchWrapper
          action={action}
          expanded={expanded}
          height={height ?? plume.SIZES.HEADER_HEIGHT}
          method="GET"
          ref={$searchFormRef}
        >
          <SearchLabel htmlFor={SEARCH_INPUT_IDENTIFIER}>
            <plume.glyphs.stroke.Search size={20} />
          </SearchLabel>
          <SearchInputWrapper>
            <HiddenSearchLabel>{placeholder}</HiddenSearchLabel>
            <SearchInput
              id={SEARCH_INPUT_IDENTIFIER}
              name={SEARCH_INPUT_IDENTIFIER}
              onChange={(event) => {
                setSearchValue(event.target.value)
              }}
              onFocus={() => {
                setAsExpanded(true)
              }}
              placeholder={placeholder}
              value={searchValue}
            />
            <ArrowedLink
              onClick={() => {
                if ($searchFormRef.current) {
                  $searchFormRef.current.submit()
                }
              }}
            >
              {/* @ts-expect-error `translations` children are unknown, we should type properly type them. */}
              {translations.searchDrawer.shortSearchPlaceholder}
            </ArrowedLink>
          </SearchInputWrapper>
          <CloseButton
            onClick={() => {
              // TODO: Empty user entry, too
              setAsExpanded(false)
            }}
          >
            <plume.glyphs.stroke.Close size={18} />
          </CloseButton>
        </SearchWrapper>
        <SearchDrawerBody visible={expanded}>
          {maybeThereAreChannels && (
            <NavigationSection>
              {/* @ts-expect-error `translations` children are unknown, we should type properly type them. */}
              <ArrowedLink href={urls.channels}>{translations.searchDrawer.exploreChannels}</ArrowedLink>
              <ChannelList>
                {channelsService.response.data?.channels.map((channel) => (
                  <Channel key={channel.absolute_url} channel={channel} />
                ))}
              </ChannelList>
            </NavigationSection>
          )}
          <NavigationSection>
            {/* @ts-expect-error `translations` children are unknown, we should type properly type them. */}
            <ArrowedLink href={urls.discover}>{translations.searchDrawer.exploreProjects}</ArrowedLink>
            {categoriesService.response.data !== undefined && categoriesService.response.data.categories.length > 0 && (
              <CategoryList categoryCount={categoriesService.response.data.categories.length}>
                {categoriesService.response.data.categories.map((category) => (
                  <li key={category.id}>
                    <CategoryLink href={category.absolute_url} kind="secondary">
                      <plume.styles.heading.Card1>
                        {/* HACK: Cannot solely rely on i18n.get() on ulule/next since it depends on "ufe.locale", which doesn't reflect the real-world value. */}
                        {category.name[sessionData.currentLocale] ??
                          models.i18n.get(category.name, sessionData.defaultLocale, '')}
                      </plume.styles.heading.Card1>
                    </CategoryLink>
                  </li>
                ))}
              </CategoryList>
            )}
          </NavigationSection>
        </SearchDrawerBody>
      </SearchDrawerWrapper>
      <S.Overlay visible={expanded}>{expanded && <S.LockScroll />}</S.Overlay>
    </>
  )
}

export const SearchDrawer = styled(SearchDrawerComponent)`
  z-index: 1;
`

type ChannelProps = { channel: api.Channel }

function Channel({ channel }: ChannelProps): React.ReactElement<ChannelProps> {
  const $miniChannelCardRef = React.useRef<HTMLLIElement>(null)
  shadow.usePartnershipTracker(channel.partner?.user_id, $miniChannelCardRef, {
    click: {
      enabled: true,
      once: true,
    },
    impression: {
      enabled: true,
      once: true,
    },
  })

  return (
    <li key={channel.absolute_url} ref={$miniChannelCardRef}>
      <plume.MiniChannelCard
        backgroundColor={channel.bg_color}
        href={channel.absolute_url}
        image={models.channel.ctaBackgroundImage(channel)}
        title={models.channel.name(channel)}
      />
    </li>
  )
}

const EASE_IN_OUT_EXPO = 'cubic-bezier(0.79, 0.14, 0.15, 0.86)'
const MAX_DESKTOP_WIDTH = '724px'

const searchInputPlaceholderStyle = css`
  font-family: ${plume.FONTS.PRIMARY_FONT};
  font-size: 13px; // HACK: Irregular font manipulation
  font-weight: 600; // HACK: Irregular font manipulation
  text-transform: uppercase;
`

const CategoryLink = styled(plume.Link)``

const CategoryList = styled.ol<{ categoryCount: number }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin: 13px 0;
  max-height: ${({ categoryCount = 16 }) => `${Math.ceil(categoryCount / 2) * 30}px`};
  padding: 0;

  > li {
    margin-bottom: 12px;
    width: 50%;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    max-height: ${({ categoryCount = 16 }) => `${Math.ceil(categoryCount / 4) * 30}px`};

    > li {
      width: 25%;
    }
  }
`

const ChannelList = styled.ul`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, minmax(72px, 120px));
  list-style: none;
  margin: 13px 0;
  padding: 0;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: flex;
    flex-wrap: wrap;
    gap: 0;

    > li {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const CloseButton = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`

// This hidden span holds the same text as the input placeholder,
// allowing us to size the input according to its placeholder content.
const HiddenSearchLabel = styled.span`
  display: none;

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    display: block;
    height: 0;
    visibility: hidden;

    ${searchInputPlaceholderStyle}
  }
`

const NavigationSection = styled.nav`
  margin: 20px 0;
  position: relative;
  transition: visibility 150ms ${EASE_IN_OUT_EXPO} 200ms;

  & + & {
    margin: 34px 0 20px;
  }
`

const SearchDrawerBody = styled.section<{ visible: boolean }>`
  background-color: ${plume.COLORS.PRIMARY_SAND};
  height: 100%;
  padding: 2px 30px 6px;

  @media screen and ${S.LARGE_BREAKPOINT} {
    height: calc(100% - 136px);
  }

  ${({ visible }) => {
    if (!visible) {
      return css`
        visibility: hidden;
      `
    }
  }}
`

const wrapperWidth = {
  ca: '200px',
  de: '300px',
  en: '300px',
  es: '214px',
  fr: '365px',
  it: '327px',
  nl: '290px',
  pt: '300px',
}

const SearchDrawerWrapper = styled.nav<{
  dualColorBackground: boolean
  expanded: boolean
  height: string
  heightFullScreen: string | null
  locale: SessionData['currentLocale']
  rightOffset: string
}>`
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  box-sizing: border-box;
  max-height: ${({ height }) => height};
  overflow: hidden;
  position: absolute;
  right: ${({ rightOffset }) => `calc(${rightOffset} - 16px)`};
  top: 0;
  transition-delay: 0ms, 0ms, 160ms, 100ms, 160ms;
  transition-duration: 150ms, 150ms, 120ms, 250ms, 120ms;
  transition-property: height, max-height, max-width, right, width;
  transition-timing-function: ${EASE_IN_OUT_EXPO};
  width: 58px;

  @media screen and ${S.LARGE_BREAKPOINT} {
    left: initial;
    right: ${({ rightOffset }) => `calc(${rightOffset} + 9px)`};
    width: 100%;

    ${({ locale = 'en' }) => {
      return css`
        max-width: ${wrapperWidth[locale]};
      `
    }}
  }

  ${({ dualColorBackground = true, expanded = false, rightOffset, heightFullScreen }) => {
    if (expanded) {
      return css`
        left: 0;
        right: 0;
        transition-delay: 200ms, 200ms, 0ms, 0ms, 0ms;
        transition-duration: 200ms, 200ms, 200ms, 0ms, 200ms;
        width: 100%;

        &::before {
          background-color: ${plume.COLORS.PRIMARY_WHITE};
          content: '';
          display: block;
          height: ${dualColorBackground ? '150px' : '62px'};
          left: 0;
          padding: 0 30px;
          position: absolute;
          top: 0;
          width: 100%;
          box-sizing: border-box;
        }

        ${SearchInput}::placeholder {
          color: ${plume.COLORS.GREY_SHADE_2};
        }

        @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
          box-shadow: ${plume.EFFECTS.SHADOW_3};
          left: initial;
          max-height: 700px;
          right: calc(${rightOffset} - 1px);
          width: ${MAX_DESKTOP_WIDTH};
        }

        @media not screen and ${S.INTERMEDIATE_BREAKPOINT} {
          height: ${heightFullScreen};
          max-height: 1200px;
        }

        @media screen and ${S.LARGE_BREAKPOINT} {
          max-width: ${MAX_DESKTOP_WIDTH};
        }
      `
    }
  }}
`

const SearchInput = styled.input`
  border: none;
  color: ${plume.COLORS.PRIMARY_BLACK};
  display: inline;
  height: 100%;
  padding: 0 0 3px;
  width: 100%;

  ${searchInputPlaceholderStyle}
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${plume.COLORS.PRIMARY_BLACK};
    display: block;
    opacity: 1;
  }
`

const SearchLabel = styled.label`
  cursor: pointer;

  ${plume.glyphs.Icon} {
    margin-right: 10px;
    position: relative;
    top: -1px;
    vertical-align: text-top;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    margin-top: -2px;
  }
`

const SearchInputWrapper = styled.div`
  width: 100%;

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    padding-right: 12px;
  }

  ${ArrowedLink} {
    display: none;
    position: absolute;
    right: 20px;
    top: 18px;
    width: max-content;
  }
`

const SearchWrapper = styled.form<{ expanded: boolean; height: string }>`
  align-items: center;
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  display: flex;
  padding-left: 30px;
  position: relative;
  height: ${({ height }) => height};

  ${({ expanded }) => {
    if (expanded) {
      return css`
        padding-left: 0;
        box-sizing: border-box;
        margin-left: 30px;

        @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
          ${SearchInputWrapper} ${ArrowedLink} {
            display: block;
          }
        }

        @media not screen and ${S.INTERMEDIATE_BREAKPOINT} {
          width: calc(100% - 60px);
        }
      `
    }
  }};

  &:hover:not(:focus-within) {
    ${SearchInput} {
      ::placeholder {
        color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
      }
    }

    ${plume.glyphs.Icon} {
      color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
    }
  }

  ${CloseButton} {
    position: absolute;
    right: 0;
    transition-delay: 100ms;
    transition-duration: 120ms;
    transition-property: opacity, visibility;
    transition-timing-function: ${EASE_IN_OUT_EXPO};

    @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
      display: none;
    }

    ${({ expanded }) => {
      if (expanded) {
        return css`
          opacity: 1;
          visibility: visible;
        `
      } else {
        return css`
          opacity: 0;
          transition-delay: 0ms;
          visibility: hidden;
        `
      }
    }};
  }
`
