import { useRealThrottle } from '@ulule/owl-kit-components/src/hooks'
import * as React from 'react'
import { useClientSide } from './useClientSide'
import { useEventListener } from './useEventListener'

/** the name of the function is there for us to remember that this hook
 * must only be used as a last resort. If you can, use CSS media-query
 * instead. Inefficiencies of this hook :
 * - Re-rendering the tree on each window resize (throttled, but still...)
 * - Each usage of the hook adds a resize listener.
 * - This is done in the main thread in js instead of being efficiently handled by CSS
 */
export function useDramaticallyInefficientMediaQuery(query: string): boolean {
  const clientSide = useClientSide()
  const [matches, setMatches] = React.useState(clientSide ? matchQuery(query) : false)

  useDramaticallyInefficientScrollListener(() => setMatches(matchQuery(query)))

  return matches
}

export function useDramaticallyInefficientMatchMediaQuery(query: string, initialServerMatches = false): boolean {
  const [matches, setMatches] = React.useState(initialServerMatches)

  React.useEffect(() => {
    const matcher = window.matchMedia(query)
    setMatches(matcher.matches)
    matcher.addEventListener('change', onMatcherChange)
    return () => {
      matcher.removeEventListener('change', onMatcherChange)
    }

    function onMatcherChange(event: MediaQueryListEvent) {
      setMatches(event.matches)
    }
  }, [])

  return matches
}

export function useDramaticallyInefficientScrollListener(callback: () => void): void {
  const clientSide = useClientSide()
  const throttledCallback = useRealThrottle(callback, 200)

  React.useEffect(callback, [clientSide])

  if (typeof window !== 'undefined') {
    useEventListener(window, 'resize', throttledCallback)
  }
}

export function matchQuery(query: string): boolean {
  if (typeof window === 'undefined') {
    return false
  }
  return window.matchMedia(query).matches
}
