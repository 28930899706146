import * as React from 'react'
import * as jotai from 'jotai'

import * as api from '@owl-nest/api-client/latest'
import * as hooks from '@owl-nest/hooks'

type ChannelsConfig = {
  fetchOnMount?: boolean | 'force'
  params: ChannelsParams
}

type ChannelsParams = {
  country: string
  lang: string
  limit: string
}

export async function get(params: ChannelsParams): Promise<api.ApiResponse<api.Pageable<api.ChannelList>>> {
  const response = await api.api.get.channels({ getParams: params })

  return response.next((success) => success.body)
}

const CHANNELS_CACHE = jotai.atom<hooks.Cache<api.Pageable<api.ChannelList>>>({})

export function useChannels({ fetchOnMount = true, params }: ChannelsConfig) {
  const { query: queryChannels, getCurrentResponse } = hooks.useSharedQuery(get, {
    atom: CHANNELS_CACHE,
    id,
  })

  hooks.useFetchOnMount(
    {
      fetchOnMount,
      query,
      getResponse,
    },
    id(params),
  )

  return { response: getResponse(), query }

  function getResponse(): hooks.Response<api.ChannelList> {
    const response = getCurrentResponse([params])

    if (response === undefined) {
      return { status: hooks.QueryStatus.PRISTINE }
    }

    return response as hooks.Response<api.ChannelList>
  }

  function id(params: ChannelsParams): string {
    return JSON.stringify([params.country, params.lang])
  }

  async function query() {
    return queryChannels(params)
  }
}
