import * as React from 'react'
import styled, { css, type FlattenInterpolation, type ThemedStyledProps } from 'styled-components'

import * as breakpoints from '../constants/breakpoints'
import * as colors from '../constants/colors'
import * as effects from '../constants/neue/effects'

export type AvatarProps = {
  /** @default 'small' */
  type?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'
  withShadow?: boolean
} & React.ImgHTMLAttributes<HTMLImageElement> &
  AsDesktopAvatarProps

type AvatarSizes = keyof typeof _avatarStyles
type AsDesktopAvatarProps = { asDesktop?: AvatarSizes; breakpoint?: string }

const asDesktopAvatarStyle: FlattenInterpolation<ThemedStyledProps<AsDesktopAvatarProps, any>> =
  css<AsDesktopAvatarProps>`
    @media screen and ${({ breakpoint = breakpoints.TABLET }) => breakpoint} {
      ${({ asDesktop }) => {
        return asDesktop === undefined ? '' : _avatarStyles[asDesktop]
      }}
    }
  `

const _extraSmallAvatarStyle = css`
  height: 30px;
  width: 30px;
`

const _smallAvatarStyle = css`
  height: 36px;
  width: 36px;
`

const _mediumAvatarStyle = css`
  height: 50px;
  width: 50px;
`

const _largeAvatarStyle = css`
  height: 90px;
  width: 90px;
`

const _extraLargeAvatarStyle = css`
  height: 180px;
  width: 180px;
`

const _avatarStyles = {
  'extra-small': _extraSmallAvatarStyle,
  small: _smallAvatarStyle,
  medium: _mediumAvatarStyle,
  large: _largeAvatarStyle,
  'extra-large': _extraLargeAvatarStyle,
}

export const Avatar = styled.img<AvatarProps>`
  background-image: ${(props) => (props.src ? `url(${props.src})` : undefined)};
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  border: 2px solid ${colors.PRIMARY_GREY_000};
  object-fit: cover;
  box-sizing: border-box;

  ${({ type = 'small' }) => {
    if (type === 'small') {
      return _smallAvatarStyle
    } else if (type === 'extra-small') {
      return _extraSmallAvatarStyle
    } else if (type === 'large') {
      return _largeAvatarStyle
    } else if (type === 'extra-large') {
      return _extraLargeAvatarStyle
    } else {
      return _mediumAvatarStyle
    }
  }};

  ${({ withShadow = false }) => {
    if (withShadow) {
      return css`
        box-shadow: ${effects.SHADOW_2};
      `
    }
  }};

  ${asDesktopAvatarStyle};
`
Avatar.displayName = 'S.image.Avatar'

type PastilleProps = {
  /** @default 'medium' */
  size?: 'extraSmall' | 'small' | 'medium'
  /** @default false */
  noBorder?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const Pastille = styled.div<PastilleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: ${({ noBorder = false }) => (noBorder ? 'none !important' : '0 0 0 5px ' + colors.PRIMARY_WHITE)};

  * {
    max-width: 120px;
    max-height: 120px;
    text-align: center;
  }

  ${({ size = 'medium' }) => {
    if (size === 'extraSmall') {
      return css`
        height: 40px;
        width: 40px;
        box-shadow: 0 0 0 2px ${colors.PRIMARY_WHITE};
      `
    }
    if (size === 'small') {
      return css`
        height: 60px;
        width: 60px;
        box-shadow: 0 0 0 2px ${colors.PRIMARY_WHITE};
      `
    }
    if (size === 'medium') {
      return css`
        width: 120px;
        height: 120px;
        box-shadow: 0 0 0 5px ${colors.PRIMARY_WHITE};
      `
    }
  }}
`
Pastille.displayName = 'S.image.Pastille'

export const Flag = styled.img<React.ImgHTMLAttributes<HTMLImageElement>>`
  width: 20px;
  height: 15px;
  margin: 0 8px 0 4px;
`
Flag.displayName = 'S.image.Flag'
