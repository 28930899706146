import styled, { css } from 'styled-components'

import * as colors from '../constants/colors'
import * as glyphs from '../icons/glyphs'
import * as copy from '../styles/copy'

type ToggleProps = {
  isActive: boolean
  isToggled: boolean
  maxHeight: number
}

export const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContentWrapper = styled.div<ToggleProps>`
  position: relative;
  max-height: ${({ isToggled, maxHeight }) => (isToggled ? 'none' : `${maxHeight}px`)};
  overflow: hidden;

  ${({ isActive, isToggled, maxHeight }) => css`
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) ${Math.min(0.2 * maxHeight + 16.5, 80)}%,
        rgba(255, 255, 255, 1) 100%
      );
      opacity: ${!isActive ? 0 : isToggled ? 0 : 1};
      pointer-events: none;
    }
  `}
`

export const ToggleButton = styled(copy.S)<{ isToggled: boolean }>`
  color: ${colors.TEXT_TERTIARY};
  align-items: center;
  display: flex;
  margin-top: 16px;
  cursor: pointer;
  font-weight: 600; // HACK: Irregular font manipulation

  ${glyphs.stroke.CaretDown} {
    color: ${colors.TEXT_TERTIARY};
    margin-left: 7px;
    position: relative;
    top: 2px;
    transform: ${({ isToggled }) => (isToggled ? 'rotate(180deg)' : 'none')};
  }
`
