import * as logger from '@owl-nest/logger'

import * as UFE from './UFE'

type Response = {
  redirect_url: string
  status: number
}

export async function updateSettings(formData: BodyInit): Promise<Response> {
  if (!formData) {
    return Promise.reject('formData argument is required')
  }

  return fetch(`${UFE.ULULE_ORIGINS ? UFE.ULULE_ORIGINS['fr'] : ''}/metasettings/dashboard/`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: formData,
  })
    .then((response) => response.json())
    .catch((error) => {
      logger.err('[user] failed to update meta settings', { error })
    })
}
