import * as React from 'react'
import styled from 'styled-components'

import * as button from '../../styles/button'

export type ButtonAsLinkProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  button.ButtonProps & {
    href?: string
    target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target']
  }

function ButtonAsLinkComponent({ children, href, target, className, ...rest }: ButtonAsLinkProps): React.ReactElement {
  return (
    <button.LinkWrapper href={href} disabled={rest.disabled} className={className} target={target}>
      <button.Button {...rest}>{children}</button.Button>
    </button.LinkWrapper>
  )
}

export const ButtonAsLink = styled(ButtonAsLinkComponent)``
