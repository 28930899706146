import styled, { css } from 'styled-components'

import * as breakpoint from '../../constants/breakpoints'
import * as color from '../../constants/colors'
import * as copy from './../copy'
import * as heading from './../heading'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${color.PRIMARY_WHITE};
  border: 1px solid ${color.GREY_SHADE_5};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`

export const Informations = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > ${copy.M}, > ${copy.XS} {
    margin-top: 4px;
  }

  > ${heading.XS}, > ${heading.XXXS} {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 4px;
  }

  @media screen and ${breakpoint.TABLET} {
    padding: 16px 16px 20px 16px;
  }
`

export const BottomInformation = styled.div<{ withCta?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  flex-grow: 1;

  ${({ withCta }) => {
    if (withCta) {
      return css`
        margin-bottom: 16px;
      `
    }
  }}
`

export const Prices = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and ${breakpoint.TABLET} {
    flex-direction: row-reverse;
    align-items: baseline;
    justify-content: flex-end;
  }
`

export const Price = styled(copy.M)`
  color: ${color.SECONDARY_MINT};
  display: block;
  font-weight: 600;

  @media screen and ${breakpoint.TABLET} {
    margin-right: 10px;
  }
`

export const DiscountPrice = styled(copy.XS)`
  color: ${color.GREY_SHADE_2};
  display: block;
  text-decoration: line-through;
`

export const VariantInformation = styled(copy.XS)`
  color: ${color.GREY_SHADE_2};
  display: block;
  height: 22px;
  margin-bottom: 4px;
  margin-top: 20px;
`
