export type CookieParams = {
  path?: string
  domain?: string
  'max-age'?: string
  expires?: string
  secure?: boolean
}

const defaultCookieParams: CookieParams = {
  path: '/',
}

/** Get a cookie value by name (for the current path and domain)  */
export function getCookie(name: string): string | undefined {
  const cookies = typeof window !== 'undefined' ? window.document.cookie : ''
  const cookieRegexp = new RegExp(`${name}=(.[^;]+)?`)
  const match = cookies.match(cookieRegexp)
  if (match === null) {
    return undefined
  }
  return match[1]
}

export function serializeCookie(name: string, value: string, params: CookieParams = defaultCookieParams): string {
  const cookieObject = { [name]: value, ...params }
  return Object.entries(cookieObject)
    .map(([key, value]) => {
      if (value === undefined || value === false) {
        return ''
      }
      if (value === true) {
        return `${key}`
      }
      return `${key}=${value}`
    })
    .join(';')
}

/** Set a cookie value (optionaly for different path or domain)  */
export function setCookie(name: string, value: string, params: CookieParams = defaultCookieParams): void {
  if (typeof window !== 'undefined') {
    window.document.cookie = serializeCookie(name, value, params)
  }
}

/** Remove a cookie (optionaly for different path)  */
export function removeCookie(name: string, params: CookieParams = defaultCookieParams): void {
  setCookie(name, '', {
    ...params,
    expires: 'Thu, 01-Jan-70 00:00:01 GMT',
  })
}
