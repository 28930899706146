import { Channel } from '@owl-nest/api-client/latest'

import * as i18n from './i18n'
import * as image from './image'

export function name(channel: Channel): string {
  return i18n.get(channel.name, 'en', '')
}

export function description(channel: Channel): string {
  return i18n.get(channel.description, 'en', '')
}

export function backgroundImage(channel: Channel, size = 'full'): string | undefined {
  return image.getUrl(channel.background?.versions, size)
}

export function ctaBackgroundImage(channel: Channel, size = 'full'): string | undefined {
  return image.getUrl(channel.cta_background?.versions, size)
}
