import * as React from 'react'
import styled from 'styled-components'

import * as api from '@owl-nest/api-client/latest'
import * as models from '@owl-nest/models'
import * as shadow from '@owl-nest/shadow'
import * as plume from '@ulule/owl-kit-components/next'

type ChannelProps = { channel: api.Channel }

export function Channel({ channel }: ChannelProps): React.ReactElement<ChannelProps> {
  const $miniChannelCardRef = React.useRef<HTMLLIElement>(null)
  shadow.usePartnershipTracker(channel.partner?.user_id, $miniChannelCardRef, {
    click: {
      enabled: true,
      once: true,
    },
    impression: {
      enabled: true,
      once: true,
    },
  })

  return (
    <li ref={$miniChannelCardRef}>
      <plume.MiniChannelCard
        backgroundColor={channel.bg_color}
        href={channel.absolute_url}
        image={models.channel.ctaBackgroundImage(channel)}
        title={models.channel.name(channel)}
      />
    </li>
  )
}

export const ChannelList = styled.ul`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, minmax(72px, 120px));
  list-style: none;
  margin: 0;
  padding: 0;

  ${plume.styles.card.channel.MiniWrapper} {
    margin: 0;
    max-width: 100%;
    min-width: 100%;
    width: auto;
  }
`
