import * as React from 'react'
import styled, { css } from 'styled-components'

import * as COLORS from '../../constants/colors'
import * as button from '../../styles/button'
import * as spinner from '../../styles/spinner'
import { Spinner } from '../Spinner'
import { useTransition } from '../../behaviours'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  button.ButtonProps & {
    /** display the button in loading state (disabled with spinner instead of label) */
    loading?: boolean
  }

function ButtonComponent({
  children,
  loading = false,
  disabled,
  ...props
}: ButtonProps): React.ReactElement<ButtonProps> {
  const { step, unmounted, onTransitionEnd } = useTransition(loading)

  const validatedColor = props.color && props.color in button.buttonColors ? props.color : undefined

  return (
    <ButtonWrapper
      {...props}
      color={validatedColor}
      disabled={loading || disabled}
      $loading={loading}
      step={step}
      unmounted={unmounted}
    >
      <button.Label>{children}</button.Label> <Spinner onTransitionEnd={onTransitionEnd} />
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled(button.Button)<{ step: 'before' | 'during' | 'after'; unmounted: boolean }>`
  position: relative;

  ${spinner.Spinner} {
    left: 50%;
    opacity: ${({ step, unmounted }) => (step === 'during' && !unmounted ? 1 : 0)};
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
  }

  ${button.Label} {
    transition: opacity 0.3s ease;
    opacity: ${({ step, unmounted }) => (step !== 'during' || unmounted ? 1 : 0)};
  }

  ${spinner.Dot} {
    ${({ kind = 'primary', color = 'blue' }) => {
      if (kind === 'primary') {
        return css`
          background-color: ${COLORS.PRIMARY_WHITE};
        `
      }
      return css`
        background-color: ${button.buttonColors[color].base};
      `
    }}
  }
`

export const Button = styled(ButtonComponent)<ButtonProps>``
Button.defaultProps = {
  loading: false,
}
Button.displayName = 'Button'
