import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../icons/glyphs'
import { SpecificIconProps } from '../icons/glyphs/types'

export type EggProgressComponentProps = {
  /** progress in percent (from 0 to 100) */
  progress: number
} & Omit<SpecificIconProps, 'collection'>

function EggProgressComponent({
  progress,
  ...iconProps
}: EggProgressComponentProps): React.ReactElement<EggProgressComponentProps> {
  if (progress < 100) {
    return <glyphs.crowdfunding.EggPartial {...iconProps} progress={progress} />
  }

  return <glyphs.crowdfunding.EggFull {...iconProps} />
}

export const EggProgress = styled(EggProgressComponent)<EggProgressComponentProps>``
EggProgress.displayName = 'EggProgress'
