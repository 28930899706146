import { LinkedItem } from './types'
import { State } from './reducer'

export function getItem<IDENTIFIER>(
  items: LinkedItem<IDENTIFIER>[],
  identifier: IDENTIFIER,
): LinkedItem<IDENTIFIER> | undefined {
  return items.find((item) => item.identifier === identifier)
}

export function getFocusedItem<IDENTIFIER>(
  state: State<IDENTIFIER>,
  identifier?: IDENTIFIER,
  disableInitFocus = false,
): LinkedItem<IDENTIFIER> | undefined {
  const targetItem =
    identifier !== undefined ? getItem(state.registered, identifier) : disableInitFocus ? undefined : state.head
  let item = targetItem
  // while item exists, is disabled or filtered
  while (item !== undefined && (item.disabled || item.filtered)) {
    item = item.next
    if (item === targetItem) {
      // we went full circle on options, break to avoid infinite loop
      break
    }
  }
  return item
}

export function getItemIndex<IDENTIFIER>(items: LinkedItem<IDENTIFIER>[], identifier: IDENTIFIER): number {
  return items.findIndex((item) => item.identifier === identifier)
}

export function normalize(str: string): string {
  try {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
  } catch (error) {
    return str.toLowerCase()
  }
}

export function matchQuery(item: LinkedItem<any>, query: string): boolean {
  return normalize(item.label).startsWith(query)
}

export function includesQuery(item: LinkedItem<any>, query: string): boolean {
  return normalize(item.label).includes(query)
}
