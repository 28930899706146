import * as React from 'react'
import styled from 'styled-components'

import * as api from '@owl-nest/api-client/latest'
import * as model from '@owl-nest/models'
import * as plume from '@ulule/owl-kit-components/next'
import { t } from '@owl-nest/localize'

type SubmitSuccessProps = {
  manager?: api.PublicUser
}

export function SubmitSuccess({ manager }: SubmitSuccessProps): React.ReactElement<SubmitSuccessProps> {
  return (
    <SubmitSuccessWrapper>
      <plume.styles.heading.XS>{t('Project sent for review')}</plume.styles.heading.XS>

      <plume.styles.copy.S>
        {t('Your project was successfully sent to %(manager)s, who will review it and get back to you shortly.', {
          manager: manager !== undefined ? model.user.fullName(manager) : 'team',
        })}
      </plume.styles.copy.S>

      <plume.ButtonAsLink
        href="https://support.ulule.com/hc/articles/360001711678-Comment-fonctionne-la-relecture-de-mon-projet-"
        target="_blank"
        rel="noopener"
      >
        {t('How does review work?')}
      </plume.ButtonAsLink>
    </SubmitSuccessWrapper>
  )
}

const SubmitSuccessWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  ${plume.ButtonAsLink} {
    margin-top: 15px;
  }

  ${plume.styles.heading.XS} {
    margin: 10px 0 20px;
  }
`
