import * as yup from 'yup'

export function url(strict = false) {
  return new UrlSchema(strict)
}

export class UrlSchema extends yup.StringSchema {
  constructor(strict?: boolean) {
    super()

    // HACK: schema.type is supposed to be read-only, but who cares, right ?
    //@ts-ignore
    this.type = 'url'

    this.withMutation((schema) => {
      this.transform(function (value) {
        // HACK: yup `url` validation doesn't understand URLs where origin has no TLD
        //  Workaround for `localhost`, where we add a `.invalid` TLD (should not
        //  be risky since `.invalid` is guaranteed to never exist)
        if (value.startsWith('http://localhost')) {
          return value.replace('http://localhost', 'http://localhost.invalid')
        }
        if (value && !strict && !value.match(/^((https?):)\/\//)) {
          return `https://${value}`
        }
        return value
      })
      return schema.url()
    })
  }
}
