import * as React from 'react'
import styled from 'styled-components'

import * as behaviours from '../../../behaviours'
import * as S from '../../../styles'

import { Field } from './Field'
import { FieldStatus } from './FieldStatus'

export type TextAreaFieldProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  className?: string
  /** short context of the field (meta info, like 'Optional') */
  context?: string
  description?: React.ReactNode
  error?: React.ReactNode
  format?: 'wide'
  /** @default String(Math.random()) */
  id?: string
  label?: React.ReactNode
  resetRef?: React.RefObject<() => void>
  resizable?: boolean
  success?: React.ReactNode
}

export type Status = 'error' | 'success' | 'default'

function TextAreaFieldComponent({
  className,
  label,
  description,
  error,
  context,
  success,
  children,
  id = String(Math.random()),
  resetRef,
  format,
  ...inputProps
}: TextAreaFieldProps): React.ReactElement<TextAreaFieldProps> {
  const [length, setLength] = React.useState(String(inputProps.value).length)
  const { classList, status } = behaviours.getStatusClassName(className, error, success)

  // This. This is bad. Don't do that unless you have a very good reason. Here we
  // want to be able to reset the field, and have the `count` update to display 0.
  // Since `length` is in a state, there is no way to update it from outside the
  // component ... unless we pass the update function to a parent via a `ref`.
  // This is a dirty hack, but every other options would be a dirty hack. This
  // solution is the simplest of the dirty hack we could think of.
  // To avoid slipping further, we voluntarily limited the function passed to the ref
  // to have it only set the state to 0, so it can only be used to reset the component.
  // UPDATE(26/04/2022): There is an alternative solution that we implemented in TextField.tsx
  // Considering that we're always capable of getting the length of the field when its value changes,
  // whether it is from an onChange event when the TextField is uncontrolled or directly from a controlled value,
  // there's no need for an external access/control to the length of the field. In this regard,
  // we can rely on the onChange event to update our length state when TextField is uncontrolled,
  // while we use a useEffect hook to update the length state when TextField is controlled.

  React.useEffect(() => {
    if (resetRef !== undefined) {
      ;(resetRef as any).current = () => setLength(0)
      return () => {
        ;(resetRef as any).current = null
      }
    }
  }, [])

  const maxLength = inputProps.maxLength

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    const onChange = inputProps.onChange
    onChange && onChange(event)

    if (maxLength !== undefined) {
      setLength(event.target.value.length)
    }
  }

  return (
    <TextAreaFieldWrapper
      className={classList.filter(Boolean).join(' ')}
      htmlFor={id}
      label={label}
      description={description}
      context={context}
      format={format}
    >
      {children}
      <S.textarea.TextArea {...inputProps} onChange={handleChange} status={status} id={id} />
      {(error || success || maxLength !== undefined) && (
        <FieldStatus error={error} success={success}>
          {maxLength !== undefined && (
            <S.input.Count isError={length > maxLength}>
              {length}/{maxLength}
            </S.input.Count>
          )}
        </FieldStatus>
      )}
    </TextAreaFieldWrapper>
  )
}

/** will forward any other props to the underlying `<input>` */
export const TextAreaField = styled(TextAreaFieldComponent)<TextAreaFieldProps>``
TextAreaField.displayName = 'TextAreaField'

export const TextAreaFieldWrapper = styled(Field)`
  ${S.field.Message} {
    margin-top: 4px;
  }

  ${S.input.Count} {
    margin-top: 10px;
    flex: 0 0 auto;
    margin-left: auto;
  }

  ${S.field.Title}, ${S.field.Description} {
    margin-bottom: 7px;
  }

  ${S.input.Input} {
    width: 100%;
  }
`
