import * as React from 'react'

import { type Lang } from '@owl-nest/api-client/latest'
import { t, tn, tp } from '@owl-nest/localize'

import { configurationContext } from './configurationContext'

// TODO: FIXME: Avoid duplication
type Configuration = {
  defaultLocale: Lang
  locale: Lang
  translations?: Record<string, unknown>
}

type ConfigurationProviderProps = {
  children: React.ReactNode
  configuration: Configuration
}

export function ConfigurationProvider({
  children,
  configuration,
}: ConfigurationProviderProps): React.ReactElement<ConfigurationProviderProps> {
  return (
    <configurationContext.Provider
      value={{
        defaultLocale: configuration.defaultLocale,
        locale: configuration.locale,
        translations: { ...getTranslations(), ...configuration.translations },
      }}
    >
      {children}
    </configurationContext.Provider>
  )
}

// TODO: Use genericity?
function getTranslations(): Record<string, unknown> {
  return {
    navigationItems: {
      discoverUlule: t('Discover Ulule'),
      seeAllProjects: t('See all projects'),
      startProject: t('Launch a project'),
    },
    searchDrawer: {
      exploreChannels: t('Check out thematic channels'),
      exploreProjects: t('Explore all projects'),
      searchPlaceholder: t('Search for a project or presale'),
      shortSearchPlaceholder: t('Search'),
      soGoodCTA: 'Découvrez le podcast du projet du jour avec',
    },
    userDrawer: {
      contributionsBoxTitle: (contributionCount = 0) =>
        tn('My latest contribution', 'My latest contributions', contributionCount),
      myContributions: tp('My contributions', 'User menu'),
      myMessages: t('My messages'),
      myProfile: t('My profile'),
      myProjects: tp('My projects', 'User menu'),
      noProjectName: t('No title'),
      projectComingSoon: {
        validatedProject: t('Ready to go online'),
        notValidatedProject: t('In progress'),
      },
      projectsBoxTitle: (projectCount = 0) => tn('My project', 'My projects', projectCount),
      seeMore: t('See more'),
      settings: t('Settings'),
      signIn: t('Sign in'),
      signOut: t('Sign out'),
      i18nSettingsLabel: t('Language and site currency'),
    },
  }
}
