import { lens } from '@owl-nest/config'
import { accounting } from '@ulule/owl-kit-components/next'
import * as api from '@owl-nest/api-client/latest'

type Currencies = {
  COUNTRIES: { [s: string]: string }
  CURRENT: string
  RATES: {
    [s: string]: number
  }
  SELECTED?: string
  SYMBOLS: {
    [s: string]: {
      separator: string
      symbol: string
      trigram: string
    }
  }
}

type Window = {
  CURRENCIES: Currencies
  UFE: {
    allowedCurrencies: {
      [code: string]: {
        name: string
        symbol: string
      }
    }
    allowedLanguages: {
      [code: string]: string
    }
    defaultLocale: api.Lang
    locale: api.Lang
    origins?: Record<api.Lang, string>
  }
}

const windowLens = lens<Window>()

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
      }
    : window

const _ALLOWED_CURRENCIES = windowLens.get('UFE').get('allowedCurrencies').value(global, { trust: true })

export const ALLOWED_LANGUAGES = windowLens.get('UFE').get('allowedLanguages').value(global, { trust: true })

export const CURRENCIES = windowLens.get('CURRENCIES').value(global, { trust: true })
accounting.setCurrencies(CURRENCIES.SYMBOLS)

export const ALLOWED_CURRENCIES = Object.entries(CURRENCIES.SYMBOLS).reduce((allowedCurrencies, [code, currency]) => {
  allowedCurrencies[code] = {
    ...currency,
    name: _ALLOWED_CURRENCIES[code].name,
    shortLabel: _ALLOWED_CURRENCIES[code].symbol,
  }
  return allowedCurrencies
}, {} as { [k in keyof Currencies['SYMBOLS']]: Currencies['SYMBOLS'][k] & { name: string; shortLabel: string } })

export const DEFAULT_LOCALE = windowLens.get('UFE').get('defaultLocale').value(global)

export const LOCALE = windowLens.get('UFE').get('locale').value(global, { forgive: true, silent: true })

export const ULULE_ORIGINS = windowLens.get('UFE').get('origins').value(global, { forgive: true, trust: true, silent: true }) ?? {
  fr: '',
  en: '',
  ca: '',
  de: '',
  es: '',
  it: '',
  nl: '',
  pt: '',
}
