import { Project } from '@owl-nest/api-client/latest'

import { AnalyticsTags, apiTagTypeToGTM } from './types'

export function getAnalyticsTags(project?: Project<'analytics'>): AnalyticsTags | undefined {
  const analyticsTags: AnalyticsTags = {}

  if (project === undefined || project.analytics === undefined) {
    return undefined
  }

  for (const { type, tag } of project.analytics) {
    analyticsTags[apiTagTypeToGTM[type]] = tag
  }

  return analyticsTags
}
