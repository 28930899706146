import * as React from 'react'
import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

type DropdownProps = {
  children: React.ReactNode
  className?: string
  open?: boolean
  setAsOpen?: (open: boolean) => void
  title: string
  withCaret?: boolean
}

function DropdownComponent({ open, setAsOpen, ...props }: DropdownProps) {
  if (open !== undefined && setAsOpen !== undefined) {
    return <ControlledDropdown {...props} open={open} setAsOpen={setAsOpen} />
  } else {
    return <UncontrolledDropdown {...props} />
  }
}

function ControlledDropdown({
  children,
  className,
  open,
  setAsOpen,
  title,
  withCaret,
}: DropdownProps & {
  open: boolean
  setAsOpen: (open: boolean) => void
}) {
  return (
    <DropdownWrapper
      active={open}
      className={className}
      onMouseLeave={() => setAsOpen(false)}
      onMouseOver={() => setAsOpen(true)}
    >
      <Title>
        {title}
        {withCaret && <plume.glyphs.stroke.CaretDown size={9} />}
      </Title>
      <Drawer open={open}>{children}</Drawer>
    </DropdownWrapper>
  )
}

function UncontrolledDropdown(props: DropdownProps) {
  const [open, setAsOpen] = React.useState(false)

  return <ControlledDropdown {...props} open={open} setAsOpen={setAsOpen} />
}

export const Dropdown = styled(DropdownComponent)`
  :hover {
    cursor: pointer;
  }
`

function Drawer({ open, children }: { open: boolean; children: React.ReactNode }) {
  const $drawerRef = React.useRef<HTMLUListElement>(null)
  const { onTransitionEnd, step, unmounted } = plume.behaviours.useTransition(open)

  if (unmounted) {
    return null
  }

  return (
    <DrawerContent onTransitionEnd={onTransitionEnd} open={open} ref={$drawerRef} step={step}>
      {children}
    </DrawerContent>
  )
}

const DrawerContent = styled.ul<{ open: boolean; step: plume.behaviours.UseTransition['step'] }>`
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  border-radius: 4px;
  box-shadow: ${plume.EFFECTS.SHADOW_5};
  box-sizing: border-box;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 24px;
  position: absolute;
  top: 43px;
  transition-duration: 300ms;
  transition-property: opacity, visibility;
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  width: max-content;
  z-index: 1;

  ${({ step }) => {
    if (step === 'before') {
      return css`
        opacity: 0;
      `
    }
    if (step === 'during') {
      return css`
        opacity: 1;
      `
    }
    if (step === 'after') {
      return css`
        opacity: 0;
      `
    }
  }}
`

const Title = styled(plume.styles.heading.Card1)`
  align-items: center;
  display: flex;
  justify-content: center;

  ${plume.glyphs.Icon} {
    margin-left: 4px;
  }
`

const DropdownWrapper = styled.li<{ active: boolean }>`
  align-items: center;
  cursor: default;
  display: flex;
  height: 100%;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  position: relative;

  ${Title} {
    color: ${plume.COLORS.PRIMARY_BLACK};
    font-weight: 600; // HACK: Irregular font manipulation
    text-transform: uppercase;
    transition: color 0.3s ease;

    &:hover {
      cursor: pointer;
    }

    ${({ active }) => {
      if (active) {
        return css`
          color: ${plume.COLORS.PRIMARY_BLUE_HOVER} !important;
        `
      }
    }};
`
