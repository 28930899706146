import * as React from 'react'
import * as jotai from 'jotai'

import * as api from '@owl-nest/api-client/latest'
import * as hooks from '@owl-nest/hooks'
import * as models from '@owl-nest/models'

type CategoriesConfig = {
  fetchOnMount?: boolean | 'force'
}

export async function get(): Promise<api.ApiResponse<api.Pageable<api.CategoryList>>> {
  const response = await api.api.get.categories()

  return response.next((success) => success.body)
}

const CATEGORIES_ATOM = jotai.atom<hooks.Cache<api.Pageable<api.CategoryList>>>({})

export function useCategories({ fetchOnMount = true }: CategoriesConfig) {
  const { query: queryCategories, getCurrentResponse } = hooks.useSharedQuery(get, {
    atom: CATEGORIES_ATOM,
    id,
  })

  hooks.useFetchOnMount(
    {
      fetchOnMount,
      query,
      getResponse,
    },
    id(),
  )

  return { response: getResponse(), query }

  function getResponse(): hooks.Response<api.CategoryList> {
    const response = getCurrentResponse([])

    if (response === undefined) {
      return { status: hooks.QueryStatus.PRISTINE }
    }

    if (response.status === hooks.QueryStatus.SUCCESS) {
      const categories = response.data.categories
        .filter((category) => category.slug !== 'other')
        .sort((a, b) => {
          const nameA = models.i18n.get(a.name)
          const nameB = models.i18n.get(b.name)
          if (nameA === undefined && nameB === undefined) {
            return 0
          }
          if (nameA === undefined) {
            return 1
          }
          if (nameB === undefined) {
            return -1
          }
          return nameA.localeCompare(nameB)
        })

      const other = response.data.categories.find((category) => category.slug === 'other')
      if (other !== undefined) {
        categories.push(other)
      }

      return { ...response, data: { ...response.data, categories: categories } } as hooks.Response<api.CategoryList>
    }

    return response as hooks.Response<api.CategoryList>
  }

  function id(): string {
    return JSON.stringify([]) // FIXME?
  }

  async function query() {
    return queryCategories()
  }
}
