import styled, { css, type FlattenInterpolation, type ThemedStyledProps } from 'styled-components'

import * as BREAKPOINT from '../constants/breakpoints'
import * as colors from '../constants/colors'
import * as fonts from '../constants/fonts'

export const _baseStyle = css`
  color: ${colors.PRIMARY_BLACK};
  font-family: ${fonts.PRIMARY_FONT};
  margin: 0;
  padding: 0;
`

export const _xSmallStyle = css`
  font-size: 11px;
  line-height: 16px;
`

export const _smallStyle = css`
  ${_baseStyle}; // added for WYSIWYG content
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`

export const _mediumStyle = css`
  ${_baseStyle}; // added for WYSIWYG content
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
`

export const _largeStyle = css`
  ${_baseStyle}; // added for WYSIWYG content
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
`

export const _serifStyle = css`
  font-size: 18px;
  line-height: 26px;
  font-family: ${fonts.SECONDARY_FONT};
`

const styles = {
  XS: _xSmallStyle,
  S: _smallStyle,
  M: _mediumStyle,
  L: _largeStyle,
  Serif: _serifStyle,
}

export const Base = styled.p<React.HTMLAttributes<HTMLParagraphElement>>`
  ${_baseStyle}
`

type AsDekstopProps = { asDesktop?: keyof typeof styles; breakpoint?: string }

const asDesktopStyle: FlattenInterpolation<ThemedStyledProps<AsDekstopProps, any>> = css<AsDekstopProps>`
  @media screen and ${({ breakpoint = BREAKPOINT.TABLET }) => breakpoint} {
    ${({ asDesktop }) => {
      return asDesktop === undefined ? '' : styles[asDesktop]
    }}
  }
`
type CopyProps = React.HTMLAttributes<HTMLParagraphElement> & AsDekstopProps

export const XS = styled(Base)<CopyProps>`
  ${_xSmallStyle}
  ${asDesktopStyle}
`
XS.displayName = 'S.copy.XS'

export const S = styled(Base)<CopyProps>`
  ${_smallStyle}
  ${asDesktopStyle}
`
S.displayName = 'S.copy.S'

export const M = styled(Base)<CopyProps>`
  ${_mediumStyle}
  ${asDesktopStyle}
`
M.displayName = 'S.copy.M'

export const L = styled(Base)<CopyProps>`
  ${_largeStyle}
  ${asDesktopStyle}
`
L.displayName = 'S.copy.L'

export const Serif = styled(Base)<CopyProps>`
  ${_serifStyle}
  ${asDesktopStyle}
`
Serif.displayName = 'S.copy.Serif'
