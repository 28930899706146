import * as React from 'react'
import styled from 'styled-components'

import * as api from '@owl-nest/api-client/latest'
import { QueryStatus } from '@owl-nest/hooks'
import { t } from '@owl-nest/localize'
import * as plume from '@ulule/owl-kit-components/next'

import { useCurrentUser } from '../../hooks/useCurrentUser'
import * as UFE from '../../utils/UFE'

import { HeaderWrapper } from '../../components/HeaderWrapper'
import { URLProvider } from '../../context/urls/URLProvider'
import { UserDrawerProvider } from '../../context/userDrawer/UserDrawerProvider'

import { MobileOnlyLogo } from '../../components/zones/center/MobileOnlyLogo'
import { Crowdfunding as CrowdfundingLeft } from '../../components/zones/left/Crowdfunding'
import { Crowdfunding } from '../../components/zones/right/Crowdfunding'

import { ConfigurationProvider } from '../../context/configuration/ConfigurationProvider'
import { useURLContext } from '../../context/urls/urlContext'
import { useCategoriesService } from '../../hooks/useCategoriesService'
import { useChannelsService } from '../../hooks/useChannelsService'

import { Toggle } from '../../components/Toggle'
import { Channel, ChannelList } from '../../components/Channel'
import { CrowdfundingSubnav } from '../../subnavs/Crowdfunding'

import * as S from '../../components/style'

import { type HeaderProps } from '../../types'

const CONFIGURATION = {
  defaultLocale: 'fr' as api.Lang,
  locale: 'fr' as api.Lang,
}

export function Header({
  lang = UFE.USER_LOCALE ?? UFE.DEFAULT_LOCALE,
  search = { action: `${UFE.ULULE_ORIGINS[lang]}${UFE.DISCOVER_URL}` },
  enableSubnav = false,
  theme = {
    colors: {
      primary: plume.COLORS.PRIMARY_BLUE,
    },
  },
}: HeaderProps): React.ReactElement<HeaderProps> {
  const { user, loadUser } = useCurrentUser(lang)

  React.useEffect(() => {
    if (enableSubnav) {
      document.body.classList.add('b-header--with-subnav')
    }
  }, [])

  const [isTop, setItTop] = React.useState(true)

  React.useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

    function handleScroll(): void {
      if (window.scrollY > 0) {
        setItTop(false)
      } else {
        setItTop(true)
      }
    }
  }, [])

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  return (
    <UserDrawerProvider lang={lang}>
      <ConfigurationProvider configuration={CONFIGURATION}>
        <URLProvider>
          <HeaderContent isTop={isTop}>
            <HeaderWrapper
              centerZone={<MobileOnlyLogo lang={lang} siteIdentifier="ulule" />}
              leftZone={
                <CrowdfundingLeft
                  siteIdentifier="ulule"
                  isDrawerOpen={isDrawerOpen}
                  setIsDrawerOpen={setIsDrawerOpen}
                  subnavConfig={
                    enableSubnav
                      ? {
                          mobileOnlySection: <SubnavMobileSection lang={lang} />,
                          withSeparator: true,
                        }
                      : undefined
                  }
                />
              }
              rightZone={<Crowdfunding search={search} loadUserExtraData={loadUser} user={user} />}
              theme={theme}
              enableSubnav={enableSubnav}
            />
            {enableSubnav && <CrowdfundingSubnav lang={lang} />}
          </HeaderContent>
        </URLProvider>
      </ConfigurationProvider>
    </UserDrawerProvider>
  )
}

function SubnavMobileSection({ lang }: { lang: api.Lang }) {
  const categoriesService = useCategoriesService()
  const channelsService = useChannelsService()
  const urls = useURLContext()

  const [isCategoriesToggleOpen, setCategoriesToggleAsOpen] = React.useState(false)
  const [isChannelsToggleOpen, setChannelsToggleAsOpen] = React.useState(false)

  const isDiscoverPage = React.useMemo(() => document.location.pathname.startsWith('/discover'), [])

  React.useEffect(() => {
    if (categoriesService.response.status === QueryStatus.PRISTINE && isCategoriesToggleOpen) {
      categoriesService.query()
    }
    if (channelsService.response.status === QueryStatus.PRISTINE && isChannelsToggleOpen) {
      channelsService.query()
    }
  }, [isCategoriesToggleOpen, isChannelsToggleOpen])

  return (
    <>
      <LinkListItem>
        <S.LinkItem $active={isDiscoverPage} href={urls.discover}>
          {t('See all projects')}
        </S.LinkItem>
      </LinkListItem>
      <CategoryToggle open={isCategoriesToggleOpen} setAsOpen={setCategoriesToggleAsOpen} title={t('Categories')}>
        {categoriesService.response.status === QueryStatus.SUCCESS ? (
          <>
            {categoriesService.response.data.categories.map((category) => {
              return (
                <CategoryItem key={category.id}>
                  <plume.Link href={category.absolute_url}>
                    <plume.styles.copy.S>{category.name[lang]}</plume.styles.copy.S>
                  </plume.Link>
                </CategoryItem>
              )
            })}
          </>
        ) : (
          <plume.Spinner />
        )}
      </CategoryToggle>
      <Toggle open={isChannelsToggleOpen} setAsOpen={setChannelsToggleAsOpen} title={t('Channels')}>
        <plume.styles.heading.XXXXXS>{t('Staff picks')}</plume.styles.heading.XXXXXS>
        <ChannelList>
          {channelsService.response.status === QueryStatus.SUCCESS ? (
            <>
              {channelsService.response.data.channels.map((channel) => (
                <Channel key={channel.absolute_url} channel={channel} />
              ))}
            </>
          ) : (
            <plume.Spinner />
          )}
        </ChannelList>
      </Toggle>
    </>
  )
}

const LinkListItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const HeaderContent = styled(S.HeaderContent)`
  ${CrowdfundingSubnav} {
    display: none;
  }

  @media screen and ${S.INTERMEDIATE_BREAKPOINT} {
    ${CrowdfundingSubnav} {
      display: flex;
    }

    ${S.LeftZone} {
      flex: auto;
    }

    ${S.CenterZone} {
      display: none;
    }

    ${S.RightZone} {
      flex: auto;
    }
  }
`

const CategoryItem = styled.li`
  margin-bottom: 12px;
  margin-left: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`

const CategoryToggle = styled(Toggle)`
  *:last-child {
    margin-bottom: 0;
  }
`
