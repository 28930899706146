import * as api from '@owl-nest/api-client/latest'
import { lens } from '@owl-nest/config'
import { accounting } from '@ulule/owl-kit-components/next'

type Currencies = {
  COUNTRIES: { [s: string]: string }
  RATES: {
    [s: string]: number
  }
  SYMBOLS: {
    [s: string]: {
      separator: string
      symbol: string
      trigram: string
    }
  }
}

type URLs = {
  channels: string
  discover: string
  discoverLandingPage: string
  discussions: string
  home: string
  proposal: string
  signin: string
  signout: string
}

type Window = {
  CURRENCIES: Currencies
  UFE: {
    allowedCurrencies: {
      [code: string]: {
        name: string
        symbol: string
      }
    }
    allowedLanguages: {
      [code: string]: string
    }
    defaultLocale: api.Lang
    locale: api.Lang
    urls: URLs
    user: api.AuthenticatedUser | { is_authenticated: false }
    origins?: Record<api.Lang, string>
  }
}

const windowLens = lens<Window>()

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
      }
    : window

export const CURRENCIES = windowLens.get('CURRENCIES').value(global, { trust: true })
accounting.setCurrencies(CURRENCIES.SYMBOLS)

export const DEFAULT_LOCALE = windowLens.get('UFE').get('defaultLocale').value(global)

export const USER_LOCALE = windowLens.get('UFE').get('locale').value(global, { forgive: true, silent: true })

export const USER = windowLens.get('UFE').get('user').value(global, { forgive: true, trust: true, silent: true })

// URLs

export const CHANNELS_URL = windowLens.get('UFE').get('urls').get('channels').value(global)

export const DISCOVER_URL = windowLens.get('UFE').get('urls').get('discover').value(global)

export const DISCOVER_LANDING_URL = windowLens.get('UFE').get('urls').get('discoverLandingPage').value(global)

export const DISCUSSIONS_URL = windowLens.get('UFE').get('urls').get('discussions').value(global, { forgive: true })

export const HOMEPAGE_URL = windowLens.get('UFE').get('urls').get('home').value(global)

export const PROPOSAL_URL = windowLens.get('UFE').get('urls').get('proposal').value(global)

export const SIGNIN_URL = windowLens.get('UFE').get('urls').get('signin').value(global)

export const SIGNOUT_URL = windowLens.get('UFE').get('urls').get('signout').value(global)

export const ULULE_ORIGINS = windowLens
  .get('UFE')
  .get('origins')
  .value(global, { silent: true, trust: true, forgive: true }) ?? {
  fr: '',
  en: '',
  ca: '',
  de: '',
  es: '',
  it: '',
  nl: '',
  pt: '',
}

const _ALLOWED_CURRENCIES = windowLens.get('UFE').get('allowedCurrencies').value(global, { trust: true })

export const ALLOWED_LANGUAGES = windowLens.get('UFE').get('allowedLanguages').value(global, { trust: true })

export const ALLOWED_CURRENCIES = Object.entries(CURRENCIES.SYMBOLS).reduce((allowedCurrencies, [code, currency]) => {
  allowedCurrencies[code] = {
    ...currency,
    name: _ALLOWED_CURRENCIES[code].name,
    shortLabel: _ALLOWED_CURRENCIES[code].symbol,
  }
  return allowedCurrencies
}, {} as { [k in keyof Currencies['SYMBOLS']]: Currencies['SYMBOLS'][k] & { name: string; shortLabel: string } })
