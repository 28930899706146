import * as React from 'react'
import styled from 'styled-components'

import * as select from '../../../behaviours/select'
import { useScrollContext, useClientSideContext } from './context'
import { isMobile } from '../../../utils/matchMediaOnBrowser'
import { scrollToVisibility } from '../../../utils/scrollToVisibility'
import * as S from '../../../styles'

export type OptionProps = {
  title?: string
  /** @default false */
  disabled?: boolean
  /** string respresentation of the label (used in search/filtering) */
  label: string
  /** value of the option */
  value: any
  /** @default () => {} */
  onToggle?: (selected: boolean) => void
  /** @default () => {} */
  onFocus?: () => void
  /** markup of the label (only used for presentation) */
  children?: ((props: ChildrenProps) => React.ReactNode) | React.ReactNode
  className?: string
  native?: boolean
}

type ChildrenProps = { isFocused: boolean; isSelected: boolean; isFiltered: boolean }

function OptionComponent({
  disabled = false,
  title,
  label,
  value,
  onToggle = () => {},
  onFocus = () => {},
  children,
  className,
  native,
}: OptionProps): React.ReactElement<OptionProps> {
  const isClientSide = useClientSideContext()

  if (native && isMobile() && isClientSide) {
    return (
      <option value={value} label={label} disabled={disabled}>
        {label}
      </option>
    )
  }

  const ref = React.useRef<HTMLLIElement>(null)
  const scrollRef = useScrollContext()

  function handleFocus(): void {
    onFocus && onFocus()
    scrollToVisibility(scrollRef?.current ?? null, ref.current)
  }

  const { isSelected, multiple, toggle, isFocused, focus, isFiltered } = select.useOption({
    value,
    label,
    disabled,
    handleToggle: onToggle,
    handleFocus,
  })

  let status: 'default' | 'selected' | 'disabled' | 'disabled-selected' = 'default'
  if (isSelected) {
    status = 'selected'
  }
  if (disabled) {
    status = 'disabled'
  }
  if (isSelected && disabled) {
    status = 'disabled-selected'
  }

  return (
    <OptionInner
      ref={ref}
      title={title}
      status={status}
      className={className}
      multiple={multiple}
      value={value}
      focus={focus}
      isFocused={isFocused}
      isSelected={isSelected}
      isFiltered={isFiltered}
      toggle={toggle}
    >
      {children ?? label}
    </OptionInner>
  )
}

/** Usually used with `<Select>` */
export const Option = styled(OptionComponent)<OptionProps>``
Option.displayName = 'Option'

type OptionInnerProps = {
  title?: string
  status: 'default' | 'selected' | 'disabled' | 'disabled-selected'
  className?: string
  multiple: boolean
  children: ((props: ChildrenProps) => React.ReactNode) | React.ReactNode
  value: any
  focus: (identifier: any) => void
  isFocused: boolean
  isSelected: boolean
  isFiltered: boolean
  toggle: () => void
}

const OptionInner = React.memo(
  React.forwardRef<HTMLLIElement, OptionInnerProps>(function OptionInner(
    { value, title, isFocused, isSelected, isFiltered, focus, toggle, className, multiple, children, status },
    ref,
  ) {
    const renderedChildren =
      typeof children === 'function' ? (children as any)({ isFocused, isSelected, isFiltered }) : children
    return (
      <S.option.Wrapper
        ref={ref}
        title={title}
        focused={isFocused}
        onMouseMove={() => focus(value)}
        onFocus={() => focus(value)}
        onClick={toggle}
        tabIndex={-1}
        className={className}
        filtered={isFiltered}
      >
        {multiple ? <S.option.MultiSelectedIcon status={status} /> : <S.option.MonoSelectedIcon status={status} />}
        <S.option.Label status={status}>{renderedChildren}</S.option.Label>
      </S.option.Wrapper>
    )
  }),
)

OptionInner.displayName = 'OptionInner'
