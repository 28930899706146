import { Project } from '@owl-nest/api-client/latest'

import { getAnalyticsTags } from '../analyticsTags'
import { SafePush } from '../safePush'
import { SocialTracking } from './types'
import { EVENT_TYPE, SocialEvent } from '../types'

export function tracking(safePush: SafePush, project?: Project<'analytics'>): SocialTracking {
  const analyticsTags = getAnalyticsTags(project)

  return {
    track: ({
      eventAction = undefined,
      eventLabel = undefined,
      eventCategory = undefined,
      eventValue = undefined,
      ...params
    }) => {
      const socialEvent: SocialEvent = {
        ...params,
        event: EVENT_TYPE.SOCIAL,
        eventAction,
        eventLabel,
        eventCategory,
        eventValue,
        ...(analyticsTags !== undefined && { poTagIDs: analyticsTags }),
      }

      if (params.socialNetwork === 'Ulule') {
        if (project === undefined) {
          console.error('[shadow] social tracking was not initialized (need project)')
          return
        }
        socialEvent.ululeProjectId = project.id
      }

      safePush(socialEvent)
    },
  }
}
