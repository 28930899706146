import * as api from '@owl-nest/api-client/latest'
import { AuthAction, InitialState, AuthState, TYPE } from './types'

export function getInitialState(): InitialState {
  return {
    type: 'initial',
    isInitializing: false,
  }
}

export function reducer(state: AuthState = getInitialState(), action: AuthAction): AuthState {
  switch (action.type) {
    case TYPE.REFRESH_START: {
      if (state.type !== 'loggedin' && state.type !== 'loggedin-as-guest') {
        return state
      }
      return {
        type: 'loggedin',
        extraFields: state.extraFields,
        isLoggingOut: false,
        authenticatedUser: state.authenticatedUser,
        isRefreshing: true,
      }
    }
    case TYPE.INITIALIZE_START: {
      return { type: 'initial', isInitializing: true }
    }
    case TYPE.INITIALIZE_FAILURE:
    case TYPE.REFRESH_FAILURE: {
      return { type: 'unknown', failure: action.failure, isIdentifying: false }
    }
    case TYPE.INITIALIZE_SUCCESS:
    case TYPE.REFRESH_SUCCESS: {
      switch (action.success.type) {
        case 'loggedin':
        case 'loggedin-as-guest':
          return {
            type: action.success.type,
            extraFields: action.success.extraFields,
            authenticatedUser: action.success.user as api.AuthenticatedUser, // needed because of EXTRA_FIELDS wreking havoc
            isLoggingOut: false,
            isRefreshing: false,
          }
        case 'known':
          return {
            type: 'known',
            isLoggingIn: false,
            knownUser: action.success.user,
          }
        case 'unknown':
          return { type: 'unknown', isIdentifying: false }
      }
      return state
    }

    case TYPE.IDENTIFY_START: {
      return { type: 'unknown', isIdentifying: true }
    }
    case TYPE.IDENTIFY_FAILURE: {
      return { type: 'unknown', failure: action.failure, isIdentifying: false }
    }
    case TYPE.IDENTIFY_EXISTING: {
      return { type: 'existing', service: action.service, identifier: action.identifier, isLoggingIn: false }
    }
    case TYPE.IDENTIFY_NEW: {
      return { type: 'new', identifier: action.identifier, isSigningUp: false, isGuestLoggingIn: false }
    }

    case TYPE.FORGET: {
      return { type: 'unknown', isIdentifying: false }
    }

    case TYPE.SIGNUP_START: {
      if (state.type !== 'new') {
        return state
      }
      return { type: 'new', identifier: state.identifier, isSigningUp: true, isGuestLoggingIn: false }
    }
    case TYPE.SIGNUP_FAILURE: {
      if (state.type !== 'new') {
        return state
      }
      return {
        type: 'new',
        identifier: state.identifier,
        isSigningUp: false,
        failure: action.failure,
        isGuestLoggingIn: false,
      }
    }
    case TYPE.SIGNUP_SUCCESS: {
      return {
        type: 'loggedin',
        extraFields: [],
        isLoggingOut: false,
        authenticatedUser: action.success,
        isRefreshing: false,
      }
    }

    case TYPE.LOGIN_START: {
      if (state.type === 'existing') {
        return { type: 'existing', service: state.service, identifier: state.identifier, isLoggingIn: true }
      }
      if (state.type === 'known') {
        return { type: 'known', knownUser: state.knownUser, isLoggingIn: true }
      }
      return state
    }
    case TYPE.LOGIN_FAILURE: {
      if (state.type === 'existing') {
        return {
          type: 'existing',
          service: state.service,
          identifier: state.identifier,
          isLoggingIn: false,
          failure: action.failure,
        }
      }
      if (state.type === 'known') {
        return {
          type: 'known',
          knownUser: state.knownUser,
          isLoggingIn: false,
          failure: action.failure,
        }
      }
      return state
    }
    case TYPE.LOGIN_SUCCESS: {
      return {
        type: 'loggedin',
        extraFields: action.success.extraFields,
        isLoggingOut: false,
        authenticatedUser: action.success,
        isRefreshing: false,
      }
    }

    case TYPE.GUEST_LOGIN_START: {
      if (state.type !== 'new') {
        return state
      }

      return { type: 'new', identifier: state.identifier, isSigningUp: false, isGuestLoggingIn: true }
    }
    case TYPE.GUEST_LOGIN_FAILURE: {
      if (state.type !== 'new') {
        return state
      }
      return {
        type: 'new',
        identifier: state.identifier,
        isSigningUp: false,
        failure: action.failure,
        isGuestLoggingIn: false,
      }
    }
    case TYPE.GUEST_LOGIN_SUCCESS: {
      return {
        type: 'loggedin-as-guest',
        extraFields: action.success.extraFields,
        isLoggingOut: false,
        authenticatedUser: action.success,
        isRefreshing: false,
      }
    }

    case TYPE.LOGOUT_START: {
      if (state.type !== 'loggedin' && state.type !== 'loggedin-as-guest') {
        return state
      }
      return {
        type: state.type === 'loggedin' ? 'loggedin' : 'loggedin-as-guest',
        extraFields: state.extraFields,
        isLoggingOut: true,
        authenticatedUser: state.authenticatedUser,
        isRefreshing: false,
      }
    }
    case TYPE.LOGOUT_FAILURE: {
      if (state.type !== 'loggedin' && state.type !== 'loggedin-as-guest') {
        return state
      }
      return {
        type: state.type === 'loggedin' ? 'loggedin' : 'loggedin-as-guest',
        extraFields: state.extraFields,
        isLoggingOut: false,
        authenticatedUser: state.authenticatedUser,
        failure: action.failure,
        isRefreshing: false,
      }
    }
    case TYPE.LOGOUT_SUCCESS: {
      return { type: 'unknown', isIdentifying: false }
    }

    case TYPE.UPDATE_REQUEST: {
      if (state.type !== 'loggedin') {
        return state
      }
      return {
        type: 'loggedin',
        extraFields: state.extraFields,
        isLoggingOut: false,
        authenticatedUser: state.authenticatedUser,
        isRefreshing: false,
      }
    }
    case TYPE.UPDATE_SUCCESS: {
      if (state.type !== 'loggedin') {
        return state
      }

      return {
        type: 'loggedin',
        extraFields: state.extraFields,
        isLoggingOut: false,
        authenticatedUser: action.success,
        isRefreshing: false,
      }
    }
    case TYPE.UPDATE_FAILURE: {
      if (state.type !== 'loggedin') {
        return state
      }

      return {
        type: 'loggedin',
        extraFields: state.extraFields,
        isLoggingOut: false,
        failure: action.failure,
        authenticatedUser: state.authenticatedUser,
        isRefreshing: false,
      }
    }

    case TYPE.SYNC: {
      return action.state
    }

    default: {
      return state
    }
  }
}
