import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles/spinner'

type SpinnerProps = React.HTMLAttributes<HTMLDivElement> & {
  /** @default false */
  small?: boolean
}

function SpinnerComponent({ small = false, ...divProps }: SpinnerProps): React.ReactElement<SpinnerProps> {
  return (
    <S.Spinner {...divProps}>
      <S.Dot small={small} delay={0} />
      <S.Dot small={small} delay={160} />
      <S.Dot small={small} delay={320} />
    </S.Spinner>
  )
}

export const Spinner = styled(SpinnerComponent)<SpinnerProps>``
Spinner.displayName = 'Spinner'
