import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'

export type JewelProps = {
  children?: React.ReactNode
  number: number
}

function JewelComponent({ children, number }: JewelProps): React.ReactElement<JewelProps> {
  return (
    <S.jewel.Wrapper>
      {children}
      <S.jewel.Bullet>{number}</S.jewel.Bullet>
    </S.jewel.Wrapper>
  )
}

export const Jewel = styled(JewelComponent)<JewelProps>``
Jewel.displayName = 'Jewel'
