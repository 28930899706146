import styled, { css } from 'styled-components'

import * as colors from '../constants/colors'
import * as effects from '../constants/neue/effects'
import { Icon } from '../icons/glyphs'

export const DropdownStyle = css`
  background-color: ${colors.PRIMARY_WHITE};
  box-shadow: ${effects.SHADOW_1};
`

export const Dropdown = styled.div`
  ${DropdownStyle}
`

export const MenuDropdownTitle = styled.div<{ isOpen: boolean }>`
  ${Icon} {
    margin-right: 7px;
  }

  a {
    ${({ isOpen }) => {
      if (isOpen) {
        return css`
          color: ${colors.PRIMARY_BLUE};
        `
      }
    }};
  }
`
