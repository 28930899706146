import * as React from 'react'
import { Header as UniversalHeader } from './src/flavours/main'
import { Header as InternationalHeader } from './src/flavours/international'
export * as boutique from './src/flavours/boutique'

import * as UFE from './src/utils/UFE'

export * as styles from './src/components/style'

import { type HeaderProps } from './src/types'

export { ConfigurationProvider } from './src/context/configuration/ConfigurationProvider'
export { UserDrawerProvider } from './src/context/userDrawer/UserDrawerProvider'
export { SearchProductDrawer } from './src/components/drawers/SearchProductDrawer'
export * as subnavStyles from './src/subnavs/style'

export function Header(props: HeaderProps): any {
  const lang = props.lang || UFE.USER_LOCALE
  if (lang === 'fr') {
    return <UniversalHeader {...props} />
  }

  return <InternationalHeader {...props} />
}

export function LegacyCompatibleHeader(props: HeaderProps): any {
  return Header(props)
}
