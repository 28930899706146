import * as base from './base.ts'
import * as queryParser from './queryParser.ts'

export const FILTERS = [
  { name: 'sizes', attribute: 'sizes' },
  { name: 'colors', attribute: 'colors' },
  { name: 'brands', attribute: 'brand' },
  { name: 'socialPillars', attribute: 'tags' },
  { name: 'environmentalPillars', attribute: 'tags' },
  { name: 'types', attribute: 'tags' },
  { name: 'genders', attribute: 'tags' },
  { name: 'deal', attribute: 'hasCompareAtPrice' },
  { name: 'category', attribute: 'tags' },
] as const

export const ARRAY_FILTERS = [
  'sizes',
  'colors',
  'brands',
  'socialPillars',
  'environmentalPillars',
  'types',
  'genders',
] as const

export type ArrayFilter = Record<(typeof ARRAY_FILTERS)[number], string[]>

export type BooleanFilter = {
  deal: boolean
}

export type StringFilter = {
  category: string
}

export type Filters = ArrayFilter & BooleanFilter & StringFilter

export type Query = base.Query<Filters> & {
  fullTextSearch?: string
  searchableTag?: string
  hierarchy?: string
}

export type Result = base.Result<Filters>

export function toUrlSearchParams(query: Query): URLSearchParams {
  const params = base.toUrlSearchParams(query)

  if (query.facetFilters?.category) {
    params.set('category', query.facetFilters.category)
  }
  if (query.facetFilters?.sizes && query.facetFilters.sizes.length > 0) {
    params.set('sizes', query.facetFilters.sizes.join(','))
  }
  if (query.facetFilters?.colors && query.facetFilters.colors.length > 0) {
    params.set('colors', query.facetFilters.colors.join(','))
  }
  if (query.facetFilters?.deal) {
    params.set('hasCompareAtPrice', 'true')
  }
  if (query.facetFilters?.environmentalPillars && query.facetFilters.environmentalPillars.length > 0) {
    params.set('environmentalPillar', query.facetFilters.environmentalPillars.join(','))
  }
  if (query.facetFilters?.socialPillars && query.facetFilters.socialPillars.length > 0) {
    params.set('socialPillar', query.facetFilters.socialPillars.join(','))
  }
  if (query.facetFilters?.types && query.facetFilters.types.length > 0) {
    params.set('types', query.facetFilters.types.join(','))
  }
  if (query.facetFilters?.genders && query.facetFilters.genders.length > 0) {
    params.set('gender', query.facetFilters.genders.join(','))
  }
  if (query.facetFilters?.brands && query.facetFilters.brands.length > 0) {
    params.set('brands', query.facetFilters.brands.join(','))
  }

  if (query.fullTextSearch) {
    params.set('q', query.fullTextSearch)
  }
  if (query.searchableTag) {
    params.set('searchableTag', query.searchableTag)
  }
  if (query.hierarchy) {
    params.set('hierarchy', query.hierarchy)
  }

  params.sort()

  return params
}

export function fromParsedUrlQuery(query: Record<string, string | string[] | undefined>): Query {
  return {
    ...base.fromParsedUrlQuery(query),
    facetFilters: {
      category: queryParser.string(query.category),
      sizes: queryParser.array(query.sizes),
      colors: queryParser.array(query.colors),
      brands: queryParser.array(query.brands),
      socialPillars: queryParser.array(query.socialPillar),
      environmentalPillars: queryParser.array(query.environmentalPillar),
      deal: queryParser.boolean(query.hasCompareAtPrice),
      types: queryParser.array(query.types),
      genders: queryParser.array(query.gender),
    },
    fullTextSearch: queryParser.string(query.q),
    searchableTag: queryParser.string(query.searchableTag),
    hierarchy: queryParser.string(query.hierarchy),
  }
}

export function resetFilters(query: Query): Query {
  return {
    ...query,
    facetFilters: { category: query.facetFilters?.category },
    priceRange: undefined,
    page: undefined,
  }
}

export function setFilter<FILTER extends keyof Filters>(query: Query, key: FILTER, value: Filters[FILTER]): Query {
  const nextQuery = {
    ...query,
    facetFilters: { ...query.facetFilters, [key]: value },
    page: undefined,
  }

  if (key === 'category') {
    return resetFilters(nextQuery)
  }

  return nextQuery
}

export function getFilterCount(query: Query): number {
  return Object.entries(query.facetFilters ?? {}).reduce((count, [key, value]) => {
    // exclude category from filter count
    if (key === 'category') {
      return count
    }
    return count + (Array.isArray(value) ? value.length : value !== undefined ? 1 : 0)
  }, 0)
}
