import styled from 'styled-components'

import * as fonts from '../../constants/fonts'

export const Bullet = styled.div<{ color: string }>`
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;

  > img {
    display: block;
    width: 45%;
  }
`

export const Title = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 20px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

export const Wrapper = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-decoration: none;
  transition: opacity 250ms ease-out;

  &:hover {
    opacity: 0.9;
  }
`
