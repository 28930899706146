import styled, { css, keyframes } from 'styled-components'

import * as COLORS from '../constants/colors'

const background = COLORS.GREY_SHADE_5

const sweepAnimation = keyframes`
    0% { background-position: -600px 0; }
    100% { background-position: 600px 0; }
`

export const skeletonAnimationRules = css`
  animation: ${sweepAnimation} 1s infinite linear forwards;
  background: ${background};
  background-image: linear-gradient(
    to right,
    ${background},
    ${COLORS.GREY_SHADE_6} 20%,
    ${background} 40%,
    ${background} 100%
  );
  background-repeat: no-repeat;
`

type CircleSkeletonProps = {
  radius?: number
}

export const Circle = styled.div<CircleSkeletonProps>`
  background-colors: ${background};
  border-radius: 50%;

  height: ${({ radius = 32 }) => radius}px;
  width: ${({ radius = 32 }) => radius}px;

  ${skeletonAnimationRules}
`

type RectangleSkeletonProps = {
  height?: number
}

export const Rectangle = styled.div<RectangleSkeletonProps>`
  background-colors: ${background};

  height: ${({ height = 32 }) => height}px;
  width: 100%;

  ${skeletonAnimationRules}
`

export const SquareCard = styled.div`
  border-radius: 12px;
  background-colors: ${background};
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  min-height: 270px;
  min-width: 270px;

  ${skeletonAnimationRules}
`

export const Tag = styled.div`
  background-colors: ${background};
  border-radius: 12px;
  height: 20px;
  width: 32%;

  & + * {
    margin-top: 10px;
  }

  ${skeletonAnimationRules}
`

type TextSkeletonProps = {
  size?: 'small' | 'medium' | 'large'
  width?: number
}

export const Text = styled.div<TextSkeletonProps>`
  background-colors: ${background};
  width: ${({ width = 100 }) => width}%;

  ${({ size = 'medium' }) => {
    if (size === 'large') {
      return css`
        height: 40px;
      `
    } else if (size === 'small') {
      return css`
        height: 10px;
      `
    }

    return css`
      height: 20px;
    `
  }}
  & + & {
    margin-top: 10px;
  }

  ${skeletonAnimationRules}
`
