import * as React from 'react'
import Lottie from 'react-lottie-player'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import blinkAnimation from './ulule-logo-blink.json'

export function UluleLogo(): React.ReactElement {
  const [playAnimation, setPlayAnimation] = React.useState(false)
  const [goToFrame, setGoToFrame] = React.useState(0)

  return (
    <>
      <DesktopOnly onMouseOver={startAnimation} onMouseOut={stopAnimation}>
        <plume.logos.ulule.OwlOnlyLogo />
        <Lottie
          animationData={blinkAnimation}
          className="ul-animated-logo"
          loop={false}
          goTo={goToFrame}
          play={playAnimation}
        />
        <plume.logos.ulule.TextOnlyLogo width={60} />
      </DesktopOnly>
      <MobileOnly onMouseOver={startAnimation} onMouseOut={stopAnimation}>
        <Lottie animationData={blinkAnimation} loop goTo={goToFrame} play={playAnimation} style={{ width: 37 }} />
      </MobileOnly>
      <HiddenTitle>Ulule</HiddenTitle>
    </>
  )

  function stopAnimation() {
    setPlayAnimation(false)
    setGoToFrame(0)
  }

  function startAnimation() {
    setPlayAnimation(true)
  }
}

const DesktopOnly = styled.span`
  display: none;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    align-items: center;
    display: flex;

    ${plume.logos.ulule.OwlOnlyLogo} {
      height: 33px;
      width: 34px;
    }

    // HACK: The Lottie player component doesn't support "styled" natively, so we force overriding values.
    ${plume.logos.ulule.OwlOnlyLogo} + .ul-animated-logo {
      left: 0;
      margin-left: -37px;
      position: relative;
      right: 0;
      top: 1px;
      vertical-align: middle;
      width: 39px;
    }

    ${plume.logos.ulule.TextOnlyLogo} {
      align-self: baseline;
      height: 38px;
      margin-left: 4px;
      margin-bottom: 5px;
      width: 68px;
    }
  }
`

const MobileOnly = styled.span`
  display: flex;
  max-width: 100px;

  svg {
    width: 31px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: none;
  }
`

const HiddenTitle = styled.span`
  left: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
`
