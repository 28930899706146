import * as React from 'react'

import { useCommittedRef } from '@ulule/owl-kit-components/src/hooks'

export function useEventListener<ELEMENT extends Window, EVENT extends keyof WindowEventMap>(
  element: ELEMENT,
  event: EVENT,
  handler: (event: WindowEventMap[EVENT]) => any,
): void
export function useEventListener<ELEMENT extends HTMLElement, EVENT extends keyof HTMLElementEventMap>(
  element: ELEMENT,
  event: EVENT,
  handler: (event: HTMLElementEventMap[EVENT]) => any,
): void {
  const handlerRef = useCommittedRef(handler)

  window.addEventListener

  React.useEffect(() => {
    const handler = (event: HTMLElementEventMap[EVENT]): any => handlerRef.current(event)
    element.addEventListener(event, handler)
    return () => {
      element.removeEventListener(event, handler)
    }
  }, [event, element])
}
