import * as router from 'next/router'
import * as api from '@owl-nest/api-client/latest'

export type NextRouter = Omit<router.NextRouter, 'locale' | 'locales' | 'defaultLocale'> & {
  locale: api.Lang
  locales: api.Lang[]
  defaultLocale: 'en'
}

export function useNextRouter(): NextRouter {
  return router.useRouter() as NextRouter
}
