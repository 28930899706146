import * as React from 'react'
import styled, { css } from 'styled-components'

import * as ds from '@ulule/owl-kit-components/next'

type ArrowedLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>

function ArrowedLinkComponent({
  children,
  className,
  href,
  ...rest
}: ArrowedLinkProps): React.ReactElement<ArrowedLinkProps> {
  return (
    <a {...rest} className={className} href={href}>
      <ArrowedLinkCore>{children}</ArrowedLinkCore>
    </a>
  )
}

type ArrowedLinkAsButton = React.ButtonHTMLAttributes<HTMLButtonElement>

function ArrowedLinkComponentAsButton({
  children,
  className,
  ...rest
}: ArrowedLinkAsButton): React.ReactElement<ArrowedLinkAsButton> {
  return (
    <button {...rest} className={className}>
      <ArrowedLinkCore>{children}</ArrowedLinkCore>
    </button>
  )
}

function ArrowedLinkCore({ children }: { children: React.ReactNode }): React.ReactElement {
  return (
    <>
      {children}
      <ds.glyphs.stroke.ArrowRight size={14} />
    </>
  )
}

const EASE_IN_OUT_EXPO = 'cubic-bezier(0.79, 0.14, 0.15, 0.86)'

export const _arrowedLinkHoverStyle = css`
  color: ${ds.COLORS.PRIMARY_BLUE_HOVER};

  ${ds.glyphs.Icon} {
    transform: translate3d(6px, -1px, 0);
  }
`

const _arrowedLinkStyle = css`
  color: ${ds.COLORS.PRIMARY_BLACK};
  cursor: pointer;
  font-family: ${ds.FONTS.PRIMARY_FONT};
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;

  ${ds.glyphs.Icon} {
    margin-left: 3px;
    transform: translate3d(0, -1px, 0);
    transition: transform 200ms ${EASE_IN_OUT_EXPO};
  }

  &:hover {
    ${_arrowedLinkHoverStyle};
  }
`

export const ArrowedLink = styled(ArrowedLinkComponent)`
  ${_arrowedLinkStyle}
`

export const ArrowedLinkAsButton = styled(ArrowedLinkComponentAsButton)`
  ${_arrowedLinkStyle};
  background: none;
  border: none;
  padding: 0;
  text-align: left;
`
