import styled from 'styled-components'

import * as breakpoints from '../../constants/breakpoints'
import * as colors from '../../constants/colors'
import * as effects from '../../constants/neue/effects'
import * as heading from '../heading'

export const MiniTitle = styled(heading.Card1)`
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  bottom: 10px;
  color: ${colors.PRIMARY_WHITE};
  display: -webkit-box;
  overflow: hidden;
  position: absolute;
  width: 80%;
  word-break: normal;
`

export const MiniWrapper = styled.a<{ backgroundColor?: string; image?: string }>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? `${backgroundColor}` : `${colors.PRIMARY_WHITE}`)};
  background-image: ${({ image }) => (image ? `url(${image})` : undefined)};
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 80px;
  max-width: 110px;
  min-width: 72px;
  padding: 10px;
  position: relative;

  &::before {
    background: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 100%);
    border-radius: 4px;
    bottom: 0;
    content: '';
    height: 80px;
    left: 0;
    position: absolute;
    right: 0;
  }

  &:hover {
    box-shadow: ${effects.SHADOW_4};
  }

  @media screen and ${breakpoints.TABLET} {
    width: 104px;
  }
`
