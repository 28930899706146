import styled, { createGlobalStyle, css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'
import { Dropdown } from './Dropdown'
import { Title as ToggleTitle } from './Toggle'

type Theme = {
  colors: {
    primary: string
  }
}
const UL_APP_ID =
  typeof window !== 'undefined' &&
  // @ts-expect-error Property is not defined on Window
  window.UL_APP_ID === 'partners' &&
  // @ts-expect-error Property is not defined on Window
  window.UL_APP_ID === 'training' &&
  // @ts-expect-error Property is not defined on Window
  window.UL_APP_ID === 'toolbox'
export const INTERMEDIATE_BREAKPOINT = UL_APP_ID ? plume.BREAKPOINTS.LAPTOP : '(min-width: 876px)'
export const LARGE_BREAKPOINT = '(min-width: 1120px)'

export const DesktopOnlyNav = styled.nav`
  display: none;

  svg {
    vertical-align: initial;
  }

  @media screen and ${INTERMEDIATE_BREAKPOINT} {
    display: flex;
    height: 100%;

    & > * {
      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }
`

export const MobileOnlyNav = styled.nav`
  @media screen and ${INTERMEDIATE_BREAKPOINT} {
    display: none;
  }
`

export const HeaderContent = styled.nav<{ isTop?: boolean }>`
  position: sticky;
  top: 0;
  transition: 0.3s;
  z-index: ${plume.ZINDEX.HEADER};

  ${({ isTop }) => {
    if (isTop === false) {
      return css`
        box-shadow: ${plume.EFFECTS.SHADOW_3};
      `
    }
  }}
`

export const HeaderWrapper = styled.div<{ enableSubnav?: boolean; theme: Theme; smallBorder?: boolean }>`
  align-items: center;
  background-color: ${plume.COLORS.PRIMARY_WHITE};
  border-bottom: 3px solid ${(props) => plume.COLORS.primary(props)};
  box-sizing: border-box;
  color: ${plume.COLORS.PRIMARY_BLACK};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: ${plume.FONTS.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  height: 60px;
  justify-content: space-between;
  padding: 0 20px;

  ${({ enableSubnav = false }) => {
    if (enableSubnav) {
      return css`
        ${CommonMobileEntries} ${ToggleTitle},
        ${Dropdown} ${plume.styles.heading.Card1},
        input::placeholder, ${plume.glyphs.stroke.Search},
        ${MainNavLink}, ${MainNavLink} > span, ${MainNavLinkItem}, ${plume.styles.menu.Title} {
          color: ${plume.COLORS.GREY_SHADE_2};
        }

        @media not screen and ${INTERMEDIATE_BREAKPOINT} {
          ${plume.glyphs.stroke.Person}, ${plume.glyphs.stroke.Search} {
            color: ${plume.COLORS.PRIMARY_BLACK};
          }
        }
      `
    }
  }}

  ${({ smallBorder }) => {
    if (smallBorder) {
      return css`
        border-bottom: 1px solid ${plume.COLORS.GREY_SHADE_5};
      `
    }
  }}
`

export const LinkItem = styled(plume.Link)<{ $active?: boolean }>`
  align-items: center;
  display: flex;
  font-size: 13px; // HACK: Irregular font manipulation
  font-weight: 600; // HACK: Irregular font manipulation
  text-transform: uppercase;
  transition: color 0.3s ease;
  width: max-content;

  ${({ $active = false }) => {
    if ($active) {
      return css`
        color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
      `
    }
  }};

  ${plume.glyphs.stroke.Menu} {
    margin-right: 6px;
  }

  &&:hover {
    color: ${plume.COLORS.PRIMARY_BLACK};
  }

  @media screen and ${INTERMEDIATE_BREAKPOINT} {
    height: 100%;
  }
`

// Alias to LinkItem used as a target for greying out when a subnav is attached to the header
export const MainNavLinkItem = styled(LinkItem)``

export const CommonMobileEntries = styled.section`
  > *:not(:last-child) {
    margin-bottom: 24px;
  }

  ${LinkItem} {
    font-weight: 600; /* HACK: Irregular font manipulation */
  }
`

export const Link = styled(plume.Link)`
  text-transform: uppercase;
`

// Alias to Link used as a target for greying out when a subnav is attached to the header
export const MainNavLink = styled(Link)``

export const LockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

export const Overlay = styled.div<{ visible: boolean }>`
  background-color: rgba(35, 34, 33, 0.4); // TODO: Replace w/ OVERLAY token once available.
  cursor: pointer;
  height: 100vh;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: fixed;
  top: ${plume.SIZES.HEADER_SUB_NAV_HEIGHT};
  transition-duration: 600ms;
  transition-property: opacity, visibility;
  transition-timing-function: ease-in-out;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: 100%;
  z-index: -2;
`

export const Zone = styled.section`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
`

export const CenterZone = styled(Zone)`
  flex: 0;
  justify-content: center;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    flex: 1;
  }
`

export const LeftZone = styled(Zone)`
  justify-content: flex-start;
`

export const RightZone = styled(Zone)`
  justify-content: flex-end;
`
