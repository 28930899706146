import { request } from './requester.ts'
import * as UFE from './UFE.ts'

export type VercelOAuth2Grants = {
  shopify_order_number: {
    email: string
    shopify_order_number: number
  }
  refresh_token: {
    refresh_token: string
  }
}

export type VercelOAuth2Response = {
  access_token: string
  expires_in: number
  refresh_token: string
  scope: string
  token_type: string
}

export async function vercelOauth2<GRANT extends keyof VercelOAuth2Grants>(
  grant: GRANT,
  params: VercelOAuth2Grants[GRANT],
) {
  const response = await request<VercelOAuth2Response>(
    process.env.VERCEL_URL === undefined ? '/api/oauth2' : process.env.VERCEL_URL + '/api/oauth2',
    {
      method: 'POST',
      type: 'json',
      body: {
        grant_type: grant,
        ...params,
      },
    },
  )

  return response.next((response) => response.body.access_token)
}

type RefreshResponsBody = { access_token: string }

export async function djangoOauth2(refreshToken: string) {
  if (UFE.REFRESH_TOKEN_ENDPOINT === undefined) {
    throw Error('No refresh token endpoint found')
  }

  const response = await request<RefreshResponsBody>(UFE.REFRESH_TOKEN_ENDPOINT, {
    type: 'formdata',
    method: 'POST',
    body: {
      refresh_token: refreshToken,
    },
  })

  return response.next((response) => response.body.access_token)
}
