import styled from 'styled-components'

import * as colors from '../../constants/colors'
import * as fonts from '../../constants/fonts'
import * as glyphs from '../../icons/glyphs'
import * as input from './input'
import * as button from '../button'

import { hexToRgb } from '../../utils/colors'

export const ResultWrapper = styled.li<{ isFocused: boolean }>`
  font-family: ${fonts.PRIMARY_FONT};
  font-size: 15px;
  color: ${colors.PRIMARY_BLACK};
  line-height: 18px;
  background-color: ${({ isFocused }) => (isFocused ? hexToRgb(colors.PRIMARY_BLUE, 0.05) : 'transparent')};
  padding: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
`

export const Wrapper = styled(input.ComposedWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Input = styled(input.Input)`
  flex: 1 1 auto;
  width: 0;
  border: none;
  padding-left: 0;
  padding-right: 0;
`

export const SearchIcon = styled(glyphs.stroke.Search).attrs({ size: 18 })`
  color: ${colors.GREY_SHADE_4};
  flex: 0 0 auto;
  margin: 0 13px;
`

export const Clear = styled(button.TextButton)`
  width: auto;
  color: ${colors.GREY_SHADE_4};
  flex: 0 0 auto;
  margin: 0 10px;
`
