import * as React from 'react'

export const scrollContext = React.createContext<React.RefObject<HTMLElement> | null>(null)

export function useScrollContext(): React.RefObject<HTMLElement> | null {
  return React.useContext(scrollContext)
}

export const clientSideContext = React.createContext<boolean>(false)

export function useClientSideContext(): boolean {
  return React.useContext(clientSideContext)
}
