import { Either } from '@owl-nest/monad'
import * as UFE from '../UFE.ts'
import { oauthRequest } from '../oauthRequester.ts'
import * as nextRequester from '../requester.ts'
import { getCookie, setCookie, removeCookie } from '@owl-nest/cookie-helper'
import { refreshAccessToken } from './compat.ts'

export type ExtraConfig = {
  getCookie?: typeof getCookie
  setCookie?: typeof setCookie
  removeCookie?: typeof removeCookie
  refreshAccessToken?: (refreshToken: string) => Promise<Either<nextRequester.HttpFailure, string>>
}

export type RetryOptions = nextRequester.RetryOptions

export type RequestOptions = nextRequester.RequestOptions & {
  token?: boolean
  withToken?: boolean
} & ExtraConfig

type RequesterConfig = {
  cookieNames: {
    accessToken: string
    refreshToken: string
  }
  endpoints: {
    refreshToken: string
  }
}

export function requester(config: RequesterConfig) {
  return <BODY = unknown>(
    url: string,
    options?: RequestOptions,
    retryOptions?: RetryOptions,
  ): Promise<HttpResponse<BODY>> => {
    return oauthRequest(
      url,
      {
        body: undefined,
        ...options,
        getAccessToken: () => (options?.getCookie ?? getCookie)(config.cookieNames.accessToken),
        getRefreshToken: () => (options?.getCookie ?? getCookie)(config.cookieNames.refreshToken),
        persistAccessToken: (accessToken: string) =>
          (options?.setCookie ?? setCookie)(config.cookieNames.accessToken, accessToken),
        refreshAccessToken: (refreshToken: string) => {
          if (options?.refreshAccessToken) {
            return options?.refreshAccessToken(refreshToken)
          }
          return refreshAccessToken(config.endpoints.refreshToken, refreshToken)
        },
      } as any,
      retryOptions,
    )
  }
}

export const request = requester({
  cookieNames: {
    accessToken: UFE.ACCESS_TOKEN_COOKIE_NAME || '',
    refreshToken: UFE.REFRESH_TOKEN_COOKIE_NAME || '',
  },
  endpoints: {
    refreshToken: UFE.REFRESH_TOKEN_ENDPOINT || '',
  },
})

export type RequestFailure = nextRequester.HttpFailure & {
  doNotRedirectToSignIn?: boolean
}

export type RequestSuccess<BODY> = nextRequester.HttpSuccess<BODY>

export type HttpResult<VALUE = unknown> = nextRequester.HttpResult<VALUE>
export type HttpResponse<BODY = unknown> = nextRequester.HttpResponse<BODY>
export type HttpRequest = <BODY = unknown>(url: string, requestOptions?: RequestOptions) => Promise<HttpResponse<BODY>>

export type ApiResponse<TYPE> = Either<nextRequester.HttpFailure, TYPE>

export const isExpectedError = nextRequester.isExpectedError

export function normalizeHeaders(headers: HeadersInit): Record<string, string> {
  if (headers instanceof Headers) {
    headers = Array.from(headers.entries())
  }
  if (Array.isArray(headers)) {
    const objectHeaders: Record<string, string> = {}
    for (const [name, value] of headers) {
      objectHeaders[name] = value
    }
    return objectHeaders
  }
  return { ...headers }
}
