import * as React from 'react'
import * as jotai from 'jotai'

import * as api from '@owl-nest/api-client/latest'
import * as logger from '@owl-nest/logger'

import { Response, QueryStatus } from './useQuery'

type UserConfig = {
  lang: api.Lang
  country: string
  currency: string
}

async function getUserConfig(origin: string): Promise<api.ApiResponse<UserConfig>> {
  const response = await api.request<any>(`${origin}/ufe/config/`)

  return response.next((result) => ({
    lang: result.body.UFE.locale,
    country: result.body.UFE.country,
    currency: result.body.CURRENCIES.CURRENT,
  }))
}

const USER_CONFIG_ATOM = jotai.atom<Response<UserConfig>>({ status: QueryStatus.PRISTINE })

export function useUserConfig(origin = ''): Response<UserConfig> {
  const store = jotai.useStore()
  const [state, setState] = jotai.useAtom(USER_CONFIG_ATOM)

  React.useEffect(() => {
    const state = store.get(USER_CONFIG_ATOM)
    if (state.status === QueryStatus.PRISTINE) {
      setState({ ...state, status: QueryStatus.PENDING })

      getUserConfig(origin).then((result) => {
        result.caseOf({
          left: (failure) => {
            logger.err('[user] Failed to update meta settings', { error: failure })
            setState({ ...state, status: QueryStatus.FAILURE, failure })
          },
          right: (success) => setState({ ...state, status: QueryStatus.SUCCESS, data: success }),
        })
      })
    }
  }, [])

  return state
}
