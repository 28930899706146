import { getCookie, setCookie, removeCookie } from '@owl-nest/cookie-helper'
import { Either } from '@owl-nest/monad'

import { HttpResponse, request } from '../requester.ts'
import { Client, EndpointDict, HttpMethod, TypedEndpoint } from '../client.ts'
import { UrlParameters } from '../UrlParameters.ts'
import * as UFE from '../UFE.ts'

export type RequestFailure = { status: number; url: string; body: any; cause?: any; doNotRedirectToSignIn?: boolean }

export type ExtraConfig = {
  getCookie?: typeof getCookie
  setCookie?: typeof setCookie
  removeCookie?: typeof removeCookie
  refreshAccessToken?: (refreshToken: string) => Promise<Either<RequestFailure, string>>
}

type BaseRequestOptions = RequestInit & {
  token?: string
  withToken?: boolean
} & ExtraConfig

type TransfromRequestOptions = Omit<BaseRequestOptions, 'body'> & {
  type: 'json' | 'formdata' | 'urlencoded'
  body: any
}

export type RequestOptions = TransfromRequestOptions | BaseRequestOptions

type OmitBetter<T, K extends keyof T> = T extends any ? Pick<T, Exclude<keyof T, K>> : never

type ClientOptions<BODY, URL_PARAMS extends string> = OmitBetter<RequestOptions, 'method' | 'body'> & {
  version?: string
  getParams?: Record<string, string> | string | URLSearchParams
  urlParams?: Record<URL_PARAMS, string>
  body?: BODY
  withToken?: boolean
}

export type BaseMethod = <BODY>(
  url: string | ((urlParams: Record<string, string>) => string),
  options?: ClientOptions<any, string>,
) => Promise<HttpResponse<BODY>>

export type ClientMethod<REQUEST_BODY = any, RESPONSE_BODY = unknown, URL_PARAMS extends string = string> = (
  options?: Partial<ClientOptions<REQUEST_BODY, URL_PARAMS>>,
) => Promise<HttpResponse<RESPONSE_BODY>>

export type OAuth2Grants = {
  shopify_order_number: {
    email: string
    shopify_order_number: number
  }
  refresh_token: {
    refresh_token: string
  }
}

export type OAuth2Response = {
  access_token: string
  expires_in: number
  refresh_token: string
  scope: string
  token_type: string
}

export type OAuth2 = <GRANT extends keyof OAuth2Grants>(
  grant: GRANT,
  params: OAuth2Grants[GRANT],
  options?: {
    getCookie?: typeof getCookie
    setCookie?: typeof setCookie
    removeCookie?: typeof removeCookie
  },
) => Promise<HttpResponse<OAuth2Response>>

type Method = BaseMethod & Record<string, ClientMethod>

type BaseApi = { delete: Method; get: Method; patch: Method; post: Method; put: Method; oauth2: OAuth2 }

type EndpointBody<
  ENDPOINTS extends TypedEndpoint<any, any>[],
  IDENTIFIER extends ENDPOINTS[number]['identifier'],
> = EndpointDict<ENDPOINTS>[IDENTIFIER] extends TypedEndpoint<IDENTIFIER, infer TRANSPUT> ? TRANSPUT['output'] : unknown

export function legacy<
  ENDPOINTS extends TypedEndpoint<any, any>[],
  DEPRECATED_ENDPOINTS extends TypedEndpoint<any, any>[],
>(
  client: Client<ENDPOINTS, DEPRECATED_ENDPOINTS>,
  config: Record<
    HttpMethod,
    (
      endpoint: <IDENTIFIER extends ENDPOINTS[number]['identifier'] | DEPRECATED_ENDPOINTS[number]['identifier']>(
        name: string,
        identifier: IDENTIFIER,
        map: (
          urlParams: Record<string, string>,
        ) => Record<UrlParameters<IDENTIFIER extends `${HttpMethod} ${infer URL}` ? URL : ''>[number], string>,
      ) => void,
    ) => void
  >,
): BaseApi {
  const api = {
    oauth2: async <GRANT extends keyof OAuth2Grants>(
      grant: GRANT,
      params: OAuth2Grants[GRANT],
      options: {
        getCookie?: typeof getCookie
        setCookie?: typeof setCookie
        removeCookie?: typeof removeCookie
      } = {},
    ) => {
      const response = await request<OAuth2Response>(
        process.env.VERCEL_URL === undefined ? '/api/oauth2' : process.env.VERCEL_URL + '/api/oauth2',
        {
          method: 'POST',
          type: 'json',
          body: {
            grant_type: grant,
            ...params,
          },
        },
      )

      return response.caseOf({
        left: () => {
          (options?.removeCookie ?? removeCookie)(process.env.NEXT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME ?? '')
          ;(options?.removeCookie ?? removeCookie)(process.env.NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_NAME ?? '')
          return response
        },
        right: (success) => {
          (options?.setCookie ?? setCookie)(
            process.env.NEXT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME ?? '',
            success.body.access_token,
          )
          ;(options?.setCookie ?? setCookie)(
            process.env.NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_NAME ?? '',
            success.body.refresh_token,
          )
          return response
        },
      })
    },
  } as BaseApi

  const accessTokenCookieName = UFE.ACCESS_TOKEN_COOKIE_NAME ?? ''
  const refreshTokenCookiName = UFE.REFRESH_TOKEN_COOKIE_NAME ?? ''
  const refreshTokenEndpoint = UFE.REFRESH_TOKEN_ENDPOINT ?? ''

  for (const method in config) {
    const methodConfig = config[method as keyof typeof config]
    const wiredMethod = (<BODY>(url: string, options: ClientOptions<BODY, string>) =>
      client._generic<BODY>(`${method} ${url}`, { body: undefined, urlParams: undefined, ...options })) as Method

    methodConfig((name, identifier, map) => {
      wiredMethod[name] = (options: Partial<ClientOptions<EndpointBody<ENDPOINTS, typeof identifier>, string>> = {}) =>
        client(identifier, {
          body: undefined,
          ...options,
          getAccessToken: () => (options.getCookie ?? getCookie)(accessTokenCookieName),
          getRefreshToken: () => (options.getCookie ?? getCookie)(refreshTokenCookiName),
          persistAccessToken: (accessToken: string) =>
            (options.setCookie ?? setCookie)(accessTokenCookieName, accessToken),
          refreshAccessToken: (refreshToken: string) => {
            if (options?.refreshAccessToken) {
              return options?.refreshAccessToken(refreshToken)
            }
            return refreshAccessToken(refreshTokenEndpoint, refreshToken)
          },
          urlParams: options?.urlParams ? map(options.urlParams) : undefined,
        } as any)
    })

    api[method.toLowerCase() as keyof BaseApi] = wiredMethod
  }

  return api
}

type RefreshResponsBody = { access_token: string }

export async function refreshAccessToken(
  refreshTokenEndpoint: string,
  refreshToken: string,
): Promise<Either<RequestFailure, string>> {
  const response = await request<RefreshResponsBody>(refreshTokenEndpoint, {
    type: 'formdata',
    method: 'POST',
    body: {
      refresh_token: refreshToken,
    },
  })

  return response.next((response) => {
    return response.body.access_token
  })
}
