import styled, { css } from 'styled-components'

import * as colors from '../constants/colors'
import * as glyphs from '../icons/glyphs'
import * as copy from '../styles/copy'

export const Toggler = styled.input`
  display: none;
`

export const Title = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -19px -15px;
  padding: 13px 15px;

  ${glyphs.Icon} {
    fill-rule: evenodd;
    fill: currentColor;
    height: 18px;
    width: 18px;
  }

  ${glyphs.stroke.CaretDown} {
    display: block;
  }

  ${glyphs.stroke.CaretUp} {
    display: none;
  }
`

export const Content = styled.div<{ open?: boolean }>`
  max-height: 0;
  transition: max-height 0.2s ease-in-out;

  ${({ open }) => {
    if (!open) {
      return css`
        overflow: hidden;
      `
    }
  }}
`

export const ClosedContent = styled.div`
  max-height: max-content;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
`

export const Wrapper = styled.section`
  border: 1px solid ${colors.GREY_SHADE_5};
  border-radius: 4px;
  padding: 16px 14px;

  ${copy.M} {
    margin: 0;
    font-weight: 600; // HACK: Irregular font manipulation. We'll be adding copy.M.semiBold, so this is just by anticipation.
  }

  ${Toggler}:checked ~ {
    ${Title} > {
      ${glyphs.stroke.CaretDown} {
        display: none;
      }
      ${glyphs.stroke.CaretUp} {
        display: block;
      }
    }

    ${Content} {
      max-height: max-content;
    }

    ${ClosedContent} {
      max-height: 0;
    }
  }
`
