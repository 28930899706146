import * as React from 'react'

import { urlContext } from './urlContext'

type URLProviderProps = {
  baseUrl?: string
  children: React.ReactNode
}

export function URLProvider({ baseUrl = '', children }: URLProviderProps): React.ReactElement<URLProviderProps> {
  return (
    <urlContext.Provider
      value={{
        discover: `${baseUrl}/discover`,
        discoverLandingPage: `${baseUrl}/pages/discover/`,
        home: `${baseUrl}/`,
        proposal: `${baseUrl}/projects/create/`,
        signIn: {
          onClick: (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault()
            window.location.href = `${baseUrl}/signin/`
          },
          url: `${baseUrl}/signin/`,
        },
        signOut: `${baseUrl}/signout/`,
        // signUp: '/signup/',
      }}
    >
      {children}
    </urlContext.Provider>
  )
}
