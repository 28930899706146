import styled from 'styled-components'

import * as bp from '../constants/breakpoints'
import * as button from './button'
import * as colors from '../constants/colors'
import * as effects from '../constants/neue/effects'
import * as glyphs from '../icons/glyphs'
import * as heading from './heading'
import * as zindex from '../constants/zindex'

export const Overlay = styled.div<{ step?: 'before' | 'during' | 'after' }>`
  align-items: center;
  background-color: ${colors.hexToRgb(colors.PRIMARY_BLACK, 0.4)};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: ${({ step }) => (step === 'before' || step === 'after' ? 0 : 1)};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 200ms;
  z-index: ${zindex.MODAL};
`

export const Wrapper = styled.div`
  background: ${colors.PRIMARY_WHITE};
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  min-height: inherit;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;

  ${glyphs.stroke.Close} {
    color: ${colors.GREY_SHADE_7};
    left: 16px;
    position: absolute;
    top: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  @media screen and ${bp.TABLET} {
    border-radius: 8px;
    border: 1px solid ${colors.GREY_SHADE_5};
    display: block;
    height: auto;
    max-height: 74vh;
    max-width: 540px;
    min-height: auto;
    position: relative;
    width: 100%;

    &::before {
      background: ${colors.PRIMARY_WHITE};
      height: 20px;
    }

    ${glyphs.stroke.Close} {
      color: ${colors.GREY_SHADE_4};
      left: auto;
      right: 13px;
      top: 13px;
    }
  }
`

export const Body = styled.div`
  padding: 52px 16px 16px 16px;

  @media screen and ${bp.TABLET} {
    padding: 16px 24px;
  }
`

export const SmallWrapper = styled(Wrapper)`
  box-shadow: ${effects.SHADOW_3};
  position: relative;

  @media screen and ${bp.TABLET} {
    border: none;
  }

  &::before {
    height: 0;
  }

  ${glyphs.stroke.Close} {
    top: 16px;
    color: ${colors.PRIMARY_WHITE};
  }
`

export const Title = styled(heading.Card1)`
  align-items: center;
  background: ${colors.PRIMARY_BLUE};
  color: ${colors.PRIMARY_WHITE};
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 13px 37px;
`

export const Actions = styled(Body)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0;

  ${button.Button} {
    width: 100%;
    margin-bottom: 10px;
  }

  ${button.LinkWrapper} {
    width: 100%;
  }

  @media screen and ${bp.TABLET} {
    ${button.Button} {
      margin-bottom: 11px;
    }
  }
`
