import styled from 'styled-components'
import * as colors from '../constants/colors'
import * as bp from '../constants/breakpoints'

import * as copy from './copy'
import * as heading from './heading'
import { Base } from './link'
import { shadowBox } from './shadowBox'

export const CurrentCurrency = styled(copy.XS)`
  border: 1px solid ${colors.GREY_SHADE_7};
  cursor: pointer;
  padding: 5px;

  &:hover {
    border: 1px solid ${(props) => colors.primary(props)};
    color: ${(props) => colors.primary(props)};
  }
`

export const CurrentLanguage = styled(copy.XS)`
  border: 1px solid ${colors.GREY_SHADE_7};
  cursor: pointer;
  padding: 5px;

  &:hover {
    border: 1px solid ${(props) => colors.primary(props)};
    color: ${(props) => colors.primary(props)};
  }
`

export const Wrapper = styled.div`
  display: flex;
  position: relative;

  ${CurrentLanguage} + ${CurrentCurrency} {
    border-left: 0;
  }

  ${CurrentLanguage} + ${CurrentCurrency} {
    &:hover {
      margin-left: -1px;
      border: 1px solid ${(props) => colors.primary(props)};
    }
  }

  ${CurrentCurrency} +  ${CurrentLanguage} {
    border-left: 0;
  }
`

export const LanguagesBox = styled.div`
  ${shadowBox};
  ${copy._smallStyle};
  background-color: ${colors.PRIMARY_WHITE};
  position: absolute;
  text-transform: uppercase;
  top: -10px;
  transform: translate3d(0, -100%, 0);
  width: 300px;
`

export const CurrencySymbol = styled(heading.XXS)`
  display: block;
  text-transform: uppercase;
  white-space: nowrap;
`

export const CurrencyName = styled(heading.Card2)`
  text-transform: uppercase;
  white-space: nowrap;
`

export const LinkList = styled.ul<{ wrapped: boolean }>`
  display: flex;
  flex-direction: ${({ wrapped }) => (wrapped ? 'row' : 'column')};
  flex-wrap: ${({ wrapped }) => (wrapped ? 'wrap' : 'nowrap')};
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    flex: 0 0 ${({ wrapped }) => (wrapped ? '110px' : '260px')};
    margin: 10px 20px;
    min-height: 30px;

    &:hover {
      ${CurrencySymbol}, ${CurrencyName}, ${Base} {
        color: ${colors.PRIMARY_BLUE};
      }
    }
  }
`

export const CurrenciesBox = styled.div`
  ${shadowBox};
  background-color: ${colors.PRIMARY_WHITE};
  position: absolute;
  top: -10px;
  transform: translate3d(0, -100%, 0);

  ${LinkList} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px 5px;
    padding: 20px 7px;

    & > li {
      margin: 0px;
    }
  }

  @media screen and ${bp.MOBILE_M} {
    ${LinkList} {
      gap: 20px 40px;
      padding: 20px 20px;
    }
  }
`
