import * as breakpoint from '../constants/breakpoints'

export function isTabletAndMore(): boolean {
  if (typeof window === 'undefined' /* SSR */) {
    return false
  } else {
    return window.matchMedia(`screen and ${breakpoint.TABLET}`).matches
  }
}

export function isMobile(): boolean {
  if (typeof window === 'undefined' /* SSR */) {
    return false
  } else {
    return window.matchMedia(`not screen and ${breakpoint.TABLET}`).matches
  }
}

export function isTouchDevice(): boolean {
  if (typeof window === 'undefined' /* SSR */) {
    return false
  } else {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0
  }
}
