import * as React from 'react'
import * as formik from 'formik'
import styled from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import * as yup from '@owl-nest/validators'
import { t } from '@owl-nest/localize'
import { SubmitFormValue } from './types'

type SubmitFormProps = {
  onSubmit: (values: SubmitFormValue) => void
}

const schema = yup.object().shape({
  message: yup.string().max(500).required(),
})

export function SubmitForm({ onSubmit }: SubmitFormProps): React.ReactElement<SubmitFormProps> {
  const initialValues: SubmitFormValue = {
    message: t(`Hello team, I’ve completed my project!`),
  }

  return (
    <formik.Formik<SubmitFormValue>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikBag) => {
        return (
          <Form onSubmit={formikBag.handleSubmit}>
            <plume.styles.heading.XS>{t('Submit for review')}</plume.styles.heading.XS>

            <plume.styles.copy.S>{t('Leave any comments, messages, questions for your coach.')}</plume.styles.copy.S>

            <formik.Field name="message">
              {({ field, meta }: formik.FieldProps<string>) => {
                return (
                  <plume.TextAreaField
                    name={field.name}
                    onChange={field.onChange}
                    error={meta.error}
                    value={field.value}
                  />
                )
              }}
            </formik.Field>

            <plume.Button type="submit" disabled={formikBag.isSubmitting}>
              {formikBag.isSubmitting ? <plume.Spinner /> : t('Submit')}
            </plume.Button>
          </Form>
        )
      }}
    </formik.Formik>
  )
}

const Form = styled.form`
  text-align: center;

  ${plume.styles.heading.XS} {
    margin-bottom: 25px;
  }

  ${plume.styles.copy.S} {
    margin-bottom: 10px;
  }

  ${plume.Button} {
    width: 100%;
    margin-top: 17px;
  }
`
