import * as React from 'react'
import styled from 'styled-components'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'
import { lightweight, standalone, SymbolProps } from '../../spritesheetUtils'

const ID = 'facebook-third-party-glyph-icon'

export function FacebookSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        d="M12.702 32V16.005H9V10.54h3.707c0-.47-.015-.869.009-1.259.075-1.673.028-3.375.276-5.02.376-2.496 1.908-3.86 4.28-4.134.465-.051.936-.114 1.396-.118 1.405-.017 2.81-.007 4.265-.007V5.53c-.95 0-1.88-.024-2.804.005-1.376.045-1.772.49-1.787 1.958-.007.969-.001 1.938-.001 2.99H23c-.188 1.853-.362 3.61-.546 5.467H18.32V32h-5.618Z"
        clipRule="evenodd"
      />
    </symbol>
  )
}

const FacebookStandaloneContent = standalone(FacebookSymbol, ID)

export const Facebook = styled(function FacebookStandaloneComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-facebook">
      <FacebookStandaloneContent />
    </Icon>
  )
})``

export const FacebookLightweight = styled(function FacebookComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-facebook">
      {lightweight(ID)}
    </Icon>
  )
})``
