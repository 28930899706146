import * as date from '@owl-nest/date-fns'
import { t } from '@owl-nest/localize'

type DateCountdown = {
  endingSoon: boolean
  label: string
  value: number | string
}

const HOUR = 60 * 60

export function getCountDown(endDate: Date, short: boolean): DateCountdown {
  const differenceInSecondsBetweenEndDateAndNow = date.differenceInSeconds(endDate, Date.now())
  const differenceInHoursBetweenEndDateAndNow = Math.round(differenceInSecondsBetweenEndDateAndNow / HOUR)

  // In short mode, live timer is shown when end date is <= 24h, otherwise when <= 6 hours
  const timeInSecond = short ? 60 * 60 : 60 * 60 * 6

  if (differenceInSecondsBetweenEndDateAndNow <= timeInSecond) {
    if (differenceInSecondsBetweenEndDateAndNow <= 0) {
      return {
        endingSoon: true,
        label: short ? t('Finished') : t('campaign ended'),
        value: '-',
      }
    } else {
      return {
        endingSoon: true,
        label: short ? formatSecondsToCountdown(differenceInSecondsBetweenEndDateAndNow, short) : t('before the end'),
        value: formatSecondsToCountdown(differenceInSecondsBetweenEndDateAndNow, false),
      }
    }
  } else if (differenceInHoursBetweenEndDateAndNow <= 48) {
    return {
      endingSoon: true,
      label: short ? t('H-%(nb)s', { nb: differenceInHoursBetweenEndDateAndNow }) : t('hours left'),
      value: differenceInHoursBetweenEndDateAndNow,
    }
  }

  // Remaining time is > 48hours
  const differenceInDaysBetweenEndDateAndNow = Math.round(differenceInHoursBetweenEndDateAndNow / 24)
  return {
    endingSoon: false,
    label: short ? t('D-%(nb)s', { nb: differenceInDaysBetweenEndDateAndNow }) : t('days left'),
    value: differenceInDaysBetweenEndDateAndNow,
  }
}

/**
 * Formats given seconds in countdown format: <HH>h<MM> if there is more than one hour, <MM>:<SS> otherwise.
 *
 * @param secondsAmount
 */
export function formatSecondsToCountdown(secondsAmount: number, short?: boolean): string {
  const normalizeTime = (time: string): string => (time.length === 1 ? `0${time}` : time)

  const milliseconds = secondsAmount * 1000
  const futureDate = new Date(milliseconds)
  const timezoneDiff = futureDate.getTimezoneOffset() / 60
  const dateWithoutTimezoneDiff = date.addHours(futureDate, timezoneDiff)

  const hours = normalizeTime(String(date.getHours(dateWithoutTimezoneDiff)))
  const minutes = normalizeTime(String(date.getMinutes(dateWithoutTimezoneDiff)))
  const seconds = normalizeTime(String(date.getSeconds(dateWithoutTimezoneDiff)))

  const hoursOutput = hours !== '00' ? `${hours}h` : ''
  const secondsOutput = hours === '00' ? (short ? `${seconds}` : `:${seconds}`) : ''

  // NOTE: Different translations are needed because they depend on usage context.
  // In short mode (e.g. Project cards), labels only show minutes and seconds, whereas full mode also features hours.
  if (short) {
    return t('%(min)s min %(sec)s sec', {
      min: minutes,
      sec: secondsOutput,
    })
  } else {
    return `${hoursOutput}${minutes}${secondsOutput}`
  }
}
