import * as React from 'react'
import styled from 'styled-components'

import { EggProgress } from './EggProgress'
import * as accounting from '../core/accounting/Accounting'

type GoalLabelProps = {
  currency: string
  goal: number
  language: string
  productsSoldCount: number
  progress: number
  raisedAmount: number
  rates: { [s: string]: number }
  targetCurrency: string | undefined
  // FIXME: Remove 'membership' once MembershipProjectCard is ready
  type: 'donation' | 'presale' | 'project' | 'vendor' | 'membership'
}

export function GoalLabel({
  currency,
  goal,
  language,
  productsSoldCount,
  progress,
  raisedAmount,
  rates,
  targetCurrency,
  type,
}: GoalLabelProps): React.ReactElement<GoalLabelProps> {
  const label =
    type === 'presale' ? (
      `${productsSoldCount} / ${goal}`
    ) : (
      <accounting.LocaleMoneyDisplayer
        amount={raisedAmount}
        currency={currency}
        localeFormat={language}
        rates={rates}
        targetCurrency={targetCurrency}
      />
    )

  return (
    <Wrapper>
      <EggProgress progress={progress} size={16} />
      {label}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: normal;
  overflow: visible;

  ${EggProgress} {
    margin-right: 3px;
  }
`
