export const HEADER_HEIGHT = '60px'
export const HEADER_INTERNAL_HEIGHT = '57px'
export const HEADER_SUB_NAV_HEIGHT = '53px'

export const MAIN_CONTAINER_WIDTH = '1360px'

export const HEADER_SHADOW_HEIGHT = '14px'

export const HEADER_DESKTOP_HEIGHT = '95px'

export const HEADER_MOBILE_HEIGHT = '85px'
