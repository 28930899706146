import * as React from 'react'
import styled, { css } from 'styled-components'

import { Link, LinkProps } from '../Link'
import * as colors from '../../constants/colors'

export type LinkAsButtonProps = LinkProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    withBorder?: boolean
  }

function LinkAsButtonComponent({
  children,
  className,
  ...buttonProps
}: LinkAsButtonProps): React.ReactElement<LinkAsButtonProps> {
  return (
    <Link forwardedAs="button" className={className} {...buttonProps}>
      {children}
    </Link>
  )
}

export const LinkAsButton = styled(LinkAsButtonComponent)<LinkAsButtonProps>`
  padding: 16px;
  cursor: pointer;
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover {
    ${({ kind = 'primary' }) =>
      kind === 'secondary' &&
      css`
        text-decoration: underline;
      `}

    ${({ withBorder }) => {
      if (withBorder) {
        return css`
          transition: 0.3s;
          border-radius: 4px;
          border: 1px solid ${colors.PRIMARY_BLUE_700};
        `
      }
    }}
  }

  ${({ withBorder }) => {
    if (withBorder) {
      return css`
        border-radius: 4px;
        border: 1px dashed ${colors.PRIMARY_SAND_200};
      `
    }
  }}
`
LinkAsButton.displayName = 'LinkAsButton'
