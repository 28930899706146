import { request, ApiResponse, RequestFailure } from '@owl-nest/api-client'
import { AuthenticatedUser, PublicUser, UpdateUser, api, ApiError } from '@owl-nest/api-client/latest'
import { getCookie } from '@owl-nest/cookie-helper'
import { Either } from '@owl-nest/monad'
import * as dateUtil from '@owl-nest/date'

import { SIGNUP_URL, SIGNOUT_URL, SIGNIN_URL } from './ufe'

export type SignupUser = {
  password: string
  lang?: string
  email: string
  username?: string
}

export type LoginUser = {
  username: string
  password: string
  'stay-connected': boolean
}

type ExtendedUser<EXTRA_FIELDS extends string> = AuthenticatedUser<EXTRA_FIELDS> & { extraFields: EXTRA_FIELDS[] }

export async function userExists(
  type: 'email' | 'username',
  identifier: string,
): Promise<ApiResponse<'ulule' | 'facebook' | undefined>> {
  const response = await request(SIGNUP_URL, {
    method: 'POST',
    type: 'urlencoded',
    body: {
      [type]: identifier,
      dry_run: true,
      // Random password to pass the password validation API-side
      password: '%JVbgkcM8yDUVCgoVzCUxtPE',
      csrfmiddlewaretoken: getCookie('csrftoken'),
    },
  })

  return response.caseOf<ApiResponse<'ulule' | 'facebook' | undefined>>({
    left: (failure) => {
      if (failure.body?.type === 'APIError') {
        failure = unwrapDjangoError(failure)
      }
      if (isAlreadyExistsError(failure)) {
        if (isUnusablePasswordError(failure)) {
          return Either.right('facebook')
        }
        return Either.right('ulule')
      }
      return Either.left({ ...failure, bypassStatusAutomaticActions: true })
    },
    right: (_) => Either.right(undefined),
  })
}

export async function currentUser<EXTRA_FIELDS extends string = ''>(
  doNotRedirectToSignIn = true,
  extraFields: EXTRA_FIELDS[] = [],
): Promise<ApiResponse<ExtendedUser<EXTRA_FIELDS>>> {
  const response = await api.get.me({
    getParams: { extra_fields: extraFields.join(',') },
  })

  return response.caseOf({
    left: (failure) => {
      return Either.left({ ...failure, doNotRedirectToSignIn })
    },
    right: (success) => {
      const authenticatedUser = success.body as AuthenticatedUser<EXTRA_FIELDS>
      return Either.right({ ...authenticatedUser, extraFields })
    },
  })
}

export async function signup(signup: SignupUser): Promise<ApiResponse<AuthenticatedUser>> {
  const response = await request<AuthenticatedUser>(SIGNUP_URL, {
    method: 'POST',
    type: 'urlencoded',
    credentials: 'include',
    body: {
      ...signup,
      csrfmiddlewaretoken: getCookie('csrftoken'),
    },
  })

  return response.caseOf<ApiResponse<AuthenticatedUser>>({
    left: (failure) => {
      if (failure.body?.type === 'APIError') {
        failure = unwrapDjangoError(failure)
      }
      if (isAlreadyExistsError(failure)) {
        return Either.left({ ...failure, isAlreadyExistsError: true })
      }
      return Either.left({ ...failure, bypassStatusAutomaticActions: true })
    },
    right: (success) => {
      const user = success.body
      markUserKnown(user)
      return Either.right(user)
    },
  })
}

export async function login<EXTRA_FIELDS extends string = ''>(
  login: LoginUser,
  doNotRedirectToSignIn = true,
  extraFields: EXTRA_FIELDS[] = [],
): Promise<ApiResponse<ExtendedUser<EXTRA_FIELDS>>> {
  const response = await request<{ access_token: string; refresh_token: string; expires_in: number }>(SIGNIN_URL, {
    method: 'POST',
    type: 'urlencoded',
    credentials: 'include',
    body: {
      ...login,
      csrfmiddlewaretoken: getCookie('csrftoken'),
    },
  })

  const userResponse = await response.caseOf<Promise<ApiResponse<ExtendedUser<EXTRA_FIELDS>>>>({
    left: async (failure) => {
      if (failure.body?.type === 'APIError') {
        failure = unwrapDjangoError(failure)
      }
      return Either.left({ ...failure, bypassStatusAutomaticActions: true })
    },
    right: async (_success) => {
      return await currentUser(doNotRedirectToSignIn, extraFields)
    },
  })
  return userResponse.next((user) => {
    markUserKnown(user)
    return user
  })
}

export async function guestLogin<EXTRA_FIELDS extends string = ''>(
  email: string,
  doNotRedirectToSignIn = true,
  extraFields: EXTRA_FIELDS[] = [],
): Promise<ApiResponse<ExtendedUser<EXTRA_FIELDS>>> {
  const response = await request<{ access_token: string; refresh_token: string; expires_in: number }>(SIGNIN_URL, {
    method: 'POST',
    type: 'urlencoded',
    credentials: 'include',
    body: {
      csrfmiddlewaretoken: getCookie('csrftoken'),
      email,
      guest: 1,
    },
  })

  const userResponse = await response.caseOf<Promise<ApiResponse<ExtendedUser<EXTRA_FIELDS>>>>({
    left: async (failure) => {
      if (failure.body?.type === 'APIError') {
        failure = unwrapDjangoError(failure)
      }
      return Either.left({ ...failure, bypassStatusAutomaticActions: true })
    },
    right: async (_success) => {
      return await currentUser(doNotRedirectToSignIn, extraFields)
    },
  })

  return userResponse.next((user) => {
    return user
  })
}

export async function logout(): Promise<ApiResponse<void>> {
  const response = await request(SIGNOUT_URL, {
    method: 'GET',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })

  return response.next((_) => {
    /* Nothing to pass in case of success */
  })
}

export type KnownUser = PublicUser & { service: 'ulule' | 'facebook' }

export async function userKnown(): Promise<KnownUser | undefined> {
  const lastUserItem = window.localStorage.getItem('ul_last_user')
  if (lastUserItem === null) {
    return undefined
  } else {
    try {
      const { username, expires } = JSON.parse(lastUserItem)

      if (expires < Date.now()) {
        forgetUser()
        return undefined
      }

      const service = (await userExists('username', username)).doThrow()

      if (service === undefined) {
        return undefined
      }

      const { body: user } = (
        await api.get.user({
          // this is an undocumented feature of `GET /users/:id` where `:id` can be the `userId` or the `username`
          urlParams: { userId: username },
        })
      ).doThrow()

      return {
        ...user,
        service,
      }
    } catch (error) {
      return undefined
    }
  }
}

export function markUserKnown<E extends string>(user: AuthenticatedUser<E>): void {
  window.localStorage.setItem(
    'ul_last_user',
    JSON.stringify({
      username: user.username,
      expires: dateUtil.daysFromNow(7 * 3),
    }),
  )
}

export function forgetUser(): void {
  window.localStorage.removeItem('ul_last_user')
}

//FIXME: this should not be here, it is not related to authentication
export async function update(userId: number, user: UpdateUser): Promise<ApiResponse<AuthenticatedUser>> {
  const response = await api.patch.user({
    urlParams: { userId: String(userId) },
    body: user,
  })

  return response.next((success) => {
    return success.body
  })
}

function unwrapDjangoError(failure: RequestFailure): RequestFailure {
  return {
    ...failure,
    body: failure.body.errors,
  }
}

export function isAlreadyExistsError(failure: RequestFailure): boolean {
  if (failure.status === 422) {
    const apiError = failure.body as ApiError
    for (const error of apiError) {
      if (error.classification === 'AlreadyExistsError') {
        return true
      }
    }
  }
  return false
}

export function isUnusablePasswordError(failure: RequestFailure): boolean {
  if (failure.status === 422) {
    const apiError = failure.body as ApiError
    for (const error of apiError) {
      if (error.classification === 'UnusablePasswordError') {
        return true
      }
    }
  }
  return false
}
