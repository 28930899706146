import styled from 'styled-components'

export const SubNavItemList = styled.ol`
  align-content: center;
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  list-style: none;
  padding: 0;
`

export const SubnavWrapper = styled.nav`
  display: inline-grid;
  grid-auto-flow: column;
  height: 53px;
  width: 100%;
`
