import * as React from 'react'

import * as colors from '../../constants/colors'

type CircularBackgroundProps = {
  diameter: number | string
  full?: boolean
}

type MediumBackgroundProps = CircularBackgroundProps & {
  inverted?: boolean
}

export function CircularBackground({
  diameter,
  full = false,
  inverted,
}: MediumBackgroundProps): React.ReactElement<MediumBackgroundProps> {
  return inverted ? <Circle diameter={diameter} full={full} /> : <Disc diameter={diameter} full={full} />
}

function Circle({ diameter, full }: CircularBackgroundProps): React.ReactElement<CircularBackgroundProps> {
  const strokeWidth = Math.floor(Number(diameter) / 64) + 1

  return (
    <circle
      cx={Number(diameter) / 2}
      cy={Number(diameter) / 2}
      r={Number(diameter) / 2 - (full ? 0 : strokeWidth)}
      fill="none"
      stroke={colors.PRIMARY_WHITE}
      strokeWidth={strokeWidth}
    />
  )
}

function Disc({ diameter, full }: CircularBackgroundProps): React.ReactElement<CircularBackgroundProps> {
  const padding = Math.floor(Number(diameter) / 64) + 1

  return (
    <circle
      cx={Number(diameter) / 2}
      cy={Number(diameter) / 2}
      r={Number(diameter) / 2 - (full ? 0 : padding)}
      fill={colors.GREY_SHADE_5}
    />
  )
}

type PartialDiskProps = {
  viewBox: string | undefined
  x?: number | string
  y?: number | string
}

export function PartialDisk({ viewBox, x = 0, y = 0 }: PartialDiskProps): React.ReactElement<PartialDiskProps> {
  return (
    <svg
      height="100%"
      preserveAspectRatio="xMidYMin meet"
      viewBox={viewBox}
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={colors.GREY_SHADE_5}
        d="M 14.5 239.32 L 259.81 239.32 C 265.42 220.7 266.89 200.53 263.25 180.08 C 250.88 110.43 184.39 64 114.74 76.38 C 45.09 88.76 -1.33 155.25 11.04 224.89 C 11.92 229.83 13.1 234.63 14.5 239.32 Z"
        transform={`translate(${x}, ${y})`}
      />
    </svg>
  )
}
