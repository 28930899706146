import * as React from 'react'
import styled, { css } from 'styled-components'

import * as S from '../../../styles'
import * as colors from '../../../constants/colors'
import * as zindex from '../../../constants/zindex'

import { SelectProps, Select } from './Select'

export type DropdownComponentProps = SelectProps & {
  /** @default 'block'
   * `inline` makes the caret stick to the label and makes the drawer width
   * independent from that of the label + caret. Avoids having huge empty
   * spaces in the label + caret group when dropdown is very wide.
   */
  mode?: 'block' | 'inline'
}

function DropdownComponent({
  children,
  ...selectProps
}: DropdownComponentProps): React.ReactElement<DropdownComponentProps> {
  return <Select {...selectProps}>{children}</Select>
}

/** Variant of `<Select>`, see there for the props */
export const Dropdown = styled(DropdownComponent)<SelectProps>`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  position: relative;

  ${S.select.Drawer}, ${S.dropdown.Dropdown}, ${S.select.ScrollList} {
    display: inline-block;
  }

  ${S.select.Drawer} {
    ${({ mode = 'block' }) => {
      if (mode === 'inline') {
        return css`
          position: absolute !important;
          left: 0px;
          top: 20px;
          width: max-content;
          max-width: 250px;
          z-index: ${zindex.ABOVE};
        `
      }
    }};
  }

  ${S.dropdown.Dropdown} {
    margin-top: 10px;
  }

  ${S.select.Button} {
    align-self: flex-end;
    border: none;
    cursor: pointer;
    height: auto;
    max-width: 170px;
    padding: 0;
    width: auto;

    ${S.select.CaretDown} {
      color: ${colors.PRIMARY_BLACK};
      flex: 0 0 10px;
    }

    ${S.select.Label} {
      ${({ mode = 'block' }) => {
        if (mode === 'inline') {
          return css`
            margin-right: 8px;
          `
        }
      }};
    }

    ${({ mode = 'block' }) => {
      if (mode === 'inline') {
        return css`
          background-color: transparent;
        `
      }
    }};
  }

  ${({ disabled }) => {
    if (!disabled) {
      return css`
        &&:hover ${S.select.Button} {
          border: none;

          ${S.select.Label}, ${S.select.CaretDown} {
            color: ${colors.PRIMARY_BLUE};
          }
        }
      `
    }
  }}
`
Dropdown.displayName = 'Dropdown'
