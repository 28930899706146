import * as React from 'react'
import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

type Kind = 'primary' | 'secondary'

type ToggleProps = {
  children: React.ReactNode
  className?: string
  kind?: Kind
  open?: boolean
  setAsOpen?: (open: boolean) => void
  title: string
}

function ToggleComponent({ open, setAsOpen, ...props }: ToggleProps) {
  if (open !== undefined && setAsOpen !== undefined) {
    return <ControlledToggle {...props} open={open} setAsOpen={setAsOpen} />
  }

  return <UncontrolledToggle {...props} />
}

function ControlledToggle({
  children,
  className,
  kind = 'primary',
  open,
  setAsOpen,
  title,
}: ToggleProps & {
  open: boolean
  setAsOpen: (open: boolean) => void
}) {
  return (
    <DropdownWrapper active={open} className={className} kind={kind}>
      <Title
        onClick={() => {
          setAsOpen(!open)
        }}
      >
        {title}
        <plume.glyphs.stroke.CaretDown size={9} />
      </Title>
      <Drawer open={open}>{children}</Drawer>
    </DropdownWrapper>
  )
}

function UncontrolledToggle({ children, ...props }: ToggleProps) {
  const [open, setAsOpen] = React.useState(false)

  return (
    <ControlledToggle {...props} open={open} setAsOpen={setAsOpen}>
      {children}
    </ControlledToggle>
  )
}

export const Toggle = styled(ToggleComponent)``

function Drawer({ open, children }: { open: boolean; children: React.ReactNode }) {
  return <DrawerContent open={open}>{children}</DrawerContent>
}

const DrawerContent = styled.ul<{ open: boolean }>`
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: ease-in-out;
  width: 100%;
  overflow: hidden;
  max-height: 0;
  opacity: 0;

  ${({ open }) => {
    if (open) {
      return css`
        opacity: 1;
        max-height: max-content;
        padding: 16px 0px;
      `
    }
  }};
`

export const Title = styled.span`
  font-weight: 600;
`

const DropdownWrapper = styled.li<{ active: boolean; kind: Kind }>`
  cursor: default;
  list-style: none;
  position: relative;

  ${({ kind }) => {
    if (kind === 'secondary') {
      return css`
        color: ${plume.COLORS.GREY_SHADE_2};
      `
    }
  }};

  ${Title} {
    align-items: center;
    color: ${({ active }) => (active ? plume.COLORS.PRIMARY_BLUE : plume.COLORS.PRIMARY_BLACK)};
    display: flex;
    font-weight: 600; // HACK: Irregular font manipulation
    text-transform: uppercase;
    transition: color 0.3s ease;

    ${plume.glyphs.stroke.CaretDown} {
      margin-left: 5px;
      position: relative;
      top: 1px;
    }

    ${({ active, kind }) => {
      if (kind === 'secondary') {
        return css`
          color: ${plume.COLORS.GREY_SHADE_2};
        `
      }

      if (active) {
        return css`
          ${plume.glyphs.stroke.CaretDown} {
            transform: rotate(180deg);
          }
        `
      }
    }}
  }
`
