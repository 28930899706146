import 'intersection-observer'
import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../../icons/glyphs'
import { Spinner } from '../Spinner'

import { BasicVideo } from './BasicVideo'
import { FacebookVideo } from './FacebookVideo'
import { VideoElementProps } from './VideoElement'

import * as S from '../../styles'

export type VideoLoaderProps = {
  alt?: string
  autoplay?: boolean
  consentWall: React.ComponentType<{
    autoplay?: boolean
    backgroundImage?: string
    children: React.ReactElement
    rawMarkup: string
  }>
  className?: string
  isActive?: boolean
  isLocked?: boolean
  onVideoMount?: () => void
  placeholder?: string
  provider?: string
  srcSet?: string
  ratio?: number
  videoHtml: string
}

declare const FB: any

function VideoLoaderComponent({
  alt,
  autoplay,
  consentWall: ConsentWall,
  className,
  isActive = true,
  isLocked,
  onVideoMount,
  placeholder,
  provider,
  srcSet,
  ratio = 0.5625,
  videoHtml,
}: VideoLoaderProps): React.ReactElement<VideoLoaderProps> {
  const [videoLoaded, setVideoLoaded] = React.useState(false)
  const [videoMounted, setVideoMounted] = React.useState(false)

  const ref = useEnterView(() => {
    if (autoplay) {
      setVideoMounted(true)
    }
  }, 1)

  if (!isActive) {
    return (
      <S.video.LoaderWrapper ref={ref} className={className} isLocked={isLocked} onClick={onLoaderClick}>
        <S.video.Button isLocked={isLocked}>
          <glyphs.fill.VideoPlay size={72} />
        </S.video.Button>
        <S.video.Thumbnail src={placeholder} srcSet={srcSet} loading="lazy" alt={alt} />
      </S.video.LoaderWrapper>
    )
  }

  const VideoElement = getVideoElement(provider)

  return (
    <>
      {!videoLoaded && (
        <S.video.LoaderWrapper ref={ref} className={className} isLocked={isLocked} onClick={onLoaderClick}>
          <S.video.Button isLocked={isLocked}>
            {videoMounted ? <Spinner /> : <glyphs.fill.VideoPlay size={72} />}
          </S.video.Button>
          <S.video.Thumbnail src={placeholder} srcSet={srcSet} loading="lazy" alt={alt} />
        </S.video.LoaderWrapper>
      )}
      {videoMounted && (
        <S.video.Wrapper videoLoaded={videoLoaded} className={className} ratio={ratio}>
          {ConsentWall !== undefined ? (
            <ConsentWall autoplay={autoplay} backgroundImage={placeholder} rawMarkup={videoHtml}>
              <VideoElement dangerouslySetInnerHTML={{ __html: videoHtml }} onReady={() => setVideoLoaded(true)} />
            </ConsentWall>
          ) : (
            <VideoElement dangerouslySetInnerHTML={{ __html: videoHtml }} onReady={() => setVideoLoaded(true)} />
          )}
        </S.video.Wrapper>
      )}
    </>
  )

  function onLoaderClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.preventDefault()
    onVideoMount?.()
    setVideoMounted(true)
  }
}

export const VideoLoader = styled(VideoLoaderComponent)<VideoLoaderProps>``
VideoLoader.displayName = 'VideoLoader'

const useEnterView = (onEnterView: () => void, threshold: number): React.RefObject<HTMLDivElement> => {
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    // TODO: FIXME: we create an intersection observer after each render. We should create once and keep a ref.

    // References to window object must be done in useEffect or componentDidMount to be
    // ignored by gatsby when building the DS website. Otherwise we get
    // `XXX is not defined` errors
    const observer = new window.IntersectionObserver(
      (entries) => {
        const hasEnteredView = entries.some((entry) => entry.intersectionRatio >= threshold)
        if (hasEnteredView) {
          onEnterView()
        }
      },
      { threshold },
    )

    if (ref.current !== null) {
      // TODO : check error ref.current "Argument of type null is..."
      observer.observe(ref.current)
    }
  })

  return ref
}

function getVideoElement(provider: string | undefined): React.ElementType<VideoElementProps> {
  if (provider === 'Facebook') {
    return FacebookVideo
  }
  return BasicVideo
}
