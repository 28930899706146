import * as React from 'react'
import styled from 'styled-components'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'
import { lightweight, standalone, SymbolProps } from '../../spritesheetUtils'

const ID = 'instagram-third-party-glyph-icon'

export function InstagramSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        d="M22.465 0c.346.052.679.129 1.02.143 4.76.261 8.303 3.695 8.422 8.439.125 4.994.125 9.994-.004 14.993-.126 4.664-3.658 8.201-8.33 8.326a290.09 290.09 0 0 1-15.072.003c-4.142-.1-7.293-2.891-8.127-6.91-.174-.832-.26-1.68-.374-2.528V9.631c.049-.368.123-.731.15-1.103C.388 4.39 3.021 1.255 7.032.39 7.887.201 8.762.13 9.625 0h12.84ZM9.257 3.051c-3.937.089-6.12 2.262-6.212 6.174-.099 4.566-.092 9.145.005 13.711.087 3.804 2.323 5.984 6.111 6.114 2.292.077 4.597.018 6.895.018v-.016c2.358 0 4.71.063 7.058-.017 1.915-.06 3.623-.72 4.798-2.35.783-1.097 1.128-2.375 1.134-3.694.04-4.574.088-9.146 0-13.715-.075-3.914-2.27-6.136-6.16-6.223a300.519 300.519 0 0 0-13.629-.002Zm6.893 4.761c4.573.064 8.153 3.717 8.125 8.278-.031 4.596-3.71 8.23-8.3 8.183-4.602-.046-8.217-3.73-8.168-8.318.066-4.6 3.756-8.204 8.343-8.143Zm-.11 2.915c-2.982.003-5.321 2.35-5.315 5.346a5.274 5.274 0 0 0 5.285 5.293c2.99.01 5.352-2.326 5.352-5.309.005-2.982-2.338-5.337-5.322-5.33Zm8.558-5.155c1.105-.003 1.92.804 1.924 1.9.005 1.074-.854 1.949-1.908 1.95-1.033.005-1.944-.896-1.952-1.933-.011-1.045.862-1.915 1.936-1.917Z"
        clipRule="evenodd"
      />
    </symbol>
  )
}

const InstagramStandaloneContent = standalone(InstagramSymbol, ID)

export const Instagram = styled(function InstagramStandaloneComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-instagram">
      <InstagramStandaloneContent />
    </Icon>
  )
})``

export const InstagramLightweight = styled(function InstagramComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-instagram">
      {lightweight(ID)}
    </Icon>
  )
})``
