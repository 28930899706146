import * as yup from 'yup'
import * as libphone from 'libphonenumber-js/max'

import { locales } from './locale'

export function phone() {
  return new PhoneSchema()
}

export class PhoneSchema extends yup.StringSchema {
  constructor() {
    super()

    // HACK
    // @ts-expect-error: schema.type is supposed to be read-only, but who cares, right ?
    this.type = 'phone'

    this.withMutation((schema) => {
      return schema.test('phone-number', locales.phone.format, (value) => {
        if (value === undefined) {
          return true
        }

        try {
          const number = libphone.parsePhoneNumber(value)
          return number.isValid()
        } catch (error) {
          return false
        }
      })
    })
  }

  size(max = 15, message: yup.Message<{ max: number }> = locales.phone.max) {
    return super.max(max, message)
  }
}
