import { PublicUser, UserLabel, UserRole } from '@owl-nest/api-client/latest'
import { t } from '@owl-nest/localize'
import { text } from '@owl-nest/utils'
import * as plume from '@ulule/owl-kit-components/next'

import * as image from './image'

/**
 *
 * @param user
 * @param version a string specifying the version to retrieve (e.g. 'small'|'medium'|'large'|'full')
 */
export function avatar(user: PublicUser, version: string): string | undefined {
  return image.getUrl(user.avatar.versions, version)
}

/**
 * Returns the user display name if any, otherwise formats the name as "First-name Last-name".
 * Falls back to the username.
 * @param user
 */
export function fullName(user: PublicUser): string {
  if (!user) {
    return ''
  }

  if (user.screenname) return user.screenname

  if (user.first_name && user.last_name) return `${text.capitalize(user.first_name)} ${text.capitalize(user.last_name)}`

  if (user.username) return user.username

  return user.name
}

/**
 * Formats the user name in a private manner.
 * e.g. "Lionel Fournier" becomes "Lionel F."
 * @param user
 */
export function privatizedName(user: PublicUser): string {
  if (user.first_name && user.last_name) {
    return `${text.capitalize(user.first_name)} ${text.capitalize(user.last_name.substr(0, 1))}.`
  }

  return user.screenname || user.name
}

/**
 * Returns a short version of the user name: their first name if found, or otherwise their custom display name or username.
 * @param user
 */
export function shortName(user: PublicUser): string {
  if (user.first_name) {
    return user.first_name
  }

  return user.screenname || user.username || user.name
}

/**
 * Returns the user display name if any, otherwise their username
 * @param user
 */
export function username(user: PublicUser): string {
  if (user.screenname) {
    return user.screenname
  }
  return user.username
}

export type RoleTagBackgroundColor = plume.RoleTagProps['backgroundColor']

export function tag(
  user: PublicUser,
  role?: UserRole,
): { backgroundColor?: RoleTagBackgroundColor; label: string } | undefined {
  if (user.is_staff) {
    return {
      backgroundColor: 'secondary-green-500',
      label: t('Ulule Team'),
    }
  }

  switch (role) {
    case UserRole.Owner: {
      return {
        backgroundColor: 'brand-indigo',
        label: t('Creator'),
      }
    }
    case UserRole.Editor:
    case UserRole.Moderator: {
      return {
        backgroundColor: 'brand-indigo',
        label: t('Moderator'),
      }
    }
  }
}

export function label(label: UserLabel): { color?: RoleTagBackgroundColor; label: string } | undefined {
  switch (label) {
    case UserLabel.Captain: {
      return {
        color: 'brand-indigo',
        label: t('Captain'),
      }
    }
    case UserLabel.Connector: {
      return {
        color: 'secondary-yellow-500',
        label: t('Ambassador'),
      }
    }
    case UserLabel.Expert: {
      return {
        color: 'primary-blue-500',
        label: t('Expert'),
      }
    }
    case UserLabel.Official: {
      return {
        color: 'primary-red-900',
        label: t('Partner'),
      }
    }
    case UserLabel.Team: {
      return {
        color: 'primary-blue-900',
        label: t('Team'),
      }
    }
  }
}
