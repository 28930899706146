import * as React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

export function toDataUrl<P extends object>(
  component: React.FunctionComponent<P> | React.ComponentClass<P> | keyof React.ReactSVG,
  props: P,
): string {
  const svgToString = renderToStaticMarkup(React.createElement(component, props))
  try {
    return `data:image/svg+xml;utf8, ${encodeURIComponent(svgToString)}`
  } catch (e) {
    console.log(component, props)
    console.log(e)
    return ''
  }
}
