import styled, { css } from 'styled-components'

import * as breakpoints from '../../constants/breakpoints'
import * as button from '../../styles/button'
import * as colors from '../../constants/colors'
import * as copy from '../../styles/copy'
import * as effects from '../../constants/neue/effects'
import * as glyphs from '../../icons/glyphs'
import * as heading from '../../styles/heading'
import * as wysiwyg from '../../styles/wysiwyg'
import { Tag } from '../tag'

type LockedProps = {
  isLocked: boolean
}
type MediaTypeProps = {
  type: 'image' | 'video'
}

type LockLabelProps = LockedProps & MediaTypeProps

export const NewsCardWrapper = styled.div`
  background-color: ${colors.PRIMARY_GREY_000};
  border-radius: 4px;
  box-shadow: ${effects.SHADOW_3};
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 592px;
  min-width: 288px;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${button.EditButton} {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 2;
  }
`

export const LockLabel = styled(copy.M)<LockLabelProps>`
  display: ${(props) => (props.isLocked ? 'flex' : 'none')};
  align-items: center;
  gap: 8px;
  color: ${colors.PRIMARY_GREY_000};
  font-weight: 600; // HACK: Irregular font manipulation.
  user-select: none;
  z-index: 1;
  position: absolute;
  top: ${(props) => (props.type === 'image' ? '50%' : '78%')};
  left: ${(props) => (props.type === 'image' ? '50%' : '4%')};
  transform: ${(props) => (props.type === 'image' ? 'translate(-50%, -50%)' : 'translate(0%, 0%)')};

  @media screen and ${breakpoints.MOBILE_L} {
    top: ${(props) => (props.type === 'image' ? '50%' : '84%')};
  }
`

export const NewsCardMediaWrapper = styled.div<LockLabelProps>`
  min-height: 162px;
  aspect-ratio: 16/9;
  width: 100%;
  overflow: hidden;
  position: relative;

  ${(props) =>
    props.isLocked &&
    props.type === 'image' &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors.PRIMARY_GREY_900};
        opacity: 0.5;
      }
    `}
`

export const NewsCardImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const NewsCardContainer = styled.div`
  padding: 16px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`
export const NewsCardHeader = styled.div`
  height: 20px;
  justify-content: space-between;
  align-items: start;
  display: flex;
  width: 100%;

  ${Tag} {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  ${glyphs.Icon} {
    top: 0px !important;
  }
`

export const NewsCardDate = styled(copy.XS)`
  color: ${colors.TEXT_TERTIARY};
  flex-grow: 1;
  font-weight: 400; // HACK: Irregular font manipulation.
  text-transform: uppercase;
`

const textBase = css`
  color: ${colors.PRIMARY_GREY_900};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
`

export const NewsCardTitle = styled(heading.XXS)`
  max-height: 50px;
  padding-bottom: 1.5px;
  -webkit-line-clamp: 2;
  ${textBase};

  @media screen and ${breakpoints.MOBILE_L} {
    max-height: 60px;
  }
`

export const NewsCardDescription = styled.div<LockedProps>`
  ${wysiwyg._paragraphStyle};
  ${textBase};
  -webkit-line-clamp: 3;
  height: fit-content;
  max-height: 80px;

  a {
    ${wysiwyg._hyperlinkStyle};
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.isLocked &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }
    `};
`

export const NewsCardFooter = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;

  @media screen and ${breakpoints.MOBILE_L} {
    gap: 20px;
  }
`

export const CommentsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;

  ${glyphs.Icon} {
    color: ${colors.TEXT_TERTIARY};
  }
`

export const CommentsCount = styled(copy.XS)`
  min-width: 110px;
  color: ${colors.TEXT_TERTIARY};
  font-weight: 400; // HACK: Irregular font manipulation.
`

export const SubscriptionContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-left: auto;

  ${glyphs.Icon} {
    margin-top: 1px;
    color: ${({ theme }) => `${theme?.colors?.primary ?? colors.PRIMARY_BLUE_500}`};
  }

  :hover {
    ${glyphs.Icon} {
      color: ${({ theme }) => `${theme?.colors?.hover ?? colors.PRIMARY_BLUE_700}`};
    }
  }
`

export const SubscriptionText = styled(heading.XXXS)`
  font-size: 14px; // HACK: Irregular font manipulation. See recette

  @media screen and ${breakpoints.MOBILE_L} {
    font-size: 16px; // HACK: Irregular font manipulation. See recette
  }
`
