import 'url-search-params-polyfill'

import { Either } from '@owl-nest/monad'
import * as logger from '@owl-nest/logger'

import { fetchAndRetry } from './fetch.ts'

export type RetryOptions = {
  delay?: number
  maxAttempts: number
}

export type RequestOptions = Omit<RequestInit, 'body'> &
  (
    | {
        type: 'json' | 'formdata' | 'urlencoded'
        body: any
      }
    | {
        type?: undefined
        body?: RequestInit['body']
      }
  )

export type HttpFailure = {
  status: number
  url: string
  body: any
  cause?: any
}

export type HttpSuccess<BODY> = {
  headers: Record<string, string>
  body: BODY
  url: string
  status: number
  ok: boolean
}

export type HttpResult<VALUE = unknown> = Either<HttpFailure, VALUE>
export type HttpResponse<BODY = unknown> = HttpResult<HttpSuccess<BODY>>

export async function request<BODY = unknown>(
  url: string,
  options: RequestOptions,
  retryOptions?: RetryOptions,
): Promise<HttpResponse<BODY>> {
  const result = await pureRequest<BODY>(url, options, retryOptions)

  result.caseOf({
    left: (failure) => {
      if (!isExpectedError(failure) && failure.status !== 401) {
        const classificationString = getErrorClassification(failure.body)
        if (classificationString === 'AlreadyExistsError') {
          return
        }

        const classification = classificationString ? `${classificationString}: ` : ''
        const message = failure.body[0]?.message ?? ''
        logger.err(
          `[api-client][${failure.status}] ${classification}${message ?? 'unknown message'}`,
          {
            request: { url, options },
            response: failure,
          },
          (scope) => {
            scope.setFingerprint(['{{ default }}', String(failure.status)])
          },
        )
      }
    },
    right: (success) => {
      if (!String(success.status).startsWith('2') || (!success.ok && !isExpectedError(success))) {
        const classification = getErrorClassification(success.body)
        if (classification === 'AlreadyExistsError') {
          return
        }

        logger.err(
          `[api-client][${success.status}] Unexpected ${classification ?? ''}`,
          {
            request: { url, options },
            response: success,
            unexpected: true,
          },
          (scope) => {
            scope.setFingerprint(['{{ default }}', String(success.status)])
          },
        )
      }
    },
  })

  return result
}

async function pureRequest<BODY = unknown>(
  url: string,
  options: RequestOptions = {},
  retryOptions: RetryOptions = { delay: 300, maxAttempts: 5 },
): Promise<HttpResponse<BODY>> {
  const init = makeRequestInit(options)

  const response = await fetchAndRetry(url, init, retryOptions.delay, retryOptions.maxAttempts)
  let body
  try {
    body = await getResponseBody(response)
  } catch (error) {
    // bypass SyntaxError for Firefox since it doesn't handle well Content-Length header
    // when a response with Content-Type: application/json is returned without any content
    // it generates an error
    if (error instanceof SyntaxError) {
      return Either.right(requestSuccess(response, body))
    }

    if (!url.includes('stats.json')) {
      logger.err('[api-client] Caught error while fetching resource', {
        body,
        error,
        response,
        url,
      })
    }

    return Either.left({
      status: response.status,
      url,
      body,
    })
  }

  if (!response.ok) {
    return Either.left({
      status: response.status,
      url,
      body,
    })
  }

  return Either.right(requestSuccess(response, body))
}

function requestSuccess<BODY>(response: Response, body: BODY): HttpSuccess<BODY> {
  // dont use {...response } or Object.assign, response won't be copied. We have to
  // manually pick and set the properties on response.
  return {
    url: response.url,
    status: response.status,
    ok: response.ok,
    headers: Object.fromEntries(response.headers.entries()),
    body,
  }
}

function makeRequestInit({ body, type, ...baseInit }: RequestOptions): RequestInit {
  const init: RequestInit = {
    method: 'GET',
    mode: 'cors',
    credentials: 'same-origin',
    body,
    ...baseInit,
  }

  if (type === 'json') {
    return jsonTransform(init, body)
  }

  if (type === 'formdata') {
    return formDataTransform(init, body)
  }

  if (type === 'urlencoded') {
    return urlSearchParamsTransform(init, body)
  }

  if (!validateBody(body)) {
    console.warn('trying to fetch with an invalid body', body)
  }

  return init
}

function jsonTransform(init: RequestInit, body: any): RequestInit {
  const headers = new Headers(init.headers)
  headers.set('Accept', 'application/json')
  headers.set('Content-Type', 'application/json')
  return {
    ...init,
    body: JSON.stringify(body),
    headers: headers,
  }
}

function formDataTransform(init: RequestInit, body: any): RequestInit {
  const formData = new FormData()
  for (const [key, value] of Object.entries(body)) {
    if (typeof value === 'string' || value instanceof window.Blob) {
      formData.append(key, value)
    } else {
      formData.append(key, String(value))
    }
  }
  return {
    ...init,
    body: formData,
  }
}

function urlSearchParamsTransform(init: RequestInit, body: any): RequestInit {
  const urlSearchParams = new URLSearchParams(body)

  // See https://github.com/jerrybendy/url-search-params-polyfill#known-issues
  // we use this pollyfill, and pollyfilled navigator don't know they have to set this header,
  // so we set it even if spec says it should be set by the browser.
  const headers = new Headers(init.headers)
  headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')

  return {
    ...init,
    headers,
    body: urlSearchParams,
  }
}

function validateBody(body: any): body is RequestInit['body'] {
  return (
    body === undefined ||
    body === null ||
    (window?.Blob && body instanceof window.Blob) ||
    (window?.ArrayBuffer && body instanceof window.ArrayBuffer) ||
    (window?.FormData && body instanceof window.FormData) ||
    (window?.URLSearchParams && body instanceof window.URLSearchParams) ||
    (window?.ReadableStream && body instanceof window.ReadableStream) ||
    typeof body === 'string'
  )
}

async function getResponseBody(response: Response): Promise<any> {
  switch (type(response)) {
    case 'empty': {
      return undefined
    }
    case 'json': {
      try {
        return response.json()
      } catch (error: any) {
        throw { response, ...error }
      }
    }
    case 'text': {
      try {
        return response.text()
      } catch (error: any) {
        throw { response, ...error }
      }
    }
  }
}

function type(response: Response): 'empty' | 'json' | 'text' {
  if (response.headers.get('Content-Length') === '0') {
    return 'empty'
  } else if (
    response.headers.get('Content-Type')?.includes('application/json') ||
    response.headers.get('Content-Type')?.includes('application/vnd.geo+json')
  ) {
    return 'json'
  } else {
    return 'text'
  }
}

/* Some errors ar business errors (like 401 when user is not registered). Those
errors should not be logged, because they are expected in this situation. We log
only errors that should not happen */
export function isExpectedError(request: HttpFailure | HttpSuccess<unknown>): boolean {
  if (typeof request.body === 'object' && request.body !== null && 'expected' in request.body) {
    return request.body.expected
  }

  return false
}

/**
 * Returns an error classification based on various API formats. The following are supported:
 *
 * {body: [{ classification: string, fieldNames: Array, message: string }]},
 * {body: { errors: [{ classification: string, fieldNames: Array, message: string}], type: string }},
 * {body: { classication: string, message: string, type: string }},
 *
 * (Yup, that 'classication' is intended. It's a typo API-side.)
 *
 * @param body Failure body to extract classification from
 * @returns The extracted classification, or `undefined` if none found
 */
function getErrorClassification(body: any): string | undefined {
  if (body === null || body === undefined) {
    return undefined
  }

  // Have a generic error for very old browsers
  if (!Array.isArray) return undefined

  if (Array.isArray(body)) {
    const [error] = body
    return error && error.classification
  }

  const { errors } = body
  if (errors && Array.isArray(errors)) {
    const [error] = errors
    return error && error.classification
  }

  if (body.classication) return body.classication
  if (body.classification) return body.classification

  return undefined
}
