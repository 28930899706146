import * as React from 'react'
import styled, { css } from 'styled-components'

import { t } from '@owl-nest/localize'
import { useClientSide } from '@owl-nest/hooks'
import * as plume from '@ulule/owl-kit-components/next'

import { useConfigurationContext } from '../../../context/configuration/configurationContext'
import { useURLContext } from '../../../context/urls/urlContext'
import { getUrlWithUTMs } from '../../../utils/urls'

import { ChannelList } from '../../Channel'
import { MobileNavigationDrawer } from '../../drawers/MobileNavigationDrawer'
import { Toggle } from '../../Toggle'
import { UluleLogo } from '../../logos/UluleLogo'

import * as S from '../../style'

const ICON_CONTAINER_CLASSNAME = 'list-icon-hover'
const ICON_CONTAINER_SELECTOR = `.${ICON_CONTAINER_CLASSNAME}`

type Items = {
  href: string
  icon: React.ReactNode
  onClick?: () => void
  title: string
}

type CrowdfundingProps = {
  siteIdentifier: string
  subnavConfig?: {
    mobileOnlySection: React.ReactNode
    title?: React.ReactNode
    withDiscoverLink?: boolean
    withSeparator?: boolean
  }
  onClose?: () => void
  isDrawerOpen: boolean
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function Crowdfunding({
  siteIdentifier,
  subnavConfig,
  onClose,
  isDrawerOpen = false,
  setIsDrawerOpen,
}: CrowdfundingProps): React.ReactElement<CrowdfundingProps> {
  const isClientSide = useClientSide()
  const { translations } = useConfigurationContext()
  const urlContext = useURLContext()

  const hasSubnav = subnavConfig?.mobileOnlySection !== undefined
  const kind = hasSubnav ? 'secondary' : 'primary'

  const menuItems = React.useMemo(() => getMenuItems(), [urlContext])

  return (
    <>
      <S.DesktopOnlyNav>
        <LogoLink href={getUrlWithUTMs(urlContext.home, siteIdentifier, 'ulule', 'logo')}>
          <UluleLogo />
        </LogoLink>
        {menuItems.map((item) => {
          if (item.items !== undefined) {
            return (
              <plume.Menu forceMount={!isClientSide} key={item.title} title={item.title}>
                {ItemsLineDropdown(item.items)}
              </plume.Menu>
            )
          } else {
            return (
              <S.MainNavLinkItem key={item.title} href={item.link}>
                {item.title}
              </S.MainNavLinkItem>
            )
          }
        })}
      </S.DesktopOnlyNav>
      <MobileOnly>
        <MobileNavigationDrawer
          setOpen={(open) => {
            setIsDrawerOpen(open)

            if (!open && onClose) {
              onClose()
            }
          }}
          open={isDrawerOpen}
          title={subnavConfig?.title}
        >
          {subnavConfig?.mobileOnlySection}
          {hasSubnav && (
            <SeparatorWrapper>
              {subnavConfig.withSeparator && <hr />}
              <plume.styles.heading.XXXXS>Ulule.com</plume.styles.heading.XXXXS>
            </SeparatorWrapper>
          )}
          <S.CommonMobileEntries>
            {menuItems.map((item) => {
              if (item.items !== undefined) {
                return (
                  <Toggle key={item.title} kind={kind} title={item.title}>
                    {ItemsLineToggle(item.items, kind)}
                  </Toggle>
                )
              } else {
                return (
                  <S.LinkItem key={item.title} kind={kind} href={item.link}>
                    {item.title}
                  </S.LinkItem>
                )
              }
            })}
            {subnavConfig?.withDiscoverLink && (
              <S.LinkItem kind={kind} href={urlContext.discoverLandingPage}>
                <>
                  <plume.glyphs.stroke.Menu size={20} />
                  {translations.discoverUlule}
                </>
              </S.LinkItem>
            )}
          </S.CommonMobileEntries>
        </MobileNavigationDrawer>
        <S.Overlay visible={isDrawerOpen}>{isDrawerOpen && <S.LockScroll />}</S.Overlay>
      </MobileOnly>
    </>
  )

  function getMenuItems() {
    return [
      {
        title: t('Start a project'),
        link: getUrlWithUTMs(urlContext.proposal, siteIdentifier, 'ulule', 'header'),
      },
      {
        title: t('Training courses'),
        items: [
          [
            {
              title: t('Training courses for individuals'),
              icon: (
                <plume.glyphs.colored.Education
                  backgroundColor={plume.COLORS.BLUE_SHADE_3}
                  tint="blue"
                  size={18}
                  hoverSelector={ICON_CONTAINER_SELECTOR}
                />
              ),
              href: getUrlWithUTMs('https://formation.ulule.com', siteIdentifier, 'formation', 'sous_header'),
            },
            {
              title: t('Skills assessment'),
              icon: (
                <plume.glyphs.colored.Company
                  backgroundColor={plume.COLORS.BLUE_SHADE_3}
                  tint="blue"
                  size={18}
                  hoverSelector={ICON_CONTAINER_SELECTOR}
                />
              ),
              href: getUrlWithUTMs(
                'https://formation.ulule.com/formations/bilan-de-competences',
                siteIdentifier,
                'formation',
                'sous_header',
              ),
            },
            {
              title: t('In-company workshops'),
              icon: (
                <plume.glyphs.colored.Training
                  backgroundColor={plume.COLORS.BLUE_SHADE_3}
                  tint="blue"
                  size={18}
                  hoverSelector={ICON_CONTAINER_SELECTOR}
                />
              ),
              href: getUrlWithUTMs(
                'https://partenaires.ulule.com/formations-entreprise',
                siteIdentifier,
                'formation',
                'sous_header',
              ),
            },
          ],
        ],
      },
      {
        title: t('Participate'),
        items: [
          [
            {
              title: t('See all projects'),
              icon: (
                <plume.glyphs.colored.Egg
                  backgroundColor={plume.COLORS.BLUE_SHADE_3}
                  tint="blue"
                  size={18}
                  hoverSelector={ICON_CONTAINER_SELECTOR}
                />
              ),
              href: getUrlWithUTMs(urlContext.discover, siteIdentifier, 'ulule', 'sous_header'),
            },
            {
              title: t('Ulule boutique'),
              icon: (
                <plume.glyphs.colored.Marketplace
                  backgroundColor={plume.COLORS.BLUE_SHADE_3}
                  tint="blue"
                  size={18}
                  hoverSelector={ICON_CONTAINER_SELECTOR}
                />
              ),
              href: getUrlWithUTMs('https://boutique.ulule.com', siteIdentifier, 'boutique', 'sous_header'),
            },
          ],
          [
            {
              title: t('Become partner'),
              icon: (
                <plume.glyphs.colored.Partners
                  backgroundColor={plume.COLORS.BLUE_SHADE_3}
                  tint="blue"
                  size={18}
                  hoverSelector={ICON_CONTAINER_SELECTOR}
                />
              ),
              href: getUrlWithUTMs('https://partenaires.ulule.com', siteIdentifier, 'partenaires', 'sous_header'),
            },
          ],
        ],
      },
    ]
  }
}

function ItemsLineDropdown(listItems: Array<Items[]>): React.ReactElement {
  return (
    <ItemsLineDropdownWrapper>
      {listItems.map((items, index) => (
        <React.Fragment key={index}>
          {items.map((item) => (
            <li key={item.title} onClick={item?.onClick} className={ICON_CONTAINER_CLASSNAME}>
              <plume.Link href={item?.href}>
                {item.icon}
                <plume.styles.copy.S>{item.title}</plume.styles.copy.S>
                <plume.glyphs.stroke.ArrowRight size={16} />
              </plume.Link>
            </li>
          ))}
          {index !== listItems.length - 1 && (
            <li>
              <hr />
            </li>
          )}
        </React.Fragment>
      ))}
    </ItemsLineDropdownWrapper>
  )
}

function ItemsLineToggle(listItems: Array<Items[]>, kind: 'secondary' | 'primary') {
  return (
    <ItemsLineToggleWrapper kind={kind}>
      {listItems.flatMap((items, index) => {
        const list = items.map((item, index) => (
          <li onClick={item?.onClick} key={`${item?.href}-${index}`}>
            <plume.Link kind={kind} href={item?.href}>
              {item.icon}
              <plume.styles.copy.S>{item.title}</plume.styles.copy.S>
            </plume.Link>
          </li>
        ))
        if (index !== listItems.length - 1) {
          list.push(
            <li key={`hr-${index}`}>
              <hr />
            </li>,
          )
        }
        return list
      })}
    </ItemsLineToggleWrapper>
  )
}

const MobileOnly = styled(S.MobileOnlyNav)`
  ${ChannelList} {
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 16px;
  }
`

const LogoLink = styled.a`
  align-items: center;
  color: ${plume.COLORS.PRIMARY_BLACK};
  display: flex;
  height: 100%;
  justify-content: center;
`

const ItemsLineDropdownWrapper = styled.div`
  *:last-child {
    margin-bottom: 0;
  }

  ${plume.glyphs.Icon} {
    overflow: visible;
  }

  li {
    margin-bottom: 16px;
    position: relative;
    padding-right: 50px;

    hr {
      border-color: ${plume.COLORS.GREY_SHADE_5};
      width: calc(100% + 50px);
      border: 0px;
      border-bottom: 1px solid ${plume.COLORS.GREY_SHADE_5};
    }

    ${plume.Link} {
      align-items: center;
      display: flex;
    }

    ${plume.styles.copy.S} {
      margin-left: 16px;
      font-weight: 600; // HACK: Irregular font manipulation
    }

    ${plume.glyphs.stroke.ArrowRight} {
      right: 15px;
      position: absolute;
      top: 10px;
      transition-duration: 300ms;
      transition-property: opacity, right;
      transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
      opacity: 0;
    }

    &:hover {
      ${plume.glyphs.colored.Wrapper} {
        background-color: ${plume.COLORS.BLUE_SHADE_1};
      }

      ${plume.styles.copy.S} {
        color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
      }

      ${plume.glyphs.stroke.ArrowRight} {
        color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
        right: -5px;
        opacity: 1;
      }
    }
  }
`

export const ItemsLineToggleWrapper = styled.div<{ kind: 'secondary' | 'primary' }>`
  *:last-child {
    margin-bottom: 0;
  }

  ${plume.glyphs.Icon} {
    overflow: visible;
  }

  li {
    margin-bottom: 16px;
    position: relative;

    hr {
      width: 100%;
      border: 0px;
      border-bottom: 1px solid ${plume.COLORS.GREY_SHADE_5};
    }

    ${plume.Link} {
      align-items: center;
      display: flex;
    }

    ${plume.styles.copy.S} {
      margin-left: 16px;
      font-weight: 600; // HACK: Irregular font manipulation
      line-height: 18px; // HACK: Irregular font manipulation

      ${({ kind }) => {
        if (kind === 'secondary') {
          return css`
            color: ${plume.COLORS.GREY_SHADE_2};
          `
        }
      }}
    }

    &:hover {
      ${plume.styles.copy.S} {
        color: ${plume.COLORS.PRIMARY_BLUE_HOVER};
      }
    }
  }
`

const SeparatorWrapper = styled.span`
  ${plume.styles.heading.XXXXS} {
    padding-top: 40px;
    color: ${plume.COLORS.PRIMARY_BLACK};
  }

  hr {
    border: 0px;
    border-bottom: 1px solid ${plume.COLORS.GREY_SHADE_5};
    left: 0;
    position: absolute;
    width: 100%;
  }
`
