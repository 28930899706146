import * as React from 'react'

type PollConfig = {
  pollTime?: number
  retryInterval?: number
  maxRetries?: number
  onFailure?: (error: any) => void
}

type Poller = { start: () => void; stop: () => void }

export function usePoll(
  callback: () => Promise<void>,
  { pollTime = 10000, retryInterval = 1000, maxRetries = 3, onFailure = () => {} }: PollConfig = {},
): Poller {
  const retryCountRef = React.useRef(0)
  const timeoutHandle = React.useRef<number | undefined>()

  return { start: poll, stop: clear }

  async function clear(): Promise<void> {
    clearTimeout(timeoutHandle.current)
  }

  async function poll(): Promise<void> {
    try {
      await callback()
      retryCountRef.current = 0
      timeoutHandle.current = window.setTimeout(poll, pollTime)
    } catch (error) {
      if (retryCountRef.current < maxRetries) {
        retryCountRef.current += 1
        timeoutHandle.current = window.setTimeout(poll, retryInterval)
      } else {
        onFailure(error)
      }
    }
  }
}
