import * as React from 'react'

import { SelectApi } from './types'

export type SelectContext = SelectApi['register']

const selectContext = React.createContext<SelectContext>(() => {
  throw Error('Option outside of Select')
})

export function useSelectContext(): SelectContext {
  return React.useContext(selectContext)
}

export const SelectContextProvider = selectContext.Provider
