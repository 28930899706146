import {
  format as dateFormat,
  formatDistanceToNow as dateFormatDistanceToNow,
  intlFormatDistance as dateIntlFormatDistance,
} from '@owl-nest/date-fns'
import * as dateFnsTz from 'date-fns-tz'

import { locales, defaultLocaleName } from './locales'
import * as UFE from './UFE'

type Locales = typeof locales
type LocaleName = keyof Locales
type Locale = Locales[LocaleName]

export function daysFromNow(daysToAdd = 7): number {
  const date = new Date()
  return date.setDate(date.getDate() + daysToAdd)
}

/**
 * Formats a given date using the provided Unicode token.
 *
 * @remarks
 * A locale may be provided through the options object.
 * If none, this uses the global setting stored window.UFE.locale, otherwise falls back to English.
 *
 * @param date
 * @param formatStr string representation of the Unicode token to use for formatting
 * @param options
 */
export function format(date: Date | number, formatStr: string, options?: { locale?: string }): string {
  return dateFormat(date, formatStr, {
    ...options,
    locale: locales[getLocaleName(options?.locale)],
  })
}

/**
 * Expresses the duration between a given date and now in words.
 *
 * @remarks
 * A locale may be provided through the options object.
 * If none, this uses the global setting stored window.UFE.locale, otherwise falls back to English.
 *
 * @param date
 * @param options
 */
export function formatDistanceToNow(
  date: Date | number,
  options?: {
    addSuffix?: boolean
    includeSeconds?: boolean
    locale?: Locale
  },
): string {
  return dateFormatDistanceToNow(date, {
    ...options,
    locale: locales[getLocaleName(options?.locale)],
  })
}

export function intlFormatDistance(
  date: Date | number,
  baseDate: Date | number = Date.now(),
  options?: {
    locale?: string
    numeric?: 'always' | 'auto'
    style?: 'long' | 'narrow' | 'short'
  },
) {
  return dateIntlFormatDistance(date, baseDate, {
    locale: locales[getLocaleName(options?.locale)].code,
    numeric: 'always',
    style: 'short',
    ...options,
  })
}

/**
 * Returns a future date from now, adding the given amount of minutes.
 *
 * @param minutesToAdd defaults to 30
 */
export function minutesFromNow(minutesToAdd = 30): number {
  const now = Date.now() // current millisecond timestamp
  return now + minutesToAdd * 60 * 1000
}

export {
  addBusinessDays,
  addDays,
  addHours,
  addMonths,
  compareAsc,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  eachDayOfInterval,
  endOfWeek,
  getHours,
  getMinutes,
  getSeconds,
  parse,
  parseISO,
  setHours,
  setMinutes,
  startOfWeek,
  subDays,
} from '@owl-nest/date-fns'
export * from './getCountDown'

export function getLocaleName(locale: Locale | string | undefined): LocaleName {
  const configLocale = typeof locale === 'string' ? locale : UFE.USER_LOCALE
  if (configLocale !== undefined && isLocaleName(configLocale)) {
    return configLocale
  }
  console.error(
    `${configLocale} was not found in date-fns locales (${Object.keys(locales).join(
      ', ',
    )}). Defaulting to ${defaultLocaleName}`,
  )
  return defaultLocaleName
}

export function isLocaleName(locale: string): locale is LocaleName {
  return locale in locales
}

export function getMonthsList(locale: Locale | string | undefined): string[] {
  let formatLocale = locale

  if (typeof locale === 'string') {
    formatLocale = locales[getLocaleName(locale)]
  }

  if (formatLocale && typeof formatLocale !== 'string') {
    const months = []
    for (let i = 0; i < 12; i++) {
      months.push(formatLocale.localize?.month(i, { width: 'abbreviated' }))
    }

    return months
  }

  return []
}

export function createDateTimezone(timezone: string, date: Date | string): Date {
  return dateFnsTz.utcToZonedTime(date, timezone)
}

export function getTimezoneCountry(timezone: string): string {
  if (timezone.includes('/')) {
    const timezoneSplited = timezone.split('/')
    return timezoneSplited[1]
  }

  return timezone
}

export function formatTime(date: Date, format: string | undefined = 'HH:mm'): string {
  return dateFnsTz.format(date, format)
}

export function zonedTimeToUtc(date: Date | string, timezone: string) {
  return dateFnsTz.zonedTimeToUtc(date, timezone)
}

export function getRelativeFormattedDateFromOffset(relativeDate: Date, offset = 60 * 60 * 24 * 1000) {
  return relativeDate.getTime() > Date.now() - offset ? intlFormatDistance(relativeDate) : format(relativeDate, 'P')
}
