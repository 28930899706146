import styled, { css } from 'styled-components'

import * as colors from '../constants/colors'
import * as fonts from '../constants/fonts'
import * as bp from '../constants/breakpoints'
import { Icon } from '../icons/glyphs'

export type BaseLinkStyleProps = {
  /** @default false */
  disabled?: boolean
  /** @default false */
  underlined?: boolean
  /** @default false */
  uppercase?: boolean
  /** @default false */
  withIcon?: boolean
}

export type LinkStyleProps = BaseLinkStyleProps & React.AnchorHTMLAttributes<HTMLAnchorElement>

const withIconStyle = css`
  align-items: center;
  display: flex;

  ${Icon} {
    color: ${colors.GREY_SHADE_4};
    margin-right: 5px;
  }

  ${Icon} + * {
    vertical-align: middle;
  }
`

export const _baseStyle = css<BaseLinkStyleProps>`
  cursor: pointer;
  font-family: ${fonts.PRIMARY_FONT};
  font-size: inherit;
  font-weight: 600;
  outline: none;
  text-decoration: none;
  transition: color 200ms ease-in-out;

  ${({ disabled = false }) =>
    disabled &&
    css`
      &[href],
      button& {
        pointer-events: none;
        color: ${colors.BACKGROUND_BUTTON_DISABLED};
      }

      color: ${colors.BACKGROUND_BUTTON_DISABLED};
    `}

  ${({ underlined = false }) =>
    underlined &&
    css`
      text-decoration: underline;
    `}

  ${({ uppercase = false }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ withIcon = false }) => withIcon && withIconStyle}
`

export const Base = styled.a<LinkStyleProps>`
  ${_baseStyle}
`

type BasePrimaryLinkStyleProps = BaseLinkStyleProps & {
  darkened?: boolean
  tinted?: boolean
}

type PrimaryLinkStyleProps = BasePrimaryLinkStyleProps & LinkStyleProps

export const _primaryTintedColor = css`
  color: ${({ theme }) => `${theme?.colors?.primary ?? colors.PRIMARY_BLUE_500}`};
`

export const _primaryStyle = css<BasePrimaryLinkStyleProps>`
  ${({ darkened = false, tinted = false }) => {
    if (tinted && darkened) {
      return css`
        color: ${({ theme }) => `${theme?.colors?.hover ?? colors.PRIMARY_BLUE_700}`};
      `
    } else if (tinted) {
      return _primaryTintedColor
    } else {
      return css`
        color: ${colors.PRIMARY_GREY_900};
      `
    }
  }};

  @media screen and ${bp.LAPTOP} {
    ${({ darkened = false, theme, tinted = false }) => {
      let activeColor = theme?.colors?.hover ?? colors.PRIMARY_BLUE_700
      if (tinted && darkened) {
        activeColor = theme?.colors?.hover ?? colors.PRIMARY_BLUE_900
      }

      return css`
        &:active,
        &:hover {
          &[href],
          button& {
            color: ${activeColor};
          }
        }
      `
    }};
  }
`

export const Primary = styled(Base)<PrimaryLinkStyleProps>`
  ${_primaryStyle}
`
Primary.displayName = 'S.link.Primary'

export const _secondaryStyle = css<BasePrimaryLinkStyleProps>`
  color: ${colors.GREY_SHADE_2};
  font-weight: 400;
  font-size: 14px;

  &:active,
  &:hover {
    &[href],
    button& {
      text-decoration: underline;
    }
  }
`
export const Secondary = styled(Base)<LinkStyleProps>`
  ${_secondaryStyle}
`
Secondary.displayName = 'S.link.Secondary'

export const Inverted = styled(Base)<LinkStyleProps>`
  color: ${colors.PRIMARY_WHITE};

  &:hover,
  &:active {
    &[href],
    button& {
      text-decoration: underline;
    }
  }
`

Inverted.displayName = 'S.link.Inverted'

export const Important = styled(Base)<LinkStyleProps>`
  color: ${colors.PRIMARY_RED};

  &:hover,
  &:active {
    &[href],
    button& {
      color: ${colors.PRIMARY_RED_HOVER};
      text-decoration: underline;
    }
  }
`
Important.displayName = 'S.link.Important'
