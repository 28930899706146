import { lens } from '@owl-nest/config'

type Window = {
  UFE: {
    urls: {
      signup: string
      signout: string
      signin: string
    }
  }
}
const windowLens = lens<Window>()

const global =
  typeof process !== 'undefined'
    ? {
        UFE: process.env.UFE ? JSON.parse(process.env.UFE) : undefined,
        CURRENCIES: process.env.CURRENCIES ? JSON.parse(process.env.CURRENCIES) : undefined,
      }
    : window

export const SIGNUP_URL = windowLens.get('UFE').get('urls').get('signup').value(global)

export const SIGNOUT_URL = windowLens.get('UFE').get('urls').get('signout').value(global)

export const SIGNIN_URL = windowLens.get('UFE').get('urls').get('signin').value(global)
