import * as React from 'react'
import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import { handleLanguageChange, handleCurrencyChange } from '../utils'

type MetaSettingDropdownProps = {
  className?: string
  currencies: {
    [x: string]: {
      separator: string
      symbol: string
      trigram: string
    } & {
      name: string
      shortLabel: string
    }
  }
  currentCurrency: string
  currentLanguage: string
  hasOffset?: boolean
  languages: {
    [code: string]: string
  }
  withRedirectParam?: boolean
}

function MetaSettingDropdownComponent({
  className,
  currencies,
  currentCurrency,
  currentLanguage,
  hasOffset,
  languages,
  withRedirectParam,
}: MetaSettingDropdownProps): React.ReactElement {
  return (
    <Wrapper className={className}>
      <LangDropdown
        hasOffset={hasOffset}
        native={true}
        selected={[currentLanguage]}
        mode="inline"
        onChange={(selected) => {
          handleLanguageChange(selected[0].value)
        }}
      >
        {Object.keys(languages).map((code) => {
          return (
            <plume.Option native={true} key={code} value={code} label={languages[code]}>
              {languages[code]}
            </plume.Option>
          )
        })}
      </LangDropdown>

      <CurrencyDropdown
        hasOffset={hasOffset}
        native={true}
        selected={[currentCurrency]}
        mode="inline"
        onChange={(selected) => {
          handleCurrencyChange(selected[0].value, withRedirectParam)
        }}
      >
        {Object.keys(currencies).map((code) => {
          return (
            <plume.Option
              native={true}
              key={code}
              value={code}
              label={`${currencies[code].symbol} (${currencies[code].trigram})`}
            >
              {currencies[code].symbol} ({currencies[code].trigram})
            </plume.Option>
          )
        })}
      </CurrencyDropdown>
    </Wrapper>
  )
}
export const MetaSettingDropdown = styled(MetaSettingDropdownComponent)``

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${plume.Dropdown} {
    margin-right: 16px;
  }

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    display: flex;
    flex-wrap: nowrap;
  }
`

const LangDropdown = styled(plume.Dropdown)<{ hasOffset?: boolean }>`
  ${({ hasOffset }) => {
    if (hasOffset) {
      return css`
        ${plume.styles.select.Drawer} {
          bottom: 280px;
          top: auto;
        }
      `
    }
  }}
`

const CurrencyDropdown = styled(plume.Dropdown)<{ hasOffset?: boolean }>`
  ${({ hasOffset }) => {
    if (hasOffset) {
      return css`
        ${plume.styles.select.Drawer} {
          bottom: 335px;
          top: auto;
        }
      `
    }
  }}
`
