import * as React from 'react'
import * as jotai from 'jotai'
import { Either } from '@owl-nest/monad'

export interface Action<TYPE = any> {
  type: TYPE
}

export type AnyAction = Action & { [s: string]: any }

export type Reducer<STATE = any, ACTION extends Action = AnyAction> = (state: STATE, action: ACTION) => STATE

export type ThunkAction<RETURN = any, STATE = any, ACTION extends Action = AnyAction> = (
  dispatch: Dispatch<STATE, ACTION>,
  getState: () => STATE,
) => RETURN

export type PromiseAction<STATE = any, RETURN = any, EXTRA = unknown> = {
  types: [string | symbol, string | symbol, string | symbol]
  promise: (dispatch: Dispatch<STATE, AnyAction>, state: STATE) => Promise<RETURN>
} & EXTRA

export interface Dispatch<STATE, ACTION extends Action> {
  // handle promise action
  <RETURN, EXTRA = void>(promise: PromiseAction<STATE, RETURN, EXTRA>): Promise<RETURN>
  // handle thunk action
  <RETURN>(thunk: ThunkAction<RETURN, STATE, ACTION>): RETURN
  // handle basic action
  <CONCRETE_ACTION extends ACTION>(action: CONCRETE_ACTION): CONCRETE_ACTION
  // handle union of all possible actions (base action, thunks and promises
  // actions) to please typescript
  <RETURN, CONCRETE_ACTION extends ACTION, EXTRA>(
    action: CONCRETE_ACTION | ThunkAction<RETURN, STATE, ACTION> | PromiseAction<STATE, RETURN, EXTRA>,
  ): CONCRETE_ACTION | RETURN | Promise<RETURN>
}

export interface Dispatcher<STATE, ACTION extends Action> {
  dispatch: Dispatch<STATE, ACTION>
}

export function getDispatch<STATE, ACTION extends Action>(
  atom: jotai.WritableAtom<STATE, [ACTION], void>,
  store: ReturnType<typeof jotai.createStore>,
): Dispatch<STATE, ACTION> {
  return dispatch

  function dispatch<RETURN, EXTRA>(action: PromiseAction<STATE, RETURN, EXTRA>): Promise<RETURN>
  function dispatch<RETURN>(action: ThunkAction<RETURN, STATE, ACTION>): RETURN
  function dispatch<CONCRETE_ACTION extends ACTION>(action: CONCRETE_ACTION): CONCRETE_ACTION
  function dispatch<RETURN, CONCRETE_ACTION extends ACTION, EXTRA>(
    action: CONCRETE_ACTION | ThunkAction<RETURN, STATE, ACTION> | PromiseAction<STATE, RETURN, EXTRA>,
  ): CONCRETE_ACTION | RETURN | Promise<RETURN> {
    if ('promise' in action) {
      return dispatchPromise(action)
    } else if (typeof action === 'function') {
      return action(dispatch, () => store.get(atom))
    } else {
      store.set(atom, action)
      return action
    }
  }

  async function dispatchPromise<RETURN, EXTRA>(action: PromiseAction<STATE, RETURN, EXTRA>): Promise<RETURN> {
    if (!validatePromiseAction(action)) {
      throw new Error(`action ${JSON.stringify(action)} is not a valid promise action`)
    }

    const {
      types: [REQUEST_TYPE, SUCCESS_TYPE, FAILURE_TYPE],
      promise,
      ...extra
    } = action

    dispatch({ ...extra, type: REQUEST_TYPE } as any)

    const result = await promise(dispatch, store.get(atom))

    if (result instanceof Either) {
      result.caseOf<void>({
        left: (failure) => {
          dispatch({ ...extra, failure, type: FAILURE_TYPE } as any)
        },
        right: (response) => {
          dispatch({ ...extra, success: response, type: SUCCESS_TYPE } as any)
        },
      })
    } else {
      dispatch({ ...extra, success: result, type: SUCCESS_TYPE } as any)
    }
    return result
  }
}

export function useDispatchAtom<STATE, ACTION extends Action>(
  atom: jotai.WritableAtom<STATE, [ACTION], void>,
  options?: Parameters<typeof jotai.useStore>[0],
): Dispatch<STATE, ACTION> {
  const store = jotai.useStore(options)

  return React.useCallback(getDispatch(atom, store), [store, atom])
}

export function useAtomWithReducer<STATE, ACTION extends Action>(
  atom: jotai.WritableAtom<STATE, [ACTION], void>,
  options?: Parameters<typeof jotai.useStore>[0],
): [STATE, Dispatch<STATE, ACTION>] {
  return [jotai.useAtomValue(atom, options), useDispatchAtom(atom, options)]
}

function validatePromiseAction(action: PromiseAction): boolean {
  if (typeof action.promise !== 'function') {
    return false
  }

  if (!Array.isArray(action.types)) {
    return false
  }
  if (action.types.length !== 3) {
    return false
  }
  const areTypesAllStrings = action.types.every((type) => typeof type === 'string')
  if (!areTypesAllStrings) {
    return false
  }

  return true
}
