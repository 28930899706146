import { Api, I18n, Lang } from '@owl-nest/api-client/latest'
import * as UFE from './utils/UFE'

/**
 * First try user lang, then param fallbackLang and fallback to first key found with defined value (and non empty if string)
 */
export function get<TYPE>(i18n: I18n<TYPE>): TYPE | undefined
export function get<TYPE>(i18n: I18n<TYPE>, fallbackLang: Lang): TYPE | undefined
export function get<TYPE>(i18n: I18n<TYPE>, fallbackLang: Lang | undefined, fallback: TYPE): TYPE
export function get<TYPE>(i18n: I18n<TYPE>, fallbackLang?: Lang | undefined, fallback?: TYPE): TYPE | undefined {
  const userLang = UFE.USER_LOCALE

  return getWithLang(i18n, userLang, fallbackLang, fallback)
}

export function getWithLang<TYPE>(
  i18n: I18n<TYPE>,
  userLang: Lang,
  fallbackLang?: Lang | undefined,
  fallback?: TYPE,
): TYPE | undefined {
  return deepGet(i18n, (t) => t, userLang, fallbackLang, fallback)
}

export function deepGet<TYPE, VALUE>(
  i18n: I18n<TYPE>,
  getter: (type: TYPE) => VALUE | undefined,
  userLang: Lang,
  fallbackLang?: Lang | undefined,
  fallback?: VALUE,
): VALUE | undefined {
  const userLangTraduction = i18n[userLang]
  if (userLangTraduction && getter(userLangTraduction)) {
    return getter(userLangTraduction)
  }

  const esTraduction = i18n['es']
  if (userLang === 'ca' && esTraduction && getter(esTraduction)) {
    return getter(esTraduction)
  }

  const defaultLocaleTraduction = i18n[UFE.DEFAULT_LOCALE]
  if (defaultLocaleTraduction && getter(defaultLocaleTraduction)) {
    return getter(defaultLocaleTraduction)
  }

  if (fallbackLang) {
    const fallbackTraduction = i18n[fallbackLang]
    if (fallbackTraduction && getter(fallbackTraduction)) {
      return getter(fallbackTraduction)
    }
  }

  for (const traduction of Object.values(i18n)) {
    if (traduction && getter(traduction)) {
      const value = getter(traduction)
      if (typeof value === 'string') {
        if (value !== '') {
          return value
        }
      } else {
        return value
      }
    }
  }

  return fallback
}

export function resetData<TYPE>(i18n: I18n<TYPE>, def: TYPE): I18n<TYPE> {
  return Object.keys(i18n).reduce((i18n, lang) => {
    i18n[lang as Lang] = def
    return i18n
  }, {} as I18n<TYPE>)
}

export function addLang<TYPE>(i18n: I18n<TYPE>, lang: Lang, value: TYPE): I18n<TYPE> {
  return {
    ...i18n,
    [lang]: value,
  }
}

export function removeLang<TYPE>(i18n: I18n<TYPE>, lang: Lang): I18n<TYPE> {
  return {
    ...i18n,
    [lang]: null,
  }
}

export function isEmpty<TYPE>(i18n: I18n<TYPE>): boolean {
  return Object.values(i18n).every((entry) => !entry)
}

export function getLangs<TYPE>(i18n: I18n<TYPE>, isEmpty: (type: TYPE) => boolean = (type) => !type): Lang[] {
  const langs: Lang[] = []

  const keys = Object.keys(i18n) as Lang[]

  for (const lang of keys) {
    const entry = i18n[lang]
    if (entry && !isEmpty(entry)) {
      langs.push(lang)
    }
  }

  return langs
}
