import * as React from 'react'
import styled from 'styled-components'

import { Icon } from '../Icon'
import { SpecificIconProps } from '../types'
import { lightweight, standalone, SymbolProps } from '../../spritesheetUtils'

const ID = 'youtube-third-party-glyph-icon'

export function YoutubeSymbol({ id = ID, ...props }: SymbolProps): React.ReactElement<SymbolProps> {
  return (
    <symbol {...props} id={id} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        d="m21.34 15.166-8.642 4.576-.003-7.046v-2.142l3.898 2.08 4.747 2.532ZM31.678 8.96s-.311-2.256-1.27-3.248c-1.217-1.3-2.58-1.308-3.204-1.384C22.723 4 16.008 4 16.008 4h-.015s-6.719 0-11.195.33c-.626.075-1.987.083-3.207 1.384C.634 6.707.32 8.962.32 8.962S0 11.607 0 14.254v2.48c0 2.648.321 5.295.321 5.295s.313 2.254 1.27 3.245c1.22 1.303 2.82 1.261 3.53 1.396C7.68 26.922 16 27 16 27s6.723-.012 11.204-.34c.623-.077 1.987-.083 3.205-1.387.958-.992 1.27-3.245 1.27-3.245S32 19.382 32 16.734v-2.48c0-2.647-.322-5.293-.322-5.293Z"
        clipRule="evenodd"
      />
    </symbol>
  )
}

const YoutubeStandaloneContent = standalone(YoutubeSymbol, ID)

export const Youtube = styled(function YoutubeStandaloneComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-youtube">
      <YoutubeStandaloneContent />
    </Icon>
  )
})``

export const YoutubeLightweight = styled(function YoutubeComponent(props: SpecificIconProps) {
  return (
    <Icon {...props} name="social-youtube">
      {lightweight(ID)}
    </Icon>
  )
})``
