import * as React from 'react'

import { VideoElementProps } from './VideoElement'

declare const FB: any

export const FacebookVideo = React.forwardRef<HTMLDivElement, VideoElementProps>(function FacebookVideo(
  { className, autoplay, onReady },
  ref,
) {
  const videoRef = React.useRef<HTMLDivElement>(null)

  const setRef = React.useCallback(function setRef(node: HTMLInputElement) {
    const anyVideoRef = videoRef as any
    anyVideoRef.current = node
    if (ref) {
      const anyRef = ref as any
      if (typeof ref === 'function') {
        ref(node)
      } else {
        anyRef.current = node
      }
    }
  }, [])

  React.useEffect(() => {
    if (!FB) {
      throw Error('Facebook SDK is not loaded')
    }

    const id = String(Math.random())
    if (videoRef.current !== null) {
      const fbVideo = videoRef.current.querySelector('.fb-video')
      if (fbVideo !== null) {
        fbVideo.setAttribute('data-autoplay', 'true')
        fbVideo.setAttribute('id', id)
      }

      FB.XFBML.parse(videoRef.current)
      FB.Event.subscribe('xfbml.ready', (msg: any) => {
        if (msg.type === 'video' && msg.id === id) {
          onReady && onReady()
          if (autoplay) {
            msg.instance.mute()
          } else {
            msg.instance.unmute()
          }
        }
      })
    }
  }, [])

  return <div className={className} ref={setRef} />
})
