import { ApiResponse } from '@owl-nest/api-client'
import { AuthenticatedUser, UpdateUser } from '@owl-nest/api-client/latest'

import * as auth from '@owl-nest/auth'
import { LoginUser, SignupUser } from '@owl-nest/auth/src/auth'
import { PromiseAction, ThunkAction } from '@owl-nest/hooks'
import { Either } from '@owl-nest/monad'

import { TYPE, UserQueryResult, AuthAction, AuthState, Forget, Sync } from './types'

export function initialize(
  /**
   * @deprecated when not using redux, this parameter has no effect (no
   * redirection will ever occur)
   */ doNotRedirectToSignIn?: boolean,
  extraFields: string[] = [],
): PromiseAction<AuthState> {
  return {
    types: [TYPE.INITIALIZE_START, TYPE.INITIALIZE_SUCCESS, TYPE.INITIALIZE_FAILURE],
    promise: () => {
      return queryUser(doNotRedirectToSignIn, extraFields)
    },
  }
}

export function refresh(
  /**
   * @deprecated when not using redux, this parameter has no effect (no
   * redirection will ever occur)
   */ doNotRedirectToSignIn?: boolean,
  extraFields: string[] = [],
): PromiseAction<AuthState> {
  return {
    types: [TYPE.REFRESH_START, TYPE.REFRESH_SUCCESS, TYPE.REFRESH_FAILURE],
    // TODO: add message dispatch if user is logged out during refresh
    promise: () => queryUser(doNotRedirectToSignIn, extraFields),
  }
}

async function queryUser<EXTRA_FIELDS extends string = string>(
  /**
   * @deprecated when not using redux, this parameter has no effect (no
   * redirection will ever occur)
   */ doNotRedirectToSignIn?: boolean,
  extraFields: EXTRA_FIELDS[] = [],
): Promise<Either<unknown, UserQueryResult<EXTRA_FIELDS>>> {
  const response = await auth.currentUser(doNotRedirectToSignIn, extraFields)
  return response
    .next<UserQueryResult<EXTRA_FIELDS>>((success) => {
      return {
        type: success.is_guest ? 'loggedin-as-guest' : 'loggedin',
        user: success,
        extraFields: success.extraFields,
      }
    })
    .exceptAsync(async (_) => {
      const response = await auth.userKnown()
      if (response !== undefined) {
        return {
          type: 'known',
          user: response,
        }
      }
      return { type: 'unknown' }
    })
}

export function login<EXTRA_FIELDS extends string = string>(
  login: LoginUser,
  /**
   * @deprecated when not using redux, this parameter has no effect (no
   * redirection will ever occur)
   */ doNotRedirectToSignIn?: boolean,
  extraFields: EXTRA_FIELDS[] = [],
): PromiseAction<AuthState, ApiResponse<AuthenticatedUser<EXTRA_FIELDS>>> {
  return {
    types: [TYPE.LOGIN_START, TYPE.LOGIN_SUCCESS, TYPE.LOGIN_FAILURE],
    promise: async (dispatch) => {
      return auth.login(login, doNotRedirectToSignIn, extraFields)
    },
  }
}

export function guestLogin<EXTRA_FIELDS extends string = string>(
  email: string,
  /**
   * @deprecated when not using redux, this parameter has no effect (no
   * redirection will ever occur)
   */ doNotRedirectToSignIn?: boolean,
  extraFields: EXTRA_FIELDS[] = [],
): PromiseAction<AuthState, ApiResponse<AuthenticatedUser<EXTRA_FIELDS>>> {
  return {
    types: [TYPE.GUEST_LOGIN_START, TYPE.GUEST_LOGIN_SUCCESS, TYPE.GUEST_LOGIN_FAILURE],
    promise: async () => {
      return auth.guestLogin(email, doNotRedirectToSignIn, extraFields)
    },
  }
}

export function identify<EXTRA_FIELDS extends string = ''>(
  type: 'email' | 'username',
  identifier: string,
  extra_fields: EXTRA_FIELDS[],
  allowGuest?: boolean,
  onGuestLoggedIn?: (user: AuthenticatedUser<EXTRA_FIELDS>) => void,
): ThunkAction<Promise<ApiResponse<undefined>>, AuthState, AuthAction> {
  return async (dispatch) => {
    dispatch({ type: TYPE.IDENTIFY_START })
    const exists = await auth.userExists(type, identifier)

    return exists.caseOf<ApiResponse<undefined>>({
      left: (error) => {
        dispatch({ type: TYPE.IDENTIFY_FAILURE, failure: error })
        return Either.left(error)
      },
      right: (service) => {
        if (service !== undefined) {
          dispatch({ type: TYPE.IDENTIFY_EXISTING, identifier: { type, value: identifier }, service })
        } else {
          if (allowGuest && type === 'email') {
            dispatch(guestLogin(identifier, true, extra_fields)).then((response) => {
              response.next((user) => {
                onGuestLoggedIn && onGuestLoggedIn(user)
              })
            })
          } else {
            dispatch({ type: TYPE.IDENTIFY_NEW, identifier: { type, value: identifier } })
          }
        }
        return Either.right(undefined)
      },
    })
  }
}

export function signup(signup: SignupUser): PromiseAction<AuthState, ApiResponse<AuthenticatedUser>> {
  return {
    types: [TYPE.SIGNUP_START, TYPE.SIGNUP_SUCCESS, TYPE.SIGNUP_FAILURE],
    promise: async () => {
      return auth.signup(signup)
    },
  }
}

export function logout(): PromiseAction<AuthState> {
  return {
    types: [TYPE.LOGOUT_START, TYPE.LOGOUT_SUCCESS, TYPE.LOGOUT_FAILURE],
    promise: async () => {
      return auth.logout()
    },
  }
}

export function update(userId: number, user: UpdateUser): PromiseAction<AuthState> {
  return {
    types: [TYPE.UPDATE_REQUEST, TYPE.UPDATE_SUCCESS, TYPE.UPDATE_FAILURE],
    promise: async () => {
      return auth.update(userId, user)
    },
  }
}

export function forget(): Forget {
  return { type: TYPE.FORGET }
}

export function sync(state: AuthState): Sync {
  return { type: TYPE.SYNC, state }
}
